var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var StatusConfigService = /** @class */ (function (_super) {
    __extends(StatusConfigService, _super);
    function StatusConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 新增配置
     */
    StatusConfigService.prototype.addConfig = function (data, loading) {
        return this.netService.send({
            server: manageService.statusConfigController.addConfig,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改配置
     */
    StatusConfigService.prototype.modifyConfig = function (data, loading) {
        return this.netService.send({
            server: manageService.statusConfigController.modifyConfig,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除配置
     */
    StatusConfigService.prototype.deleteConfig = function (id, loading) {
        return this.netService.send({
            server: manageService.statusConfigController.deleteConfig,
            data: {
                id: id
            },
            loading: loading
        });
    };
    /**
     * 查询全部配置
     */
    StatusConfigService.prototype.findAllConfig = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.statusConfigController.findAllConfig,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 不分页查询全部配置
     */
    StatusConfigService.prototype.findAllConfigNoPage = function (type, loading) {
        return this.netService.send({
            server: manageService.statusConfigController.findAllConfigNoPage,
            data: {
                type: type
            },
            loading: loading
        });
    };
    /**
     * 获取手工状态
     */
    StatusConfigService.prototype.getStatusConfig = function (principalId, loading) {
        console.log("dfsdfsdfsaaaaaadfdsfds", principalId);
        return this.netService.send({
            server: manageService.statusConfigController.getStatusConfig,
            data: {
                principalId: principalId
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], StatusConfigService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "addConfig", null);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "modifyConfig", null);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "deleteConfig", null);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "findAllConfig", null);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "findAllConfigNoPage", null);
    __decorate([
        Debounce()
    ], StatusConfigService.prototype, "getStatusConfig", null);
    return StatusConfigService;
}(Service));
export { StatusConfigService };
