import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'taskBoxController';
export default {
    /**
     * 获取任务消息列表
     */
    queryTaskBox: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryTaskBox',
        type: requestType.Get
    },
    /**
    * 根据用户id获取任务列表
    */
    sendTaskByUserId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'sendTaskByUserId',
        type: requestType.Get
    },
    /**
     * 删除任务
     */
    deletedTaskBox: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deletedTaskBox',
        type: requestType.Delete
    }
};
