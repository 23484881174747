var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseApplyOperateService } from "~/services/domain-service/case-apply-operate.service";
import { ProcessConfigService } from "~/services/management-service/process-config.service";
import { Emit, Prop } from "vue-property-decorator";
import { OrganizationService } from "~/services/management-service/organization.service";
var collectionManageModule = namespace("collection-manage");
var AppForAudit = /** @class */ (function (_super) {
    __extends(AppForAudit, _super);
    function AppForAudit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            caseId: _this.caseId,
            applyType: "CHECK_OVERDUE_AMOUNT_APPLY",
            applyRemark: "",
            usernames: ''
        };
        _this.isNext = false;
        _this.userData = [];
        _this.rules = {
            applyRemark: [
                { required: true, message: "请输入申请说明", trigger: "blur" }
            ],
            usernames: [{ required: true, message: "请输入下级处理人员", trigger: "change" }]
        };
        return _this;
    }
    AppForAudit.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    AppForAudit.prototype.refreshData = function () {
        var _this = this;
        this.processConfigService.findConfigFlowByType(this.model.applyType, this.principalInfo.id)
            .subscribe(function (data) {
            if (data.applyState === 'PERSONAL_APPLY') {
                _this.isNext = true;
            }
            else {
                _this.isNext = false;
            }
        });
        this.organizationService.findChooseOperatorList()
            .subscribe(function (data) {
            _this.userData = data.map(function (x) {
                return { value: x.showName, usernames: x.username };
            });
        });
    };
    AppForAudit.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.userData.filter(this.createFilter(queryString)) : this.userData;
        cb(results);
    };
    AppForAudit.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    };
    /**
     * 查账申请提交
     */
    AppForAudit.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            if (_this.isNext) {
                if (!_this.userData.find(function (x) { return x.value === _this.model.usernames; })) {
                    _this.$message.warning('请选择下级处理人员');
                    return;
                }
            }
            _this.loading.state = true;
            var model = {
                caseId: _this.model.caseId,
                applyType: _this.model.applyType,
                applyRemark: _this.model.applyRemark,
                usernames: _this.isNext ? [_this.userData.find(function (x) { return x.value === _this.model.usernames; }).usernames] : []
            };
            _this.caseApplyOperateService.caseApplyOperated(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("查账申请成功");
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], AppForAudit.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Dependencies(ProcessConfigService)
    ], AppForAudit.prototype, "processConfigService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], AppForAudit.prototype, "organizationService", void 0);
    __decorate([
        Prop({})
    ], AppForAudit.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], AppForAudit.prototype, "principalInfo", void 0);
    __decorate([
        Emit("close")
    ], AppForAudit.prototype, "close", null);
    AppForAudit = __decorate([
        Component({
            components: {}
        })
    ], AppForAudit);
    return AppForAudit;
}(Vue));
export default AppForAudit;
