var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileUpload from '~/components/common/file-upload.vue';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { CaseApplyOperateService } from '~/services/domain-service/case-apply-operate.service';
import { Emit, Prop } from 'vue-property-decorator';
import { ProcessConfigService } from "~/services/management-service/process-config.service";
import { OrganizationService } from "~/services/management-service/organization.service";
var collectionManageModule = namespace("collection-manage");
var SupplementPayment = /** @class */ (function (_super) {
    __extends(SupplementPayment, _super);
    function SupplementPayment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.isNext = false;
        _this.userData = [];
        _this.model = {
            applyAmount: '',
            caseId: _this.caseId,
            applyType: 'SUPPLEMENT_APPLY',
            fileId: '',
            applyRemark: '',
            usernames: ''
        };
        _this.rules = {
            applyAmount: [{ required: true, message: '请输入补款金额', trigger: 'blur' }, { validator: _this.$validator.money, trigger: 'blur' }],
            usernames: [{ required: true, message: "请输入下级处理人员", trigger: "change" }]
        };
        return _this;
    }
    SupplementPayment.prototype.close = function () {
        var form = this.$refs['form'];
        this.model.fileId = '';
        form.resetFields();
        var upload = this.$refs['upload_import'];
        upload.reset();
    };
    SupplementPayment.prototype.refreshData = function () {
        var _this = this;
        this.processConfigService.findConfigFlowByType(this.model.applyType, this.principalInfo.id)
            .subscribe(function (data) {
            if (data.applyState === 'PERSONAL_APPLY') {
                _this.isNext = true;
            }
            else {
                _this.isNext = false;
            }
        });
        this.organizationService.findChooseOperatorList()
            .subscribe(function (data) {
            _this.userData = data.map(function (x) {
                return { value: x.showName, usernames: x.username };
            });
        });
    };
    SupplementPayment.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.userData.filter(this.createFilter(queryString)) : this.userData;
        cb(results);
    };
    SupplementPayment.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    };
    /**
     * 补款申请提交
     */
    SupplementPayment.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            if (_this.isNext) {
                if (!_this.userData.find(function (x) { return x.value === _this.model.usernames; })) {
                    _this.$message.warning('请选择下级处理人员');
                    return;
                }
            }
            _this.loading.state = true;
            var model = {
                caseId: _this.model.caseId,
                applyType: _this.model.applyType,
                applyAmount: _this.model.applyAmount,
                fileId: _this.model.fileId,
                applyRemark: _this.model.applyRemark,
                usernames: _this.isNext ? [_this.userData.find(function (x) { return x.value === _this.model.usernames; }).usernames] : []
            };
            _this.caseApplyOperateService
                .caseApplyOperated(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('补款申请成功');
                _this.close();
            });
        });
    };
    /**
     * 文件上传成功后回调函数
     * @param fileId
     * @param data
     */
    SupplementPayment.prototype.onUploadSuccess = function (id, data) {
        if (data) {
            this.model.fileId = data.id;
            this.model.fileName = data.originalName;
        }
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], SupplementPayment.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Dependencies(ProcessConfigService)
    ], SupplementPayment.prototype, "processConfigService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], SupplementPayment.prototype, "organizationService", void 0);
    __decorate([
        Prop({})
    ], SupplementPayment.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], SupplementPayment.prototype, "principalInfo", void 0);
    __decorate([
        Emit('close')
    ], SupplementPayment.prototype, "close", null);
    SupplementPayment = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], SupplementPayment);
    return SupplementPayment;
}(Vue));
export default SupplementPayment;
