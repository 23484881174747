import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'strategyDistributeController';
export default {
    /**
     * 手动执行策略分案
     */
    manualExecuteStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'manualExecuteStrategy',
        type: requestType.Get
    }
};
