import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'caseWaringInfoController';
export default {
    /**
     * 个案管理添加警告信息
     */
    insertCaseWarningInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insertCaseWarningInfo ',
        type: requestType.Post
    },
    /**
     * 个案管理添加警告信息
     */
    queryCaseWarningInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryCaseWarningInfo',
        type: requestType.Get
    },
    /**
     * 查询警告信息列表
     */
    queryCaseWarningInfoList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryCaseWarningInfoList',
        type: requestType.Get
    },
    /**
     * 修改警告信息
     */
    editCaseWarningInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'editCaseWarningInfo',
        type: requestType.Post
    },
    /**
     * 删除警告信息
     */
    deleteCaseWarningInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteCaseWarningInfo',
        type: requestType.Delete
    },
};
