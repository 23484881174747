var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var SpecialTransferDataService = /** @class */ (function () {
    function SpecialTransferDataService() {
    }
    /**
     * 特调资料查询
     */
    SpecialTransferDataService.prototype.search = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.search,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 特调资料创建
     */
    SpecialTransferDataService.prototype.insert = function (data, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.insert,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除特调资料
     */
    SpecialTransferDataService.prototype.delete = function (data, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.delete,
            data: {
                id: data.id
            },
            loading: loading
        });
    };
    /**
     * 修改特调资料
     */
    SpecialTransferDataService.prototype.update = function (data, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.update,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改计生凭证
     */
    SpecialTransferDataService.prototype.addCredential = function (credentialSet, id, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.addCredential,
            data: {
                credentialSet: credentialSet,
                id: id
            },
            loading: loading
        });
    };
    /**
     * 根据用户证件号获取图片明细信息
     */
    SpecialTransferDataService.prototype.searchDetails = function (parentId, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.searchDetails,
            data: {
                parentId: parentId,
            },
            loading: loading
        });
    };
    /**
     * 按导入批次删除户籍资料
     */
    SpecialTransferDataService.prototype.deleteByBatch = function (batchNumber, loading) {
        return this.netService.send({
            server: repairService.specialTransferDataController.deleteByBatch,
            data: {
                batchNumber: batchNumber
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], SpecialTransferDataService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], SpecialTransferDataService.prototype, "search", null);
    return SpecialTransferDataService;
}());
export { SpecialTransferDataService };
