var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import TaskBox from "~/components/workspace/task/task-box.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
var workspaceModule = namespace("workspace");
var WorkTask = /** @class */ (function (_super) {
    __extends(WorkTask, _super);
    function WorkTask() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = {
            taskBox: false
        };
        return _this;
    }
    WorkTask.prototype.mounted = function () {
        var _this = this;
        this.$reminder.addTaskListener().subscribe(function (data) {
            if (data &&
                data.taskBoxStatus === "FINISHED") {
                _this.taskList.push(+new Date());
            }
        });
    };
    __decorate([
        workspaceModule.Action
    ], WorkTask.prototype, "updateUnReadCount", void 0);
    __decorate([
        workspaceModule.State
    ], WorkTask.prototype, "unReadCount", void 0);
    __decorate([
        workspaceModule.State
    ], WorkTask.prototype, "taskList", void 0);
    __decorate([
        workspaceModule.Mutation
    ], WorkTask.prototype, "updateTaskList", void 0);
    WorkTask = __decorate([
        Component({
            components: {
                TaskBox: TaskBox,
                SvgIcon: SvgIcon
            }
        })
    ], WorkTask);
    return WorkTask;
}(Vue));
export default WorkTask;
