var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { SpecialTransferDataService } from "~/services/repair-service/special-transfer-data.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import RepairFileUpload from "~/components/common/repair-file-upload.vue";
var AddSpecialData = /** @class */ (function (_super) {
    __extends(AddSpecialData, _super);
    function AddSpecialData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kosekiModel = {
            name: '',
            idNo: '',
            bank: '',
            applyTransferArea: '',
            remark: '',
            type: ''
        };
        _this.loading = { state: false };
        _this.rulesAdd = {
            name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
            idNo: [{ required: true, message: '请输入证件号', trigger: 'blur' }]
        };
        _this.personalInformation = [];
        return _this;
    }
    AddSpecialData.prototype.success = function () { this.close(); };
    AddSpecialData.prototype.close = function () { this.reset(); };
    AddSpecialData.prototype.init = function () {
        this.personalInformation = [];
        this.refresh();
    };
    AddSpecialData.prototype.refresh = function () {
        this.personalInformation.push({ fileUrl: [], relation: '', certificatesType: '', remark: '', relationIdNo: '' });
    };
    AddSpecialData.prototype.AddDetail = function () {
        var _this = this;
        var addform = this.$refs["add-form"];
        addform.validate(function (valid) {
            if (!valid)
                return;
            var personalForm = _this.personalInformation.map(function (x) {
                return {
                    fileName: x.fileUrl[0] ? x.fileUrl[0].response.fileName : '',
                    fileUrl: x.fileUrl[0] ? x.fileUrl[0].response.url : '',
                    relation: x.relation,
                    certificatesType: x.certificatesType,
                    remark: x.remark,
                    relationIdNo: x.relationIdNo,
                    idNo: _this.kosekiModel.idNo,
                };
            });
            var model = {
                name: _this.kosekiModel.name,
                idNo: _this.kosekiModel.idNo,
                bank: _this.kosekiModel.bank,
                type: _this.kosekiModel.type,
                applyTransferArea: _this.kosekiModel.applyTransferArea,
                remark: _this.kosekiModel.remark,
                credentialSet: personalForm
            };
            _this.loading.state = true;
            _this.specialTransferDataService.insert(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('添加成功!');
                _this.success();
            });
        });
    };
    AddSpecialData.prototype.reset = function () {
        var add = this.$refs['add-form'];
        add.resetFields();
        this.personalInformation = [];
    };
    AddSpecialData.prototype.addInformation = function () {
        this.personalInformation.push({ fileUrl: [], relation: '', certificatesType: '', remark: '', relationIdNo: '' });
    };
    AddSpecialData.prototype.deleteInformation = function (index) {
        this.personalInformation.splice(index, 1);
    };
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], AddSpecialData.prototype, "specialTransferDataService", void 0);
    __decorate([
        Emit("success")
    ], AddSpecialData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], AddSpecialData.prototype, "close", null);
    __decorate([
        Watch("kosekiModel.idNo")
    ], AddSpecialData.prototype, "init", null);
    AddSpecialData = __decorate([
        Component({
            components: { SvgIcon: SvgIcon, RepairFileUpload: RepairFileUpload }
        })
    ], AddSpecialData);
    return AddSpecialData;
}(Vue));
export default AddSpecialData;
