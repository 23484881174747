var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Dependencies } from '~/core/decorator';
import { State, namespace } from 'vuex-class';
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
var collectionManageModule = namespace('collection-manage');
var AddNote = /** @class */ (function (_super) {
    __extends(AddNote, _super);
    function AddNote() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            caseId: '',
            commentType: 'POSTIL',
            commentContent: '',
            operatorName: '',
            reminderTime: '',
        };
        _this.loading = {
            state: false
        };
        _this.rules = {
            reminderTime: [
                { required: true, message: '请选择批注时间', trigger: 'change' },
            ],
            commentContent: [
                { required: true, message: '请输入批注内容', trigger: 'blur' },
            ],
        };
        return _this;
    }
    AddNote.prototype.close = function () {
        var form = this.$refs['form'];
        form.resetFields();
    };
    /**
     * 添加批注提交
     */
    AddNote.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.model.caseId = _this.rowData.id;
            var model = Object.assign({}, _this.model);
            _this.loading.state = true;
            _this.caseFollowRecordService.addTag(model, _this.loading).subscribe(function (data) {
                _this.$message.success('添加批注成功');
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], AddNote.prototype, "caseFollowRecordService", void 0);
    __decorate([
        State
    ], AddNote.prototype, "userData", void 0);
    __decorate([
        collectionManageModule.State
    ], AddNote.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], AddNote.prototype, "currentComment", void 0);
    __decorate([
        Prop()
    ], AddNote.prototype, "rowData", void 0);
    __decorate([
        Emit('close')
    ], AddNote.prototype, "close", null);
    AddNote = __decorate([
        Component({
            components: {},
        })
    ], AddNote);
    return AddNote;
}(Vue));
export default AddNote;
