var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Emit, Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
var AllCaseList = /** @class */ (function (_super) {
    __extends(AllCaseList, _super);
    function AllCaseList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.switchCaseType = "1";
        _this.queryParamsModel = {
            // 委托方Id
            principalId: "",
            // 批次号
            batchNumber: "",
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.dialog = {
            detail: false
        };
        _this.currentCollection = {};
        _this.loading = {
            state: false
        };
        return _this;
    }
    AllCaseList.prototype.principalIdChange = function (val) {
        if (!val)
            return;
        this.handleResetForm();
        this.queryParamsModel.principalId = val;
        this.refreshData();
    };
    AllCaseList.prototype.onClickBatchNumber = function (currentCollection, groupType) { };
    /**
     * keep-alive生命周期钩子函数
     */
    AllCaseList.prototype.activated = function () {
        // 加载数据
        // this.refreshData();
    };
    AllCaseList.prototype.handleResetForm = function () {
        this.queryParamsModel.batchNumber = '';
    };
    AllCaseList.prototype.refreshData = function () {
        var _this = this;
        this.caseCollectorService.findAllBatchCase(this.queryParamsModel.batchNumber, this.queryParamsModel.principalId, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(CaseCollectorService)
    ], AllCaseList.prototype, "caseCollectorService", void 0);
    __decorate([
        Prop({ required: true })
    ], AllCaseList.prototype, "principalId", void 0);
    __decorate([
        Watch("principalId", { immediate: true })
    ], AllCaseList.prototype, "principalIdChange", null);
    __decorate([
        Emit("onClickBatchNumber")
    ], AllCaseList.prototype, "onClickBatchNumber", null);
    AllCaseList = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], AllCaseList);
    return AllCaseList;
}(Vue));
export default AllCaseList;
