import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "statusConfigController";
export default {
    /**
     * 新增配置
     */
    addConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addConfig',
        type: requestType.Post
    },
    /**
     * 修改配置
     */
    modifyConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyConfig',
        type: requestType.Put
    },
    /**
     * 删除配置
     */
    deleteConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteConfig',
        type: requestType.Delete
    },
    /**
     * 查询全部配置
     */
    findAllConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllConfig',
        type: requestType.Get
    },
    /**
     * 不分页查询全部配置
     */
    findAllConfigNoPage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllConfigNoPage',
        type: requestType.Get
    },
    /**
     * 获取手工状态
     */
    getStatusConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getStatusConfig',
        type: requestType.Get
    }
};
