var DashboardRecruiter = function () { return import('~/pages/dashboard/dashboard-recruiter.vue'); };
var DashboardAdmin = function () { return import('~/pages/dashboard/dashboard-admin.vue'); };
var DashboardBulletin = function () { return import('~/pages/dashboard/dashboard-bulletin.vue'); };
export default [
    {
        path: '/dashboard/dashboard-recruiter',
        name: 'dashboard-recruiter',
        component: DashboardRecruiter
    },
    {
        path: '/dashboard/dashboard-admin',
        name: 'dashboard-admin',
        component: DashboardAdmin
    },
    {
        path: '/dashboard/dashboard-bulletin',
        name: 'dashboard-bulletin',
        component: DashboardBulletin
    }
];
