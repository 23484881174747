var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { RoleService } from "~/services/manage-service/role.service";
import { Emit } from "vue-property-decorator";
var AddRole = /** @class */ (function (_super) {
    __extends(AddRole, _super);
    function AddRole() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.addModel = {
            name: "",
            status: "",
            description: "",
        };
        _this.submitLoading = false;
        _this.rules = {
            name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
            status: [{ required: true, message: "请选择角色状态", trigger: "change" }]
        };
        return _this;
    }
    AddRole.prototype.refreshList = function () { };
    AddRole.prototype.close = function () { };
    AddRole.prototype.reset = function () {
        var addForm = this.$refs["add-form"];
        addForm.resetFields();
    };
    AddRole.prototype.commit = function () {
        var _this = this;
        var addForm = this.$refs["add-form"];
        addForm.validate(function (valid) {
            if (!valid)
                return false;
            _this.loading.state = true;
            _this.submitLoading = true;
            _this.roleService.addRole(_this.addModel, _this.loading).subscribe(function (data) {
                _this.$message.success("新增成功!");
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    AddRole.prototype.mounted = function () { };
    __decorate([
        Dependencies(RoleService)
    ], AddRole.prototype, "roleService", void 0);
    __decorate([
        Emit("refreshList")
    ], AddRole.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], AddRole.prototype, "close", null);
    AddRole = __decorate([
        Component({
            components: {}
        })
    ], AddRole);
    return AddRole;
}(Vue));
export default AddRole;
