import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "caseInfoController";
export default {
    /**
     * 部门待分配案件
     */
    queryOrganizationDistributeCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryOrganizationDistributeCases",
        type: requestType.Get,
    },
    /**
     * 公共案件池
     */
    queryPublicCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryPublicCases",
        type: requestType.Get,
    },
    /**
     * 停调案件池
     */
    queryStopCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryStopCases",
        type: requestType.Get,
    },
    /**
     * 在调案件池
     */
    queryCollectingCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryCollectingCases",
        type: requestType.Get,
    },
    /**
     * 在调案件池
     */
    querySettleCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "querySettleCases",
        type: requestType.Get,
    },
    /**
     * 案件停调
     */
    stopCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "stopCases",
        type: requestType.Post,
    },
    /**
     * 案件复调
     */
    backCollectCasePool: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "backCollectCasePool",
        type: requestType.Post,
    },
    /**
     * 案件退案
     */
    changeRetireCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changeRetireCases",
        type: requestType.Post,
    },
    /**
     * 案件批次号查询
     */
    getBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBatchNumber",
        type: requestType.Get,
    },
    /**
     * 刷新退案时间
     */
    updateCaseEndDate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateCaseEndDate",
        type: requestType.Post,
    },
    /**
     * 查询客户信息
     */
    getPersonalById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPersonalById",
        type: requestType.Get,
    },
    /**
     * 更新案件信息
     */
    updateCaseMessage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateCaseMessage",
        type: requestType.Post,
    },
    /**
     * 查询打印信息
     */
    getPrintMessage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPrintMessage",
        type: requestType.Post,
    },
    /**
     * 快捷菜单查询案件信息
     */
    caseCollectionStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseCollectionStatus",
        type: requestType.Get,
    },
    /**
     * 根据客户信息查询共债案件批次
     */
    searchDebtBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtBatch",
        type: requestType.Post,
    },
    /**
     * 根据客户信息查询共债案件卡号
     */
    searchDebtCardInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtCardInfo",
        type: requestType.Get,
    },
    /**
     * 根据客户信息查询公债案件的数量
     */
    searchDebtCaseCount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchDebtCaseCount",
        type: requestType.Get,
    },
    /**
     * 更改调收方式
     */
    changeCaseType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changeCaseType",
        type: requestType.Post,
    },
    /**
     * 流转待分配查询
     */
    getAllCirculationCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllCirculationCase",
        type: requestType.Get,
    },
    /**
     * 检查案件是否存在协调申请和协调案件
     */
    checkCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "checkCase",
        type: requestType.Post,
    },
};
