var unit = 25;
var HelperService = /** @class */ (function () {
    function HelperService() {
    }
    HelperService.getColumnWidth = function (count) {
        return count * unit;
    };
    return HelperService;
}());
export { HelperService };
