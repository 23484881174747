var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import { UserService } from "~/services/manage-service/user.service";
var WorkPassword = /** @class */ (function (_super) {
    __extends(WorkPassword, _super);
    function WorkPassword() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.percentage = 0;
        _this.passwordModel = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        };
        /**
         * 验证密码
         */
        _this.validatePassword = function (rule, value, callback) {
            var pwd = _this.passwordModel.newPassword;
            var repwd = _this.passwordModel.confirmPassword;
            if (pwd !== "" && repwd !== "" && pwd !== repwd) {
                callback(new Error("请确认两次输入密码是否一致"));
            }
            else {
                callback();
            }
        };
        // 数据校验规则
        _this.rules = {
            oldPassword: [
                { required: true, message: "请输入原密码", trigger: "blur" },
                {
                    min: 6,
                    max: 64,
                    message: "密码长度不能小于6位大于64位",
                    trigger: "blur"
                }
            ],
            newPassword: [
                { required: true, message: "请输入新密码", trigger: "blur" },
                { validator: _this.validatePassword, trigger: "blur" },
                {
                    min: 6,
                    max: 64,
                    message: "密码长度不能小于6位大于64位",
                    trigger: "blur"
                }
            ],
            confirmPassword: [
                { required: true, message: "请再次输入新密码", trigger: "blur" },
                { validator: _this.validatePassword, trigger: "blur" },
                {
                    min: 6,
                    max: 64,
                    message: "密码长度不能小于6位大于64位",
                    trigger: "blur"
                }
            ]
        };
        return _this;
    }
    WorkPassword.prototype.cancel = function () {
        this.reset();
    };
    WorkPassword.prototype.loginOnce = function () {
    };
    /**
     * 确定
     */
    WorkPassword.prototype.confirm = function () {
        var _this = this;
        this.passwordForm.validate(function (valid) {
            if (!valid) {
                return false;
            }
            _this.userService
                .updatePassword({
                oldPassword: _this.passwordModel.oldPassword,
                newPassword: _this.passwordModel.newPassword,
                confirmPassword: _this.passwordModel.confirmPassword,
            })
                .subscribe(function (data) {
                _this.$message.success("修改密码成功");
                _this.loginOnce();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    WorkPassword.prototype.reset = function () {
        this.passwordForm.resetFields();
    };
    WorkPassword.prototype.mounted = function () {
        this.passwordForm = this.$refs["password-form"];
    };
    /**
     * 密码复杂度验证
     */
    WorkPassword.prototype.passwordChange = function (value) {
        var regUpper = /[A-Z]/;
        var regLower = /[a-z]/;
        var regNum = /[0-9]/;
        var regTeShu = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
        this.percentage = 0;
        // if(value.length<6) return
        if (regLower.test(value)) {
            this.percentage += 25;
        }
        if (regUpper.test(value)) {
            this.percentage += 25;
        }
        if (regNum.test(value)) {
            this.percentage += 25;
        }
        if (regTeShu.test(value)) {
            this.percentage += 25;
        }
    };
    __decorate([
        Dependencies(UserService)
    ], WorkPassword.prototype, "userService", void 0);
    __decorate([
        State
    ], WorkPassword.prototype, "userData", void 0);
    __decorate([
        Emit("cancel")
    ], WorkPassword.prototype, "cancel", null);
    __decorate([
        Emit("loginOnce")
    ], WorkPassword.prototype, "loginOnce", null);
    WorkPassword = __decorate([
        Component({
            components: {}
        })
    ], WorkPassword);
    return WorkPassword;
}(Vue));
export default WorkPassword;
