var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
import FileUpload from "~/components/common/file-upload.vue";
import { State } from "vuex-class";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { dataExcelCaseService } from "~/services/dataimp-service/data-excelcase.service";
import { TemplateDataService } from "~/services/management-service/template-data.service";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
var NewExcelTemplate = /** @class */ (function (_super) {
    __extends(NewExcelTemplate, _super);
    function NewExcelTemplate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.templateModel = {
            dataStartCol: 1,
            dataStartRow: 2,
            items: [],
            name: "",
            principalId: "",
            principalName: "",
            sheetNumber: 1,
            type: "",
            titleStartCol: 1,
            titleStartRow: 1,
            fileId: "",
        };
        _this.disabledInput = false;
        _this.flag = true;
        _this.tableData = [];
        _this.tableFirstData = [];
        _this.tableSecondData = [];
        _this.fileList = [];
        _this.propertyResponse = {};
        _this.fileData = {};
        _this.rules = {
            principalId: [{ required: true, message: "请选择" }],
            principalName: [{ required: true, message: "请选择" }],
            type: [{ required: true, message: "请选择" }],
            name: [{ required: true, message: "请输入模板名称" }],
            titleStartRow: [
                { required: true, message: "请输入标题起始行" },
                { pattern: /^[0-9]*[1-9][0-9]*$/, message: "行号只能为正整数" },
            ],
            titleStartCol: [
                { required: true, message: "请输入标题起始列" },
                { pattern: /^[1-9]|[1-9]\d+$/, message: "行号只能为大于1的正整数" },
            ],
            dataStartRow: [
                { required: true, message: "请输入数据起始行" },
                { pattern: /^[1-9]|[1-9]\d+$/, message: "行号只能为大于1的正整数" },
            ],
            dataStartCol: [
                { required: true, message: "请输入数据起始列" },
                { pattern: /^[1-9]|[1-9]\d+$/, message: "列号只能为大于1的正整数" },
            ],
        };
        return _this;
    }
    NewExcelTemplate.prototype.success = function () { };
    NewExcelTemplate.prototype.dialogOpen = function (i, v, templateType, sheetLength, propertyResponse) { };
    NewExcelTemplate.prototype.close = function () {
        this.reset();
        this.tableData = [];
        this.tableFirstData = [];
        this.tableSecondData = [];
        this.fileData = {};
    };
    NewExcelTemplate.prototype.errMsg = function (err) { };
    Object.defineProperty(NewExcelTemplate.prototype, "isShow", {
        get: function () {
            return this.tableSecondData.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewExcelTemplate.prototype, "sheetLength", {
        get: function () {
            return this.tableSecondData.length > 0 ? 2 : 1;
        },
        enumerable: false,
        configurable: true
    });
    NewExcelTemplate.prototype.refreshData = function (modifyData) {
        var _this = this;
        this.disabledInput = true;
        this.flag = false;
        if (modifyData) {
            this.loading.state = true;
            this.importTemplateService.getImportTemplateById(modifyData.id, this.loading).subscribe(function (data) {
                _this.templateType = data.type;
                _this.propertyResponse = data.propertyResponse;
                _this.templateModel = {
                    id: data.id,
                    dataStartCol: data.dataStartCol,
                    dataStartRow: data.dataStartRow,
                    name: data.name,
                    principalId: data.principalId,
                    sheetNumber: data.sheetNumber,
                    type: data.type,
                    titleStartCol: data.titleStartCol,
                    titleStartRow: data.titleStartRow,
                    fileId: data.fileId,
                };
                _this.tableData = data.items;
                _this.tableFirstData = _this.tableData.filter(function (v) { return v.sheetNumber === 1; });
                _this.tableSecondData = _this.tableData.filter(function (v) { return v.sheetNumber === 2; });
            }, function (_a) {
                var msg = _a.msg;
            });
        }
    };
    /**
     * 模板类型选中改变事件
     */
    NewExcelTemplate.prototype.templateChanged = function (item) {
        this.templateType = item;
        // 清空所有关联字段
        this.tableFirstData.forEach(function (x) {
            x.name = "";
            x.attribute = "";
            x.propertyType = "";
        });
        // 清空所有关联字段
        this.tableSecondData.forEach(function (x) {
            x.name = "";
            x.attribute = "";
            x.propertyType = "";
        });
        if (this.fileData.id) {
            var templateHeaderModel = {
                id: this.templateModel.id,
                titleStartRow: this.templateModel.titleStartRow,
                titleStartCol: this.templateModel.titleStartCol,
                sheetNumber: this.templateModel.sheetNumber,
                fileId: this.fileData.id,
                sheetTotals: 1,
                importTemplateType: this.templateModel.type,
            };
            this.uploadtemplate(templateHeaderModel);
        }
    };
    /**
     * 上传模板
     * @param fileId
     * @param data
     */
    NewExcelTemplate.prototype.onUploadSuccess = function (id, data) {
        var _this = this;
        if (data) {
            this.fileData = data;
            var templateHeaderModel = {
                id: this.templateModel.id,
                titleStartRow: this.templateModel.titleStartRow,
                titleStartCol: this.templateModel.titleStartCol,
                sheetNumber: this.templateModel.sheetNumber,
                fileId: data.id,
                sheetTotals: 1,
                importTemplateType: this.templateModel.type,
            };
            this.templateModel.fileId = data.id; // 文件ID
            // 新增
            if (this.flag) {
                this.uploadtemplate(templateHeaderModel);
            }
            else {
                this.loading.state = true;
                this.importTemplateService.checkTemplate(templateHeaderModel, this.loading).subscribe(function (data) {
                    if (data.resultList.length || data.insertResultList.length)
                        _this.$alert("<div style=\"width: 100%; max-height: 200px; overflow: auto;\"><el-row><el-col style=\"display:block;\">\u65B0\u65E7\u6A21\u677F\u91CD\u590D\u9879\uFF1A</el-col>" + data.resultList
                            .map(function (item) { return '<el-col :span="24" style="display:block;">' + item + "</el-col>"; })
                            .join("") + "</el-row><el-row><el-col style=\"display:block;\">\u6A21\u677F\u65B0\u589E\u9879\uFF1A</el-col>" + data.insertResultList
                            .map(function (item) { return '<el-col :span="24" style="display:block;">' + item + "</el-col>"; })
                            .join("") + "</el-row></div>", {
                            dangerouslyUseHTMLString: true,
                            customClass: "confirm-message-box",
                        }).catch(function (ex) { });
                    _this.tableData = data.items;
                    _this.tableFirstData = _this.tableData.filter(function (v) { return v.sheetNumber === 1; });
                    _this.tableSecondData = _this.tableData.filter(function (v) { return v.sheetNumber === 2; });
                });
            }
        }
    };
    NewExcelTemplate.prototype.uploadtemplate = function (templateHeaderModel) {
        var _this = this;
        this.loading.state = true;
        this.importTemplateService.analyzeExcelTitle(templateHeaderModel, this.loading).subscribe(function (data) {
            _this.propertyResponse = data.propertyResponse;
            _this.tableData = data.analyzeExcelResponse.map(function (v) {
                return Object.assign({
                    keyFlag: "NO",
                    relationFlag: "NO",
                    hideFlag: "NO",
                    blankFlag: "YES",
                    sort: 999,
                    name: "",
                    attribute: "",
                    propertyType: "",
                }, v);
            });
            _this.tableFirstData = _this.tableData.filter(function (v) { return v.sheetNumber === 1; });
            _this.tableSecondData = _this.tableData.filter(function (v) { return v.sheetNumber === 2; });
            _this.$message.info(data.msg);
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 查看映射字段
     * @param scope
     */
    NewExcelTemplate.prototype.relateNameClick = function (scope) {
        var disabledItems = this.tableData
            .filter(function (v) { return v.name !== ""; })
            .map(function (item) { return ({
            attribute: item.attribute,
            propertyType: item.propertyType,
        }); });
        if (!this.templateType) {
            this.$message.error("请选择导入模板类型");
            return;
        }
        this.dialogOpen(scope.row, disabledItems, this.templateType, this.sheetLength, this.propertyResponse);
    };
    /**
     * 保存数据
     */
    NewExcelTemplate.prototype.submit = function () {
        var _this = this;
        var items = this.tableData.map(function (v) {
            var item = Object.assign({}, v);
            return {
                titleName: item.titleName,
                attribute: item.attribute,
                col: item.col,
                name: item.name,
                propertyType: item.propertyType,
                keyFlag: item.keyFlag,
                relationFlag: item.relationFlag,
                hideFlag: item.hideFlag,
                sort: item.sort,
                blankFlag: item.blankFlag,
                sheetNumber: item.sheetNumber,
                fileId: item.fileId,
                remark: item.remark,
            };
        });
        if (this.isShow) {
            var firstRelationFlag = this.tableFirstData.filter(function (v) { return v.sheetNumber === 1; }).find(function (x) { return x.relationFlag; });
            var secondRelationFlag = this.tableSecondData.filter(function (v) { return v.sheetNumber === 2; }).find(function (x) { return x.relationFlag; });
            if (!firstRelationFlag || !secondRelationFlag) {
                this.$message.error("请选择关联字段");
                return;
            }
        }
        var keyFlag = items.find(function (x) { return x.keyFlag; });
        if (!keyFlag) {
            this.$message.error("请选择关联主键");
            return;
        }
        //案件导入
        if (this.templateModel.type === "CASE_IMPORT" && items.filter(function (x) { return x.name === "案件编号" || x.name === "委案金额" || x.name === "姓名" || x.name === "证件号"; }).length !== 4) {
            return this.$message("请选择红色必输映射字段");
        }
        //案件更新
        if (this.templateModel.type === "CASE_UPDATE" && items.filter(function (x) { return x.name === "案件编号"; }).length !== 1) {
            return this.$message("请选择红色必输映射字段");
        }
        //对账单导入
        if (this.templateModel.type === "BILL_IMPORT" &&
            items.filter(function (x) { return x.name === "案件编号" || x.name === "账单类型(0:委前还款,1:还款)" || x.name === "还款日期" || x.name === "还款金额"; }).length !== 4) {
            return this.$message("请选择红色必输映射字段");
        }
        //委前调记
        if (this.templateModel.type === "PRE_RECORD_IMPORT" && items.filter(function (x) { return x.name === "调记日期" || x.name === "调记内容" || x.name === "证件号"; }).length !== 3) {
            return this.$message("请选择红色必输映射字段");
        }
        //工单导入
        if (this.templateModel.type === "WORK_ORDER_IMPORT" && items.filter(function (x) { return x.name === "案件编号" || x.name === "工单日期" || x.name === "工单信息"; }).length !== 3) {
            return this.$message("请选择红色必输映射字段");
        }
        //警告导入
        if (this.templateModel.type === "WARNING_IMPORT" && items.filter(function (x) { return x.name === "案件编号" || x.name === "警告信息"; }).length !== 2) {
            return this.$message("请选择红色必输映射字段");
        }
        //留案导入
        if (this.templateModel.type === "LEAVE_IMPORT" && items.filter(function (x) { return x.name === "案件编号" || x.name === "到期日期"; }).length !== 2) {
            return this.$message("请选择红色必输映射字段");
        }
        //停调导入
        if (this.templateModel.type === "STOP_IMPORT" && items.filter(function (x) { return x.name === "案件编号"; }).length !== 1) {
            return this.$message("请选择红色必输映射字段");
        }
        var form = this.$refs["data-form"];
        form.validate(function (valid) {
            if (!valid) {
                return;
            }
            // 实体调整
            _this.templateModel.sheetNumber = Number(_this.templateModel.sheetNumber);
            if (_this.templateModel.principalId) {
                _this.templateModel.principalName = _this.principalList.find(function (v) { return v.id === _this.templateModel.principalId; }).name;
            }
            _this.templateModel.items = items;
            if (_this.flag) {
                if (!_this.fileList) {
                    _this.$message("请上传导入文件");
                    return;
                }
                _this.importTemplateService.addImportTemplate(_this.templateModel).subscribe(function (data) {
                    _this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    _this.success();
                    _this.close();
                }, function (err) {
                    _this.errMsg(err.object);
                });
            }
            else {
                _this.importTemplateService.modifyImportTemplate(_this.templateModel).subscribe(function (data) {
                    _this.$message({
                        type: "success",
                        message: "修改成功",
                    });
                    _this.success();
                    _this.close();
                }, function (err) {
                    if (!err.object) {
                        _this.$message({
                            type: "info",
                            message: "修改失败！Excel数据格式错误",
                        });
                        return;
                    }
                    _this.errMsg(err.object);
                });
            }
        });
    };
    NewExcelTemplate.prototype.downLoadTemplate = function (fileId) {
        var _this = this;
        this.uploadFileService.getFileStreamById(fileId).subscribe(function () { return _this.$message.success("下载成功"); });
    };
    NewExcelTemplate.prototype.reSetClick = function (scope) {
        scope.row.name = "";
        scope.row.attribute = "";
        scope.row.propertyType = "";
    };
    NewExcelTemplate.prototype.reset = function () {
        var form = this.$refs["data-form"];
        form.resetFields();
        var download = this.$refs["upload_case_import"];
        download.reset();
    };
    __decorate([
        Dependencies(UploadFileService)
    ], NewExcelTemplate.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(dataExcelCaseService)
    ], NewExcelTemplate.prototype, "dataExcelCaseService", void 0);
    __decorate([
        Dependencies(TemplateDataService)
    ], NewExcelTemplate.prototype, "templateDataService", void 0);
    __decorate([
        Dependencies(ImportTemplateService)
    ], NewExcelTemplate.prototype, "importTemplateService", void 0);
    __decorate([
        State
    ], NewExcelTemplate.prototype, "principalList", void 0);
    __decorate([
        Emit("success")
    ], NewExcelTemplate.prototype, "success", null);
    __decorate([
        Emit("dialogOpen")
    ], NewExcelTemplate.prototype, "dialogOpen", null);
    __decorate([
        Emit("close")
    ], NewExcelTemplate.prototype, "close", null);
    __decorate([
        Emit("errMsg")
    ], NewExcelTemplate.prototype, "errMsg", null);
    NewExcelTemplate = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
                SvgIcon: SvgIcon,
                DataBox: DataBox,
            },
        })
    ], NewExcelTemplate);
    return NewExcelTemplate;
}(Vue));
export default NewExcelTemplate;
