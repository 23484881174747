var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
var RepayService = /** @class */ (function () {
    function RepayService() {
    }
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findGrades = function (data, sort, page, loading) {
        return this.netService.send({
            server: businessService.repayController.findGrades,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 新增行调业绩列表
     */
    RepayService.prototype.addGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.addGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 更新行调业绩列表
     */
    RepayService.prototype.updateGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.updateGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.delGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.delGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.batchDelGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.batchDelGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.exportGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.exportGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.findProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.getProductByDeptBatchMonth = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.getProductByDeptBatchMonth,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findGradesProductTotal = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.findGradesProductTotal,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.listProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.listProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 新增行调业绩列表
     */
    RepayService.prototype.addProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.addProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 更新行调业绩列表
     */
    RepayService.prototype.updateProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.updateProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.delProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.delProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.exportProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesProductController.exportProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.findUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.getUserListByProduct = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getUserListByProduct,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findUserGroup = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.findUserGroup,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findGradesUserGroupByBatchMonth = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.findGradesUserGroupByBatchMonth,
            data: data,
            loading: loading,
        });
    };
    /**
     * 行调业绩列表
     */
    RepayService.prototype.listUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.listUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 新增行调业绩列表
     */
    RepayService.prototype.addUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.addUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 更新行调业绩列表
     */
    RepayService.prototype.updateUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.updateUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.delUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.delUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.batchDelGradesUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.batchDelGradesUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.exportUser = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.exportUser,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询产品各职场总还款
     */
    RepayService.prototype.queryProductTotalRepayAmount = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.queryProductTotalRepayAmount,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询产品各职场总还款
     */
    RepayService.prototype.queryProductTotalRepayAmountByDept = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.queryProductTotalRepayAmountByDept,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.getLineData = function (loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getLineData,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.getGroupBarData = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getGroupBarData,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.getUserBarData = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getUserBarData,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.getGroupData = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getGroupData,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.findGroupNameList = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.findGroupNameList,
            data: data,
            loading: loading,
        });
    };
    /**
     * 调解员提成列表
     */
    RepayService.prototype.getCommissionList = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getCommissionList,
            data: data,
            loading: loading,
        });
    };
    /**
     * 组长提成列表
     */
    RepayService.prototype.getGroupNameCommissionList = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getGroupNameCommissionList,
            data: data,
            loading: loading,
        });
    };
    /**
     * 主管提成列表
     */
    RepayService.prototype.getLeaderCommissionList = function (data, loading) {
        return this.netService.send({
            server: businessService.gradesUserController.getLeaderCommissionList,
            data: data,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], RepayService.prototype, "netService", void 0);
    return RepayService;
}());
export { RepayService };
