var OnlineExam = function () { return import('~/pages/assist-work/online-exam.vue'); };
var CompanyAchieve = function () { return import('~/pages/assist-work/company-achieve.vue'); };
var SwapForum = function () { return import('~/pages/assist-work/swap-forum.vue'); };
var BulletinManage = function () { return import('~/pages/assist-work/bulletin-manage.vue'); };
var AttendanceManage = function () { return import('~/pages/assist-work/attendance-manage.vue'); };
var DataTrain = function () { return import('~/pages/assist-work/data-train.vue'); };
var OrganizationManage = function () { return import('~/pages/assist-work/organization-manage.vue'); };
var addBulletin = function () { return import('~/components/assist-work/dia-log/add-bulletin.vue'); };
export default [
    {
        path: '/assist-work/online-exam',
        name: 'online-exam',
        component: OnlineExam
    },
    {
        path: '/assist-work/company-achieve',
        name: 'company-achieve',
        component: CompanyAchieve
    },
    {
        path: '/assist-work/swap-forum',
        name: 'swap-forum',
        component: SwapForum
    },
    {
        path: '/assist-work/bulletin-manage',
        name: 'bulletin-manage',
        component: BulletinManage,
    },
    {
        path: '/assist-work/bulletin-manage/add-bulletin',
        name: 'add-bulletin',
        component: addBulletin,
    },
    {
        path: '/assist-work/attendance-manage',
        name: 'attendance-manage',
        component: AttendanceManage
    },
    {
        path: '/assist-work/data-train',
        name: 'data-train',
        component: DataTrain
    },
    {
        path: '/assist-work/organization-manage',
        name: 'organization-manage',
        component: OrganizationManage
    }
];
