import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'workOrderController';
export default {
    /**
     * 添加工单
     */
    addWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addWorkOrder',
        type: requestType.Post
    },
    /**
     * 查询工单
     */
    findAllWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllWorkOrder',
        type: requestType.Get
    },
    /**
     * 修改工单
     */
    modifyWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyWorkOrder',
        type: requestType.Put
    },
    /**
     * 删除工单
     */
    deleteWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteWorkOrder',
        type: requestType.Delete
    }
};
