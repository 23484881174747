import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'personalController';
export default {
    /**
     * 创建联系人地址
     */
    addPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addPersonalAddress',
        type: requestType.Post
    },
    /**
     * 修改联系人地址
     */
    modifyPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyPersonalAddress',
        type: requestType.Put
    },
    /**
     * 创建联系人
     */
    addPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addPersonalContact',
        type: requestType.Post
    },
    /**
     * 修改联系人
     */
    modifyPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyPersonalContact',
        type: requestType.Put
    },
    /**
     * 联系人地址查询
     */
    findPersonalAddress: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findPersonalAddress',
        type: requestType.Get
    },
    /**
     * 联系人电话查询
     */
    findPersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findPersonalContact',
        type: requestType.Get
    },
    /**
     * 电话或地址状态更新
     */
    markPhoneOrAddressStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'markPhoneOrAddressStatus',
        type: requestType.Post
    },
    /**
   * 修改联系人禁用状态
   */
    modifyBanState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyBanState',
        type: requestType.Post
    },
    /**
   * 标记状态
   */
    markStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'markStatus',
        type: requestType.Post
    },
    /**
     * 删除联系人/地址
     */
    deletePersonalContact: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deletePersonalContact',
        type: requestType.Delete
    },
    /**
     * 修改备注
     */
    modifyRemark: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyRemark',
        type: requestType.Put
    }
};
