var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Dependencies } from '~/core/decorator';
import { State } from 'vuex-class';
import { CaseInfoService } from '~/services/business-service/case-info.service';
var RefreshRetirementTime = /** @class */ (function (_super) {
    __extends(RefreshRetirementTime, _super);
    function RefreshRetirementTime() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.batchNumberList = [];
        _this.model = {
            batchNumber: '',
            endCaseDate: '',
            caseId: ''
        };
        _this.batchNumberRules = {
            batchNumber: [{ required: true, message: '请选择批次号', trigger: 'blur' }],
        };
        _this.loading = { state: false };
        return _this;
    }
    RefreshRetirementTime.prototype.close = function () { this.reset(); };
    RefreshRetirementTime.prototype.getBatchNumber = function () {
        var _this = this;
        this.caseInfoService.getBatchNumber(this.poolName).subscribe(function (data) {
            _this.batchNumberList = data;
        });
    };
    RefreshRetirementTime.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            if (_this.caseId) {
                _this.model.caseId = _this.caseId;
            }
            ;
            _this.caseInfoService.updateCaseEndDate(_this.model, _this.loading).subscribe(function (data) {
                _this.$message.success('退案时间刷新成功!');
                _this.close();
            });
        });
    };
    RefreshRetirementTime.prototype.reset = function () {
        var noteForm = this.$refs['form'];
        noteForm.resetFields();
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], RefreshRetirementTime.prototype, "caseInfoService", void 0);
    __decorate([
        State
    ], RefreshRetirementTime.prototype, "userData", void 0);
    __decorate([
        Prop()
    ], RefreshRetirementTime.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], RefreshRetirementTime.prototype, "poolName", void 0);
    __decorate([
        Emit('close')
    ], RefreshRetirementTime.prototype, "close", null);
    RefreshRetirementTime = __decorate([
        Component({
            components: {},
        })
    ], RefreshRetirementTime);
    return RefreshRetirementTime;
}(Vue));
export default RefreshRetirementTime;
