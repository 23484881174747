var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { VillageCommitteeDataService } from "~/services/repair-service/village-committee-data.service";
import { AreaService } from "~/services/repair-service/area.service";
var AddCountryData = /** @class */ (function (_super) {
    __extends(AddCountryData, _super);
    function AddCountryData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.kosekiModel = {
            province: [],
            village: "",
            linkman: "",
            queryMan: "",
            position: "",
            officePhone: "",
            mobile: "",
            queryDate: "",
            homePhone: "",
            areaCode: "",
            remark: "",
            fileIdList: []
        };
        _this.loading = {
            state: false
        };
        _this.rulesAdd = {
            province: [{ required: true, message: "请选择省市区", trigger: "blur" }],
            village: [{ required: true, message: "请输入村委地址", trigger: "blur" }],
            linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }]
        };
        _this.options = [];
        _this.props = { value: "id", label: 'name', children: 'cities' };
        return _this;
    }
    AddCountryData.prototype.success = function () {
        this.close();
    };
    AddCountryData.prototype.close = function () {
        this.reset();
    };
    AddCountryData.prototype.mounted = function () {
        this.getNodes();
    };
    AddCountryData.prototype.getNodes = function (val) {
        var _this = this;
        var idArea;
        var sizeArea;
        if (!val)
            idArea = null, sizeArea = 0;
        else if (val.length === 1)
            idArea = val[0], sizeArea = val.length;
        else if (val.length === 2)
            idArea = val[1], sizeArea = val.length;
        this.areaService.searchAreaInformation(idArea)
            .subscribe(function (data) {
            if (sizeArea === 0) { // 初始化加载一级省
                _this.options = data.areaList.map(function (value) {
                    return { id: value.id, name: value.name, cities: [] };
                });
            }
            else if (sizeArea === 1) { // 点击一级加载二级 市
                _this.options.map(function (x) {
                    if (x.id === val[0]) {
                        if (!x.cities.length) {
                            x.cities = data.areaList.map(function (value) {
                                return { id: value.id, name: value.name, cities: [] };
                            });
                        }
                    }
                });
            }
            else if (sizeArea === 2) { // 点击二级加载三级区
                _this.options.map(function (x) {
                    if (x.id === val[0]) {
                        x.cities.map(function (value) {
                            if (value.id === val[1]) {
                                if (!value.cities.length) {
                                    value.cities = data.areaList.map(function (value) {
                                        _this.kosekiModel.areaCode = value.areaCode;
                                        return { id: value.id, name: value.name };
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });
    };
    AddCountryData.prototype.handleItemChange = function (value) {
        this.getNodes(value);
    };
    // 保存户籍信息
    AddCountryData.prototype.save = function () {
        var _this = this;
        var addform = this.$refs["add-form"];
        addform.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            if (_this.fileList && _this.fileList.length)
                _this.kosekiModel.fileIdList = _this.fileList.map(function (v) { return v.response.id; });
            _this.loading.state = true;
            _this.villageCommitteeDataService
                .insert(_this.kosekiModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("添加成功!");
                _this.success();
            });
        });
    };
    AddCountryData.prototype.onUploadSuccess = function (id, data) {
        this.kosekiModel.fileIdList = data.id;
    };
    AddCountryData.prototype.reset = function () {
        var add = this.$refs["add-form"];
        add.resetFields();
        //let fileForm: any = this.$refs["file-upload"];
        //fileForm.reset();
    };
    __decorate([
        Dependencies(VillageCommitteeDataService)
    ], AddCountryData.prototype, "villageCommitteeDataService", void 0);
    __decorate([
        Dependencies(AreaService)
    ], AddCountryData.prototype, "areaService", void 0);
    __decorate([
        Emit("success")
    ], AddCountryData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], AddCountryData.prototype, "close", null);
    AddCountryData = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], AddCountryData);
    return AddCountryData;
}(Vue));
export default AddCountryData;
