export default {
    namespaced: true,
    state: {
        printInfo: {},
        someSymbol: {},
        browserType: {},
        ymdDate: {}
    },
    mutations: {
        updatePrintInfo: function (state, value) {
            state.printInfo = value;
        },
        printSomeSymbol: function (state, value) {
            state.someSymbol = value;
        },
        browserType: function (state, value) {
            state.browserType = value;
        },
        printYmdDate: function (state, value) {
            state.ymdDate = value;
        }
    },
    actions: {}
};
