var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { Getter } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { PaymentRecordService } from "~/services/business-service/payment-record.service";
var PaymentCase = /** @class */ (function (_super) {
    __extends(PaymentCase, _super);
    function PaymentCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.deptIds = [];
        _this.queryParamsModel = {
            principalId: "",
            personalName: "",
            certificateNo: "",
            caseNumber: "",
            batchNumber: "",
            departCode: "",
            stage: "",
            payDate: [],
            payAmt: {
                min: "",
                max: ""
            },
            collectorUsername: "",
            cardNo: ""
        };
        _this.dataSet = [];
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
        };
        return _this;
    }
    PaymentCase.prototype.onRouteChange = function (val) {
        if (!val)
            return;
        this.handleResetForm();
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
    };
    PaymentCase.prototype.mounted = function () {
        this.refreshData();
    };
    PaymentCase.prototype.refreshData = function () {
        var _this = this;
        this.paymentRecordService.findAllPaymentRecords(this.queryParamsModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    PaymentCase.prototype.handleResetForm = function () {
        this.queryParamsModel.departCode = '';
        this.queryParamsModel.personalName = '';
        this.queryParamsModel.certificateNo = '';
        this.queryParamsModel.principalId = '';
        this.queryParamsModel.batchNumber = '';
        this.queryParamsModel.caseNumber = '';
        this.queryParamsModel.stage = '';
        this.queryParamsModel.payDate = [];
        this.queryParamsModel.collectorUsername = '';
        this.queryParamsModel.cardNo = '';
        this.queryParamsModel.payAmt = [];
        this.deptIds = [];
    };
    /**
     * 机构改变
     */
    PaymentCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.queryParamsModel.departCode = valueArray[valueArray.length - 1];
    };
    __decorate([
        Dependencies(PageService)
    ], PaymentCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PaymentCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PaymentRecordService)
    ], PaymentCase.prototype, "paymentRecordService", void 0);
    __decorate([
        Watch("$route", { immediate: true })
    ], PaymentCase.prototype, "onRouteChange", null);
    __decorate([
        Getter
    ], PaymentCase.prototype, "departmentData", void 0);
    PaymentCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange
            }
        })
    ], PaymentCase);
    return PaymentCase;
}(Vue));
export default PaymentCase;
