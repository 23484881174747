import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'historyCasesController';
export default {
    /**
     * 查询退案案件
     */
    queryReturnCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryReturnCases',
        type: requestType.Get
    },
    /**
       * 删除案件查询
       */
    queryDeleteCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryDeleteCases',
        type: requestType.Get
    },
    /**
     * 退案案件删除
     */
    deleteCases: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteCases',
        type: requestType.Get
    },
    /**
       * 退案案件批次号查询
       */
    getReturnCasesBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getReturnCasesBatchNumber',
        type: requestType.Get
    },
    /**
       * 永久删除案件信息
       */
    deleteCasesPermanent: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteCasesPermanent',
        type: requestType.Get
    },
    /**
       * 删除案件池获取所有批次号
       */
    getDeleteCasesBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getDeleteCasesBatchNumber',
        type: requestType.Get
    },
    /**
       * 删除案件池获取所有批次号
       */
    recoverCaseToNormal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'recoverCaseToNormal',
        type: requestType.Post
    },
    /**
       * 删除案件池获取所有批次号
       */
    addHistoryCaseWorkerOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addHistoryCaseWorkerOrder',
        type: requestType.Post
    }
};
