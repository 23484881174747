var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { RoleService } from "~/services/manage-service/role.service";
import { ResourceService } from "~/services/manage-service/resource.service";
import { Emit, Watch, Prop } from "vue-property-decorator";
import ResourceTree from "./resource-tree.vue";
import ResourceControl from "./resource-control.vue";
import CommonTitle from "~/components/common/common-title.vue";
var BatchUpdateRole = /** @class */ (function (_super) {
    __extends(BatchUpdateRole, _super);
    function BatchUpdateRole() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.checkedMenuIds = [];
        _this.controlCheckedIds = [];
        _this.controlSet = [];
        _this.controlDataSet = [];
        _this.currentTreeKey = "";
        _this.loading = { state: false };
        return _this;
    }
    BatchUpdateRole.prototype.refreshList = function () { };
    BatchUpdateRole.prototype.changeRoleId = function (val) {
        if (!val)
            return;
        this.refreshDate(val);
    };
    BatchUpdateRole.prototype.mounted = function () {
        this.refreshDate(this.roleId);
    };
    BatchUpdateRole.prototype.refreshDate = function (val) {
        var _this = this;
        if (!val)
            return;
        this.roleService.findAllResource(val).subscribe(function (data) { return _this.checkedMenuIds = data.map(function (v) { return v.id; }); });
        this.roleService.findAllControls(val).subscribe(function (data) {
            _this.controlSet = data;
            _this.controlDataSet = data.map(function (v) {
                return {
                    id: v.id,
                    parent: v.parent
                };
            });
            _this.controlCheckedIds = data.map(function (v) { return v.id; });
        });
    };
    BatchUpdateRole.prototype.onCheckedMenuIdsChange = function (values, oldVlaues) {
        var _this = this;
        var oldCheckedControls = __spreadArrays(this.controlCheckedIds);
        // 处理增加的
        var addKeys = values.filter(function (x) { return !oldVlaues.includes(x); });
        addKeys.forEach(function (v) {
            var controls = _this.controlDataSet.filter(function (x) { return x.parent === v; }).map(function (v) { return v.id; });
            oldCheckedControls.push.apply(oldCheckedControls, controls);
        });
        var checkedControlSet = new Set(oldCheckedControls);
        // 处理减少的
        var deleteKeys = oldVlaues.filter(function (x) { return !values.includes(x); });
        deleteKeys.forEach(function (v) {
            var controls = _this.controlDataSet.filter(function (x) { return x.parent === v; }).map(function (v) { return v.id; });
            controls.forEach(function (c) { return checkedControlSet.delete(c); });
        });
        this.controlCheckedIds = Array.from(checkedControlSet);
        var oldCurrentTreeKey = this.currentTreeKey;
        this.currentTreeKey = "";
        this.$nextTick(function () {
            _this.currentTreeKey = oldCurrentTreeKey;
        });
    };
    BatchUpdateRole.prototype.emitClose = function () {
        this.refreshDate(this.roleId);
    };
    BatchUpdateRole.prototype.onCheckTreeNodeChange = function () {
        if (this.checkedMenuIds.includes(this.currentTreeKey))
            return;
        this.checkedMenuIds.push(this.currentTreeKey);
    };
    BatchUpdateRole.prototype.saveModule = function () {
        var _this = this;
        var treeresource = this.$refs['resource-tree'];
        var checkedResults = __spreadArrays(treeresource.getCheckedNodes(), this.controlCheckedIds);
        var roleInfo = {
            resources: checkedResults,
            roleIds: this.selectList
        };
        this.loading.state = true;
        this.roleService.distributePermission(roleInfo).subscribe(function (data) {
            _this.$message.success("操作成功!");
            _this.refreshList();
            _this.emitClose();
        });
    };
    __decorate([
        Dependencies(ResourceService)
    ], BatchUpdateRole.prototype, "resourceService", void 0);
    __decorate([
        Dependencies(RoleService)
    ], BatchUpdateRole.prototype, "roleService", void 0);
    __decorate([
        Prop({ required: true })
    ], BatchUpdateRole.prototype, "selectList", void 0);
    __decorate([
        Prop({ required: true })
    ], BatchUpdateRole.prototype, "roleId", void 0);
    __decorate([
        Emit("refreshList")
    ], BatchUpdateRole.prototype, "refreshList", null);
    __decorate([
        Watch('roleId', { immediate: true })
    ], BatchUpdateRole.prototype, "changeRoleId", null);
    __decorate([
        Watch('checkedMenuIds')
    ], BatchUpdateRole.prototype, "onCheckedMenuIdsChange", null);
    __decorate([
        Emit('close')
    ], BatchUpdateRole.prototype, "emitClose", null);
    BatchUpdateRole = __decorate([
        Component({
            components: {
                ResourceTree: ResourceTree,
                ResourceControl: ResourceControl,
                CommonTitle: CommonTitle
            }
        })
    ], BatchUpdateRole);
    return BatchUpdateRole;
}(Vue));
export default BatchUpdateRole;
