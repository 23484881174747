var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { Watch, Prop, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { TemplateService } from "~/services/manage-service/template.service";
var CallAccTemplete = /** @class */ (function (_super) {
    __extends(CallAccTemplete, _super);
    function CallAccTemplete() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.callModel = {
            principalId: "",
            principalName: "",
            isDefault: false,
            templateName: "",
            isEnabled: "YES",
            content: "",
            type: "TEL"
        };
        _this.rules = {
            principalId: [
                {
                    required: true,
                    message: "请选择委托方",
                    trigger: "blur"
                }
            ],
            content: [
                {
                    required: true,
                    message: "请输入话术内容",
                    trigger: "blur"
                },
                {
                    min: 0,
                    max: 1000,
                    message: "不超过1000个字符",
                    trigger: "blur"
                }
            ],
            templateName: [
                {
                    required: true,
                    message: "请输入模板名称",
                    trigger: "blur"
                },
                {
                    min: 2,
                    max: 10,
                    message: "长度在 2 到 10 个字符",
                    trigger: "blur"
                }
            ]
        };
        _this.loading = {
            state: false
        };
        _this.defaultFlag = false;
        _this.isModify = false;
        return _this;
    }
    CallAccTemplete.prototype.close = function () {
        var callFrom = this.$refs["call-form"];
        callFrom.resetFields();
        this.callModel.isDefault = false;
    };
    CallAccTemplete.prototype.updateDataChange = function (val) {
        if (!val)
            return;
        this.isModify = true;
        this.callModel = Object.assign({}, this.updateData);
        this.callModel.isDefault =
            this.callModel.isDefault === "YES" ? true : false;
    };
    CallAccTemplete.prototype.success = function () { };
    /**
     * 提交新建模板按钮
     */
    CallAccTemplete.prototype.submitClick = function (type) {
        var _this = this;
        var messageFrom = this.$refs["call-form"];
        messageFrom.validate(function (valid) {
            if (!valid) {
                return;
            }
            if (_this.callModel.isDefault && _this.callModel.isEnabled === "NO") {
                _this.$message({
                    type: "info",
                    message: "默认模板不可设为停用!"
                });
                return;
            }
            switch (type) {
                case "add":
                    _this.addTemplate();
                    break;
                case "update":
                    _this.modifyTemplate();
                    break;
            }
        });
    };
    /**
     * 新建模板
     */
    CallAccTemplete.prototype.addTemplate = function () {
        var _this = this;
        var callModel = Object.assign({}, this.callModel);
        callModel.isDefault = callModel.isDefault ? "YES" : "NO";
        callModel.principalName =
            this.principalList.find(function (v) { return v.id === callModel.principalId; })
                .name || "";
        this.loading.state = true;
        this.templateService.createTemplate(callModel, this.loading).subscribe(function (data) {
            _this.$message({
                type: "success",
                message: "新增模版成功!"
            });
            _this.$emit("success");
            _this.close();
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 修改模板
     */
    CallAccTemplete.prototype.modifyTemplate = function () {
        var _this = this;
        var callModel = Object.assign({}, this.callModel);
        callModel.isDefault = this.callModel.isDefault ? "YES" : "NO";
        callModel.principalName =
            this.principalList.find(function (v) { return v.id === callModel.principalId; })
                .name || "";
        this.loading.state = true;
        this.templateService.updateTemplate(callModel, this.loading).subscribe(function (data) {
            _this.$message({
                type: "success",
                message: "修改模版成功!"
            });
            _this.$emit("success");
            _this.close();
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(TemplateService)
    ], CallAccTemplete.prototype, "templateService", void 0);
    __decorate([
        State
    ], CallAccTemplete.prototype, "principalList", void 0);
    __decorate([
        Emit("close")
    ], CallAccTemplete.prototype, "close", null);
    __decorate([
        Prop()
    ], CallAccTemplete.prototype, "updateData", void 0);
    __decorate([
        Watch("updateData", { immediate: true })
    ], CallAccTemplete.prototype, "updateDataChange", null);
    __decorate([
        Emit("success")
    ], CallAccTemplete.prototype, "success", null);
    CallAccTemplete = __decorate([
        Component({
            components: {}
        })
    ], CallAccTemplete);
    return CallAccTemplete;
}(Vue));
export default CallAccTemplete;
