var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { Layout, Dependencies } from "~/core/decorator";
import { ExportLetterService } from "~/services/management-service/export-letter.service";
import ComplianceLetterConfig from "~/components/system-manage/compliance-config/compliance-letter-config.vue";
var LetterTemplate = /** @class */ (function (_super) {
    __extends(LetterTemplate, _super);
    function LetterTemplate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.Model = {
            principalId: "",
            templateDataName: "",
            type: ""
        };
        _this.dataSet = [];
        _this.dialog = { addConfig: false };
        _this.modifyData = {};
        return _this;
    }
    LetterTemplate.prototype.mounted = function () {
        this.refreshData();
    };
    LetterTemplate.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.exportLetterService
            .getTemplateByPrin(this.Model, this.pageService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        }, function (err) {
            _this.$message.error(err.msg);
        });
    };
    LetterTemplate.prototype.updateClick = function (row) {
        this.modifyData = Object.assign({}, row);
        this.dialog.addConfig = true;
    };
    LetterTemplate.prototype.deleteClick = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5220\u9664 " + row.templateName + " \u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.exportLetterService.deleteLetter(row.id).subscribe(function (data) {
                _this.$message({ type: "success", message: "删除成功!" });
                _this.refreshData();
            }, function (err) { });
        })
            .catch(function () { });
    };
    __decorate([
        Dependencies(PageService)
    ], LetterTemplate.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ExportLetterService)
    ], LetterTemplate.prototype, "exportLetterService", void 0);
    __decorate([
        State
    ], LetterTemplate.prototype, "principalList", void 0);
    LetterTemplate = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ComplianceLetterConfig: ComplianceLetterConfig
            }
        })
    ], LetterTemplate);
    return LetterTemplate;
}(Vue));
export default LetterTemplate;
