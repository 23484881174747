var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from '~/core/decorator';
import { Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import DataBox from "~/components/common/data-box.vue";
import { OrganizationService } from '~/services/management-service/organization.service';
import PersonRoster from '~/components/assist-work/person-roster.vue';
import PersonUploadFile from '~/components/assist-work/person-upload-file.vue';
import PersonDetail from '~/components/assist-work/person-detail.vue';
var OnJob = /** @class */ (function (_super) {
    __extends(OnJob, _super);
    function OnJob() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userId = '';
        _this.modifyData = {};
        _this.DataSet = [];
        _this.selectionList = [];
        _this.loading = {
            state: false
        };
        _this.dialog = {
            modify: false,
            personRoster: false,
            editOrganizeFlag: false,
            addOrganizeFlag: false,
            upload: false,
            detail: false,
        };
        return _this;
    }
    OnJob.prototype.modify = function (row) {
        this.modifyData = row;
        this.dialog.personRoster = true;
    };
    OnJob.prototype.upload = function (id) {
        this.userId = id;
        this.dialog.upload = true;
    };
    /**
   * 选择点变化
   */
    OnJob.prototype.getCheckedNodes = function (item) {
        // this.nowNode = item;
        this.refreshData();
    };
    OnJob.prototype.refreshData = function () {
        var _this = this;
        if (!this.userModel)
            return;
        this.organizationService
            .queryUserByOrganizationId(this.userModel, this.pageService)
            .subscribe(function (data) {
            _this.DataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    OnJob.prototype.personRosterClose = function () {
        this.modifyData = {};
        var personRoster = this.$refs['person-roster'];
        personRoster.reset();
    };
    OnJob.prototype.viewDetails = function (row) {
        this.modifyData = row;
        this.dialog.detail = true;
    };
    __decorate([
        Dependencies(PageService)
    ], OnJob.prototype, "pageService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], OnJob.prototype, "organizationService", void 0);
    __decorate([
        Prop()
    ], OnJob.prototype, "userModel", void 0);
    OnJob = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                PersonRoster: PersonRoster,
                PersonUploadFile: PersonUploadFile,
                PersonDetail: PersonDetail,
            }
        })
    ], OnJob);
    return OnJob;
}(Vue));
export default OnJob;
