var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { VeLine } from 'v-charts';
import { Prop } from "vue-property-decorator";
var LineEcharts = /** @class */ (function (_super) {
    __extends(LineEcharts, _super);
    function LineEcharts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.optionData = {
            data: {
                columns: [],
                rows: []
            },
            settings: {
                labelMap: {},
            },
            colors: [],
            legend: {
                show: false,
                type: "scroll",
                bottom: '0',
                left: 'center',
                // align: 'center',
                textStyle: {
                    color: '#8C8C8C'
                },
                height: 250,
            },
            yAxis: {
                type: 'value',
                name: '',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#242424'
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dotted',
                        color: '#cccccc'
                    }
                },
                axisTick: {
                    show: false
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#242424',
                    }
                },
                axisTick: {
                    show: false
                },
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '10%',
                top: '10%',
                containLabel: true
            },
        };
        return _this;
    }
    LineEcharts.prototype.mounted = function () {
        this.optionData.data.columns = this.lineData.columns;
        this.optionData.data.rows = this.lineData.rows;
        this.optionData.settings.labelMap = this.lineData.settingsLabel;
        this.optionData.yAxis.name = this.lineData.yAxisName;
        this.optionData.colors = this.lineData.colors;
        this.optionData.legend.show = this.lineData.legendShow;
    };
    __decorate([
        Prop()
    ], LineEcharts.prototype, "lineData", void 0);
    LineEcharts = __decorate([
        Component({
            components: {
                VeLine: VeLine
            },
        })
    ], LineEcharts);
    return LineEcharts;
}(Vue));
export default LineEcharts;
