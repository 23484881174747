var CaseStatusConfig = function () { return import('~/pages/system-manage/case-status-config.vue'); };
var ContactWayConfig = function () { return import('~/pages/system-manage/contact-way-config.vue'); };
export default [
    {
        // 案件状态配置
        path: '/case-status-manage/case-status-config',
        name: 'case-status-config',
        component: CaseStatusConfig
    },
    {
        // 联系方式配置
        path: '/case-status-manage/contact-way-config',
        name: 'contact-way-config',
        component: ContactWayConfig
    }
];
