var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { EditType } from "~/config/enum.framework";
import SvgIcon from "~/components/common/svg-icon.vue";
var DataTreeNode = /** @class */ (function (_super) {
    __extends(DataTreeNode, _super);
    function DataTreeNode() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = {};
        _this.expanded = false;
        _this.selected = false;
        _this.indeterminate = false;
        // 加载中
        _this.loading = false;
        return _this;
    }
    Object.defineProperty(DataTreeNode.prototype, "props", {
        get: function () {
            return this.root.propsObject;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "checked", {
        get: function () {
            return this.data._checked;
        },
        set: function (value) {
            this.data._checked = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "showCheckbox", {
        get: function () {
            return this.root.showCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "disabled", {
        /** 是否禁用操作项 */
        get: function () {
            return this.data._disabled || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "showEdit", {
        get: function () {
            return this.root.showEdit;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "hasAdd", {
        get: function () {
            return this.root.editConfig.includes(EditType.ADD);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "hasEdit", {
        get: function () {
            return this.root.editConfig.includes(EditType.MODIFY);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "hasDelete", {
        get: function () {
            return this.root.editConfig.includes(EditType.DELETE);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "hasMoveUp", {
        get: function () {
            return this.root.editConfig.includes(EditType.MOVE_UP);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "hasMoveDown", {
        get: function () {
            return this.root.editConfig.includes(EditType.MOVE_DOWN);
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 调用树的编辑方法，向调用者发送edit事件
     */
    DataTreeNode.prototype.editHandle = function () {
        this.root.emitEdit(this.data);
    };
    /**
     * 点击树添加事件
     */
    DataTreeNode.prototype.addItemHandle = function () {
        this.root.addEdit(this.data);
    };
    /**
     * 点击树删除事件
     */
    DataTreeNode.prototype.deleteHandle = function () {
        this.root.deleteEdit(this.data);
    };
    /**
     * 向上移动
     */
    DataTreeNode.prototype.moveUpHandle = function () {
        this.root.moveUp(this.data);
    };
    /**
     * 向下移动
     */
    DataTreeNode.prototype.moveDownHandle = function () {
        this.root.moveDown(this.data);
    };
    /**
     * 点击当前节点
     */
    DataTreeNode.prototype.currentNode = function () {
        this.root.clickNode(this.data);
    };
    /**
     * 当前节点更新操作
     * 保证更新顺序
     */
    DataTreeNode.prototype.onChecked = function (value) {
        // 取消自动关联状态
        this.indeterminate = false;
        // step1: 更新子节点
        this.updateChildrenChecked(value);
        // step2: 更新父节点
        this.updateParentChecked(value);
        // 发送节点更新事件
        this.root.emitCurrentCheckedChange(this.data.id, value);
    };
    /**
     * 更新父节点选中状态
     */
    DataTreeNode.prototype.updateParentChecked = function (value) {
        // 获取父节点
        var parent = this.getParent();
        if (parent) {
            // 获取目标状态
            var targetChecked = parent.data.children.some(function (x) { return x._checked === true; });
            // 更新半选状态
            parent.indeterminate = targetChecked && !parent.data.children.every(function (x) { return x._checked === true; });
            // 如果状态发生变化则更新父节点
            if (parent.checked !== targetChecked) {
                parent.checked = targetChecked;
                parent.updateParentChecked();
            }
        }
    };
    /**
     * 更新子节点选中状态
     */
    DataTreeNode.prototype.updateChildrenChecked = function (value) {
        var _this = this;
        if (this.data.children && this.data.children.length) {
            // 获取子节点组件
            var nodes = this.$refs['children-node'];
            // 通知子组件更新
            nodes.forEach(function (item) {
                item.checked = value;
                // 发送节点更新事件
                _this.root.emitCurrentCheckedChange(item.data.id, value);
                item.updateChildrenChecked(value);
            });
        }
    };
    Object.defineProperty(DataTreeNode.prototype, "isLeaf", {
        /**
         * 是否叶子节点 或者 懒加载模式
         */
        get: function () {
            if (this.root.loadData !== undefined) {
                if (this.data.loading) {
                    return !this.HasChildren;
                }
                else {
                    return false;
                }
            }
            else {
                return !this.HasChildren;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataTreeNode.prototype, "HasChildren", {
        /**
         * 是否有children数据
         */
        get: function () {
            return this.data.children;
        },
        enumerable: false,
        configurable: true
    });
    DataTreeNode.prototype.getSubTreeStyle = function () {
        return {
            marginLeft: this.isLeaf ? "15px" : "10px",
            marginTop: "3px"
        };
    };
    /**
     * 节点展开处理
     */
    DataTreeNode.prototype.onExpand = function () {
        var _this = this;
        if (!this.expanded) {
            if (this.root.loadData !== undefined && !this.data.loading) {
                // 将加载后的子数据给当前节点
                this.loading = true;
                var load = this.root.loadData(this.data);
                if (load) {
                    load.then(function (data) {
                        _this.data.children = data;
                        _this.data.loading = true;
                        _this.loading = false;
                    });
                }
                else {
                    this.loading = false;
                }
            }
        }
        this.expanded = !this.expanded;
    };
    /**
     * 节点选择处理
     */
    DataTreeNode.prototype.onNodeClick = function () {
        this.selected = true;
        this.root.selected = this;
    };
    DataTreeNode.prototype.getParent = function (target) {
        if (target === void 0) { target = "data-tree-node"; }
        var parent = this.$parent;
        // 向上寻找根节点元素
        while (parent && parent.$options && parent.$options.name !== target) {
            parent = parent.$parent;
        }
        return parent;
    };
    DataTreeNode.prototype.created = function () {
        this.root = this.getParent('data-tree');
        this.expanded = this.data.expanded;
    };
    DataTreeNode.prototype.mounted = function () {
        this.root.registerNode(this, this.data);
        this.checked = !!this.data._checked;
        if (this.data._selected)
            this.onNodeClick();
    };
    __decorate([
        Prop({
            required: true
        })
    ], DataTreeNode.prototype, "data", void 0);
    DataTreeNode = __decorate([
        Component({
            name: "data-tree-node",
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], DataTreeNode);
    return DataTreeNode;
}(Vue));
export default DataTreeNode;
