import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'areaInformation';
export default {
    searchAreaInformationByPid: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchAreaInformationByPid',
        type: requestType.Get
    },
    searchAreaInformation: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchAreaInformation',
        type: requestType.Get
    },
};
