/**
 * 请求类型
 */
export var requestType = {
    Delete: "DELETE",
    Get: "GET",
    Post: "POST",
    Put: "PUT",
};
/**
 * 文件类型
 */
export var FileType = {
    /**
     * 图片类型支持的格式
     */
    PICTURE: {
        title: "图片",
        value: ["png", "jpeg", "jpe", "jfif", "jpg", "gif", "bmp"],
    },
    /**
     * 压缩文件支持的格式
     */
    compressed: {
        title: "压缩文件",
        value: ["zip"],
    },
    /**
     * MP3文件类型
     */
    MP3: {
        title: "音频",
        value: ["mp3"],
    },
    /**
     * MP4文件类型
     */
    MP4: {
        title: "视频",
        value: ["mp4"],
    },
};
/**
 * 消息类型
 */
export var reminderType = {
    repayment: {
        name: "还款提醒",
        value: "REPAYMENT",
        code: 69,
    },
    followUp: {
        name: "跟进提醒",
        value: "FLLOWUP",
    },
    repaired: {
        value: "REPAIRED",
        name: "修复提醒",
    },
    derate: {
        value: "DERATE",
        name: "减免审批提醒",
    },
    apply: {
        value: "APPLY",
        name: "还款审核提醒",
    },
    assistApprove: {
        value: "ASSIST_APPROVE",
        name: "协调审批提醒",
    },
    visitApprove: {
        value: "VISIT_APPROVE",
        name: "外访申请审批提醒",
    },
    leaveCase: {
        value: "LEAVE_CASE",
        name: "留案案件提醒",
    },
    circulation: {
        value: "CIRCULATION",
        name: "提前流转审批提醒",
    },
    forceTurn: {
        value: "FORCE_TURN",
        name: "强制流转案件提醒",
    },
    memoModify: {
        value: "MEMO_MODIFY",
        name: "修改备注提醒",
    },
    caseExpire: {
        value: "CASE_EXPIRE",
        name: "案件到期提醒",
    },
    followUpExport: {
        value: "FOLLOWUP_EXPORT",
        name: "跟进记录导出提醒",
    },
    dowloadFile: {
        value: "DOWNLOAD_FILE",
        name: "文件下载提醒",
    },
    caseImport: {
        value: "CASE_IMPORT",
        name: "案件导入提醒",
    },
    caseImportExcel: {
        value: "IMPORT_EXCEL_MSG",
        name: "案件导入提醒",
    },
    caseImportConfirmed: {
        value: "IMPORT_CONFIRMED_MSG",
        name: "导入确认提醒",
    },
    caseImportUpdate: {
        value: "IMPORT_UPDATE_CASE",
        name: "案件更新导入提醒",
    },
    caseImportUpdateConfirmed: {
        value: "IMPORT_UPDATE_CONFIRMED",
        name: "案件更新导入确认提醒",
    },
    importBill: {
        value: "IMPORT_BILL",
        name: "导入对账单提醒",
    },
    importBillConfirmed: {
        value: "IMPORT_BILL_CONFIRMED",
        name: "导入对账单确认提醒",
    },
    importWarningInfo: {
        value: "IMPORT_WARNING_INFO",
        name: "警告信息提醒",
    },
    importWarningConfirmed: {
        value: "IMPORT_WARNING_CONFIRMED",
        name: "警告信息确认提醒",
    },
    importWorkerOrder: {
        value: "IMPORT_WORKER_ORDER",
        name: "工单信息提醒",
    },
    importWorkerConfirmed: {
        value: "IMPORT_WORKER_CONFIRMED",
        name: "工单信息确认提醒",
    },
    importLeftCase: {
        value: "IMPORT_LEFT_CASE",
        name: "留案导入提醒",
    },
    importLeftConfirmed: {
        value: "IMPORT_LEFT_CONFIRMED",
        name: "留案确认提醒",
    },
    importChangeCity: {
        value: "IMPORT_CHANGE_CITY",
        name: "城市调整导入提醒",
    },
    importChangeConfirmed: {
        value: "IMPORT_CHANGE_CONFIRMED",
        name: "城市调整确认提醒",
    },
    importFollowRecord: {
        value: "IMPORT_FOLLOW_RECORD",
        name: "委前调记导入提醒",
    },
    importFollowConfirmed: {
        value: "IMPORT_FOLLOW_CONFIRMED",
        name: "委前调记确认提醒",
    },
    importEndCase: {
        value: "IMPORT_END_CASE",
        name: "停调导入提醒",
    },
    importEndConfirmed: {
        value: "IMPORT_END_CONFIRMED",
        name: "停调确认提醒",
    },
    applyApprove: {
        value: "APPLY_APPROVE_MSG",
        name: "申请审批提醒",
    },
    strategy: {
        value: "STRATEGY",
        name: "策略分配提醒",
    },
    comment: {
        value: "COMMENT",
        name: "备忘录提醒",
    },
    ImportAnnex: {
        value: "IMPORT_ANNEX",
        name: "补充材料导入",
    },
    ImportAnnexConfirmed: {
        value: "IMPORT_ANNEX_CONFIRMED",
        name: "补充材料确认",
    },
    DistributeCase: {
        value: "DISTRIBUTE_CASE",
        name: "策略分配完成",
    },
    ImportOperator: {
        value: "IMPORT_OPERATOR",
        name: "员工花名册导入",
    },
    assistCallBack: {
        value: "ASSIST_CALL_BACK",
        name: "协调撤回",
    },
    stopAssist: {
        value: "STOP_ASSIST",
        name: "结束协调",
    },
    followReminder: {
        value: "FOLLOW_REMINDER",
        name: "待跟进提醒",
    },
};
/**
 * 消息状态
 */
export var messageState = {
    read: {
        name: "已读消息",
        value: "Read",
    },
    unRead: {
        name: "未读消息",
        value: "UnRead",
    },
};
/**
 * 调收方式
 */
export var caseType;
(function (caseType) {
    /**
     * 电调
     */
    caseType[caseType["call"] = 15] = "call";
    /**
     * 外访
     */
    caseType[caseType["visit"] = 16] = "visit";
    /**
     * 协调
     */
    caseType[caseType["assist"] = 17] = "assist";
    /**
     * 委外
     */
    caseType[caseType["outside"] = 18] = "outside";
    /**
     * 提醒
     */
    caseType[caseType["remind"] = 19] = "remind";
    /**
     * other
     */
    caseType[caseType["complex"] = 217] = "complex";
})(caseType || (caseType = {}));
// 案件状态
export var caseState;
(function (caseState) {
    /**
     * 20 待调收
     */
    caseState[caseState["waitCollection"] = 20] = "waitCollection";
    /**
     * 21 调收中
     */
    caseState[caseState["collection"] = 21] = "collection";
    /**
     * 22 逾期还款中
     */
    caseState[caseState["overPaying"] = 22] = "overPaying";
    /**
     * 提前结清还款中
     */
    caseState[caseState["earlyPaying"] = 23] = "earlyPaying";
    /**
     * 24 已结案
     */
    caseState[caseState["caseOver"] = 24] = "caseOver";
    /**
     * 25 待分配
     */
    caseState[caseState["waitForDis"] = 25] = "waitForDis";
    /**
     * 166 已委外
     */
    caseState[caseState["caseOut"] = 166] = "caseOut";
    /**
     * 171 已还款
     */
    caseState[caseState["repaid"] = 171] = "repaid";
    /**
     * 172 部分已还款
     */
    caseState[caseState["partRepaid"] = 172] = "partRepaid";
})(caseState || (caseState = {}));
/**
 * 跟进类型
 */
export var followType;
(function (followType) {
    /**
     * 电话调收
     */
    followType[followType["call"] = 78] = "call";
    /**
     * 外访调收
     */
    followType[followType["visit"] = 79] = "visit";
    /**
     * 协助调收
     */
    followType[followType["assist"] = 162] = "assist";
})(followType || (followType = {}));
/**
 * 跟进记录数据来源
 */
export var sourceType;
(function (sourceType) {
    /**
     * 电话
     */
    sourceType[sourceType["call"] = 80] = "call";
    /**
     * 外访
     */
    sourceType[sourceType["visit"] = 81] = "visit";
    /**
     * 协调
     */
    sourceType[sourceType["assist"] = 203] = "assist";
})(sourceType || (sourceType = {}));
// 协调状态
export var assistState = {
    approveing: 26,
    refused: 27,
    collection: 28,
    complated: 29,
    waitAssign: 117,
    waitAcc: 118,
    failure: 212,
};
export var dashboardRankType = {
    amount: 0,
    count: 1,
    radio: 2,
};
export var dashboardTimeType = {
    year: 0,
    month: 1,
    week: 2,
};
export var dashboardQueryType = {
    inner: 1,
    outer: 2,
};
export var templateType = {
    101: {
        code: "MessageTemplate",
        name: "消息模版",
    },
    103: {
        code: "EmailTemplate",
        name: "邮件模版",
    },
    104: {
        code: "LetterTemplate",
        name: "信函模版",
    },
    105: {
        code: "CallAccTemplate",
        name: "电调话术",
    },
};
export var templateVarType = [
    { name: "姓名", value: "$personalName" },
    // { name: '金额', value: '$overdueAmount' },
    // { name: '案件编号', value: '$caseNumber' },
    // { name: '逾期日期', value: '$perdueDate' },
    { name: "合同编号", value: "$contractNumber" },
    { name: "合同金额", value: "$contractAmount" },
    { name: "逾期天数", value: "$overdueDays" },
    // {name: "逾期实际还款金额", value: "$realPayAmount"},
    { name: "承诺还款日期", value: "$promiseTime" },
];
export var monthList = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
export var weekList = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
/**
 * 导出信息(设置更新型)基础数据信息
 * @prop baseDataOptions 各个维度得基本数据
 * @prop productBasic 产品信息基本数据
 * @prop productWork 产品信息工作信息
 * @prop productContact 产品信息联系人
 * @prop productOpenAccount 产品信息账户
 */
export var exportSelectionData = {
    // 基础数据
    baseDataOptions: ["机构名称", "客户姓名", "身份证号", "联系电话", "归属城市", "总期数", "逾期天数", "逾期金额", "贷款日期", "逾期期数"],
    // 基础数据信息
    productBasic: ["客户姓名", "身份证号", "归属城市", "手机号", "身份证户籍地址", "家庭地址", "家庭电话", "产品名称", "产品系列"],
    // 工作信息
    productWork: ["工作单位名称", "工作单位地址", "工作单位电话"],
    //联系人信息
    productContact: ["关系", "姓名", "手机号码", "住宅电话", "现居住地址", "工作单位", "单位电话"],
    //  开户信息
    productOpenAccount: ["还款卡银行", "还款卡号"],
};
export var selectionUpData = {
    // 客户基本信息
    productBasic: ["客户姓名", "身份证号", "手机号码", "身份证户籍地址", "家庭地址", "固定电话"],
    // 工作信息
    productWork: ["工作单位名称", "工作单位地址", "工作单位电话"],
    // 案件基本信息
    baseDataOptions: [
        "产品系列",
        "合同编号",
        "贷款日期",
        "合同金额",
        "剩余本金(元)",
        "剩余利息(元)",
        "逾期总金额(元)",
        "逾期本金(元)",
        "逾期利息(元)",
        "逾期罚息(元)",
        "还款期数",
        "每期还款金额(元)",
        "其他费用(元)",
        "逾期日期",
        "逾期天数",
        "已还款金额(元)",
        "已还款期数",
        "最近还款日期",
        "最近还款金额(元)",
        "佣金比例(%)",
    ],
    //  开户信息
    productOpenAccount: ["还款卡银行", "还款卡号"],
};
export var exportItemsUpData = {
    // 客户基本信息
    productBasic: ["客户姓名", "身份证号", "省份", "城市", "手机号码", "身份证户籍地址", "家庭地址", "固定电话"],
    // 工作信息
    productWork: ["工作单位名称", "工作单位地址", "工作单位电话"],
    //联系人信息
    productContact: ["姓名", "手机号码", "住宅电话", "现居住地址", "与客户关系", "工作单位", "单位电话"],
    // 案件基本信息
    baseDataOptions: [
        "产品系列",
        "合同编号",
        "贷款日期",
        "合同金额",
        "剩余本金(元)",
        "剩余利息(元)",
        "逾期总金额(元)",
        "逾期本金(元)",
        "逾期利息(元)",
        "逾期罚息(元)",
        "还款期数",
        "每期还款金额(元)",
        "其他费用(元)",
        "逾期日期",
        "逾期天数",
        "已还款金额(元)",
        "已还款期数",
        "最近还款日期",
        "最近还款金额(元)",
        "佣金比例(%)",
    ],
    //  开户信息
    productOpenAccount: ["还款卡银行", "还款卡号"],
    // 跟进信息
    followMessage: ["跟进时间", "跟进方式", "调收对象", "姓名", "电话/地址", "调收反馈", "跟进记录", "跟进人员"],
};
// 数据状态
export var caseDataStatus = {
    approveing: 26,
    refused: 27,
    collection: 28,
    complated: 29,
    waitAssign: 117,
    waitAcc: 118,
    failure: 212,
};
/**
 * 数据修复模块名称
 */
export var dataRepair;
(function (dataRepair) {
    /**
     * 户籍资料
     */
    dataRepair["kosekiData"] = "koseki_data";
    /**
     * 户籍备注
     */
    dataRepair["kosekiRemark"] = "koseki_remark";
    /**
     * 关联关系
     */
    dataRepair["relationship"] = "relationship";
    /**
     * 社保资料
     */
    dataRepair["socialSecurityData"] = "social_security_data";
    /**
     * 村委资料
     */
    dataRepair["villageCommitteeData"] = "village_committee_data";
    /**
     * 通讯资料
     */
    dataRepair["communicationData"] = "communication_data";
    /**
     * 特调计生资料
     */
    dataRepair["specialTransferData"] = "special_transfer_data";
})(dataRepair || (dataRepair = {}));
