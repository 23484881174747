var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from '~/config/server';
import { Inject, Debounce } from "~/core/decorator";
var TemplateService = /** @class */ (function () {
    function TemplateService() {
    }
    /**
     * 模板按条件查询
     */
    TemplateService.prototype.getAllTemplates = function (data, page, loading) {
        return this.netService.send({
            server: manageService.templateController.getAllTemplates,
            data: data,
            page: page,
            loading: loading
        });
    };
    /**
     * 判断修改模板名称、编号是否可用
     */
    TemplateService.prototype.getTemplateByNameOrCode = function (data) {
        return this.netService.send({
            server: manageService.templateController.getTemplateByNameOrCode,
            data: {
                id: data.id,
                companyName: data.companyName,
                companyCode: data.companyCode
            }
        });
    };
    /**
     * 新增模板信息
     */
    TemplateService.prototype.createTemplate = function (data, loading) {
        return this.netService.send({
            server: manageService.templateController.createTemplate,
            data: data,
            loading: loading
        });
    };
    /**
     * 更新模板信息
     */
    TemplateService.prototype.updateTemplate = function (data, loading) {
        return this.netService.send({
            server: manageService.templateController.updateTemplate,
            data: data,
            loading: loading
        });
    };
    /**
     * 根据id删除模板
     */
    TemplateService.prototype.deleteTemplateById = function (data) {
        return this.netService.send({
            server: manageService.templateController.deleteTemplateById,
            data: {
                id: data
            }
        });
    };
    /**
     * 模板按条件查询
     * @param type 模板类型
     */
    TemplateService.prototype.getTemplateByType = function (type, loading) {
        return this.netService.send({
            server: manageService.templateController.getTemplateByType,
            data: {
                templateType: type
            },
            loading: loading
        });
    };
    /**
     * 获取不同委托方下模板
     */
    TemplateService.prototype.getTemplate = function (type, principalId, loading) {
        return this.netService.send({
            server: manageService.templateController.getTemplate,
            data: {
                type: type,
                principalId: principalId
            },
            loading: loading
        });
    };
    __decorate([
        Inject()
    ], TemplateService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "getAllTemplates", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "getTemplateByNameOrCode", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "createTemplate", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "updateTemplate", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "deleteTemplateById", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "getTemplateByType", null);
    __decorate([
        Debounce()
    ], TemplateService.prototype, "getTemplate", null);
    return TemplateService;
}());
export { TemplateService };
