import { requestType } from "~/config/enum.config";
// 报表相关
var SERVICE = "report-service";
var CONTROLLER = "reportController";
export default {
    /**
     * 导出调收员回款报表
     */
    export_back_money_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportBackMoneyReport",
        type: requestType.Get,
    },
    /**
     * 导出调收员每日调收过程报表
     */
    export_daily_process_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportDailyProcessReport",
        type: requestType.Get,
    },
    /**
     * 导出调收员每日调收结果报表
     */
    export_daily_result_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportDailyResultReport",
        type: requestType.Get,
    },
    /**
     * 导出调收员业绩进展报表
     */
    export_performance_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportPerformanceReport",
        type: requestType.Get,
    },
    /**
     * 调收员回款报表
     */
    get_back_money_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBackMoneyReport",
        type: requestType.Get,
    },
    /**
     * 调收员每日调收过程报表
     */
    get_daily_process_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getDailyProcessReport",
        type: requestType.Get,
    },
    /**
     * 调收员每日调收结果报表
     */
    get_daily_result_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getDailyResultReport",
        type: requestType.Get,
    },
    /**
     * 调收员业绩进展报表
     */
    get_performance_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPerformanceReport",
        type: requestType.Get,
    },
    /**
     * 调收员业绩报名汇总报表
     */
    get_group_leader_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getGroupLeaderReport",
        type: requestType.Get,
    },
    /**
     * 调收员业绩报名小组汇总报表
     */
    get_summary_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getSummaryReport",
        type: requestType.Get,
    },
    /**
     * 调收员业绩排名报表
     */
    get_performance_ranking_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPerformanceRankingReport",
        type: requestType.Get,
    },
    /**
     * 导出调收员业绩排名报表
     */
    export_performance_ranking_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportPerformanceRankingReport",
        type: requestType.Get,
    },
    /**
     * 导出调收员业绩排名小组汇总报表
     */
    export_summary_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportSummaryReport",
        type: requestType.Get,
    },
    /**
     * 短信发送统计报表
     */
    get_sms_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getSmsReport",
        type: requestType.Get,
    },
    /**
     * 导出短信发送统计报表
     */
    export_sms_report: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportSmsReport",
        type: requestType.Get,
    },
    /**
     * 查询行为过程监控表
     */
    getBehavioralProcess: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBehavioralProcess",
        type: requestType.Get,
    },
    /**
     *查询委托方回款报表
     */
    getPrincipalPayment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getPrincipalPayment",
        type: requestType.Get,
    },
    /**
     *导出行为过程监控表
     */
    exportBehavioralProcess: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportBehavioralProcess",
        type: requestType.Get,
    },
    /**
     *导出委托方回款报表
     */
    exportPrincipalPayment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportPrincipalPayment",
        type: requestType.Get,
    },
    /**
     *查询每日调收统计
     */
    dailyCollectionStatistics: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "dailyCollectionStatistics",
        type: requestType.Get,
    },
    /**
     *导出每日调收统计
     */
    exportDailyStatistics: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportDailyStatistics",
        type: requestType.Get,
    },
    /**
     *查询调收员回款报表
     */
    getUserPayment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getUserPayment",
        type: requestType.Get,
    },
    /**
     *导出调收员回款报表
     */
    exportUserPayment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportUserPayment",
        type: requestType.Get,
    },
};
