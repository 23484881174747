var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var VillageCommitteeDataService = /** @class */ (function () {
    function VillageCommitteeDataService() {
    }
    /**
     * 村委资料查询
     */
    VillageCommitteeDataService.prototype.search = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.search,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 村委资料创建
     */
    VillageCommitteeDataService.prototype.insert = function (data, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.insert,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除村委资料
     */
    VillageCommitteeDataService.prototype.delete = function (data, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.delete,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改村委资料
     */
    VillageCommitteeDataService.prototype.update = function (data, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.update,
            data: data,
            loading: loading
        });
    };
    /**
     * 关键字查询村委资料
     */
    VillageCommitteeDataService.prototype.searchByKeyword = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.searchByKeyword,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 按导入批次删除户籍资料
     */
    VillageCommitteeDataService.prototype.deleteByBatch = function (batchNumber, loading) {
        return this.netService.send({
            server: repairService.villageCommitteeDataController.deleteByBatch,
            data: {
                batchNumber: batchNumber
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], VillageCommitteeDataService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], VillageCommitteeDataService.prototype, "search", null);
    return VillageCommitteeDataService;
}());
export { VillageCommitteeDataService };
