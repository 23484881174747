var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isDate, getTime } from "date-fns";
/**
 * 调收执行页面用到的重要数据
 */
var CollectionData = /** @class */ (function () {
    /**
     * @param mPrincipalId 委托方ID
     * @param mCaseId 案件ID
     * @param mQueryData 查询条件
     * @param mOtherData 附加数据
     */
    function CollectionData(mPrincipalId, mCaseId, mQueryData, mOtherData) {
        this.mPrincipalId = mPrincipalId;
        this.mCaseId = mCaseId;
        this.mOtherData = mOtherData;
        this.convertDate(mQueryData);
    }
    /**
     * 处理数据中的Date/DateTime格式
     * @param key
     */
    CollectionData.prototype.convertDate = function (data) {
        var _this = this;
        this.mQueryData = __assign({}, data);
        Object.keys(this.mQueryData)
            .filter(function (x) {
            var key = x.toLocaleLowerCase();
            return key.includes("date") || key.includes("time");
        })
            .forEach(function (key) {
            var value = data[key];
            if (!value)
                return;
            if (Array.isArray(value)) {
                _this.mQueryData[key] = value.map(function (v) {
                    if (isDate(v))
                        return getTime(v);
                    return v;
                });
            }
            else {
                if (isDate(value)) {
                    _this.mQueryData[key] = getTime(value);
                }
            }
        });
    };
    Object.defineProperty(CollectionData.prototype, "principalId", {
        /**
         * 当前委托方ID
         */
        get: function () {
            return this.mPrincipalId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionData.prototype, "caseId", {
        /**
         * 当前案件ID
         */
        get: function () {
            return this.mCaseId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionData.prototype, "queryData", {
        get: function () {
            return this.mQueryData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionData.prototype, "otherData", {
        /**
         * 其他附加数据
         */
        get: function () {
            return this.mOtherData;
        },
        enumerable: false,
        configurable: true
    });
    return CollectionData;
}());
export { CollectionData };
