var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var StrategyConfigService = /** @class */ (function (_super) {
    __extends(StrategyConfigService, _super);
    function StrategyConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 新增策略实体
     * @param data 策略实体
     * @param loading 加载等待
     */
    StrategyConfigService.prototype.addStrategyConfig = function (data, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.addStrategyConfig,
            data: data,
            loading: loading
        });
    };
    /**
     * 根据ID查询分配策略实体
     * @param strategyId 策略Id
     * @param loading 加载等待
     */
    StrategyConfigService.prototype.getStrategyConfigById = function (strategyId, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.getStrategyConfigById,
            append: [strategyId],
            loading: loading
        });
    };
    /**
     * 修改分配策略
     * @param data 策略实体
     * @param loading 加载等待
     */
    StrategyConfigService.prototype.updateStrategyConfig = function (data, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.updateStrategyConfig,
            data: data,
            loading: loading
        });
    };
    /**
     * 查询策略列表
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     * @param loading 加载等待
     */
    StrategyConfigService.prototype.searchStrategy = function (data, page, sort, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.searchStrategy,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
    * 导入策略
    * @param data 查询参数实体
    * @param loading 加载等待
    */
    StrategyConfigService.prototype.strategyImport = function (data, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.strategyImport,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除策略
     * @param id 关键字
     * @param loading 加载等待
     */
    StrategyConfigService.prototype.deletetSrategyConfig = function (id, loading) {
        return this.netService.send({
            server: managementService.strategyConfigController.deletetSrategyConfig,
            append: [id],
            loading: loading
        });
    };
    /**
   * 查询策略
   * @param data 查询参数实体
   *
   */
    StrategyConfigService.prototype.searchByPrincipal = function (data) {
        return this.netService.send({
            server: managementService.strategyConfigController.searchByPrincipal,
            data: data
        });
    };
    __decorate([
        Inject(NetService)
    ], StrategyConfigService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], StrategyConfigService.prototype, "addStrategyConfig", null);
    __decorate([
        Debounce()
    ], StrategyConfigService.prototype, "updateStrategyConfig", null);
    return StrategyConfigService;
}(Service));
export { StrategyConfigService };
