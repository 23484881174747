var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import DefaultLauout from "~/layouts/default.layout.vue";
import WorkspaceLayout from "~/layouts/workspace.layout.vue";
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(App.prototype, "getCacheLayout", {
        get: function () {
            var cache = [];
            if (this.userToken) {
                cache.push("WorkSpaceLayout");
            }
            return cache;
        },
        enumerable: false,
        configurable: true
    });
    App.prototype.created = function () {
        // WaterMark.init({ watermark_txt: "指旺金科贷后调解系统"});
    };
    __decorate([
        State
    ], App.prototype, "ready", void 0);
    __decorate([
        State
    ], App.prototype, "layout", void 0);
    __decorate([
        State
    ], App.prototype, "userToken", void 0);
    App = __decorate([
        Component({
            components: {
                default: DefaultLauout,
                workspace: WorkspaceLayout
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
