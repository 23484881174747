var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout } from '~/core/decorator';
import AdjustCityImportList from "~/components/case-manage/adjust-city-import/adjust-city-import-list.vue";
import AdjustCityImportDetails from "~/components/case-manage/adjust-city-import/adjust-city-import-details.vue";
var AdjustCityImpor = /** @class */ (function (_super) {
    __extends(AdjustCityImpor, _super);
    function AdjustCityImpor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentComponent = "AdjustCityImportList";
        _this.operBatchNumber = "";
        return _this;
    }
    /**
     * 点击案件详情
     */
    AdjustCityImpor.prototype.onClickCaseDetail = function (rowData) {
        this.operBatchNumber = rowData.operBatchNumber;
        this.currentComponent = "AdjustCityImportDetails";
    };
    /**
     * 返回案件导入列表
     */
    AdjustCityImpor.prototype.onBackCaseList = function () {
        this.currentComponent = "AdjustCityImportList";
    };
    AdjustCityImpor = __decorate([
        Layout('workspace'),
        Component({
            components: {
                AdjustCityImportList: AdjustCityImportList,
                AdjustCityImportDetails: AdjustCityImportDetails
            }
        })
    ], AdjustCityImpor);
    return AdjustCityImpor;
}(Vue));
export default AdjustCityImpor;
