import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'caseRecodingController';
export default {
    /**
     * 添加app版本
     */
    downloadRecodingPackage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'downloadRecodingPackage',
        type: requestType.Get
    },
};
