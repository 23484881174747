var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { KosekiDataService } from "~/services/repair-service/koseki-data.service";
import AddDetail from "~/components/data-repair/census-data/add-detail.vue";
import EditDetail from "~/components/data-repair/census-data/edit-detail.vue";
import CensusDetail from "~/components/data-repair/census-data/census-detail.vue";
import ImportBatchDeletion from "~/components/data-repair/census-data/import-batch-deletion.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
import RepairDataImport from "~/components/data-repair/repair-data-import.vue";
import { dataRepair } from "~/config/enum.config";
//@Auth(105)
var CensusData = /** @class */ (function (_super) {
    __extends(CensusData, _super);
    function CensusData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.rowData = {};
        _this.importType = "HOUSEHOLD_REGISTER_RESOURCE"; //户籍资料
        _this.currentType = dataRepair;
        _this.censusModel = {};
        _this.editModel = {};
        _this.dataSet = [];
        _this.kosekiModel = {
            id: "",
            name: "",
            idNo: "",
            bank: "",
            koseki: "",
            applyTransferArea: "",
            remark: "",
            applyFileContent: ""
        };
        _this.data = {};
        _this.dialog = {
            detail: false,
            edit: false,
            import: false,
            deletion: false
        };
        return _this;
    }
    CensusData.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * keep-alive生命周期钩子函数
     */
    CensusData.prototype.activated = function () {
        // 加载数据
        this.refreshData();
    };
    /**
     * 查询户籍数据
     */
    CensusData.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.kosekiDataService
            .search(this.kosekiModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    //添加户籍弹窗
    CensusData.prototype.addClick = function () {
        this.dialog.detail = true;
    };
    CensusData.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    /**
     * 获取下载模板
     */
    CensusData.prototype.downLoadTemplate = function () {
        var _this = this;
        this.kosekiModel.applyFileContent = this.importType;
        this.dataRepairImportService
            .getSystemTemplate(this.kosekiModel, this.loading)
            .subscribe(function (data) {
            if (data) {
                _this.uploadFileService
                    .getFileStreamById(data.value)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
        this.kosekiModel.applyFileContent = null;
    };
    /**
     * 查看户籍详情
     */
    CensusData.prototype.viewDetails = function (row) {
        this.rowData = row;
        this.dialog.census = true;
    };
    /**
     * 删除户籍数据
     */
    CensusData.prototype.deleteKoseki = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.kosekiDataService
                .delete(scope.row, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        }, function (err) {
            _this.$message.error(err.msg);
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消删除"
            });
        });
    };
    /**
     * 修改户籍弹框
     */
    CensusData.prototype.editKoseki = function (scope) {
        this.editModel = scope.row;
        this.dialog.edit = true;
    };
    CensusData.prototype.importBatchDeletion = function () {
        this.dialog.deletion = true;
    };
    __decorate([
        Dependencies(PageService)
    ], CensusData.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CensusData.prototype, "sortService", void 0);
    __decorate([
        Dependencies(KosekiDataService)
    ], CensusData.prototype, "kosekiDataService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], CensusData.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(DataRepairImportService)
    ], CensusData.prototype, "dataRepairImportService", void 0);
    CensusData = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddDetail: AddDetail,
                EditDetail: EditDetail,
                CensusDetail: CensusDetail,
                RepairDataImport: RepairDataImport,
                ImportBatchDeletion: ImportBatchDeletion
            }
        })
    ], CensusData);
    return CensusData;
}(Vue));
export default CensusData;
