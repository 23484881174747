var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
var collectionManageModule = namespace("collection-manage");
var LetterModify = /** @class */ (function (_super) {
    __extends(LetterModify, _super);
    function LetterModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            caseId: "",
            applyRemark: "",
            flowType: "LETTER_PRINT_APPLY",
            addressDetail: "",
            addressType: "",
            personalName: "",
            personalAddressId: "",
            relationShip: "",
            caseType: ""
        };
        _this.rules = {
            applyRemark: [
                { required: true, message: "请输入申请说明", trigger: "blur" }
            ]
        };
        return _this;
    }
    LetterModify.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    LetterModify.prototype.setPropertyValue = function () {
        // 维安人员id
        this.model.personalId = this.currentCase.personalId;
        // 案件id
        this.model.caseId = this.caseId;
        // 地址信息
        this.model.addressDetail = this.currentAddress.addressDetail;
        // 地址类型
        this.model.addressType = this.currentAddress.addressType;
        // 姓名
        this.model.personalName = this.currentAddress.name;
        // 地址id
        this.model.personalAddressId = this.currentAddress.id;
        // 关系
        this.model.relationShip = this.currentAddress.relation;
        //案件类型
        this.model.caseType = this.otherData.caseType;
    };
    /**
     * 信函申请提交
     */
    LetterModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.setPropertyValue();
            _this.loading.state = true;
            _this.caseApplyManageService.caseApplyOperated(_this.model, _this.loading).subscribe(function (data) {
                _this.$message.success("信函申请成功!");
                _this.updateAssistCount();
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], LetterModify.prototype, "caseApplyManageService", void 0);
    __decorate([
        collectionManageModule.State
    ], LetterModify.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], LetterModify.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.State
    ], LetterModify.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], LetterModify.prototype, "updateAssistCount", void 0);
    __decorate([
        Prop()
    ], LetterModify.prototype, "currentAddress", void 0);
    __decorate([
        Emit("close")
    ], LetterModify.prototype, "close", null);
    LetterModify = __decorate([
        Component({
            components: {}
        })
    ], LetterModify);
    return LetterModify;
}(Vue));
export default LetterModify;
