var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import DataBox from "~/components/common/data-box.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { SpecialTransferDataService } from "~/services/repair-service/special-transfer-data.service";
import ViewImage from "~/components/common/view-image.vue";
var SpecialDetail = /** @class */ (function (_super) {
    __extends(SpecialDetail, _super);
    function SpecialDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.personalInformation = [];
        _this.dialog = { view: false };
        _this.url = "";
        return _this;
    }
    SpecialDetail.prototype.downloadFile = function (row) {
        this.url = row.fileUrl;
        this.dialog.view = true;
    };
    SpecialDetail.prototype.refresh = function () {
        var _this = this;
        this.loading.state = true;
        this.specialTransferDataService.searchDetails(this.rowDetail.id, this.loading)
            .subscribe(function (data) {
            _this.personalInformation = data.map(function (x) {
                var model = {
                    fileName: x.fileName,
                    fileUrl: x.fileUrl,
                    relation: x.relation,
                    certificatesType: x.certificatesType,
                    remark: x.remark,
                    type: x.type,
                    relationIdNo: x.relationIdNo,
                };
                if (Object.values(model).every(function (x) { return x === ""; })) {
                    return null;
                }
                return model;
            }).filter(function (n) { return !!n; });
        }, function (_a) {
            var msg = _a.msg;
            _this.$message.error(msg);
        });
    };
    __decorate([
        Dependencies(UploadFileService)
    ], SpecialDetail.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], SpecialDetail.prototype, "specialTransferDataService", void 0);
    __decorate([
        State
    ], SpecialDetail.prototype, "departmentList", void 0);
    __decorate([
        Prop({ required: true })
    ], SpecialDetail.prototype, "rowDetail", void 0);
    SpecialDetail = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DataBox: DataBox,
                ViewImage: ViewImage
            }
        })
    ], SpecialDetail);
    return SpecialDetail;
}(Vue));
export default SpecialDetail;
