var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State, Getter } from "vuex-class";
import { FilterService } from "~/utils/filter.service";
import { UserService } from "~/services/manage-service/user.service";
import { CollectorBackMoneyService } from "~/services/report-service/collector-back-money.service";
var CollectorBackMoney = /** @class */ (function (_super) {
    __extends(CollectorBackMoney, _super);
    function CollectorBackMoney() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.dateShow = ""; //日期显示隐藏
        _this.searchModel = {
            departCode: "",
            userIds: [],
            type: "REAL_TIME",
            startDate: "",
            endDate: "",
        };
        _this.dataSet = [];
        _this.deptIds = [];
        _this.collectorFromList = [];
        _this.validStart = function (rule, value, callback) {
            var startTime = new Date(value).getTime();
            var endTime = new Date(_this.searchModel.endDate).getTime();
            if (endTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("起始时间不能大于截止时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.validEnd = function (rule, value, callback) {
            var startTime = new Date(_this.searchModel.startDate).getTime();
            var endTime = new Date(value).getTime();
            if (startTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("截止时间不能小于起始时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.rules = {
            startDate: [
                { required: true, message: "请选择起始时间", trigger: "change" },
                { validator: _this.validStart, trigger: "change" },
            ],
            endDate: [
                { required: true, message: "请选择截止时间", trigger: "change" },
                { validator: _this.validEnd, trigger: "change" },
            ],
        };
        return _this;
    }
    CollectorBackMoney.prototype.mounted = function () { };
    /**
     * 起止到截止时间间隔
     */
    CollectorBackMoney.prototype.timeLong = function () {
        var start = new Date(this.searchModel.startDate);
        var end = new Date(this.searchModel.endDate);
        var long = FilterService.dateLong(start, end);
        return long;
    };
    /**
     * 根据部门code查询调解员
     */
    CollectorBackMoney.prototype.orgChange = function (valueArray) {
        var _this = this;
        this.searchModel.userIds = ""; // 清空调解员选中
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.departCode = valueArray[valueArray.length - 1];
        this.userService.getSimpleUser(this.searchModel.departCode).subscribe(function (data) {
            _this.collectorFromList = data;
        });
    };
    CollectorBackMoney.prototype.handleResetForm = function () {
        this.deptIds = [];
        this.searchModel.departCode = "";
        this.dateShow = "";
        this.searchModel.startDate = "";
        this.searchModel.endDate = "";
    };
    /**
     * 刷新列表
     */
    CollectorBackMoney.prototype.refreshData = function () {
        var _this = this;
        /* 查询日期超过3个月不查询 */
        if (this.timeLong() > 90) {
            this.$message("请选择三个月内数据进行查询，大于三个月数据请使用导出功能导出查看");
            return;
        }
        this.loading.state = true;
        this.collectorBackMoneyService.getUserPayment(this.searchModel, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 导出数据
     */
    CollectorBackMoney.prototype.exportDataClick = function () {
        var _this = this;
        this.collectorBackMoneyService.exportUserPayment(this.searchModel, this.loading).subscribe(function (data) {
            _this.$message.success("正在导出,请关注任务中心!");
            /* 查询日期小于3个月刷新列表 */
            if (_this.timeLong() > 90) {
                _this.refreshData();
            }
            else {
                _this.dataSet = [];
            }
        });
    };
    CollectorBackMoney.prototype.valueChange = function (value) {
        /* 类型为历史时展示时间 */
        this.dateShow = value;
        if (this.dateShow === "REAL_TIME") {
            this.searchModel.startDate = "";
            this.searchModel.endDate = "";
        }
    };
    __decorate([
        Dependencies(UserService)
    ], CollectorBackMoney.prototype, "userService", void 0);
    __decorate([
        Dependencies(CollectorBackMoneyService)
    ], CollectorBackMoney.prototype, "collectorBackMoneyService", void 0);
    __decorate([
        Getter
    ], CollectorBackMoney.prototype, "departmentData", void 0);
    __decorate([
        State
    ], CollectorBackMoney.prototype, "clientList", void 0);
    CollectorBackMoney = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
            },
        })
    ], CollectorBackMoney);
    return CollectorBackMoney;
}(Vue));
export default CollectorBackMoney;
