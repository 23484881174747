import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'complianceDataConfigController';
export default {
    /**
     * 查询配置
     */
    queryConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryConfig",
        type: requestType.Get
    },
    /**
     * 刷新缓存
     */
    refreshRedis: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "refreshRedis",
        type: requestType.Get
    },
    /**
     * 状态启停
     */
    updateConfigStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateConfigStatus",
        type: requestType.Post
    },
    /**
     * 删除操作
     */
    deleteConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteConfig",
        type: requestType.Get
    },
    /**
     * 查询单个配置
     */
    getConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getConfig",
        type: requestType.Get
    },
    /**
     * 增加配置
     */
    addConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addConfig",
        type: requestType.Post
    },
    /**
     * 修改配置
     */
    updateConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateConfig",
        type: requestType.Post
    },
    /**
     * 获取选中的资源
     */
    getComplianceResource: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getComplianceResource",
        type: requestType.Get
    },
    /**
     * 保存选中的资源
     */
    addComplianceResource: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addComplianceResource",
        type: requestType.Post
    }
};
