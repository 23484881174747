import operatorsController from '~/config/server/management-service/operators.controller';
import organizationController from '~/config/server/management-service/organization.controller';
import templateDataController from '~/config/server/management-service/template-data.controller';
import systemParaController from '~/config/server/management-service/system-param.controller';
import caseStatusController from '~/config/server/management-service/case-status.controller';
import custConfig from '~/config/server/management-service/cust-config.controller';
import processConfigController from '~/config/server/management-service/process-config.controller';
import exportConfigController from '~/config/server/management-service/export-config.controller';
import periodTransformController from '~/config/server/management-service/period-transform-controller';
import complianceConfigController from '~/config/server/management-service/compliance-config.controller';
import userDeviceController from '~/config/server/management-service/user-device.controller';
import strategyConfigController from '~/config/server/management-service/strategy-config.controller';
import impDataToSysData from "~/config/server/management-service/imp-data-to-sys-data.controller";
import operatorLoginLog from '~/config/server/management-service/login-log.controller';
import complianceDataConfigController from "~/config/server/management-service/compliance-data-config.controller";
import exportLetterController from '~/config/server/management-service/export-letter.controller';
export var managementService = {
    operatorsController: operatorsController,
    organizationController: organizationController,
    templateDataController: templateDataController,
    systemParaController: systemParaController,
    caseStatusController: caseStatusController,
    custConfig: custConfig,
    processConfigController: processConfigController,
    exportConfigController: exportConfigController,
    periodTransformController: periodTransformController,
    complianceConfigController: complianceConfigController,
    userDeviceController: userDeviceController,
    strategyConfigController: strategyConfigController,
    impDataToSysData: impDataToSysData,
    operatorLoginLog: operatorLoginLog,
    complianceDataConfigController: complianceDataConfigController,
    exportLetterController: exportLetterController
};
