var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { dashboardTimeType, monthList, weekList } from "~/config/enum.config";
import appConfig from "~/config/app.config";
import { getDaysInMonth, addDays, startOfYear, endOfYear, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from "date-fns";
import { FilterService } from "~/utils/filter.service";
/**
 * 公共函数
 */
var CommonService = /** @class */ (function () {
    function CommonService() {
    }
    /**
     * 获取组件名称
     * @param path
     */
    CommonService.getComponentName = function (path) {
        var pathArray = path.split(".")[0].split("/");
        return ("-" + pathArray[pathArray.length - 1]).replace(/\-(\w)/g, function ($0, $1) {
            return $1.toUpperCase();
        });
    };
    /**
     * 获取日期列表
     */
    CommonService.getDateList = function (date, type) {
        var result = [];
        switch (type) {
            case dashboardTimeType.year:
                result = monthList;
                break;
            case dashboardTimeType.month:
                var days = getDaysInMonth(date);
                for (var i = 1; i <= days; i++) {
                    result.push(i.toString().padStart(2, "0") + "\u65E5");
                }
                break;
            case dashboardTimeType.week:
                result = weekList;
                break;
            default:
                break;
        }
        return result;
    };
    /**
     * 返回此时系统的年月日数组
     */
    CommonService.getDate = function () {
        var myDate = new Date();
        return [myDate.getFullYear(), myDate.getMonth() + 1, myDate.getDate()];
    };
    /**
     * 根据时间计算出各种日期单位的首尾天数
     * @param date 要计算的时间
     * @param type 0: 年,1：月,2：周
     * @returns 字符串类型 start 开始日期 end 结束日期
     */
    CommonService.getDateTimeRange = function (date, type) {
        var start = null;
        var end = null;
        if (type === 0) {
            // 年
            start = startOfYear(date);
            end = endOfYear(date);
        }
        else if (type === 1) {
            // 月
            start = startOfMonth(date);
            end = endOfMonth(date);
        }
        else if (type === 2) {
            // 周
            start = startOfWeek(date);
            end = endOfWeek(date);
        }
        else {
            start = end = new Date(date);
        }
        return {
            start: FilterService.dateFormat(start),
            end: FilterService.dateFormat(end)
        };
    };
    /**
     * 获取以某时间偏移之后的日期数组
     * @param offset 偏移量 (天)
     * @param currentDate 基准日期,不传则为当前日期
     */
    CommonService.getDateArrayOnOffset = function (offset, currentDate) {
        if (offset === void 0) { offset = 0; }
        currentDate = currentDate || new Date();
        var beforeDate = addDays(currentDate, offset);
        return [beforeDate, currentDate];
    };
    CommonService.base64ConvertBlob = function (dataURI, mimeString) {
        var byteString = atob(dataURI.split(",")[1]);
        if (!mimeString) {
            mimeString = dataURI
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
        }
        else {
            mimeString =
                mimeString +
                    "/" +
                    dataURI
                        .split(",")[0]
                        .split(":")[1]
                        .split(";")[0]
                        .split("/")[1];
        }
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };
    CommonService.downloadFileByStream = function (data, downloadName) {
        if (!data) {
            return;
        }
        var url = window.URL.createObjectURL(new Blob([data]));
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        link.click();
    };
    /**
     * 下载文件
     */
    CommonService.downloadFile = function (url, filename) {
        if (filename === void 0) { filename = ""; }
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
    };
    /**
     * 批量下载文件
     * @param pathList 需要下载的文件列表，需要包含 url,filename
     */
    CommonService.downloadAll = function (pathList) {
        var _this = this;
        var download = function () { return __awaiter(_this, void 0, void 0, function () {
            var _loop_1, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _loop_1 = function (index) {
                            var v;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        v = pathList[index];
                                        return [4 /*yield*/, new Promise(function (reslove) {
                                                CommonService.downloadFile(v.url, v.filename);
                                                setTimeout(function () {
                                                    reslove();
                                                }, 100);
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        index = 0;
                        _a.label = 1;
                    case 1:
                        if (!(index < pathList.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(index)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        index++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        download();
    };
    /**
     * 下载文件
     */
    CommonService.downloadImage = function (url, filename) {
        if (filename === void 0) { filename = ""; }
        return new Promise(function (reslove, reject) {
            var img = new Image();
            img.onload = function () {
                // 创建接受image的canvas
                var canvas = document.createElement("canvas");
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                // 获取canvas
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                // 得到图片的base64编码数据
                var base64 = canvas.toDataURL("application/png");
                var blob = CommonService.base64ConvertBlob(base64, "application");
                var aTag = document.createElement("a");
                var objectURL = URL.createObjectURL(blob);
                aTag.href = objectURL;
                aTag.click();
                URL.revokeObjectURL(objectURL);
            };
            var appUrl = appConfig.url;
            if (appUrl.image) {
                img.setAttribute("crossOrigin", appUrl.image);
            }
            img.src = url;
        });
    };
    /**
     * 浏览器类型判断
     */
    CommonService._mime = function (option, value) {
        var mimeTypes = navigator.mimeTypes;
        for (var mt in mimeTypes) {
            if (mimeTypes[mt][option] === value) {
                return true;
            }
        }
        return false;
    };
    CommonService.getBrowserType = function () {
        var ua = navigator.userAgent.toLowerCase();
        var is360 = CommonService._mime("type", "application/vnd.chromium.remoting-viewer");
        var isOpera = ua.indexOf("Opera") > -1;
        if (isOpera) {
            return "opera";
        }
        else if (ua.indexOf("compatible") > -1 &&
            ua.indexOf("MSIE") > -1 &&
            !isOpera) {
            return "ie";
        }
        else if (ua.indexOf("firefox") !== -1) {
            return "firefox";
        }
        else if (ua.indexOf("safari") !== -1 && ua.indexOf("Version") !== -1) {
            return "safari";
        }
        else if (ua.indexOf("chrome") > 1 && is360) {
            return "360";
        }
        else if (ua.indexOf("chrome") > 1) {
            return "chrome";
        }
    };
    /**
     * 对象赋值
     * @param target
     * @param resource
     */
    CommonService.revertData = function (target, resource) {
        if (!target || !resource)
            return;
        Object.keys(target).forEach(function (key) {
            var value = resource[key];
            if (!value)
                return;
            if (target[key] instanceof Array) {
                target[key] = value.map(function (v) { return (__assign({}, v)); });
            }
            if (typeof target[key] === "string") {
                target[key] = value;
            }
        });
    };
    /**
     * 生成树型结构数据
     * @param sourceList 包含id 和 pid 的线性数据
     * @param options 数据项配置 配置 keyName: 主键名称,parentKeyName: 父级键名称
     */
    CommonService.generateTreeData = function (sourceList, options) {
        if (!sourceList) {
            return [];
        }
        // key
        var keyName = options ? options.keyName : "id";
        // parentKey
        var parentKeyName = options ? options.parentKeyName : "pid";
        // sort
        var sortKeyName = options ? options.sortKey : undefined;
        /**
         * 用当前节点去生成他的children节点
         * @param any node
         */
        var fun = function (node) {
            // 用找到的孩子节点去递归查找孙子节点
            var children = sourceList.filter(function (x) { return x[parentKeyName] === node[keyName]; });
            if (sortKeyName) {
                children = children.sort(function (e1, e2) { return e1[sortKeyName] - e2[sortKeyName]; });
            }
            if (children.length) {
                node.children = children;
                children.map(fun);
            }
            return node;
        };
        // 如果有排序则根据排序键进行排序
        var rootList = CommonService.findRoot(sourceList, keyName, parentKeyName);
        if (sortKeyName) {
            rootList = rootList.sort(function (e1, e2) { return e1[sortKeyName] - e2[sortKeyName]; });
        }
        var treeData = rootList.map(fun);
        return treeData;
    };
    /**
     * 查找数据源的所有根节点
     * @param dataList 要查找的数据
     * @param keyName 节点ID 标识
     * @param parentKey 节点父ID 标识
     */
    CommonService.findRoot = function (dataList, keyName, parentKey) {
        var resultList = dataList.filter(function (x) { return !x[parentKey]; });
        dataList.filter(function (x) { return !!x[parentKey]; }).forEach(findParent);
        /**
         * 递归找最终的root节点
         * @param 要去查找的节点
         */
        function findParent(currentNode) {
            /**
             * 用父ID去找自己的父亲节点
             * 如果找到说明这个节点不是最终的祖先节点
             * 否则这个节点就是祖先节点
             */
            var parent = dataList.find(function (k) { return k[keyName] === currentNode[parentKey]; });
            if (!parent) {
                if (resultList.indexOf(currentNode) < 0) {
                    resultList.push(currentNode);
                }
            }
            else {
                findParent(parent);
            }
        }
        return resultList;
    };
    return CommonService;
}());
export { CommonService };
