import { requestType } from '~/config/enum.config';
var SERVICE = 'common-service';
var CONTROLLER = 'webSocketMsgController';
export default {
    /**
     * 获取消息列表
     */
    getMsgList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getMsgList',
        type: requestType.Get
    },
    /**
     * 获取未读消息总数
     */
    getUnReadMsgCount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getUnReadMsgCount ',
        type: requestType.Get
    },
    /**
     * 删除消息
     */
    updateMsgStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updateMsgStatus ',
        type: requestType.Post
    },
    /**
     * 获取消息详情
     */
    getMsgDetail: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getMsgDetail ',
        type: requestType.Get
    },
    /**
     * 获取最新三条未读消息
     */
    getWorkbenchReminder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getWorkbenchReminder',
        type: requestType.Get
    },
    /**
     * 删除
     */
    deletedReminderMessage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deletedReminderMessage',
        type: requestType.Post
    }
};
