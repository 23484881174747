export default (function (_a) {
    var store = _a.store;
    return ({
        /**
         * 资源认证
         */
        auth: {
            bind: function (el, binding, vnode) {
                // 获取权限编码
                var authCode = binding.value;
                // 验证权限码
                if (!authCode) {
                    console.error('未传入权限');
                    return;
                }
                if (authCode < 0)
                    return;
                var hasAuth = (store.state.controlResource || []).includes(authCode);
                // 验证权限
                if (!hasAuth) {
                    el.style.display = "none";
                }
            }
        },
        iconSvg: {
            bind: function (el) {
                el.classList.add("include-svg");
            }
        }
    });
});
