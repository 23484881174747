var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject } from "~/core/decorator";
var ApprovalExportManageService = /** @class */ (function () {
    function ApprovalExportManageService() {
    }
    /**
     * 审批信息导出
     */
    ApprovalExportManageService.prototype.approvalMessageExport = function (data, loading) {
        return this.netService.send({
            server: dataimpService.approvalExportManageController.approvalMessageExport,
            data: data,
            loading: loading
        });
    };
    /**
     * 审批信息导入
     */
    ApprovalExportManageService.prototype.approvalByImportData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.approvalExportManageController.approvalByImportData,
            data: data,
            loading: loading
        });
    };
    /**
     * 查账申请导出
     */
    ApprovalExportManageService.prototype.exportPaymentCheck = function (data, loading) {
        return this.netService.send({
            server: dataimpService.approvalExportManageController.exportPaymentCheck,
            data: {
                ids: data
            },
            loading: loading
        });
    };
    /**
     * 回复导入
     */
    ApprovalExportManageService.prototype.importPaymentCheck = function (data) {
        return this.netService.send({
            server: dataimpService.approvalExportManageController.importPaymentCheck,
            append: [data]
        });
    };
    __decorate([
        Inject(NetService)
    ], ApprovalExportManageService.prototype, "netService", void 0);
    return ApprovalExportManageService;
}());
export { ApprovalExportManageService };
