var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { State } from "vuex-class";
import { Prop, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { ComplianceCheckControllerService } from "~/services/business-service/compliance-check-controller.service";
import { OrganizationService } from "~/services/management-service/organization.service";
import { ResourceService } from "~/services/manage-service/resource.service";
import CheckUser from "~/components/process-config//check-user.vue";
var ModifyDataComplianceConfig = /** @class */ (function (_super) {
    __extends(ModifyDataComplianceConfig, _super);
    function ModifyDataComplianceConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userList = [];
        _this.tableData = [];
        _this.modifyRoleData = [];
        _this.selectionList = [];
        _this.dialog = {
            selectUser: false
        };
        _this.loading = {
            state: false,
            commit: false
        };
        _this.model = {
            // id:'',
            configName: "",
            status: "ENABLE",
            principalIds: [],
            users: [],
            resourceIds: [],
            principals: []
        };
        _this.rules = {
            configName: { required: true, message: "请输入配置名称" },
            status: { required: true, message: "请选择配置状态" },
            principalIds: { required: true, message: "请选择委托方" },
            users: { required: true, message: "请选择人员" }
        };
        return _this;
    }
    ModifyDataComplianceConfig.prototype.close = function () {
        this.reset();
    };
    ModifyDataComplianceConfig.prototype.initData = function () {
        if (!this.configData)
            return;
        this.model.id = this.configData.id;
        this.model.configName = this.configData.configName;
        this.model.status = this.configData.status;
        this.model.principalIds = this.configData.principals.map(function (x) {
            return x.principalId;
        });
        this.userList = this.configData.target.map(function (x) {
            return x;
        });
        this.model.users = this.userList;
        this.modifyRoleData = this.configData.resources;
    };
    ModifyDataComplianceConfig.prototype.refreshData = function () {
        var _this = this;
        if (this.right === "EDIT") {
            this.initData();
        }
        this.resourceService.findAllResource(this.loading).subscribe(function (data) {
            try {
                _this.spanMap = new Map();
                var menuList_1 = [];
                // 目录
                var dircetorys = data
                    .filter(function (x) { return x.type === "DIRECTORY" && x.level === 1; })
                    .sort(function (x, y) { return x.sort - y.sort; })
                    .forEach(function (directory) {
                    _this.spanMap.set(directory.id, 0);
                    // 一级菜单
                    var parentMenus = data
                        .filter(function (x) { return x.level === 2 && x.parent === directory.id; })
                        .sort(function (x, y) { return x.sort - y.sort; })
                        .forEach(function (parentMenu) {
                        // 二级菜单
                        var menus = data
                            .filter(function (x) { return x.level === 3 && x.parent === parentMenu.id; })
                            .sort(function (x, y) { return x.sort - y.sort; });
                        // 目录合并的数量
                        // 一级菜单的合并数量
                        var menuParentSpan = menus.length;
                        var directorySpan = _this.spanMap.get(directory.id) + menuParentSpan;
                        // 重新设置目录合并的数量
                        _this.spanMap.set(directory.id, directorySpan);
                        // 设置一级菜单合并的数量
                        _this.spanMap.set(parentMenu.id, menuParentSpan);
                        // 生成菜单行数据
                        menus.forEach(function (menu) {
                            // 获取菜单对应的按钮
                            var controls = data
                                .filter(function (c) { return c.type === "CONTROL" && c.parent === menu.id; })
                                .sort(function (x, y) { return x.sort - y.sort; })
                                .map(function (x) {
                                return {
                                    name: x.name,
                                    id: x.id
                                };
                            });
                            var modifyCheckedControls = [];
                            if (_this.modifyRoleData && controls) {
                                controls.map(function (control) {
                                    if (_this.modifyRoleData.find(function (x) { return x.name === control.name && x.id === control.id; })) {
                                        modifyCheckedControls.push(_this.modifyRoleData.find(function (x) { return x.name === control.name && x.id === control.id; }).id);
                                    }
                                });
                            }
                            menuList_1.push({
                                dircetoryName: directory.name,
                                dircetoryId: directory.id,
                                dircetorySpan: 0,
                                dircetoryChecked: _this.modifyRoleData.filter(function (x) { return x.name === directory.name; })
                                    .length !== 0
                                    ? true
                                    : false,
                                menuParentName: parentMenu.name,
                                menuParentId: parentMenu.id,
                                menuParentSpan: menuParentSpan,
                                menuParentChecked: _this.modifyRoleData.filter(function (x) { return x.name === parentMenu.name; }).length !== 0
                                    ? true
                                    : false,
                                menuName: menu.name,
                                menuId: menu.id,
                                menuChecked: _this.modifyRoleData.filter(function (x) { return x.name === menu.name; })
                                    .length !== 0
                                    ? true
                                    : false,
                                controls: controls,
                                checkedControls: modifyCheckedControls
                                    ? modifyCheckedControls
                                    : []
                            });
                        });
                    });
                });
                menuList_1.forEach(function (x) { });
                _this.tableData = menuList_1;
            }
            catch (error) {
                console.error(error);
            }
        });
    };
    ModifyDataComplianceConfig.prototype.customSpanMethod = function (_a) {
        var row = _a.row, column = _a.column, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        if (["dircetoryId", "menuParentId"].includes(column.property)) {
            var propertyName = column.property;
            var beforRow = this.tableData[rowIndex - 1];
            if (beforRow && beforRow[propertyName] === row[propertyName]) {
                return [0, 1];
            }
            else {
                var rowSpan = this.spanMap.get(row[propertyName]);
                return [rowSpan, 1];
            }
        }
    };
    ModifyDataComplianceConfig.prototype.emitParentExec = function (row, lv) {
        if (lv === 3) {
            row.menuChecked = row.checkedControls.length > 0;
        }
        if (lv === 2) {
            var menuParents = this.tableData.filter(function (x) { return x.menuParentId === row.menuParentId; });
            // 如果菜单有一个被选中
            if (menuParents.some(function (x) { return x.menuChecked; }))
                menuParents.forEach(function (x) { return (x.menuParentChecked = true); });
            // 如果菜单没有一个被选中
            if (menuParents.every(function (x) { return !x.menuChecked; }))
                menuParents.forEach(function (x) { return (x.menuParentChecked = false); });
        }
        if (lv === 1) {
            var dircetorys = this.tableData.filter(function (x) { return x.dircetoryId === row.dircetoryId; });
            // 如果菜单有一个被选中
            if (dircetorys.some(function (x) { return x.menuParentChecked; }))
                dircetorys.forEach(function (x) { return (x.dircetoryChecked = true); });
            // 如果菜单没有一个被选中
            if (dircetorys.every(function (x) { return !x.menuParentChecked; }))
                dircetorys.forEach(function (x) { return (x.dircetoryChecked = false); });
        }
        if (lv < 1)
            return;
        this.emitParentExec(row, lv - 1);
    };
    //按钮
    ModifyDataComplianceConfig.prototype.controlCheckedChange = function (row, values) {
        if (values.length) {
            this.emitParentExec(row, 3);
        }
    };
    // 三级菜单选中设置
    ModifyDataComplianceConfig.prototype.menuCheckedChange = function (row, checked, emit) {
        row.menuChecked = checked;
        row.checkedControls = checked ? row.controls.map(function (c) { return c.id; }) : [];
        if (emit)
            return;
        this.emitParentExec(row, 2);
    };
    // 二级菜单选中设置
    ModifyDataComplianceConfig.prototype.parentMenuCheckedChange = function (row, checked, emit) {
        var _this = this;
        // 处理子
        this.tableData
            .filter(function (x) { return x.menuParentId === row.menuParentId; })
            .forEach(function (row) {
            row.menuParentChecked = checked;
            _this.menuCheckedChange(row, checked, true);
        });
        if (emit)
            return;
        this.emitParentExec(row, 1);
    };
    // 一级菜单选中设置
    ModifyDataComplianceConfig.prototype.directoryCheckedChange = function (row, checked) {
        var _this = this;
        this.tableData
            .filter(function (x) { return x.dircetoryId === row.dircetoryId; })
            .forEach(function (x) {
            x.dircetoryChecked = checked;
            _this.parentMenuCheckedChange(x, checked, true);
        });
    };
    ModifyDataComplianceConfig.prototype.success = function () {
        this.$message.success("操作成功");
        this.reset();
        this.close();
    };
    ModifyDataComplianceConfig.prototype.selectUser = function () {
        this.dialog.selectUser = true;
    };
    ModifyDataComplianceConfig.prototype.onSelected = function (users) {
        var _a;
        var userIds = this.userList.map(function (v) { return v.userId; });
        var appendUsers = users
            .filter(function (x) { return !userIds.includes(x.id); })
            .map(function (v) {
            return {
                userId: v.id,
                userName: v.realName
            };
        });
        (_a = this.userList).push.apply(_a, appendUsers);
        this.model.users = this.userList;
    };
    ModifyDataComplianceConfig.prototype.reset = function () {
        var addForm = this.$refs["add-form"];
        addForm.resetFields();
        this.model = {
            configName: "",
            status: "ENABLE",
            principalIds: [],
            users: [],
            resourceIds: [],
            principals: []
        };
        this.modifyRoleData = [];
        this.userList = [];
        this.tableData = [];
        this.model.id = "";
    };
    ModifyDataComplianceConfig.prototype.commit = function () {
        var _this = this;
        var addForm = this.$refs["add-form"];
        addForm.validate(function (v) {
            if (!v)
                return;
            _this.model.resourceIds = [];
            _this.tableData.forEach(function (x) {
                if (x.menuChecked === true) {
                    _this.model.resourceIds.push(x.dircetoryId);
                    _this.model.resourceIds.push(x.menuParentId);
                    _this.model.resourceIds.push(x.menuId);
                    x.checkedControls.forEach(function (z) { return _this.model.resourceIds.push(z); });
                }
            });
            _this.model.resourceIds = Array.from(new Set(_this.model.resourceIds));
            _this.model.principals = [];
            _this.model.users = [];
            _this.model.principalIds.map(function (x) {
                _this.model.principals.push({
                    principalId: x,
                    principalName: _this.principalList.filter(function (v) { return v.id === x; })[0].name
                });
            });
            _this.userList.map(function (x) {
                _this.model.users.push({
                    userId: x.userId,
                    userName: x.userName
                });
            });
            delete _this.model.principalIds;
            _this.save();
        });
    };
    ModifyDataComplianceConfig.prototype.save = function () {
        var _this = this;
        this.loading.commit = true;
        this.complianceCheckControllerService
            .createComplianceCheck(this.model)
            .subscribe(function (data) {
            _this.loading.commit = false;
            _this.success();
        });
    };
    __decorate([
        Dependencies(ComplianceCheckControllerService)
    ], ModifyDataComplianceConfig.prototype, "complianceCheckControllerService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], ModifyDataComplianceConfig.prototype, "organizationService", void 0);
    __decorate([
        Dependencies(ResourceService)
    ], ModifyDataComplianceConfig.prototype, "resourceService", void 0);
    __decorate([
        State
    ], ModifyDataComplianceConfig.prototype, "principalList", void 0);
    __decorate([
        Prop()
    ], ModifyDataComplianceConfig.prototype, "configData", void 0);
    __decorate([
        Prop()
    ], ModifyDataComplianceConfig.prototype, "right", void 0);
    __decorate([
        Emit("close")
    ], ModifyDataComplianceConfig.prototype, "close", null);
    __decorate([
        Emit("success")
    ], ModifyDataComplianceConfig.prototype, "success", null);
    ModifyDataComplianceConfig = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                CheckUser: CheckUser
            }
        })
    ], ModifyDataComplianceConfig);
    return ModifyDataComplianceConfig;
}(Vue));
export default ModifyDataComplianceConfig;
