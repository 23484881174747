var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { CaseInfoService } from "~/services/business-service/case-info.service";
import SvgIcon from "~/components/common/svg-icon.vue";
var CheckCase = /** @class */ (function (_super) {
    __extends(CheckCase, _super);
    function CheckCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.caseNumber1 = "";
        _this.caseNumber2 = "";
        _this.value1 = 0;
        _this.value2 = 0;
        _this.showCollapseContext1 = false;
        _this.showCollapseContext2 = false;
        _this.flag1 = false;
        _this.flag2 = false;
        _this.caseType = "";
        return _this;
    }
    CheckCase.prototype.close = function () { };
    CheckCase.prototype.submit = function () {
        var _this = this;
        this.caseInfoService.changeCaseType({ caseIds: this.selectionList.map(function (x) { return x.id; }), caseType: this.caseType }).subscribe(function (data) {
            _this.$message.success("调解方式更新成功!");
            _this.close();
        });
    };
    CheckCase.prototype.refresh = function (modelData, selectionList, caseType) {
        this.caseNumber1 = modelData.caseNumbers1.join(",");
        this.caseNumber2 = modelData.caseNumbers2.join(",");
        this.value1 = modelData.caseNumbers1.length;
        this.value2 = modelData.caseNumbers2.length;
        this.selectionList = selectionList;
        this.caseType = caseType;
    };
    CheckCase.prototype.changeCollapseHandle1 = function () {
        this.showCollapseContext1 = !this.showCollapseContext1;
        this.flag1 = this.showCollapseContext1;
    };
    CheckCase.prototype.changeCollapseHandle2 = function () {
        this.showCollapseContext2 = !this.showCollapseContext2;
        this.flag2 = this.showCollapseContext2;
    };
    CheckCase.prototype.closeDialog = function () {
        this.close();
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], CheckCase.prototype, "caseInfoService", void 0);
    __decorate([
        Emit()
    ], CheckCase.prototype, "close", null);
    CheckCase = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
            },
        })
    ], CheckCase);
    return CheckCase;
}(Vue));
export default CheckCase;
