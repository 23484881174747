var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
var HomeNoticeService = /** @class */ (function (_super) {
    __extends(HomeNoticeService, _super);
    function HomeNoticeService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询备忘录
     */
    HomeNoticeService.prototype.getComment = function (flag, loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.getComment,
            data: {
                flag: flag
            },
            loading: loading,
        });
    };
    /**
     * 明星调收员
     */
    HomeNoticeService.prototype.getStarCollector = function (loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.getStarCollector,
            loading: loading,
        });
    };
    /**
     * 明星调收部门
     */
    HomeNoticeService.prototype.getStarOrganization = function (loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.getStarOrganization,
            loading: loading,
        });
    };
    /**
     * 查询待办事项
     */
    HomeNoticeService.prototype.getWaitHandleList = function (loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.getWaitHandleList,
            loading: loading,
        });
    };
    /**
     * 新增备忘录
     */
    HomeNoticeService.prototype.addRemark = function (data, loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.addRemark,
            data: data,
            loading: loading,
        });
    };
    /**
     * 编辑备忘录
     */
    HomeNoticeService.prototype.modifyRemark = function (data, loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.modifyRemark,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除备忘录
     */
    HomeNoticeService.prototype.deleteRemark = function (id, loading) {
        return this.netService.send({
            server: dataimpService.homeNoticeController.deleteRemark,
            append: [id],
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], HomeNoticeService.prototype, "netService", void 0);
    return HomeNoticeService;
}(Service));
export { HomeNoticeService };
