var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var CaseApplyOperateService = /** @class */ (function (_super) {
    __extends(CaseApplyOperateService, _super);
    function CaseApplyOperateService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**、
     * 协调管理模块中，管理页面的，审批查询
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    CaseApplyOperateService.prototype.caseApprovalSearch = function (data, page, sort, loading) {
        // 申请日期
        var applyDate = FilterService.dateRanageFormat(data.applyDate);
        return this.netService.send({
            server: domainService.caseApplyOperateController.caseApprovalSearch,
            data: {
                applyType: data.applyType,
                approvalStatus: data.approvalStatus,
                exportState: data.exportState,
                principalName: data.principalName,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                applyRealName: data.applyRealName,
                idCard: data.idCard,
                applyDateStart: applyDate.start,
                applyDateEnd: applyDate.end,
                approvalResult: data.approvalResult,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 协调管理模块中，管理页面的，审批查询
     */
    CaseApplyOperateService.prototype.caseApplyApprove = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.caseApplyApprove,
            data: data,
            loading: loading,
        });
    };
    /**
     * 留案申请
     * @param data
     * @param loading
     */
    CaseApplyOperateService.prototype.caseApplyOperated = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.caseApplyOperated,
            data: data,
            loading: loading,
        });
    };
    /**
     * 协调审批查询
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    CaseApplyOperateService.prototype.assistCaseApproveSearch = function (data, page, sort, loading) {
        // 申请日期
        var applyDate = FilterService.dateRanageFormat(data.applyDate);
        return this.netService.send({
            server: domainService.caseApplyOperateController.assistCaseApproveSearch,
            data: {
                assistFlag: data.assistFlag,
                assistFlags: data.assistFlags,
                approvalStatus: data.approvalStatus,
                principalName: data.principalName,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                idCard: data.idCard,
                applyDateStart: applyDate.start,
                applyDateEnd: applyDate.end,
                applyRealName: data.applyRealName,
                cardFlag: data.cardFlag,
                addressDetail: data.addressDetail,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 协调申请操作
     * @param data
     */
    CaseApplyOperateService.prototype.assistApply = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.assistApply,
            data: data,
            loading: loading,
        });
    };
    /**
     * 本地外访协调审批操作
     * @param data
     */
    CaseApplyOperateService.prototype.localApplyApprove = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.localApplyApprove,
            data: {
                approveMemo: data.approveMemo,
                approveResult: data.approveResult,
                idList: data.idList,
            },
            loading: loading,
        });
    };
    /**
     * 协调申请记录查询
     * @param queryParamsModel 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    CaseApplyOperateService.prototype.assistApplySearch = function (queryParamsModel, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.assistApplySearch,
            data: {
                caseId: queryParamsModel.caseId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 申请就记录查询
     * @param queryParamsModel 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    CaseApplyOperateService.prototype.caseApplySearch = function (queryParamsModel, page, sort, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.caseApplySearch,
            data: {
                caseId: queryParamsModel.caseId,
                applyType: queryParamsModel.applyType,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 异地协调管理审批操作
     * @param data
     */
    CaseApplyOperateService.prototype.assistApplyApprove = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.assistApplyApprove,
            data: {
                approveMemo: data.approveMemo,
                approveResult: data.approveResult,
                idList: data.idList,
            },
            loading: loading,
        });
    };
    /**
     * 检查资料是否已申请
     * @param data
     * @param loading
     */
    CaseApplyOperateService.prototype.validCheckMaterialApply = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.validCheckMaterialApply,
            data: {
                caseId: data.caseId,
                applyType: data.applyType,
                applyContent: data.applyContent,
            },
            loading: loading,
        });
    };
    /**
     * 批量单卡导出
     * @param data
     */
    CaseApplyOperateService.prototype.BatchExport = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.BatchExport,
            data: {
                callPhone: data.callPhone,
                fullName: data.fullName,
                letterTemplateId: data.letterTemplateId,
                principalId: data.principalId,
            },
            loading: loading,
        });
    };
    /**
     * 批量多卡导出Excel
     */
    CaseApplyOperateService.prototype.BatchExportExcel = function (data, loading) {
        return this.netService.send({
            server: domainService.caseApplyOperateController.BatchExportExcel,
            data: {
                principalId: data.principalId,
                operBatchNumber: data.operBatchNumber,
                fullName: data.fullName,
                callPhone: data.callPhone,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseApplyOperateService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "caseApplyOperated", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "assistCaseApproveSearch", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "assistApply", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "localApplyApprove", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "assistApplyApprove", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "BatchExport", null);
    __decorate([
        Debounce()
    ], CaseApplyOperateService.prototype, "BatchExportExcel", null);
    return CaseApplyOperateService;
}(Service));
export { CaseApplyOperateService };
