var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { CaseInfoService } from '~/services/business-service/case-info.service';
import CheckCase from "~/components/case-info/check-case.vue";
var CaseTypeForm = /** @class */ (function (_super) {
    __extends(CaseTypeForm, _super);
    function CaseTypeForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            caseType: "",
        };
        _this.dialog = {
            checkCase: false
        };
        _this.modelData = {};
        _this.caseTypeRules = {
            caseType: [{ required: true, message: '请选择调解方式', trigger: 'blur' }]
        };
        return _this;
    }
    CaseTypeForm.prototype.close = function () { };
    CaseTypeForm.prototype.submit = function () {
        var _this = this;
        if (!this.model.caseType) {
            this.$message.warning("请选择调解方式");
            return;
        }
        if (this.model.caseType === this.selectionList[0].caseType) {
            this.$message.warning("更改后的调解方式与选中的调解方式一致,请重新选择");
            return;
        }
        var caseIds = this.selectionList.map(function (x) { return x.id; });
        if (this.model.caseType === 'VISIT_CASE') {
            this.caseInfoService.checkCase({ caseIds: caseIds }).subscribe(function (data) {
                var caseNumbers1 = data.caseNumbers1;
                var caseNumbers2 = data.caseNumbers2;
                if (caseNumbers1.length > 0 || caseNumbers2.length > 0) {
                    _this.modelData = data;
                    _this.dialog.checkCase = true;
                }
                else {
                    _this.caseInfoService.changeCaseType({ "caseIds": _this.selectionList.map(function (x) { return x.id; }), "caseType": _this.model.caseType }).subscribe(function (data) {
                        _this.$message.success("批量流转成功，请在流转案件池继续操作!");
                        _this.close();
                    });
                }
            });
        }
        else {
            this.caseInfoService.changeCaseType({ "caseIds": this.selectionList.map(function (x) { return x.id; }), "caseType": this.model.caseType }).subscribe(function (data) {
                _this.$message.success("批量流转成功，请在流转案件池继续操作!");
                _this.close();
            });
        }
    };
    CaseTypeForm.prototype.closeDialog = function () {
        this.close();
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], CaseTypeForm.prototype, "caseInfoService", void 0);
    __decorate([
        Emit()
    ], CaseTypeForm.prototype, "close", null);
    __decorate([
        Prop()
    ], CaseTypeForm.prototype, "selectionList", void 0);
    CaseTypeForm = __decorate([
        Component({
            components: {
                CheckCase: CheckCase
            },
        })
    ], CaseTypeForm);
    return CaseTypeForm;
}(Vue));
export default CaseTypeForm;
