var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import DepartmentTree from "~/components/system-manage/department-tree.vue";
import AdjustmentAllocate from "~/components/case-info/case-allot/adjustment-allocate.vue";
import { Prop, Emit } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import { SortService } from "~/utils/sort.service";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
// import { UserService } from "~/services/business-service/user.service";
import { OrganizationService } from "~/services/management-service/organization.service";
import { CaseDistributeService } from "~/services/dataimp-service/case-distribute.service";
var inrushCaseModule = namespace("inrush-case");
var AdjustmentCaseAllot = /** @class */ (function (_super) {
    __extends(AdjustmentCaseAllot, _super);
    function AdjustmentCaseAllot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.selectionList = [];
        _this.userDataSet = [];
        _this.distributeConfigModel = {};
        _this.userModel = {
            organizationId: "",
            userName: "",
            fullName: "",
            isShowAll: "0",
            operatorType: "COLLECTOR",
        };
        _this.totalCases = 0;
        _this.totalAmt = 0;
        _this.model = {};
        _this.distributeWay = "MANUAL_WAY";
        _this.dialog = {
            personalManualDivisionNext: false
        };
        return _this;
    }
    AdjustmentCaseAllot.prototype.onclose = function () {
        var databox = this.$refs["data-box"];
        databox.clearSelection();
    };
    AdjustmentCaseAllot.prototype.onSuccess = function () {
        this.onclose();
    };
    Object.defineProperty(AdjustmentCaseAllot.prototype, "totalPerson", {
        get: function () {
            return this.selectionList.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdjustmentCaseAllot.prototype, "personalDataSet", {
        get: function () {
            var items = [];
            return this.selectionList.map(function (item) {
                return Object.assign({ limitNum: 0, limitAmt: 0 }, item);
            });
        },
        enumerable: false,
        configurable: true
    });
    AdjustmentCaseAllot.prototype.created = function () {
        this.userModel.organizationId = this.userData.organization;
    };
    AdjustmentCaseAllot.prototype.mounted = function () { };
    /**
     * 下一步，进入分配确认阶段
     */
    AdjustmentCaseAllot.prototype.nextStep = function () {
        var _this = this;
        if (this.selectionList.length == 0) {
            this.$message.info("请选择人员");
            return;
        }
        var ids = [];
        if (this.selectionCases) {
            ids = this.selectionCases.map(function (x) { return x.id; });
        }
        var distributeConfigModels = this.personalDataSet.map(function (x) {
            var distributeConfigModel = {
                // 分配人员ID
                userId: x.id,
                // 员工工号
                userName: x.username,
                // 全名
                fullName: x.fullName,
                // 机构Id
                detaptId: x.organization,
                // 分配人员姓名
                detaptName: x.detaptName,
                // 分配数量上限
                limitNum: x.limitNum,
                // 分配金额上限
                limitAmt: x.limitAmt,
                // 分配数量
                caseNumTotal: 0,
                // 分配金额
                leftAmtTotal: 0,
                // 百分比
                perNum: 0,
                // 分配的案件ID
                resultMap: ids
            };
            return distributeConfigModel;
        });
        this.model = {
            // 案件Ids
            caseIds: ids,
            // 案件总数
            caseNumTotal: this.totalCases,
            // 案件总金额
            caseAmtTotal: this.totalAmt,
            // 调解员总数
            collectorTotal: this.selectionList.length,
            // 分配规则
            distributeConfigModels: distributeConfigModels,
            // 分配方式
            distributeWay: this.distributeWay
        };
        this.caseDistributeService
            .groupCaseDistributeRenew(this.model)
            .subscribe(function (data) {
            _this.distributeConfigModel = data;
            _this.dialog.personalManualDivisionNext = true;
        });
    };
    /**
     * 分配给调解员open
     */
    AdjustmentCaseAllot.prototype.userOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var adjustmentAllocate = _this.$refs["adjustment-allocate"];
        });
    };
    AdjustmentCaseAllot.prototype.getCheckedNodes = function (node) {
        this.userModel.organizationId = node.id;
        this.refreshData();
    };
    AdjustmentCaseAllot.prototype.refreshData = function () {
        var _this = this;
        if (this.selectionCases) {
            // 计算总金额
            this.totalAmt = this.selectionCases
                .map(function (x) { return x.leftAmt; })
                .reduce(function (result, item) { return result + item; });
            // 获取总案件
            this.totalCases = this.selectionCases.length;
        }
        this.organizationService
            .queryByOrganizationId(this.userModel, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.userDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    Object.defineProperty(AdjustmentCaseAllot.prototype, "divisionIsEdited", {
        get: function () {
            return this.distributeWay == "MANUAL_WAY" ? false : true;
        },
        enumerable: false,
        configurable: true
    });
    AdjustmentCaseAllot.prototype.divisionTypeChanged = function (data) {
        if (data !== 3) {
            this.personalDataSet.forEach(function (x) {
                x.maxCount = 0;
                x.maxAmt = 0;
            });
        }
    };
    AdjustmentCaseAllot.prototype.cancel = function () {
        this.onclose();
    };
    __decorate([
        Dependencies(SortService)
    ], AdjustmentCaseAllot.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], AdjustmentCaseAllot.prototype, "pageService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], AdjustmentCaseAllot.prototype, "organizationService", void 0);
    __decorate([
        Dependencies(CaseDistributeService)
    ], AdjustmentCaseAllot.prototype, "caseDistributeService", void 0);
    __decorate([
        State
    ], AdjustmentCaseAllot.prototype, "userData", void 0);
    __decorate([
        Prop({})
    ], AdjustmentCaseAllot.prototype, "selectionCases", void 0);
    __decorate([
        Emit("close")
    ], AdjustmentCaseAllot.prototype, "onclose", null);
    __decorate([
        Emit("success")
    ], AdjustmentCaseAllot.prototype, "onSuccess", null);
    AdjustmentCaseAllot = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DepartmentTree: DepartmentTree,
                AdjustmentAllocate: AdjustmentAllocate
            }
        })
    ], AdjustmentCaseAllot);
    return AdjustmentCaseAllot;
}(Vue));
export default AdjustmentCaseAllot;
