var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { VeMap } from 'v-charts';
var MapEcharts = /** @class */ (function (_super) {
    __extends(MapEcharts, _super);
    function MapEcharts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.optionData = {
            data: {
            // columns: ['name', 'value'],
            // rows: [
            //   { name: '北京', value: 32 },
            //   { name: '山西', value: 4 },
            //   { name: '新疆', value: 9 },
            // ]
            },
            settings: {
                selectData: true,
                label: false
            },
            backgroundColor: '#ffffff',
            title: {
                textStyle: {
                    color: '#4C4C4C',
                    fontSize: '14',
                    fontWeight: 'normal',
                },
                text: '调解中案件数量（单位：笔）',
                x: '5%',
                y: '0%'
            },
            tooltip: {
                trigger: 'item',
                formatter: function (_a) {
                    var name = _a.name, value = _a.value;
                    return value ? name + ":" + value : name + ":0";
                }
            },
            visualMap: {
                type: 'piecewise',
                right: '6%',
                top: 'center',
                pieces: [
                    { min: 8, color: '#005574' },
                    { min: 6, max: 8, color: '#007BB1' },
                    { min: 4, max: 6, color: '#00A5FC' },
                    { min: 2, max: 4, color: '#80DFFF' },
                    { max: 2, color: '#D9E2E8' } // 不指定 min，表示 min 为无限大（-Infinity）。
                ],
                text: ['高', '低'],
                itemWidth: 10,
                itemHeight: 10
            },
            series: [{
                    name: '中国',
                    type: 'map',
                    map: 'china',
                    mapType: 'china',
                    // left: '13%',
                    top: '4%',
                    roam: false,
                    itemStyle: {
                        borderColor: '#DCDFE6'
                    },
                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                    label: {
                        show: false
                    },
                    markPoint: {
                        symbol: 'none'
                    },
                    data: [
                        { name: '北京', value: 30 },
                        { name: '山西', value: 4 },
                        { name: '新疆', value: 9 },
                        { name: '甘肃', value: 11 },
                        { name: '湖南', value: 6 },
                        { name: '云南', value: 7 },
                    ]
                }]
        };
        return _this;
    }
    MapEcharts.prototype.mounted = function () {
    };
    MapEcharts = __decorate([
        Component({
            components: {
                VeMap: VeMap
            },
        })
    ], MapEcharts);
    return MapEcharts;
}(Vue));
export default MapEcharts;
