var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import ForeignVisits from "~/components/collection-manage/co-urging/foreign-visits.vue";
import TelephoneCollection from "~/components/collection-manage/co-urging/telephone-collection.vue";
import RaiseForeignVisits from "~/components/collection-manage/co-urging/raise-foreign-visits.vue";
import { Layout } from "~/core/decorator";
var CoUrgingCase = /** @class */ (function (_super) {
    __extends(CoUrgingCase, _super);
    function CoUrgingCase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CoUrgingCase.prototype.created = function () { };
    CoUrgingCase.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * keep-alive生命周期钩子函数
     */
    CoUrgingCase.prototype.activated = function () { };
    CoUrgingCase.prototype.refreshData = function () { };
    /**
     * tab切换事件
     */
    CoUrgingCase.prototype.tabClick = function (tab) {
        // 被选中的标签 tab 实例
        if (tab.$children && tab.$children.length > 0) {
            var template = tab.$children[0];
            if (template) {
                template.refreshData();
            }
        }
    };
    CoUrgingCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ForeignVisits: ForeignVisits,
                TelephoneCollection: TelephoneCollection,
                RaiseForeignVisits: RaiseForeignVisits,
            },
        })
    ], CoUrgingCase);
    return CoUrgingCase;
}(Vue));
export default CoUrgingCase;
