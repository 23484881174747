var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var StrategyService = /** @class */ (function (_super) {
    __extends(StrategyService, _super);
    function StrategyService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 多条件查询分案策略
     */
    StrategyService.prototype.findAllStrategy = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.strategyController.findAllStrategy,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 新增策略
     */
    StrategyService.prototype.addStrategy = function (data, loading) {
        return this.netService.send({
            server: manageService.strategyController.addStrategy,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除策略
     */
    StrategyService.prototype.deleteStrategy = function (id, loading) {
        return this.netService.send({
            server: manageService.strategyController.deleteStrategy,
            data: {
                id: id
            },
            loading: loading
        });
    };
    /**
     * 修改策略
     */
    StrategyService.prototype.modifyStrategy = function (data, loading) {
        return this.netService.send({
            server: manageService.strategyController.modifyStrategy,
            data: data,
            loading: loading
        });
    };
    /**
     * 待分配案件池策略预览
     */
    StrategyService.prototype.strategyPreview = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.strategyController.strategyPreview,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 其余策略预览
     */
    StrategyService.prototype.caseStrategyPreview = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.strategyController.caseStrategyPreview,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 获取策略对象
     */
    StrategyService.prototype.getStrategy = function (id, loading) {
        return this.netService.send({
            server: manageService.strategyController.getStrategy,
            data: {
                id: id
            },
            loading: loading
        });
    };
    /**
     * 复制策略
     */
    StrategyService.prototype.copyStrategy = function (id, loading) {
        return this.netService.send({
            server: manageService.strategyController.copyStrategy,
            data: {
                id: id
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], StrategyService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "findAllStrategy", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "addStrategy", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "deleteStrategy", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "modifyStrategy", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "strategyPreview", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "caseStrategyPreview", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "getStrategy", null);
    __decorate([
        Debounce()
    ], StrategyService.prototype, "copyStrategy", null);
    return StrategyService;
}(Service));
export { StrategyService };
