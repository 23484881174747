var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var AlipayInfoService = /** @class */ (function () {
    function AlipayInfoService() {
    }
    /**
     * 村委资料查询
     */
    AlipayInfoService.prototype.search = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.alipayInfoController.search,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 村委资料创建
     */
    AlipayInfoService.prototype.insert = function (data, loading) {
        return this.netService.send({
            server: repairService.alipayInfoController.insert,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除村委资料
     */
    AlipayInfoService.prototype.delete = function (data, loading) {
        return this.netService.send({
            server: repairService.alipayInfoController.delete,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改村委资料
     */
    AlipayInfoService.prototype.update = function (data, loading) {
        return this.netService.send({
            server: repairService.alipayInfoController.update,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], AlipayInfoService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], AlipayInfoService.prototype, "search", null);
    return AlipayInfoService;
}());
export { AlipayInfoService };
