import { requestType } from "~/config/enum.config";
var SERVICE = "domain-service";
var CONTROLLER = "historyCase";
export default {
    /**
     * 历史案件查询（案件管理）
     */
    getHisCaseForCaseManage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCaseForCaseManage",
        type: requestType.Get,
    },
    /**
     * 历史案件查询（调收管理）
     */
    getHisCaseForCollManage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCaseForCollManage",
        type: requestType.Get,
    },
    getHisCaseAmtForCollManage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCaseAmtForCollManage",
        type: requestType.Get,
    },
    /**
     * 删除案件查询（案件管理）
     */
    getDeleteCaseForCaseManage: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getDeleteCaseForCaseManage",
        type: requestType.Get,
    },
    /**
     * 历史案件查询（工单处理）
     */
    getHisCaseForWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCaseForWorkOrder",
        type: requestType.Get,
    },
    /**
     * 更改工单处理状态
     */
    changeWorkOrderType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "changeWorkOrderType",
        type: requestType.Get,
    },
    /**
     * 添加历史案件跟进记录
     */
    saveHisFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveHisFollowRecord",
        type: requestType.Post,
    },
    /**
     * 查询历史案件工单跟进记录
     */
    getHistoryFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHistoryFollowRecord",
        type: requestType.Get,
    },
    /**
     * 回复工单
     */
    replayOtherFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "replayOtherFollowRecord",
        type: requestType.Post,
    },
    /**
     * 执行页历史案件查询
     */
    caseCollectionSearch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "caseCollectionSearch",
        type: requestType.Get,
    },
    /**
     * 退案工单查询委案总额和欠款总额
     */
    getHisCaseAmtForWorkOrder: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getHisCaseAmtForWorkOrder",
        type: requestType.Get,
    },
    /**
     * 恢复退案案件
     */
    recoverPauseCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "recoverPauseCase",
        type: requestType.Post,
    },
};
