import { requestType } from "~/config/enum.config";
var SERVICE = "management-service";
var CONTROLLER = "custConfig";
export default {
    /**
     * 删除自定义配置
     */
    deleteConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteConfig",
        type: requestType.Delete,
    },
    /**
     * 获取所有自定义配置
     */
    getAllConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllConfig",
        type: requestType.Get,
    },
    /**
     * 保存自定义配置
     */
    saveConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveConfig",
        type: requestType.Post,
    },
    /**
     * 删除调收结果
     */
    deleteContactResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteContactResult",
        type: requestType.Delete,
    },
    /**
     * 获取所有调收结果
     */
    getAllContactResults: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllContactResults",
        type: requestType.Get,
    },
    /**
     * 保存调收结果
     */
    saveContactResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "saveContactResult",
        type: requestType.Post,
    },
    /**
     * 修改调收结果
     */
    updateContactResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateContactResult",
        type: requestType.Post,
    },
    /**
     * 获取系统匹配调记字段
     */
    getFollowMarchRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowMarchRecord",
        type: requestType.Get,
    },
    /**
     * 自动生成调记字段
     */
    generalFollowRecordFields: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "generalFollowRecordFields",
        type: requestType.Get,
    },
    /**
     * 获取自动生成调记字段
     */
    getFollowRecordFields: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFollowRecordFields",
        type: requestType.Get,
    },
};
