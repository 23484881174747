import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'importTemplateController';
export default {
    /**
     * 多条件查询导入模板
     */
    findAllImportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllImportTemplate',
        type: requestType.Get
    },
    /**
     * 解析excel表头
     */
    analyzeExcelTitle: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'analyzeExcelTitle',
        type: requestType.Post
    },
    /**
     * 获取案件字段
     */
    getCaseProperty: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCaseProperty',
        type: requestType.Get
    },
    /**
     * 新增模板
     */
    addImportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addImportTemplate',
        type: requestType.Post
    },
    /**
     * 删除模板
     */
    deleteTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteTemplate',
        type: requestType.Delete
    },
    /**
     * 获取模板信息
     */
    getImportTemplateById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getImportTemplateById',
        type: requestType.Get
    },
    /**
     * 修改模板
     */
    modifyImportTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyImportTemplate',
        type: requestType.Put
    },
    /**
     * 查询委托方下模板
     */
    getTemplateByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getTemplateByPrincipal',
        type: requestType.Get
    },
    /**
     * 解析Excel数据模板头信息
     */
    checkTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'checkTemplate',
        type: requestType.Post
    }
};
