var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State, Getter } from "vuex-class";
import { UserService } from "~/services/manage-service/user.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import { SortService } from "~/utils/sort.service";
import { PageService } from "~/utils/page.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import ftRequest from "~/utils/ft.request";
var DayCollectionStatistic = /** @class */ (function (_super) {
    __extends(DayCollectionStatistic, _super);
    function DayCollectionStatistic() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
            export: false,
        };
        _this.searchModel = {
            certificateNo: "",
            batchNumber: "",
            principalId: "",
            minFollowTime: "",
            maxFollowTime: "",
        };
        _this.dataSet = [];
        _this.deptIds = []; //部门列表
        _this.collectorFromList = []; //调解员列表
        _this.validStart = function (rule, value, callback) {
            var startTime = new Date(value).getTime();
            var endTime = new Date(_this.searchModel.endDate).getTime();
            if (endTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("起始时间不能大于截止时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.validEnd = function (rule, value, callback) {
            var startTime = new Date(_this.searchModel.startDate).getTime();
            var endTime = new Date(value).getTime();
            if (startTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("截止时间不能小于起始时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.rules = {
            minFollowTime: [
                { required: true, message: "请选择起始时间", trigger: "change" },
                { validator: _this.validStart, trigger: "change" },
            ],
            maxFollowTime: [
                { required: true, message: "请选择截止时间", trigger: "change" },
                { validator: _this.validEnd, trigger: "change" },
            ],
        };
        return _this;
    }
    DayCollectionStatistic.prototype.mounted = function () {
    };
    /**
     * 根据部门code查询调解员
     */
    DayCollectionStatistic.prototype.orgChange = function (valueArray) {
        var _this = this;
        this.searchModel.userIds = ""; // 清空调解员选中
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.departCode = valueArray[valueArray.length - 1];
        this.userService.getSimpleUser(this.searchModel.departCode).subscribe(function (data) {
            _this.collectorFromList = data;
        });
    };
    DayCollectionStatistic.prototype.handleResetForm = function () {
        this.deptIds = [];
        this.searchModel.departCode = "";
    };
    /**
     * 刷新列表
     */
    DayCollectionStatistic.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.caseFollowRecordService.getAllCaseFollowRecord(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 导出数据
     */
    DayCollectionStatistic.prototype.exportDataClick = function () {
        var _this = this;
        this.loading.export = true;
        ftRequest.getDownload("/business-service/api/caseFollowRecordController/export", this.searchModel, this.userToken).then(function (data) {
            _this.loading.export = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "调解记录表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.export = false;
        });
    };
    __decorate([
        Dependencies(UserService)
    ], DayCollectionStatistic.prototype, "userService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DayCollectionStatistic.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], DayCollectionStatistic.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], DayCollectionStatistic.prototype, "caseFollowRecordService", void 0);
    __decorate([
        Getter
    ], DayCollectionStatistic.prototype, "departmentData", void 0);
    __decorate([
        State
    ], DayCollectionStatistic.prototype, "userToken", void 0);
    DayCollectionStatistic = __decorate([
        Layout("workspace"),
        Component({
            components: {
                PrincipalBatch: PrincipalBatch,
                DataForm: DataForm,
                DataBox: DataBox,
            },
        })
    ], DayCollectionStatistic);
    return DayCollectionStatistic;
}(Vue));
export default DayCollectionStatistic;
