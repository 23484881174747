var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Action, State, Getter, namespace } from "vuex-class";
import { UserService } from "~/services/manage-service/user.service";
import { DepartmentInfoService } from "~/services/datasync-service/department-info.service";
import { UserDeviceService } from "~/services/manage-service/user-device.service";
import { DepartmentService } from "~/services/manage-service/department.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import BatchAllotRole from "~/components/system-manage/user-manage/batch-allot-role.vue";
import AllotRole from "~/components/system-manage/user-manage/allot-role.vue";
import FacilityManage from "~/components/system-manage/user-manage/facility-manage.vue";
import AddOrganize from "~/components/system-manage/user-manage/add-organize.vue";
import ModifyOrganize from "~/components/system-manage/user-manage/modify-organize.vue";
import DataTree from "~/components/common/data-tree.vue";
import { UserExportService } from "~/services/dataimp-service/user-export.service";
import { UploadFileService } from "~/services/common-service/upload-file.service";
// 数据导入
import ImportRoster from "~/components/assist-work/import-roster.vue";
import PersonRoster from "~/components/assist-work/person-roster.vue";
var systemManageModule = namespace("system-manage");
// @Auth(852)
var UserManage = /** @class */ (function (_super) {
    __extends(UserManage, _super);
    function UserManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userDataSet = [];
        _this.userModel = {
            departId: "",
            departCode: "",
            userName: "",
            realName: "",
            status: "",
            isShowAll: "1"
        };
        _this.userId = "";
        _this.exportModel = {
            ids: []
        };
        _this.userObj = {};
        _this.companyCode = "";
        _this.dialog = {
            batchRole: false,
            roleAllot: false,
            facility: false,
            batchFacilityAll: false,
            addUser: false,
            editOrganizeFlag: false,
            addOrganizeFlag: false,
            import: false,
            personRoster: false
        };
        _this.modifyData = {};
        _this.editdata = {};
        _this.selectionList = [];
        _this.userList = [];
        _this.state = 0;
        _this.deptId = "";
        _this.dept = "";
        _this.pid = "";
        _this.loading = {
            state: false
        };
        _this.deleteModel = {
            idList: []
        };
        return _this;
    }
    UserManage.prototype.updataOrganizationTree = function () {
        // this.updateOrgNameInfo(this.userData.organization);
        this.getDepartmentList();
    };
    UserManage.prototype.onChange = function (value) {
        this.userModel.departId = value.id;
        this.userModel.departCode = value.departCode;
        this.deptId = value.id;
        this.dept = value;
        this.refreshData();
    };
    UserManage.prototype.onEdit = function (value) {
        this.dialog.editOrganizeFlag = true;
        var parentData = this.orgInfo.find(function (v) { return v.id === value.parent; }) || {};
        this.editdata = Object.assign(value, {
            parentName: parentData.name,
            parentLevel: parentData.level
        });
    };
    UserManage.prototype.onAdd = function (value) {
        this.dialog.addOrganizeFlag = true;
    };
    UserManage.prototype.onDelete = function (value) {
        var _this = this;
        this.$confirm("此操作将永久删除该组织机构, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.departmentService.deleteDepartment(value.id).subscribe(function (data) {
                // this.updateOrgNameInfo(this.userData.organization);
                _this.getDepartmentList();
                _this.$message.success("删除成功!");
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message.error("已取消删除");
        });
    };
    UserManage.prototype.refresh = function (formName) {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs[formName];
            form.refresh();
        });
    };
    /**
     * 修改机构open事件(获取机构)
     */
    UserManage.prototype.getOragnize = function () {
        var _this = this;
        this.$nextTick(function () {
            var edit = _this.$refs["edit"];
            edit.refresh();
        });
        document.documentElement.style.overflow = "hidden";
    };
    UserManage.prototype.dialogOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var addUser = _this.$refs["add-dept"];
            addUser.refresh(_this.dept);
        });
    };
    /**
     * 重置密码
     */
    UserManage.prototype.resetClick = function (scope) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u7528\u6237" + scope.row.username + "\u91CD\u7F6E\u5BC6\u7801\u5417?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            var userIds = [scope.row.id];
            _this.userService.resetPassword({
                userIds: userIds
            }).subscribe(function (data) {
                _this.$message.success("重置密码成功");
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消重置"
            });
        });
    };
    /**
     * 批量分配角色
     */
    UserManage.prototype.batchRoleClick = function () {
        if (this.selectionList.length === 0) {
            this.$alert("请选择要分配角色的用户", "提示", {
                confirmButtonText: "确定"
            });
        }
        else {
            this.dialog.batchRole = true;
        }
    };
    /**
     * 分配角色
     */
    UserManage.prototype.allotRoleClick = function () { };
    UserManage.prototype.refreshData = function () {
        var _this = this;
        this.userService
            .findUserByDepartment(this.userModel, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.userDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    UserManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    UserManage.prototype.mounted = function () {
        // this.updateOrgNameInfo(this.userData.organization);
        this.userModel.organizationId = this.userData.organization;
        this.dept = {
            id: this.userData.organization
        };
    };
    UserManage.prototype.enableLockClick = function () {
        var _this = this;
        this.userDeviceService.batchDeviceManage("ON").subscribe(function (data) {
            _this.$message.success("启用设备锁成功");
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    UserManage.prototype.disableLockClick = function () {
        var _this = this;
        this.userDeviceService.batchDeviceManage("OFF").subscribe(function (data) {
            _this.$message.success("停用设备锁成功");
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 用户解锁，用于密码输入3次被锁定后解锁
     */
    UserManage.prototype.recoverLockedStatus = function (scope) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u7528\u6237" + scope.row.username + "\u89E3\u9501\u5417?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.userService.unlock(scope.row.id).subscribe(function (data) {
                _this.$message.success("解锁成功");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消解锁"
            });
        });
    };
    /**
     * 一键解锁
     */
    UserManage.prototype.recoverAllLockedStatus = function () {
        var _this = this;
        this.userService.batchUnlock().subscribe(function (data) {
            _this.$message.success("解锁成功");
            _this.refreshData();
        });
    };
    UserManage.prototype.deletedOperator = function (data) {
        var _this = this;
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            var userIds = [data.id];
            _this.userService.deleteUser({
                userId: userIds
            }).subscribe(function (data) {
                // this.updateOrgNameInfo(this.userData.organization);
                _this.refreshData();
                _this.$message.success("删除成功!");
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message.error("已取消删除");
        });
    };
    UserManage.prototype.modify = function (row) {
        this.modifyData = row;
        this.dialog.addUser = true;
    };
    /**
     * 批量重置密码
     */
    UserManage.prototype.updateAllPassword = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$alert("请选择要操作的用户", "提示", {
                confirmButtonText: "确定"
            });
        }
        else {
            this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u9009\u62E9\u7684\u7528\u6237\u91CD\u7F6E\u5BC6\u7801\u5417?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                var userIds = [];
                _this.selectionList.forEach(function (role) {
                    userIds.push(role.id);
                });
                _this.userService.resetPassword({
                    userIds: userIds
                }).subscribe(function (data) {
                    _this.$message.success("重置成功");
                    _this.refreshData();
                });
            });
        }
    };
    /**
     * 导入人员花名册
     */
    UserManage.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    UserManage.prototype.personRosterClose = function () {
        this.modifyData = {};
        var personRoster = this.$refs["person-roster"];
        personRoster.reset();
    };
    /**
     * 导出人员花名册
     */
    UserManage.prototype.dataExportClick = function () {
        var _this = this;
        if (this.selectionList.length > 0) {
            this.exportModel.ids = this.selectionList.map(function (x) { return x.id; });
            this.loading.state = true;
            this.userExportService
                .exportUserList(this.exportModel, this.loading)
                .subscribe(function (data) {
                _this.$message.success("正在导出,请关注任务中心");
            }, function (err) { return _this.$message.error(err.message); });
        }
        if (this.selectionList.length === 0) {
            this.$confirm("您未选择要导出的用户，请确认是否导出所有用户？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                _this.exportModel.ids = [];
                _this.loading.state = true;
                _this.userExportService
                    .exportUserList(_this.exportModel, _this.loading)
                    .subscribe(function (data) {
                    _this.$message.success("正在导出,请关注任务中心");
                }, function (err) { return _this.$message.error(err.message); });
            });
        }
    };
    /**
     * 下载花名册模板
     */
    UserManage.prototype.downloadTemplate = function () {
        var _this = this;
        this.loading.state = true;
        this.userExportService.downloadUserTemplate(this.loading).subscribe(function (data) {
            _this.uploadFileService
                .getFileStreamById(data.id, data.originalName)
                .subscribe(function () { return _this.$message.success("下载成功"); });
        }, function (err) { return _this.$message.error(err.message); });
    };
    __decorate([
        Dependencies(UserService)
    ], UserManage.prototype, "userService", void 0);
    __decorate([
        Dependencies(DepartmentInfoService)
    ], UserManage.prototype, "departmentInfoService", void 0);
    __decorate([
        Dependencies(UserDeviceService)
    ], UserManage.prototype, "userDeviceService", void 0);
    __decorate([
        Dependencies(PageService)
    ], UserManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], UserManage.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserExportService)
    ], UserManage.prototype, "userExportService", void 0);
    __decorate([
        Dependencies(DepartmentService)
    ], UserManage.prototype, "departmentService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], UserManage.prototype, "uploadFileService", void 0);
    __decorate([
        Action
    ], UserManage.prototype, "getDepartmentList", void 0);
    __decorate([
        Getter
    ], UserManage.prototype, "orgTreeData", void 0);
    __decorate([
        systemManageModule.State
    ], UserManage.prototype, "orgInfo", void 0);
    __decorate([
        State
    ], UserManage.prototype, "userData", void 0);
    UserManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataTree: DataTree,
                FacilityManage: FacilityManage,
                AllotRole: AllotRole,
                BatchAllotRole: BatchAllotRole,
                DataForm: DataForm,
                DataBox: DataBox,
                AddOrganize: AddOrganize,
                ModifyOrganize: ModifyOrganize,
                ImportRoster: ImportRoster,
                PersonRoster: PersonRoster
            }
        })
    ], UserManage);
    return UserManage;
}(Vue));
export default UserManage;
