var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Emit, Prop } from "vue-property-decorator";
import { FlowConfigurationService } from "~/services/business-service/flow-configuration.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import CheckUser from "./check-user.vue";
var option;
(function (option) {
    // 操作选项
    option[option["UP"] = 0] = "UP";
    option[option["DOWN"] = 1] = "DOWN";
})(option || (option = {}));
var numbreChars = ["一", "二", "三", "四", "五", "六", "七", "八", "九"];
var ProcessConfigCommon1 = /** @class */ (function (_super) {
    __extends(ProcessConfigCommon1, _super);
    function ProcessConfigCommon1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentChoickIndex = -1;
        _this.dialog = {
            checkUser: false
        };
        _this.saveModel = {
            flowNodes: [],
            configName: "",
            flowType: "",
            id: ""
        };
        _this.rules = {
            configName: [{ required: true, message: "请输入配置名称" }],
            flowType: [{ required: true, message: "请选择配置类型" }]
        };
        _this.taskNodeList = [];
        _this.submitLoading = false;
        return _this;
    }
    ProcessConfigCommon1.prototype.close = function () {
        this.reset();
    };
    ProcessConfigCommon1.prototype.success = function () {
        this.close();
    };
    //数据回显
    ProcessConfigCommon1.prototype.revertData = function () {
        if (this.modifyData.id) {
            this.saveModel.configName = this.modifyData.configName;
            this.saveModel.flowType = this.modifyData.flowType;
            this.saveModel.id = this.modifyData.id;
            this.saveModel.flowNodes = [];
            if (this.modifyData.flowNode) {
                this.taskNodeList = this.modifyData.flowNode.sort(function (a, b) { return a.nodeValue - b.nodeValue; }).map(function (v) {
                    return {
                        approverConfigs: v.approverConfigs,
                        get description() {
                            return this.approverConfigs.map(function (v) { return v.userName; }).join();
                        }
                    };
                });
            }
        }
    };
    ProcessConfigCommon1.prototype.checkUser = function (index) {
        this.currentChoickIndex = index;
        this.dialog.checkUser = true;
    };
    ProcessConfigCommon1.prototype.onSelected = function (users) {
        var _a;
        var row = this.taskNodeList[this.currentChoickIndex];
        var selectedUserIds = row.approverConfigs.map(function (v) { return v.userId; });
        var appendUsers = users.filter(function (x) { return !selectedUserIds.includes(x.id); }).map(function (v) {
            return {
                userId: v.id,
                userName: v.realName
            };
        });
        (_a = row.approverConfigs).push.apply(_a, appendUsers);
    };
    /**
     * 获取当前步数
     */
    ProcessConfigCommon1.prototype.stepChar = function (step) {
        var index = new Number(step).valueOf();
        return numbreChars[index];
    };
    // 节点移动
    ProcessConfigCommon1.prototype.move = function (type, index) {
        if (type === option.UP) {
            this.taskNodeList[index].nodeValue = index - 1;
            this.taskNodeList[index - 1].nodeValue = index;
        }
        else {
            this.taskNodeList[index].nodeValue = index + 1;
            this.taskNodeList[index + 1].nodeValue = index;
        }
        this.sort(this.taskNodeList);
    };
    //sort on operate in up or down
    ProcessConfigCommon1.prototype.sort = function (flowNodes) {
        this.taskNodeList = flowNodes.sort(function (a, b) { return a.nodeValue - b.nodeValue; });
    };
    // 增加节点
    ProcessConfigCommon1.prototype.addTaskNode = function () {
        this.taskNodeList.push({
            approverConfigs: [],
            get description() {
                return this.approverConfigs.map(function (v) { return v.userName; }).join();
            }
        });
    };
    /**
     * 提交表单
     */
    ProcessConfigCommon1.prototype.confirmAdd = function () {
        var _this = this;
        // form表单检测
        var dataForm = this.$refs["form"];
        dataForm.validate(function (val) {
            if (!val)
                return;
            // 任务节中含有空数据或者表单验证不通过
            if (_this.checkError())
                return;
            _this.saveModel.flowNodes = _this.taskNodeList;
            //如果saveModel存在id则为修改，不存则为新增
            if (_this.saveModel.id === "") {
                _this.flowConfigurationService
                    .createFlowConfiguration(_this.saveModel)
                    .subscribe(function (data) {
                    _this.$message.success("流程配置创建成功!");
                    _this.success();
                }, function (_a) {
                    var msg = _a.msg;
                });
            }
            else {
                _this.flowConfigurationService
                    .modifyFlowConfig(_this.saveModel)
                    .subscribe(function (data) {
                    _this.$message.success("修改成功!");
                    _this.success();
                }, function (_a) {
                    var msg = _a.msg;
                });
            }
        });
    };
    // 检测错误
    ProcessConfigCommon1.prototype.checkError = function () {
        var hasError = false;
        if (this.taskNodeList.length < 1) {
            this.$message.error("审批流程最少1步");
            return true;
        }
        for (var i = 0; i < this.taskNodeList.length; i++) {
            if (!this.taskNodeList[i].description) {
                this.$message("\u8BF7\u9009\u62E9\u7B2C" + (i + 1) + "\u6B65\u7684\u5BA1\u6279\u4EBA");
                hasError = true;
                break;
            }
        }
        return hasError;
    };
    ProcessConfigCommon1.prototype.reset = function () {
        this.taskNodeList = [];
        this.submitLoading = false;
        var form = this.$refs["form"];
        form.resetFields();
        this.saveModel = {
            flowNodes: [],
            configName: "",
            id: ""
        };
    };
    __decorate([
        Dependencies(FlowConfigurationService)
    ], ProcessConfigCommon1.prototype, "flowConfigurationService", void 0);
    __decorate([
        Prop()
    ], ProcessConfigCommon1.prototype, "modifyData", void 0);
    __decorate([
        Emit("close")
    ], ProcessConfigCommon1.prototype, "close", null);
    ProcessConfigCommon1 = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                SvgIcon: SvgIcon,
                CheckUser: CheckUser
            }
        })
    ], ProcessConfigCommon1);
    return ProcessConfigCommon1;
}(Vue));
export default ProcessConfigCommon1;
