var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CollectionOverview from "~/components/dashboard/user/collection-overview.vue";
import LineEcharts from "~/components/dashboard/admin/line-echarts.vue";
import MapEcharts from "~/components/dashboard/admin/map-echarts.vue";
var CaseRepaymentStatistics = /** @class */ (function (_super) {
    __extends(CaseRepaymentStatistics, _super);
    function CaseRepaymentStatistics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.options = [];
        _this.value = "";
        _this.radioClick = "";
        _this.lineData = {
            columns: ["date", "name", "value", "ptp", "alpa"],
            rows: [
                { date: "周一", name: 123, value: 333, ptp: 343, alpa: 33 },
                { date: "周二", name: 35, value: 323, ptp: 211, alpa: 332 },
                { date: "周三", name: 455, value: 453, ptp: 567, alpa: 444 },
                { date: "周四", name: 333, value: 232, ptp: 343, alpa: 352 },
                { date: "周五", name: 223, value: 333, ptp: 343, alpa: 678 },
                { date: "周六", name: 123, value: 333, ptp: 343, alpa: 33 },
                { date: "周日", name: 133, value: 331, ptp: 456, alpa: 33 },
            ],
            settingsLabel: {
                name: "外呼数量",
                value: "调记数量",
                ptp: "PTP数量",
                alpa: "ALPA数量",
            },
            yAxisName: "回收率：%",
            colors: ["#E0B353", "#7278EE", "#54A0EE", "#4CC99B"],
            legendShow: true,
        };
        return _this;
    }
    CaseRepaymentStatistics.prototype.onYearClick = function () { };
    CaseRepaymentStatistics.prototype.onMonthClick = function () { };
    CaseRepaymentStatistics.prototype.onWeakClick = function () { };
    CaseRepaymentStatistics = __decorate([
        Component({
            components: {
                CollectionOverview: CollectionOverview,
                LineEcharts: LineEcharts,
                MapEcharts: MapEcharts,
            },
        })
    ], CaseRepaymentStatistics);
    return CaseRepaymentStatistics;
}(Vue));
export default CaseRepaymentStatistics;
