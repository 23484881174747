var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { SpecialTransferDataService } from "~/services/repair-service/special-transfer-data.service";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import FileUpload from "~/components/common/file-upload.vue";
var CreatedSpecialData = /** @class */ (function (_super) {
    __extends(CreatedSpecialData, _super);
    function CreatedSpecialData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.imgSrc = "";
        _this.censusModel = {};
        _this.credentialModel = [];
        _this.dataSet = [];
        _this.kosekiModel = {
            remark: "",
            fileId: "",
            relationship: "",
            type: "",
            id: ""
        };
        _this.loading = {
            state: false
        };
        _this.dialog = {
            certificate: false,
            detail: false
        };
        _this.rules = {
            type: [
                {
                    required: true,
                    message: "请选择调解对象",
                    trigger: "change",
                    type: "string"
                }
            ]
        };
        return _this;
    }
    CreatedSpecialData.prototype.success = function () {
        this.reset();
    };
    CreatedSpecialData.prototype.close = function () {
        this.reset();
    };
    CreatedSpecialData.prototype.onUploadSuccess = function (id, data) {
        this.kosekiModel.fileId = data.id;
    };
    // 添加新凭证
    CreatedSpecialData.prototype.CreatedDetail = function () {
        var _this = this;
        var credentialSet = {
            remark: this.kosekiModel.remark,
            fileId: this.kosekiModel.fileId,
            relationship: this.kosekiModel.relationship,
            type: this.kosekiModel.type
        };
        this.dataSet.push(credentialSet);
        var editform = this.$refs["created-form"];
        editform.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.specialTransferDataService
                .addCredential(_this.dataSet, _this.kosekiModel.id, _this.loading)
                .subscribe(function (data) {
                _this.dataSet = data.credentialSet;
                _this.$message.success("上传成功!");
                _this.success();
            });
        });
    };
    // 删除凭证
    CreatedSpecialData.prototype.deletecredent = function (scope) {
        this.dataSet.splice(scope.$index, 1);
    };
    /**
     * keep-alive生命周期钩子函数
     */
    CreatedSpecialData.prototype.activated = function () {
        // 加载数据
    };
    CreatedSpecialData.prototype.refresh = function (obj) {
        this.dataSet = obj.credentialSet || [];
        this.kosekiModel.id = obj.id;
    };
    CreatedSpecialData.prototype.reset = function () {
        var fileUp = this.$refs["file-upload"];
        fileUp.reset();
        var add = this.$refs["created-form"];
        add.resetFields();
    };
    /**
     * 下载凭证
     */
    CreatedSpecialData.prototype.fileDownLoad = function (value) {
        var _this = this;
        this.uploadFileService.getFileUrlById(value).subscribe(function (data) {
            _this.imgSrc = data;
            _this.dialog.certificate = true;
        });
    };
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], CreatedSpecialData.prototype, "specialTransferDataService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], CreatedSpecialData.prototype, "uploadFileService", void 0);
    __decorate([
        Emit("success")
    ], CreatedSpecialData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], CreatedSpecialData.prototype, "close", null);
    CreatedSpecialData = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FileUpload: FileUpload
            }
        })
    ], CreatedSpecialData);
    return CreatedSpecialData;
}(Vue));
export default CreatedSpecialData;
