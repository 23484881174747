var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var ImpDataToSysDataService = /** @class */ (function (_super) {
    __extends(ImpDataToSysDataService, _super);
    function ImpDataToSysDataService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取上传excel文件列表
     * parseExcelCols
     */
    ImpDataToSysDataService.prototype.parseExcelCols = function (data, loading) {
        return this.netService.send({
            server: managementService.impDataToSysData.parseExcelCols,
            data: data,
            loading: loading
        });
    };
    /**
     * 执行合并操作
     * @param data
     */
    ImpDataToSysDataService.prototype.impDataToSysData = function (mergeItems, loading) {
        return this.netService.send({
            server: managementService.impDataToSysData.impDataToSysData,
            data: {
                requestList: mergeItems
            },
            loading: loading
        });
    };
    /**
     * 行转换列
     */
    ImpDataToSysDataService.prototype.rowToCol = function (data, loading) {
        return this.netService.send({
            server: managementService.impDataToSysData.rowToCol,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ImpDataToSysDataService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ImpDataToSysDataService.prototype, "parseExcelCols", null);
    __decorate([
        Debounce()
    ], ImpDataToSysDataService.prototype, "impDataToSysData", null);
    __decorate([
        Debounce()
    ], ImpDataToSysDataService.prototype, "rowToCol", null);
    return ImpDataToSysDataService;
}(Service));
export { ImpDataToSysDataService };
