var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import SvgIcon from "~/components/common/svg-icon.vue";
import FileUpload from "~/components/common/file-upload.vue";
import { ImpDataToSysDataService } from "~/services/management-service/imp-data-to-sys-data.service";
var ExcelRowConvertCell = /** @class */ (function (_super) {
    __extends(ExcelRowConvertCell, _super);
    function ExcelRowConvertCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cellDataSet = [];
        _this.model = {};
        _this.dialog = false;
        _this.fileList = [];
        _this.rules = {};
        _this.loading = {
            state: false
        };
        return _this;
    }
    ExcelRowConvertCell.prototype.emitClose = function () {
        this.fileList = [];
        var form = this.$refs.form;
        form.resetFields();
    };
    ExcelRowConvertCell.prototype.created = function () {
        this.model = {
            fileId: "",
            titleStartRow: "",
            titleStartCol: "",
            relationColNos: [],
            changeCol: []
        };
        this.rules = {
            fileId: { required: true, message: "请上传文件" },
            titleStartRow: { required: true, message: "请输入起始行" },
            titleStartCol: { required: true, message: "请输入起始列" },
            relationColNos: { required: true, message: "请选择关联列" },
            changeCol: { required: true, message: "请选择关联列" }
        };
    };
    ExcelRowConvertCell.prototype.onUploadSuccess = function (id, data) {
        this.model.fileId = data.id;
    };
    ExcelRowConvertCell.prototype.getExcelCellsClick = function () {
        var _this = this;
        if (!this.fileList.length) {
            this.$message("请先上传文件");
            return;
        }
        this.impDataToSysDataService.parseExcelCols(this.model).subscribe(function (data) { return _this.cellDataSet = data; });
    };
    // 提交合并请求
    ExcelRowConvertCell.prototype.submitClick = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (v) {
            if (!v)
                return;
            _this.loading.state = true;
            _this.impDataToSysDataService.rowToCol(_this.model, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功，请关注任务盒子通知");
                _this.emitClose();
            });
        });
    };
    __decorate([
        Dependencies(ImpDataToSysDataService)
    ], ExcelRowConvertCell.prototype, "impDataToSysDataService", void 0);
    __decorate([
        Emit("close")
    ], ExcelRowConvertCell.prototype, "emitClose", null);
    ExcelRowConvertCell = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                FileUpload: FileUpload
            }
        })
    ], ExcelRowConvertCell);
    return ExcelRowConvertCell;
}(Vue));
export default ExcelRowConvertCell;
