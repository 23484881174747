var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { SpecialTransferDataService } from "~/services/repair-service/special-transfer-data.service";
import clone from "clone";
import SvgIcon from "~/components/common/svg-icon.vue";
import RepairFileUpload from "~/components/common/repair-file-upload.vue";
var EditSpecialData = /** @class */ (function (_super) {
    __extends(EditSpecialData, _super);
    function EditSpecialData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kosekiModel = {
            id: "",
            name: "",
            idNo: "",
            bank: "",
            applyTransferArea: "",
            type: "",
            remark: "",
        };
        _this.loading = { state: false };
        _this.personalInformation = [];
        _this.rulesEdit = {
            name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
            idNo: [{ required: true, message: "请输入身份证号", trigger: "blur" }]
        };
        return _this;
    }
    EditSpecialData.prototype.success = function () { this.close(); };
    EditSpecialData.prototype.close = function () { this.reset(); };
    // 保存户籍信息
    EditSpecialData.prototype.save = function () {
        var _this = this;
        var editform = this.$refs["edit-form"];
        editform.validate(function (valid) {
            if (!valid)
                return;
            var personalForm = _this.personalInformation.map(function (x) {
                return {
                    fileName: x.fileUrl[0] ? x.fileUrl[0].response.fileName : '',
                    fileUrl: x.fileUrl[0] ? x.fileUrl[0].response.url : '',
                    relation: x.relation,
                    certificatesType: x.certificatesType,
                    remark: x.remark,
                    relationIdNo: x.relationIdNo,
                    idNo: _this.kosekiModel.idNo,
                };
            });
            var model = {
                name: _this.kosekiModel.name,
                idNo: _this.kosekiModel.idNo,
                bank: _this.kosekiModel.bank,
                type: _this.kosekiModel.type,
                id: _this.kosekiModel.id,
                applyTransferArea: _this.kosekiModel.applyTransferArea,
                remark: _this.kosekiModel.remark,
                credentialSet: personalForm
            };
            _this.loading.state = true;
            _this.specialTransferDataService.update(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("修改成功!");
                _this.success();
            }, function (_a) {
                var msg = _a.msg;
                _this.$message.error(msg);
            });
        });
    };
    EditSpecialData.prototype.refresh = function (obj) {
        var _this = this;
        this.kosekiModel = clone(obj);
        this.specialTransferDataService.searchDetails(this.kosekiModel.id, this.loading)
            .subscribe(function (data) {
            _this.personalInformation = data.map(function (x) {
                return {
                    fileUrl: !!x.fileUrl ? [{ response: { fileName: x.fileName, url: x.fileUrl } }] : [],
                    relation: x.relation,
                    certificatesType: x.certificatesType,
                    remark: x.remark,
                    relationIdNo: x.relationIdNo,
                };
            });
        }, function (_a) {
            var msg = _a.msg;
            _this.$message.error(msg);
        });
    };
    EditSpecialData.prototype.reset = function () {
        var add = this.$refs["edit-form"];
        add.resetFields();
        this.personalInformation = [];
    };
    EditSpecialData.prototype.addInformation = function () {
        this.personalInformation.push({ fileUrl: [], relation: '', certificatesType: '', remark: '', relationIdNo: '' });
    };
    EditSpecialData.prototype.deleteInformation = function (index) {
        this.personalInformation.splice(index, 1);
    };
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], EditSpecialData.prototype, "specialTransferDataService", void 0);
    __decorate([
        Emit("success")
    ], EditSpecialData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], EditSpecialData.prototype, "close", null);
    EditSpecialData = __decorate([
        Component({
            components: { RepairFileUpload: RepairFileUpload, SvgIcon: SvgIcon }
        })
    ], EditSpecialData);
    return EditSpecialData;
}(Vue));
export default EditSpecialData;
