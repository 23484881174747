var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyOperateService } from "~/services/domain-service/case-apply-operate.service";
import { Emit, Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { UserService } from "~/services/manage-service/user.service";
import DataBox from "~/components/common/data-box.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var SupplementManageHandled = /** @class */ (function (_super) {
    __extends(SupplementManageHandled, _super);
    function SupplementManageHandled() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.nowNode = null;
        _this.caseList = []; // 案件数组
        _this.supplementDataSet = [];
        _this.supplementselectionList = [];
        _this.SearchModel = {
            applyType: "SUPPLEMENT_AMOUNT_APPLY",
            approvalStatus: "APPROVED_COMPLETED"
        };
        return _this;
    }
    SupplementManageHandled.prototype.refreshList = function () { };
    SupplementManageHandled.prototype.close = function () { };
    SupplementManageHandled.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.fileId, row.fileName)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    /**
     * 刷新用户列表
     */
    SupplementManageHandled.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.SearchModel = Object.assign(this.SearchModel, this.supplementModel);
        this.caseApplyOperateService
            .caseApprovalSearch(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.supplementDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], SupplementManageHandled.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SupplementManageHandled.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserService)
    ], SupplementManageHandled.prototype, "userService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], SupplementManageHandled.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], SupplementManageHandled.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Emit("refreshList")
    ], SupplementManageHandled.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], SupplementManageHandled.prototype, "close", null);
    __decorate([
        Prop()
    ], SupplementManageHandled.prototype, "supplementModel", void 0);
    SupplementManageHandled = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], SupplementManageHandled);
    return SupplementManageHandled;
}(Vue));
export default SupplementManageHandled;
