var LetterPrint = function () { return import('~/pages/assistant-manage/letter-print.vue'); };
var visitManage = function () { return import('~/pages/assistant-manage/visit-manage.vue'); };
var assistManage = function () { return import('~/pages/assistant-manage/assist-manage.vue'); };
var repaymentManage = function () { return import('~/pages/assistant-manage/repayment-manage.vue'); };
var supplementManage = function () { return import('~/pages/assistant-manage/supplement-manage.vue'); };
var derateManage = function () { return import('~/pages/assistant-manage/derate-manage.vue'); };
var reportcaseManage = function () { return import('~/pages/assistant-manage/reportcase-manage.vue'); };
var applicationData = function () { return import('~/pages/assistant-manage/application-data.vue'); };
var LeaveCaseApply = function () { return import('~/pages/assistant-manage/leave-case-apply.vue'); };
var PubliccaseManage = function () { return import('~/pages/assistant-manage/publiccase-manage.vue'); };
var TurnCasesManage = function () { return import('~/pages/assistant-manage/turn-cases-manage.vue'); };
export default [
    {
        path: '/assistant-manage/turn-cases-manage',
        name: 'turn-cases-manage',
        component: TurnCasesManage
    },
    {
        path: '/assistant-manage/letter-print',
        name: 'letter-print',
        component: LetterPrint
    },
    {
        path: '/assistant-manage/visit-manage',
        name: 'visit-manage',
        component: visitManage
    },
    {
        path: '/assistant-manage/assist-manage',
        name: 'assist-manage',
        component: assistManage
    },
    {
        path: '/assistant-manage/repayment-manage',
        name: 'repayment-manage',
        component: repaymentManage
    },
    {
        path: '/assistant-manage/supplement-manage',
        name: 'supplement-manage',
        component: supplementManage
    },
    {
        path: '/assistant-manage/derate-manage',
        name: 'derate-manage',
        component: derateManage
    },
    {
        path: '/assistant-manage/reportcase-manage',
        name: 'reportcase-manage',
        component: reportcaseManage
    },
    {
        path: '/assistant-manage/application-data',
        name: 'application-data',
        component: applicationData
    },
    {
        path: '/assistant-manage/leave-case-apply',
        name: 'leave-case-apply',
        component: LeaveCaseApply
    },
    {
        path: '/assistant-manage/publiccase-manage',
        name: 'publiccase-manage',
        component: PubliccaseManage,
    },
];
