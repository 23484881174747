var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { CaseStatusService } from "~/services/management-service/case-status.service";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataBox from "~/components/common/data-box.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
import { CommonService } from "~/utils/common.service";
import { ValidatorService } from "~/utils/validator.service";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
import { FilterService } from "~/utils/filter.service";
var collectionManageModule = namespace("collection-manage");
var FollowRecordPhoneSecond = /** @class */ (function (_super) {
    __extends(FollowRecordPhoneSecond, _super);
    function FollowRecordPhoneSecond() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.dataSet = [
            {
                level: "提示",
                sensitiveWord: "",
            },
            {
                level: "严重",
                sensitiveWord: "",
            },
        ];
        _this.dialog = {
            sensitiveWord: false,
        };
        _this.phoneModel = {};
        _this.templateNames = [];
        _this.dontView = [];
        _this.dynamicView = [];
        _this.cacheModel = {};
        _this.rules = {};
        return _this;
    }
    /**
     * 级联列表渲染
     */
    FollowRecordPhoneSecond.prototype.getChildOptions = function (item) {
        var option = {
            keyName: "id",
            parentKeyName: "pid",
            sortKey: "sort",
        };
        var source = item.childList.map(function (v) { return (__assign({}, v)); });
        var treeData = CommonService.generateTreeData(source, option);
        return treeData;
    };
    FollowRecordPhoneSecond.prototype.close = function () {
        this.reset();
    };
    // 生成动态表单
    FollowRecordPhoneSecond.prototype.getAllTemplates = function () {
        var _this = this;
        this.contactConfigService.getFollowRecordFields(this.principalId, this.loading).subscribe(function (data) {
            var model = {};
            _this.cacheModel = {};
            // 最后初始化数据
            _this.templateNames = data;
            // 筛选出不需要显示的选项
            _this.dontView = data.filter(function (v) { return v.configState === "DISABLED"; });
            var self = _this;
            Promise.all(data
                .sort(function (a, b) { return a.sort - b.sort; })
                .map(function (v) {
                return new Promise(function (resolve) {
                    console.log(v.attribute);
                    if (v.attribute) {
                        model[v.attribute] = _this.getDefaultValue(v.propertyType);
                        //手机号进行了脱敏处理  所以不需要进行validate
                        if (v.attribute === 'contactPhone') {
                            _this.rules[v.attribute] = [
                                { required: v.isNecessary === "YES", message: "\u8BF7" + (v.propertyType === "INPUT" ? "输入" : "选择") + v.name }
                            ];
                        }
                        else {
                            _this.rules[v.attribute] = [
                                { required: v.isNecessary === "YES", message: "\u8BF7" + (v.propertyType === "INPUT" ? "输入" : "选择") + v.name },
                                { validator: _this.$validator.dynamicVerification(v.fieldDataType) },
                            ];
                        }
                    }
                    resolve(v);
                });
            })).then(function () {
                _this.phoneModel = model;
                var form = _this.$refs["form"];
                form.resetFields();
                _this.phoneModel.name = _this.phoneData.name;
                _this.phoneModel.relation = _this.$dict.getDictData("Relationship").filter(function (x) { return x.code === _this.phoneData.relation; })[0].name;
                _this.phoneModel.contactPhone = FilterService.encryptPhoneNumber(_this.phoneData.phoneNo);
                _this.phoneModel.phoneType = _this.$dict.getDictData("PhoneType").filter(function (x) { return x.code === _this.phoneData.phoneType; })[0].name;
            });
        });
    };
    /**
     * 获取各个控件对应的默认值
     */
    FollowRecordPhoneSecond.prototype.getDefaultValue = function (type) {
        var result;
        switch (type) {
            case "RADIO":
                result = "NO";
                break;
            case "CASCADER":
                result = [];
                break;
            default:
                result = "";
                break;
        }
        return result;
    };
    Object.defineProperty(FollowRecordPhoneSecond.prototype, "showControls", {
        get: function () {
            var _this = this;
            return this.templateNames.filter(function (item) { return _this.dontView.findIndex(function (x) { return x.id === item.id; }) < 0; });
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 当下拉选项关闭展示的时候触发的事件
     */
    FollowRecordPhoneSecond.prototype.onSelectVisibleChange = function (item, value) {
        var _this = this;
        var attribute = item.attribute;
        if (value) {
            this.cacheModel[attribute] = this.phoneModel[attribute];
        }
        else {
            var hideItem = item.childList.find(function (x) { return x.name === _this.cacheModel[attribute]; });
            var showItem = item.childList.find(function (x) { return x.name === _this.phoneModel[attribute]; });
            this.setVisableControls(hideItem, showItem, item);
        }
    };
    /**
     * 当级联菜单关闭展示的时候触发的事件
     */
    FollowRecordPhoneSecond.prototype.onCascaderVisibleChange = function (item, value) {
        var attribute = item.attribute;
        if (value) {
            this.cacheModel[attribute] = this.phoneModel[attribute];
        }
        else {
            var oldItems = this.findItems(this.cacheModel[attribute], item);
            var newItems = this.findItems(this.phoneModel[attribute], item);
            this.setVisableControls(oldItems[oldItems.length - 1], newItems[newItems.length - 1], item);
        }
    };
    /**
     * 设置隐藏显示的控件
     */
    FollowRecordPhoneSecond.prototype.setVisableControls = function (oldItem, newItem, item) {
        var _this = this;
        var attribute = item.attribute;
        oldItem &&
            oldItem.hideList.forEach(function (v) {
                var index = _this.dontView.findIndex(function (x) { return x.id === v.id; });
                if (index < 0) {
                    _this.dontView.push(v);
                    _this.phoneModel[v.attribute] = _this.getDefaultValue(v.propertyType);
                }
            });
        newItem &&
            newItem.hideList.forEach(function (v) {
                var index = _this.dontView.findIndex(function (x) { return x.id === v.id; });
                index > -1 && _this.dontView.splice(index, 1);
            });
    };
    /**
     * 查找操作项对应的路径
     */
    FollowRecordPhoneSecond.prototype.findItems = function (values, item) {
        if (!values.length)
            return [];
        var tmpItems = [];
        values.forEach(function (id) {
            tmpItems.push(item.childList.find(function (v) { return v.id === id; }));
        });
        return tmpItems;
    };
    FollowRecordPhoneSecond.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var form, validateResult, text, params;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        form = this.$refs["form"];
                        return [4 /*yield*/, form.validate().then(function () { return true; }).catch(function () { return false; })];
                    case 1:
                        validateResult = _a.sent();
                        if (!validateResult)
                            return [2 /*return*/];
                        text = Object.entries(this.phoneModel)
                            .filter(function (_a) {
                            var k = _a[0], v = _a[1];
                            return v;
                        }).map(function (_a) {
                            var k = _a[0], v = _a[1];
                            var item = _this.templateNames.find(function (x) { return x.attribute === k; });
                            if (!item)
                                return "";
                            var name = item.name;
                            if (["INPUT", "TEXTAREA"].includes(item.propertyType)) {
                                return [name, v].join(":");
                            }
                            else if (item.propertyType === "SELECT") {
                                var childrenItem = (item.childList || []).find(function (x) { return x.id === v; });
                                return [name, childrenItem ? childrenItem.name : ""].join(":");
                            }
                        }).join("&");
                        params = {
                            principalId: this.principalId,
                            str: text,
                        };
                        this.loading.state = true;
                        this.caseFollowRecordService.checkString(params, this.loading).subscribe(function (data) {
                            if (data) {
                                if (data.prompt.length || data.serious.length) {
                                    _this.dialog.sensitiveWord = true;
                                    var a = ""; // 敏感词提示
                                    var b = ""; // 敏感词严重
                                    if (data.prompt.length > 0) {
                                        for (var _i = 0, _a = data.prompt; _i < _a.length; _i++) {
                                            var i = _a[_i];
                                            a += i;
                                        }
                                    }
                                    if (data.serious.length > 0) {
                                        for (var _b = 0, _c = data.serious; _b < _c.length; _b++) {
                                            var i = _c[_b];
                                            b += i;
                                        }
                                    }
                                    _this.dataSet[0].sensitiveWord = a;
                                    _this.dataSet[1].sensitiveWord = b;
                                }
                                else {
                                    _this.conserve();
                                }
                            }
                            else {
                                _this.conserve();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FollowRecordPhoneSecond.prototype.reset = function () {
        var _this = this;
        var form = this.$refs["form"];
        form && form.resetFields();
        this.dontView = [];
        // 初始化隐藏项
        this.templateNames
            .filter(function (v) { return v.configState === "DISABLED"; })
            .forEach(function (hide) {
            _this.dontView.push(hide);
        });
    };
    FollowRecordPhoneSecond.prototype.conserve = function () {
        var _this = this;
        var reqData = {};
        Object.keys(this.phoneModel).forEach(function (key) {
            reqData[key] = _this.convertValue(_this.templateNames.find(function (x) { return x.attribute === key; }));
        });
        this.loading.state = true;
        // 设置基础值
        this.caseFollowRecordService.addCaseFollowRecord(Object.assign({ contactId: this.phoneData.id, callId: this.phoneData.callId }, __assign(__assign({}, reqData), this.getDefaultReqData())), this.loading).subscribe(function (data) {
            _this.$message.success("添加调记成功");
            _this.dialog.sensitiveWord = false;
            _this.updateRecordCount();
            _this.reset();
            _this.close();
        });
    };
    // 设置基础值
    FollowRecordPhoneSecond.prototype.getDefaultReqData = function () {
        return {
            caseId: this.caseId,
            personalId: this.currentCase.personalId,
            collectionWay: "1",
            type: "TEL",
            contactPhone: this.phoneData.phoneNo,
            caseType: this.otherData.caseType,
        };
    };
    /**
     * 因为下拉选择和级联菜单需要使用ID，在这里统一处理值转换
     */
    FollowRecordPhoneSecond.prototype.convertValue = function (item) {
        if (!item)
            return "";
        var value = this.phoneModel[item.attribute];
        if (item.propertyType === "SELECT") {
            if (value) {
                var findItem = item.childList.find(function (x) { return x.id === value; });
                return findItem ? findItem.id : value;
            }
        }
        else if (item.propertyType === "CASCADER") {
            if (value && value.length) {
                return value.map(function (id) {
                    var findItem = item.childList.find(function (x) { return x.id === id; });
                    return findItem ? findItem.id : "";
                });
            }
        }
        return value;
    };
    FollowRecordPhoneSecond.prototype.convertValueName = function (item) {
        if (!item)
            return "";
        var value = this.phoneModel[item.attribute];
        if (item.propertyType === "SELECT") {
            if (value) {
                var findItem = item.childList.find(function (x) { return x.id === value; });
                return findItem ? findItem.name : value;
            }
        }
        else if (item.propertyType === "CASCADER") {
            if (value && value.length) {
                return value.map(function (id) {
                    var findItem = item.childList.find(function (x) { return x.id === id; });
                    return findItem ? findItem.name : "";
                });
            }
        }
        return value;
    };
    __decorate([
        Dependencies(CustConfigService)
    ], FollowRecordPhoneSecond.prototype, "custConfigService", void 0);
    __decorate([
        Dependencies(ValidatorService)
    ], FollowRecordPhoneSecond.prototype, "validatorService", void 0);
    __decorate([
        Dependencies(CaseStatusService)
    ], FollowRecordPhoneSecond.prototype, "caseStatusService", void 0);
    __decorate([
        Dependencies(CaseFollowService)
    ], FollowRecordPhoneSecond.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(PageService)
    ], FollowRecordPhoneSecond.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FollowRecordPhoneSecond.prototype, "sortService", void 0);
    __decorate([
        Dependencies(ContactConfigService)
    ], FollowRecordPhoneSecond.prototype, "contactConfigService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowRecordPhoneSecond.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordPhoneSecond.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], FollowRecordPhoneSecond.prototype, "updateRecordCount", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordPhoneSecond.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordPhoneSecond.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordPhoneSecond.prototype, "currentCase", void 0);
    __decorate([
        Prop()
    ], FollowRecordPhoneSecond.prototype, "phoneData", void 0);
    __decorate([
        Emit("close")
    ], FollowRecordPhoneSecond.prototype, "close", null);
    FollowRecordPhoneSecond = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SvgIcon: SvgIcon,
            },
        })
    ], FollowRecordPhoneSecond);
    return FollowRecordPhoneSecond;
}(Vue));
export default FollowRecordPhoneSecond;
