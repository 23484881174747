import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'periodTransform';
export default {
    /*
    *查询数据
    */
    getAllTransformTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAllTransformTemplate',
        type: requestType.Get
    },
    /*
    *新增模板配置
    */
    createTransformTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'createTransformTemplate',
        type: requestType.Post
    },
    /*
  *修改模板配置
  */
    modifyTransformTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyTransformTemplate',
        type: requestType.Post
    },
    /**
     * 删除配置
     */
    deleteTemplateById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteTemplateById',
        type: requestType.Delete
    },
    /*
    *获取指定委托方配置
    */
    getPrincipalTransformTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getPrincipalTransformTemplate',
        type: requestType.Get
    },
};
