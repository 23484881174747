var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import { DataExportService } from '~/services/dataimp-service/data-export.service';
import { CaseCollectorService } from '~/services/business-service/case-collector.service';
import { State, Getter } from 'vuex-class';
var DistributeCaseInfoExport = /** @class */ (function (_super) {
    __extends(DistributeCaseInfoExport, _super);
    function DistributeCaseInfoExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rules = {};
        _this.batchNumberList = [];
        _this.organization = [];
        _this.company = [];
        _this.deptIds = [];
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            departCode: '',
            batchNumber: '',
            principalId: '',
            cities: ''
        };
        return _this;
    }
    DistributeCaseInfoExport.prototype.created = function () {
        this.rules = {
            principalId: {
                required: true,
                message: '请选择委托方',
                trigger: 'change'
            },
        };
    };
    DistributeCaseInfoExport.prototype.handleResetForm = function () {
        this.searchModel.departCode = '';
        this.deptIds = [];
    };
    DistributeCaseInfoExport.prototype.exportCaseDisInfo = function () {
        var _this = this;
        if (!this.searchModel.principalId) {
            this.$message.warning("请选择委托方");
        }
        else {
            this.dataExportService.exportDistribute(this.searchModel, this.loading).subscribe(function (data) {
                _this.$message.success('正在导出,请关注任务中心!');
            }, function (err) { });
        }
    };
    DistributeCaseInfoExport.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.deptCode = valueArray[valueArray.length - 1];
    };
    /**
     * 重置输入表单
     */
    DistributeCaseInfoExport.prototype.resetForm = function () {
        var dataForm = this.$refs['data-form'];
        dataForm.resetFields();
        this.handleResetForm();
    };
    DistributeCaseInfoExport.prototype.searchBatchNumber = function (value) {
        var _this = this;
        this.caseCollectorService.findAllBatchCase("", this.searchModel.principalId, this.loading).subscribe(function (data) {
            var batchNumber = [];
            data.map(function (e) {
                batchNumber.push(e.batchNumber);
            });
            _this.batchNumberList = batchNumber;
        }, function (err) { });
    };
    __decorate([
        Dependencies(DataExportService)
    ], DistributeCaseInfoExport.prototype, "dataExportService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], DistributeCaseInfoExport.prototype, "caseCollectorService", void 0);
    __decorate([
        Getter
    ], DistributeCaseInfoExport.prototype, "departmentData", void 0);
    __decorate([
        State
    ], DistributeCaseInfoExport.prototype, "principalList", void 0);
    DistributeCaseInfoExport = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                NumberRange: NumberRange
            }
        })
    ], DistributeCaseInfoExport);
    return DistributeCaseInfoExport;
}(Vue));
export default DistributeCaseInfoExport;
