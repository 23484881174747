import { requestType } from '~/config/enum.config';
var SERVICE = 'report-service';
var CONTROLLER = 'strateDataManualController';
export default {
    /**
     * 预览策略
     */
    previewResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'previewResult',
        type: requestType.Post
    }
};
