var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { reminderService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject } from "~/core/decorator";
import { Service } from '~/core/service';
var ReminderMessageService = /** @class */ (function (_super) {
    __extends(ReminderMessageService, _super);
    function ReminderMessageService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取未读消息数列表
     */
    ReminderMessageService.prototype.getUnReadCount = function (loading) {
        return this.netService.send({
            server: reminderService.reminderMessageController.getUnReadCount,
            loading: loading
        });
    };
    /**
    * 批量删除消息
    */
    ReminderMessageService.prototype.batchDelete = function (ids, loading) {
        return this.netService.send({
            server: reminderService.reminderMessageController.batchDelete,
            data: {
                ids: ids
            },
            loading: loading
        });
    };
    /**
     * 获取消息列表
     */
    ReminderMessageService.prototype.getReminderMessages = function (page, loading) {
        return this.netService.send({
            server: reminderService.reminderMessageController.getReminderMessages,
            data: {
                state: 'null'
            },
            page: page,
            loading: loading
        });
    };
    /**
     * 设置消息状态为已读
     * @param id
     */
    ReminderMessageService.prototype.setMessageRead = function (id, loading) {
        return this.netService.send({
            server: reminderService.reminderMessageController.setSelectedMessageRead,
            data: {
                messageId: id
            },
            loading: loading
        });
    };
    /**
     * 取弹框三条消息
     */
    ReminderMessageService.prototype.getWorkbenchReminder = function (loading) {
        return this.netService.send({
            server: reminderService.reminderMessageController.getWorkbenchReminder,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ReminderMessageService.prototype, "netService", void 0);
    return ReminderMessageService;
}(Service));
export { ReminderMessageService };
