var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { Emit, Prop } from 'vue-property-decorator';
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
var collectionManageModule = namespace("collection-manage");
var AppInfo = /** @class */ (function (_super) {
    __extends(AppInfo, _super);
    function AppInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            caseId: _this.caseId,
            flowType: 'CHECK_MATERIAL_APPLY',
            applyContent: '',
            applyRemark: '',
            applyFileDepartName: '',
            caseType: ''
        };
        _this.loading = { state: false };
        _this.rules = {
            applyContent: [
                { required: true, message: '请选择申请资料', trigger: 'change' },
            ],
            applyRemark: [
                { required: true, message: '请输入申请说明', trigger: 'blur' },
            ]
        };
        return _this;
    }
    AppInfo.prototype.close = function () { };
    AppInfo.prototype.reset = function () {
        var form = this.$refs['form'];
        form.resetFields();
    };
    /**
     * 申请资料提交
     */
    AppInfo.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            var model = {
                caseId: _this.caseId,
                flowType: _this.model.flowType,
                applyContent: _this.model.applyContent,
                applyRemark: _this.model.applyRemark,
                applyFileDepartName: _this.model.applyFileDepartName,
                caseType: _this.otherData.caseType
            };
            _this.caseApplyManageService.caseApplyOperated(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('申请资料成功');
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], AppInfo.prototype, "caseApplyManageService", void 0);
    __decorate([
        collectionManageModule.State
    ], AppInfo.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], AppInfo.prototype, "caseId", void 0);
    __decorate([
        Emit('close')
    ], AppInfo.prototype, "close", null);
    AppInfo = __decorate([
        Component({
            components: {},
        })
    ], AppInfo);
    return AppInfo;
}(Vue));
export default AppInfo;
