var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Watch, Prop, Emit } from "vue-property-decorator";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
var BeforeOutImportDetails = /** @class */ (function (_super) {
    __extends(BeforeOutImportDetails, _super);
    function BeforeOutImportDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.searchModel = {
            batchNumber: _this.batchNumber,
            certificateNo: "",
        };
        _this.dataSet = [];
        return _this;
    }
    /**
     * 批次号变化更新Model并刷新数据列
     */
    BeforeOutImportDetails.prototype.updateCaseDetail = function () {
        this.searchModel.batchNumber = this.batchNumber;
        this.refreshData();
    };
    /**
     * 刷新数据列
     */
    BeforeOutImportDetails.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.dataImportService.findImportPreByBatchNumber(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) { return (_this.dataSet = data); });
    };
    /**
     * 搜索条件重置
     */
    BeforeOutImportDetails.prototype.reset = function () {
        this.searchModel = {
            batchNumber: this.batchNumber,
            certificateNo: "",
        };
    };
    /**
     * 返回
     */
    BeforeOutImportDetails.prototype.onBackCaseList = function () { };
    __decorate([
        Dependencies(PageService)
    ], BeforeOutImportDetails.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], BeforeOutImportDetails.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], BeforeOutImportDetails.prototype, "dataImportService", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], BeforeOutImportDetails.prototype, "batchNumber", void 0);
    __decorate([
        Watch("batchNumber", { immediate: true })
    ], BeforeOutImportDetails.prototype, "updateCaseDetail", null);
    __decorate([
        Emit("onBackCaseList")
    ], BeforeOutImportDetails.prototype, "onBackCaseList", null);
    BeforeOutImportDetails = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
            },
        })
    ], BeforeOutImportDetails);
    return BeforeOutImportDetails;
}(Vue));
export default BeforeOutImportDetails;
