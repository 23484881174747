import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'caseDetail';
export default {
    /**
     * 查询案件详情
     */
    caseDetail: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseDetail',
        type: requestType.Get
    },
    /**
     * 获取卡信息
     */
    searchCardInformation: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchCardInformation',
        type: requestType.Get
    }
};
