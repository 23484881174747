var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { RoleService } from "~/services/manage-service/role.service";
import { ResourceService } from "~/services/manage-service/resource.service";
import { Emit } from "vue-property-decorator";
import DataTree from "~/components/common/data-tree.vue";
var EntrustPowerManage = /** @class */ (function (_super) {
    __extends(EntrustPowerManage, _super);
    function EntrustPowerManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // @Watch('allData',{immediate:true})
        // allDataChange(val) {
        //   this.getTreeData()
        //   setTimeout(() => {
        //     this.setDefaultSelection()
        //   }, 500);
        //
        // }
        _this.tableDefaultSelection = [];
        _this.treeExpanded = [];
        _this.treeDefaultSelection = [];
        _this.selectionList = [];
        _this.moduleData = [];
        _this.allData = [];
        _this.treeData = [];
        _this.tableData = [];
        _this.changeFlag = false;
        _this.nowResoId = "";
        _this.loading = { state: false };
        return _this;
    }
    EntrustPowerManage.prototype.refreshList = function () { };
    EntrustPowerManage.prototype.close = function () { };
    EntrustPowerManage.prototype.mounted = function () {
        // this.resourceService.findAll().subscribe(
        //   data => {
        //     const orgList = data.map(v => ({ ...v }))
        //     const option = {
        //       keyName: 'id',
        //       parentKeyName: 'parent'
        //     }
        //     this.allData = CommonService.generateTreeData(orgList, option)
        //   },
        //   ({ msg }) => { }
        // );
    };
    /**
     * 保存点击
     */
    EntrustPowerManage.prototype.saveModule = function () {
        if (this.nowResoId) {
            this.moduleData[this.nowResoId] = this.selectionList.map(function (v) {
                return v.id;
            });
        }
        var tree = this.$refs.tree;
        this.roleInfo.resources = tree
            .getHalfCheckedKeys()
            .filter(function (v) { return v != 0; })
            .concat(tree.getCheckedKeys());
        this.loading.state = true;
        // this.roleService.modify(this.roleInfo, this.loading).subscribe(
        //   data => {
        //     this.$message.success("操作成功!");
        //     this.refreshList();
        //     this.close();
        //   },
        //   ({ msg }) => { }
        // );
    };
    /**
     * 选择改变事件
     */
    EntrustPowerManage.prototype.handleCheckChange = function (data, checked, indeterminate) {
        if (this.changeFlag && data.function) {
            if (checked) {
                this.moduleData[data.id] = data.function.map(function (v) { return v.id; });
                if (data.id === this.nowResoId) {
                    this.setDefaultSelection();
                }
            }
            else {
                this.moduleData[data.id] = [];
                if (data.id === this.nowResoId) {
                    var table = this.$refs.table;
                    table.clearSelection();
                }
            }
        }
    };
    /**
     * 获取当前树选择项
     */
    EntrustPowerManage.prototype.getCheckedNodes = function (item) {
        if (item.id === this.nowResoId) {
            return;
        }
        if (item.function) {
            if (this.nowResoId) {
                this.moduleData[this.nowResoId] = this.selectionList.map(function (v) {
                    return v.id;
                });
            }
            this.tableData = item.function;
            this.nowResoId = item.id;
            this.setDefaultSelection();
        }
    };
    /**
     * 设置table默认选中
     */
    EntrustPowerManage.prototype.setDefaultSelection = function () {
        var _this = this;
        if (this.moduleData[this.nowResoId] &&
            this.moduleData[this.nowResoId].length) {
            this.$nextTick(function () {
                _this.selectionList = _this.tableData.filter(function (d) {
                    return _this.moduleData[_this.nowResoId].find(function (v) { return v === d.id; });
                });
            });
        }
    };
    __decorate([
        Dependencies(RoleService)
    ], EntrustPowerManage.prototype, "roleService", void 0);
    __decorate([
        Dependencies(ResourceService)
    ], EntrustPowerManage.prototype, "resourceService", void 0);
    __decorate([
        Emit("refreshList")
    ], EntrustPowerManage.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], EntrustPowerManage.prototype, "close", null);
    EntrustPowerManage = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                DataTree: DataTree
            }
        })
    ], EntrustPowerManage);
    return EntrustPowerManage;
}(Vue));
export default EntrustPowerManage;
