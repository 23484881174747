var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseApplyOperateService } from "~/services/domain-service/case-apply-operate.service";
import { OrganizationService } from "~/services/management-service/organization.service";
var collectionManageModule = namespace("collection-manage");
var RemotePhoneModify = /** @class */ (function (_super) {
    __extends(RemotePhoneModify, _super);
    function RemotePhoneModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.model = {
            caseId: "",
            applyReason: "",
            assistFlag: "OFFSITE_PHONE_ASSIST",
            personalName: "",
            assistDeptId: "",
            relationShip: "",
            personalContactId: "",
        };
        _this.assistDeptArr = [];
        _this.rules = {
            assistDeptId: [{ required: true, message: "请选择分公司", trigger: "change" }],
            applyReason: [{ required: true, message: "请输入申请说明", trigger: "blur" }],
        };
        return _this;
    }
    RemotePhoneModify.prototype.currentPhoneChange = function () {
        // 委案人员id
        this.model.personalId = this.personalId;
        // 案件id
        this.model.caseId = this.caseId;
        // 姓名
        this.model.personalName = this.currentPhone.name;
        // 关系
        this.model.relationShip = this.currentPhone.relation;
        // 异地协调ID
        this.model.personalContactId = this.currentPhone.id;
    };
    RemotePhoneModify.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    RemotePhoneModify.prototype.mounted = function () {
        //获取分公司列表
        this.findAllBranch();
    };
    /**
     * 异地电话申请
     */
    RemotePhoneModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            var model = Object.assign({}, _this.model);
            _this.loading.state = true;
            _this.caseApplyOperateService.assistApply(model, _this.loading).subscribe(function (data) {
                _this.$message.success("异地电话申请成功");
                _this.updateAssistCount();
                _this.caseCollectionSearch(_this.caseId);
                _this.close();
            }, function (err) { });
        });
    };
    /**
     * 获取所有的分公司
     */
    RemotePhoneModify.prototype.findAllBranch = function () {
        var _this = this;
        this.organizationService.findAllBranch().subscribe(function (data) {
            _this.assistDeptArr = data;
        }, function (error) { });
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], RemotePhoneModify.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], RemotePhoneModify.prototype, "organizationService", void 0);
    __decorate([
        collectionManageModule.State
    ], RemotePhoneModify.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], RemotePhoneModify.prototype, "personalId", void 0);
    __decorate([
        collectionManageModule.Action
    ], RemotePhoneModify.prototype, "caseCollectionSearch", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], RemotePhoneModify.prototype, "updateAssistCount", void 0);
    __decorate([
        Prop()
    ], RemotePhoneModify.prototype, "currentPhone", void 0);
    __decorate([
        Watch("currentPhone", { immediate: true })
    ], RemotePhoneModify.prototype, "currentPhoneChange", null);
    __decorate([
        Emit("close")
    ], RemotePhoneModify.prototype, "close", null);
    RemotePhoneModify = __decorate([
        Component({
            components: {},
        })
    ], RemotePhoneModify);
    return RemotePhoneModify;
}(Vue));
export default RemotePhoneModify;
