import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'publicCollectionCaseController';
export default {
    /**
     * 查询公共案件
     */
    queryPublicCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryPublicCase',
        type: requestType.Get
    },
    /**
    * 查询欠款总额
    */
    queryPublicCaseAmt: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryPublicCaseAmt',
        type: requestType.Get
    },
    /**
   * 公共案件池  根据委托方获取批次列表
   */
    getPubBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getPubBatchNumber',
        type: requestType.Get,
    }
};
