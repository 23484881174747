var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import RepairFileUpload from "~/components/common/repair-file-upload.vue";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
var RepairDataImport = /** @class */ (function (_super) {
    __extends(RepairDataImport, _super);
    function RepairDataImport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.kosekiModel = {
            fileId: "",
            importContentType: "",
            hasPicture: ""
        };
        return _this;
    }
    RepairDataImport.prototype.success = function () {
        this.close();
    };
    RepairDataImport.prototype.close = function () {
        this.reset();
    };
    RepairDataImport.prototype.created = function () { };
    RepairDataImport.prototype.onUploadSuccess = function (id, data) {
        this.kosekiModel.fileId = data.id;
        this.kosekiModel.importContentType = this.importType;
    };
    // 添加新凭证
    RepairDataImport.prototype.CreatedDetail = function () {
        var _this = this;
        this.loading.state = true;
        if (this.fileList.length === 0) {
            this.$message('请上传导入文件');
            return;
        }
        if (this.flag) {
            this.kosekiModel.hasPicture = "YES";
        }
        else {
            delete this.kosekiModel.hasPicture;
        }
        this.dataRepairImportService
            .import(this.kosekiModel)
            .subscribe(function (data) {
            _this.$message.success('上传成功!');
            _this.success();
        });
    };
    /**
     * keep-alive生命周期钩子函数
     */
    RepairDataImport.prototype.activated = function () {
        // 加载数据
    };
    RepairDataImport.prototype.reset = function () {
        var fileForm = this.$refs['repair-file-upload'];
        fileForm.reset();
        var form = this.$refs['created-form'];
        form.resetFields();
        this.kosekiModel.hasPicture = "";
    };
    __decorate([
        Dependencies(DataRepairImportService)
    ], RepairDataImport.prototype, "dataRepairImportService", void 0);
    __decorate([
        Emit("success")
    ], RepairDataImport.prototype, "success", null);
    __decorate([
        Emit("close")
    ], RepairDataImport.prototype, "close", null);
    __decorate([
        Prop()
    ], RepairDataImport.prototype, "importType", void 0);
    __decorate([
        Prop()
    ], RepairDataImport.prototype, "flag", void 0);
    RepairDataImport = __decorate([
        Component({
            components: {
                RepairFileUpload: RepairFileUpload
            }
        })
    ], RepairDataImport);
    return RepairDataImport;
}(Vue));
export default RepairDataImport;
