var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Watch, Prop } from "vue-property-decorator";
import { UserRegisterService } from "~/services/management-service/user-register.service";
var PersonUploadFile = /** @class */ (function (_super) {
    __extends(PersonUploadFile, _super);
    function PersonUploadFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.submitLoading = false;
        _this.model = {
            userId: "",
            fileId: "",
            filePath: "",
            fileName: ""
        };
        return _this;
    }
    PersonUploadFile.prototype.userIdChange = function (val) {
        this.model.userId = val;
    };
    PersonUploadFile.prototype.onUploadSuccess = function (id, data) {
        this.model.fileName = data.originalName;
        this.model.fileId = data.id;
        this.model.filePath = data.url;
    };
    PersonUploadFile.prototype.submit = function () {
        if (this.fileList.length === 0) {
            this.$message("请上传文件");
            return;
        }
        this.submitLoading = true;
        // this.userRegisterService.saveFileId(this.model).subscribe(data => {
        //   this.$message.success("上传成功!");
        //   this.success();
        //   this.submitLoading = false;
        // }, err => (this.submitLoading = false));
    };
    PersonUploadFile.prototype.close = function () {
        this.reset();
    };
    PersonUploadFile.prototype.success = function () {
        this.close();
    };
    PersonUploadFile.prototype.reset = function () {
        var fileupload = this.$refs["file-upload"];
        fileupload.reset();
    };
    __decorate([
        Dependencies(UserRegisterService)
    ], PersonUploadFile.prototype, "userRegisterService", void 0);
    __decorate([
        Prop({ required: true })
    ], PersonUploadFile.prototype, "userId", void 0);
    __decorate([
        Watch("userId", { immediate: true })
    ], PersonUploadFile.prototype, "userIdChange", null);
    __decorate([
        Emit()
    ], PersonUploadFile.prototype, "close", null);
    __decorate([
        Emit()
    ], PersonUploadFile.prototype, "success", null);
    PersonUploadFile = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], PersonUploadFile);
    return PersonUploadFile;
}(Vue));
export default PersonUploadFile;
