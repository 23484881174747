var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch, Emit } from "vue-property-decorator";
import SvgIcon from "~/components/common/svg-icon.vue";
import { WarningService } from "~/services/business-service/warning.service";
import { namespace } from "vuex-class";
import { Dependencies } from "~/core/decorator";
var collectionManageModule = namespace("collection-manage");
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasMsg = false;
        _this.message = "";
        _this.msgIndex = -1;
        _this.messageList = [];
        return _this;
    }
    App.prototype.isDisplay = function (boo) { };
    App.prototype.onMsgFlagChange = function (val) {
        var _this = this;
        if (val) {
            this.msgIndex += 1;
            if (this.msgIndex === this.messageList.length)
                this.msgIndex = 0;
            this.message = this.messageList[this.msgIndex];
            this.$nextTick(this.getNext);
        }
        else {
            this.eventHandle = setTimeout(function () {
                if (!_this.messageList.length)
                    return;
                _this.hasMsg = true;
            }, 1);
        }
    };
    App.prototype.onCaseIdChanged = function (val) {
        val && this.registerClock();
    };
    App.prototype.getNext = function () {
        var _this = this;
        var message = this.$refs.message;
        message.addEventListener("webkitAnimationEnd", function () {
            _this.hasMsg = false;
        });
    };
    App.prototype.registerClock = function () {
        var _this = this;
        window.clearTimeout(this.eventHandle);
        this.hasMsg = false;
        this.messageList = [];
        this.msgIndex = -1;
        this.warningService.getWarningByCaseId(this.caseId).subscribe(function (data) {
            if (data.length > 0) {
                _this.isDisplay(true);
                _this.messageList = data;
                _this.hasMsg = true;
            }
            else {
                _this.isDisplay(false);
            }
        }, function (err) { });
    };
    __decorate([
        Dependencies(WarningService)
    ], App.prototype, "warningService", void 0);
    __decorate([
        collectionManageModule.State
    ], App.prototype, "caseId", void 0);
    __decorate([
        Emit('isDisplay')
    ], App.prototype, "isDisplay", null);
    __decorate([
        Watch("hasMsg")
    ], App.prototype, "onMsgFlagChange", null);
    __decorate([
        Watch('caseId', { immediate: true })
    ], App.prototype, "onCaseIdChanged", null);
    App = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
