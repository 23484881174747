var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var createWebSocketMsgService = function () { return import('~/services/common-service/websocket-msg.service'); };
export default {
    namespaced: true,
    state: {
        currentPage: '',
        currentTabs: [],
        unReadCount: 0,
        unReadMailCount: 0,
        taskList: [],
        messageBoxVisible: false,
        messageMailVisible: false
    },
    mutations: {
        updateMessageBoxVisible: function (state, visible) {
            state.messageBoxVisible = visible;
        },
        updateMessageMailVisible: function (state, visible) {
            state.messageMailVisible = visible;
        },
        updateTaskList: function (state, list) {
            state.taskList = list;
        },
        updateCurrentPage: function (state, page) {
            state.currentPage = page;
        },
        updateCurrentTabs: function (state, tabs) {
            state.currentTabs = tabs;
        },
        /**
         * 更新未读消息数
         * @param state
         * @param count
         */
        updateUnReadCount: function (state, count) {
            state.unReadCount = count;
        },
    },
    actions: {
        /**
         * 查询未读消息树
         */
        updateUnReadCount: function (_a) {
            var commit = _a.commit, rootState = _a.rootState;
            return __awaiter(this, void 0, void 0, function () {
                var WebSocketMsgService;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, createWebSocketMsgService()];
                        case 1:
                            WebSocketMsgService = (_b.sent()).WebSocketMsgService;
                            if (rootState.userToken) {
                                WebSocketMsgService
                                    .getInstance()
                                    .getUnReadMsgCount()
                                    .subscribe(function (count) {
                                    commit('updateUnReadCount', count);
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateTabs: function (_a, parentId) {
            var state = _a.state, commit = _a.commit, rootState = _a.rootState;
            // 获取tabs标签页
            var tabs = rootState.menuResource
                .filter(function (x) { return x.type === 18; })
                .filter(function (x) { return x.parentId === parentId; })
                .sort(function (x, y) { return x.sort - y.sort; });
            // 更新tabs标签页
            commit('updateCurrentTabs', tabs);
        }
    }
};
