var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { FlowConfigurationService } from "~/services/business-service/flow-configuration.service";
import ProcessConfigCommon from "~/components/process-config/process-config-common.vue";
var ProcessConfig = /** @class */ (function (_super) {
    __extends(ProcessConfig, _super);
    function ProcessConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            configName: "",
            flowType: ""
        };
        _this.dialog = {
            processConfigCommon: false
        };
        _this.dataSet = [];
        _this.modifyData = {};
        return _this;
    }
    ProcessConfig.prototype.mounted = function () {
        this.refreshData();
    };
    ProcessConfig.prototype.refreshData = function () {
        var _this = this;
        this.flowConfigurationService
            .getAllFlowConfig(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) { return (_this.dataSet = data); });
    };
    ProcessConfig.prototype.addProcessConfig = function () {
        this.modifyData = {};
        this.dialog.processConfigCommon = true;
    };
    ProcessConfig.prototype.modifyProcessConfig = function (row) {
        this.modifyData = row;
        this.dialog.processConfigCommon = true;
    };
    ProcessConfig.prototype.deleteProcessConfig = function (row) {
        var _this = this;
        this.$confirm("\u786E\u5B9A\u8981\u5220\u9664 " + row.configName + " \u914D\u7F6E\u5417?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.loading.state = true;
            _this.flowConfigurationService.deleteFlowConfig(row.id)
                .subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], ProcessConfig.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], ProcessConfig.prototype, "sortService", void 0);
    __decorate([
        Dependencies(FlowConfigurationService)
    ], ProcessConfig.prototype, "flowConfigurationService", void 0);
    ProcessConfig = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ProcessConfigCommon: ProcessConfigCommon
            }
        })
    ], ProcessConfig);
    return ProcessConfig;
}(Vue));
export default ProcessConfig;
