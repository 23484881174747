var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { PrincipalService } from "~/services/manage-service/principal.service";
import { Emit } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Business_Type, Principal_Type } from "~/config/enum.business";
var ModifyPrincipal = /** @class */ (function (_super) {
    __extends(ModifyPrincipal, _super);
    function ModifyPrincipal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.submitLoading = false;
        _this.principalModel = {
            principalId: "",
            phone: "",
            name: "",
            remark: "",
            status: ""
        };
        _this.principalRules = {
            principalName: [
                { required: true, message: "请输入委托方名称", trigger: "blur" }
            ],
            businessType: [{ required: true, message: "请选择状态", trigger: "change" }]
        };
        return _this;
    }
    ModifyPrincipal.prototype.refreshList = function () { };
    ModifyPrincipal.prototype.close = function () { };
    ModifyPrincipal.prototype.commit = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (valid) {
            if (!valid)
                return false;
            _this.principalModel.status == 0 ? (_this.principalModel.status = "OFF") : (_this.principalModel.status = "ON");
            _this.submitLoading = true;
            _this.principalService.modifyPrincipal(_this.principalModel).subscribe(function (data) {
                _this.$message.success("修改成功!");
                _this.refreshList();
                _this.submitLoading = false;
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    ModifyPrincipal.prototype.refresh = function (obj) {
        var form = this.$refs.form;
        form.resetFields();
        obj.businessType = Business_Type[obj.businessType];
        obj.type = Principal_Type[obj.type];
        this.principalModel = Object.assign({}, obj);
    };
    __decorate([
        Dependencies(PrincipalService)
    ], ModifyPrincipal.prototype, "principalService", void 0);
    __decorate([
        Emit("refreshList")
    ], ModifyPrincipal.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], ModifyPrincipal.prototype, "close", null);
    __decorate([
        Getter
    ], ModifyPrincipal.prototype, "isSupperAdmin", void 0);
    ModifyPrincipal = __decorate([
        Component({
            components: {}
        })
    ], ModifyPrincipal);
    return ModifyPrincipal;
}(Vue));
export default ModifyPrincipal;
