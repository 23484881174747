import caseCollectorController from '~/config/server/business-service/case-collector.controller';
import caseInfoDistributeController from '~/config/server/business-service/caseInfo-distribute.controller';
import caseInfoController from '~/config/server/business-service/case-info.controller';
import historyCasesController from '~/config/server/business-service/history-cases.controller';
import personalController from '~/config/server/business-service/personal.controller';
import leaveCaseController from '~/config/server/business-service/leave-case.controller';
import assistCaseController from '~/config/server/business-service/assist-case.controller';
import caseFollowRecordController from "~/config/server/business-service/case-follow-record.controller";
import caseWaringInfoController from '~/config/server/business-service/case-waring-info.controller';
import flowConfigurationController from '~/config/server/business-service/flow-configuration.controller';
import caseApplyManageController from '~/config/server/business-service/case-apply-manage.controller';
import caseTransferLogController from '~/config/server/business-service/case-transfer-log.controller';
import paymentRecordController from '~/config/server/business-service/payment-record.controller';
import workOrderController from '~/config/server/business-service/work-order.controller';
import warningController from '~/config/server/business-service/warning.controller';
import complianceCheckController from '~/config/server/business-service/compliance-check-controller.controller';
import paymentCheckController from '~/config/server/business-service/payment-check.controller';
import repayController from '~/config/server/report-service/repay.controller';
import gradesProductController from "~/config/server/report-service/grades-product.controller";
import gradesUserController from "~/config/server/report-service/grades-user.controller";
export var businessService = {
    caseCollectorController: caseCollectorController,
    caseInfoDistributeController: caseInfoDistributeController,
    caseInfoController: caseInfoController,
    historyCasesController: historyCasesController,
    personalController: personalController,
    leaveCaseController: leaveCaseController,
    assistCaseController: assistCaseController,
    caseFollowRecordController: caseFollowRecordController,
    caseWaringInfoController: caseWaringInfoController,
    flowConfigurationController: flowConfigurationController,
    caseApplyManageController: caseApplyManageController,
    caseTransferLogController: caseTransferLogController,
    paymentRecordController: paymentRecordController,
    workOrderController: workOrderController,
    warningController: warningController,
    complianceCheckController: complianceCheckController,
    paymentCheckController: paymentCheckController,
    repayController: repayController,
    gradesProductController: gradesProductController,
    gradesUserController: gradesUserController,
};
