var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import CommonTitle from "~/components/common/common-title.vue";
import WorkIdCardQuery from "~/components/workspace/work-idCard-query.vue";
import { Watch } from "vue-property-decorator";
import { CaseSearchRecordService } from "~/services/dataimp-service/case-search-record.service";
import { differenceInYears } from "date-fns";
var collectionManageModule = namespace("collection-manage");
var CustomerInfo = /** @class */ (function (_super) {
    __extends(CustomerInfo, _super);
    function CustomerInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saveIdCard = "";
        _this.paymentInfo = {};
        _this.dialog = {
            idCard: false,
        };
        return _this;
    }
    Object.defineProperty(CustomerInfo.prototype, "age", {
        get: function () {
            if (!this.currentCase.birthday)
                return null;
            else {
                return differenceInYears(new Date(), this.currentCase.birthday);
            }
        },
        enumerable: false,
        configurable: true
    });
    CustomerInfo.prototype.idCardQuery = function () {
        this.saveIdCard = this.currentCase.certificateNo;
        this.dialog.idCard = true;
    };
    CustomerInfo.prototype.onNameChange = function (val) {
        if (!val)
            return;
        document.title = this.currentCase.personalName ? this.currentCase.personalName : "调解系统";
        this.searchCasePaymentRecord();
    };
    CustomerInfo.prototype.searchCasePaymentRecord = function () {
        // this.caseSearchRecordService.searchPaymentRecord(this.caseId).subscribe(
        //   data => this.paymentInfo = data
        // )
    };
    __decorate([
        Dependencies(CaseSearchRecordService)
    ], CustomerInfo.prototype, "caseSearchRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], CustomerInfo.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], CustomerInfo.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], CustomerInfo.prototype, "overdueAmtTotalInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], CustomerInfo.prototype, "leftAmtTotalInfo", void 0);
    __decorate([
        Watch("currentCase", { immediate: true })
    ], CustomerInfo.prototype, "onNameChange", null);
    CustomerInfo = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                CommonTitle: CommonTitle,
                WorkIdCardQuery: WorkIdCardQuery,
            },
        })
    ], CustomerInfo);
    return CustomerInfo;
}(Vue));
export default CustomerInfo;
