import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'caseSearchRecordController';
export default {
    /**
     * 查询对账记录
     */
    caseBillRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseBillRecord',
        type: requestType.Get
    },
    /**
     * 查询更新记录
     */
    caseUpdateRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseUpdateRecord',
        type: requestType.Get
    },
    /**
     * 查询还款纪录
     */
    searchPaymentRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchPaymentRecord',
        type: requestType.Get
    },
    /**
     * 查询对账列表
     */
    searchBillList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchBillList',
        type: requestType.Get
    },
    /**
     * 对账金额统计
     */
    queryBillsearchByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'queryBillsearchByPrincipal',
        type: requestType.Get
    }
};
