import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'systemParamController';
export default {
    /*
     * 修改参数表
     */
    systemParamModify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'systemParamModify',
        type: requestType.Post
    },
    /*
     *系统参数查询
     */
    systemParamQuery: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'systemParamQuery',
        type: requestType.Get
    },
    /*
     *
     */
    downLoadImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'downLoadImport',
        type: requestType.Post
    },
};
