var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop, Watch } from "vue-property-decorator";
import { CaseDetailService } from "~/services/domain-service/case-detail.service";
import { PersonalService } from "~/services/domain-service/personal.service";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import CommonTitle from "~/components/common/common-title.vue";
var DetailedInfoQuery = /** @class */ (function (_super) {
    __extends(DetailedInfoQuery, _super);
    function DetailedInfoQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentCard = "";
        _this.cardDetail = {};
        _this.personalAddrs = [];
        _this.personalContacts = [];
        _this.data = null;
        return _this;
    }
    DetailedInfoQuery.prototype.onCaseIdChange = function (val) {
        val && this.refreshData();
    };
    Object.defineProperty(DetailedInfoQuery.prototype, "personalInfo", {
        get: function () {
            return this.caseDetail.personal || {};
        },
        enumerable: false,
        configurable: true
    });
    DetailedInfoQuery.prototype.refreshData = function () {
        var _this = this;
        this.caseDetailService.caseDetail(this.caseId).subscribe(function (data) {
            _this.data = data;
            _this.customerCardNumbers.length && _this.cardNumberClick(_this.customerCardNumbers[0]);
            // 获取联系人地址
            _this.personalService.searchPersonalAddress(_this.personalInfo.id).subscribe(function (data) { return (_this.personalAddrs = data); });
            // 获取联系方式
            _this.personalService.searchPersonalContact(_this.personalInfo.id).subscribe(function (data) { return (_this.personalContacts = data); });
        });
    };
    /**
     * 点击卡号
     */
    DetailedInfoQuery.prototype.cardNumberClick = function (card) {
        var _this = this;
        this.currentCard = card;
        // 获取卡明细
        this.caseDetailService.searchCardInformation(this.caseId, this.currentCard).subscribe(function (data) { return (_this.cardDetail = data); });
    };
    Object.defineProperty(DetailedInfoQuery.prototype, "cardRemarkMap", {
        get: function () {
            return this.cardDetail.remarkMap || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DetailedInfoQuery.prototype, "caseDetail", {
        get: function () {
            return this.data || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DetailedInfoQuery.prototype, "customerCardNumbers", {
        get: function () {
            return this.caseDetail.cardInformationNos || [];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Dependencies(PageService)
    ], DetailedInfoQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DetailedInfoQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseDetailService)
    ], DetailedInfoQuery.prototype, "caseDetailService", void 0);
    __decorate([
        Dependencies(PersonalService)
    ], DetailedInfoQuery.prototype, "personalService", void 0);
    __decorate([
        Prop()
    ], DetailedInfoQuery.prototype, "caseId", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], DetailedInfoQuery.prototype, "onCaseIdChange", null);
    DetailedInfoQuery = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                CommonTitle: CommonTitle,
            },
        })
    ], DetailedInfoQuery);
    return DetailedInfoQuery;
}(Vue));
export default DetailedInfoQuery;
