import { requestType } from '~/config/enum.config';
var SERVICE = 'report-service';
var CONTROLLER = 'ExportCaseInfoController';
export default {
    /**
     * 导出案件
     */
    exportCaseInfo: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'exportCaseInfo',
        type: requestType.Post
    }
};
