var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import ManualCaseAllot from "~/components/case-info/manual-case-allot.vue";
import CaseBatchAllot from "~/components/case-info/case-batch-allot.vue";
import OrgCaseStrategyAllot from "~/components/case-info/org-case-strategy-allot.vue";
import { Layout } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
import { StrategyDistributeService } from "~/services/dataimp-service/strategy-distribute.service";
import { State } from "vuex-class";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import CollectorAllocate from "~/components/case-info/case-allot/collector-allocate.vue";
var WaitDistributeCase = /** @class */ (function (_super) {
    __extends(WaitDistributeCase, _super);
    function WaitDistributeCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataName = [];
        _this.fileList = [];
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.batchNumberList = [];
        _this.waoptions = [{
                value: '',
                label: '行业调解'
            }, {
                value: '集中诉讼',
                label: '集中诉讼'
            }, {
                value: '分散诉讼',
                label: '分散诉讼'
            }];
        _this.searchModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            selfPhoneNo: "",
            certificateNo: "",
            cardNo: "",
            caseNumber: "",
            stage: "",
            employerName: "",
            address: "",
            leftAmt: {
                min: "",
                max: ""
            },
            delegationDate: [],
            overdueAmtTotal: {
                min: "",
                max: ""
            },
            endCaseDate: [],
            city: "",
            overduePeriods: {
                min: "",
                max: ""
            },
            overdueDays: {
                min: "",
                max: ""
            },
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
            //    cardNo: [{ min: 6, message: '请输入至少6位的卡号', trigger: 'blur' }],
            account: [{ min: 6, message: '请输入至少6位的账号', trigger: 'blur' }]
        };
        _this.distributeConfigModel = {};
        _this.dialog = {
            regionManualDivision: false,
            batchDistribution: false,
            strategyAllocation: false,
            collectorManualDivisionNext: false,
            regionManualDivisionNext: false,
        };
        return _this;
    }
    WaitDistributeCase.prototype.mounted = function () {
        this.refreshData();
    };
    WaitDistributeCase.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.leftAmt = [];
        this.searchModel.overdueAmtTotal = [];
        this.searchModel.overduePeriods = [];
        this.searchModel.overdueDays = [];
    };
    /**
     * 刷新数据列
     */
    WaitDistributeCase.prototype.refreshData = function () {
        var _this = this;
        this.dataImportService.findDistributeCase(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
            _this.loading.state = false;
        });
    };
    /**
     * 搜索条件重置
     */
    WaitDistributeCase.prototype.reset = function () {
        var form = this.$refs["form"];
        if (form) {
            form.resetForm();
        }
    };
    WaitDistributeCase.prototype.close = function () {
        this.dialog.regionManualDivisionNext = false;
        this.dialog.collectorManualDivisionNext = false;
    };
    /**
     * 手动分案
     */
    WaitDistributeCase.prototype.onManualSeparate = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.regionManualDivision = true;
        this.$nextTick(function () {
            var form = _this.$refs["manual-case-allot"];
            form.refreshData();
        });
    };
    /**
     * 批次分配
     */
    WaitDistributeCase.prototype.onBatchDistribution = function () {
        this.dialog.batchDistribution = true;
    };
    /**
     * 策略分配
     */
    WaitDistributeCase.prototype.onStrategyAllocation = function () {
        var _this = this;
        // this.dialog.strategyAllocation = true;
        this.$confirm('确定执行策略分案?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(function () {
            _this.loading.state = true;
            _this.strategyDistributeService.manualExecuteStrategy('WAIT_DISTRIBUTE', _this.loading).subscribe(function (data) {
                _this.$message({
                    type: 'success',
                    message: '正在进行策略分案，请稍后刷新页面!'
                });
            });
        }).catch(function () {
            _this.$message({
                type: 'info',
                message: '已取消策略分案'
            });
        });
    };
    /**
     * 策略分配窗口打开时调用
     */
    WaitDistributeCase.prototype.onStrategyAllocationOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["org-case-strategy-allot"];
            form.refreshData();
        });
    };
    WaitDistributeCase.prototype.uploadSuccess = function (response, file, fileList) {
        if (response.caseNumTotal && response.caseNumTotal > 0) {
            this.$message.success("导入成功");
            this.fileList = [];
            this.distributeConfigModel = response;
            this.distributeConfigModel.distributePoolType = 'WAIT_POOL';
            this.dialog.collectorManualDivisionNext = true;
            this.refreshData();
        }
        else {
            this.$message.error(response);
        }
    };
    WaitDistributeCase.prototype.uploadError = function (response) {
        this.$message.error(response ? response : "导入失败");
    };
    __decorate([
        Dependencies(PageService)
    ], WaitDistributeCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], WaitDistributeCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], WaitDistributeCase.prototype, "dataImportService", void 0);
    __decorate([
        Dependencies(StrategyDistributeService)
    ], WaitDistributeCase.prototype, "strategyDistributeService", void 0);
    __decorate([
        State
    ], WaitDistributeCase.prototype, "principalList", void 0);
    __decorate([
        State
    ], WaitDistributeCase.prototype, "userToken", void 0);
    __decorate([
        Emit("close")
    ], WaitDistributeCase.prototype, "close", null);
    WaitDistributeCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                CollectorAllocate: CollectorAllocate,
                DataBox: DataBox,
                DataForm: DataForm,
                ManualCaseAllot: ManualCaseAllot,
                CaseBatchAllot: CaseBatchAllot,
                OrgCaseStrategyAllot: OrgCaseStrategyAllot,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch
            }
        })
    ], WaitDistributeCase);
    return WaitDistributeCase;
}(Vue));
export default WaitDistributeCase;
