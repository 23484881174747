var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop, Watch } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
var collectionManageModule = namespace("collection-manage");
var Other = /** @class */ (function (_super) {
    __extends(Other, _super);
    function Other() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.repeatDataSet = [];
        _this.dialog = { repeat: false, lookRepeat: false };
        _this.currentId = '';
        _this.replyModel = {
            id: '',
            replayContent: '',
            caseId: "",
            caseType: ""
        };
        _this.viewModel = {
            replayContent: ''
        };
        _this.approvalRules = {
            replayContent: [
                { required: true, message: "请输入回复内容", trigger: "blur" }
            ]
        };
        return _this;
    }
    Other.prototype.onCaseIdChanged = function (val) {
        val && this.refreshData();
    };
    Other.prototype.refreshData = function () {
        var _this = this;
        var params = {
            certificateNo: this.certificateNo
        };
        this.caseFollowRecordService.getAllOtherRecord(params, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    Other.prototype.cancel = function () {
        this.dialog.repeat = false;
        var form = this.$refs['approval-form'];
        form.resetFields();
    };
    Other.prototype.repeatContent = function (row) {
        this.dialog.repeat = true;
        this.currentId = row.id;
        this.replyModel.id = row.id;
    };
    Other.prototype.lookRepeatContent = function (row) {
        this.dialog.lookRepeat = true;
        this.viewModel.replayContent = row.replayContent;
    };
    Other.prototype.closeWindow = function () {
        this.dialog.lookRepeat = false;
    };
    Other.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['approval-form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.replyModel.caseId = _this.caseId;
            _this.replyModel.caseType = _this.otherData.caseType;
            _this.caseFollowRecordService.replyOtherRecord(_this.replyModel)
                .subscribe(function (data) {
                _this.$message.success("回复成功!");
                _this.cancel();
                _this.refreshData();
            }, function (err) { return _this.$message.error(err.msg); });
        });
    };
    Other.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], Other.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], Other.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], Other.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], Other.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.State
    ], Other.prototype, "caseId", void 0);
    __decorate([
        Prop({
            required: true,
            default: ""
        })
    ], Other.prototype, "certificateNo", void 0);
    __decorate([
        Prop({
            required: true,
            default: ""
        })
    ], Other.prototype, "personalId", void 0);
    __decorate([
        Watch("certificateNo", { immediate: true })
    ], Other.prototype, "onCaseIdChanged", null);
    Other = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], Other);
    return Other;
}(Vue));
export default Other;
