import { requestType } from '~/config/enum.config';
var SERVICE = 'datasync-service';
var CONTROLLER = 'departmentInfoController';
export default {
    /**
     * 手工同步机构
     */
    handleDepartment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'handleDepartment',
        type: requestType.Get
    }
};
