var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { DepartmentService } from "~/services/manage-service/department.service";
var AddOrganize = /** @class */ (function (_super) {
    __extends(AddOrganize, _super);
    function AddOrganize() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOrigin = false;
        _this.submitLoading = false;
        _this.addOrgModel = {
            name: "",
            parentName: "",
            parentId: "",
            type: "",
            collectionType: "",
            status: "",
            contact: "",
            contactNo: "",
            address: "",
        };
        _this.disabled = false;
        _this.loading = {
            state: false
        };
        /**
         * 添加机构信息校验规则
         */
        _this.addOrganizeRules = {
            name: { required: true, message: "请输入机构名称" },
            type: { required: true, message: "请选择组织机构" },
            collectionType: { required: true, message: "请选择调解类型" },
            status: { required: true, message: "请选择机构状态" }
        };
        return _this;
    }
    AddOrganize.prototype.originalType = function (value) {
        if (value === 'BRANCH') {
            this.isOrigin = true;
        }
        else {
            this.isOrigin = false;
        }
    };
    AddOrganize.prototype.refresh = function (adddata) {
        if (!adddata) {
            return;
        }
        if (adddata.level > 8) {
            this.disabled = true;
        }
        else {
            this.disabled = false;
        }
        this.addOrgModel.parentId = adddata.id;
        this.addOrgModel.parentName = adddata.name;
    };
    /**
     * 添加机构确认
     */
    AddOrganize.prototype.addOrganizeCommit = function () {
        var _this = this;
        var form = this.$refs["addOrg"];
        form.validate(function (valid) {
            if (valid) {
                _this.submitLoading = true;
                _this.departmentService.addDepartment(_this.addOrgModel).subscribe(function (data) {
                    _this.$message.success("添加机构成功");
                    _this.submitLoading = false;
                    _this.$emit("refresh");
                    _this.close();
                });
            }
        });
    };
    /**
     * 关闭窗口
     */
    AddOrganize.prototype.close = function () {
        var form = this.$refs["addOrg"];
        form.resetFields();
        this.isOrigin = false;
        this.$emit("close");
    };
    AddOrganize.prototype.mounted = function () {
    };
    __decorate([
        Dependencies(DepartmentService)
    ], AddOrganize.prototype, "departmentService", void 0);
    AddOrganize = __decorate([
        Component({
            components: {}
        })
    ], AddOrganize);
    return AddOrganize;
}(Vue));
export default AddOrganize;
