var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var createDataInfoExcelService = function () { return import("~/services/dataimp-service/data-info-excel.service"); };
export default {
    namespaced: true,
    state: {
        caseAllotOrg: null,
        clientList: [],
        batchList: [],
        caseAllotUser: [],
        importSuccess: false // 案件导入是否成功
    },
    getters: {},
    mutations: {
        // 更新委托方列表
        updateClientList: function (state, data) {
            state.clientList = data;
        },
        updateStatus: function (state, flag) {
            state.importSuccess = flag;
        },
        // 更新批次号列表
        updateBatchList: function (state, data) {
            state.batchList = data;
        },
        // 案件分配机构列表
        updateCaseAllotOrg: function (state, data) {
            state.caseAllotOrg = data;
        },
        // 案件分配用户Id
        updateCaseAllotUser: function (state, data) {
            state.caseAllotUser = data;
        }
    },
    actions: {
        /**
         * 获取批次号列表
         */
        getBatchList: function (_a, _b) {
            var state = _a.state, commit = _a.commit;
            var companyCode = _b.companyCode;
            return __awaiter(this, void 0, void 0, function () {
                var DataInfoExcelService;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, createDataInfoExcelService()];
                        case 1:
                            DataInfoExcelService = (_c.sent()).DataInfoExcelService;
                            DataInfoExcelService.
                                getInstance()
                                .queryBatchNumGroup(companyCode).subscribe(function (data) {
                                commit("updateBatchList", data);
                                if (state.importSuccess) {
                                    commit("updateStatus", false);
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
