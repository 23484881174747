import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'operatorLoginLog';
export default {
    /**
     * 获取系统日志列表
     */
    getOperatorLoginLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getOperatorLoginLog',
        type: requestType.Get
    },
    /**
     * 批量删除日志
     */
    deleteOperatorLoginLog: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteOperatorLoginLog',
        type: requestType.Get
    }
};
