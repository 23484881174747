var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { quillEditor } from 'vue-quill-editor';
import { Emit } from "vue-property-decorator";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
var AddData = /** @class */ (function (_super) {
    __extends(AddData, _super);
    function AddData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.bulletionModel = {};
        _this.dialogImageUrl = '';
        _this.dialogVisible = false;
        return _this;
    }
    AddData.prototype.close = function () {
    };
    AddData.prototype.created = function () {
    };
    AddData.prototype.mounted = function () {
        this.bulletionModel = {
            name: '',
        };
    };
    __decorate([
        Emit("close")
    ], AddData.prototype, "close", null);
    AddData = __decorate([
        Component({
            components: {
                quillEditor: quillEditor,
            }
        })
    ], AddData);
    return AddData;
}(Vue));
export default AddData;
