var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var ContactConfigService = /** @class */ (function (_super) {
    __extends(ContactConfigService, _super);
    function ContactConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询联络配置树结构
     */
    ContactConfigService.prototype.getContactConfigTree = function (loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getContactConfigTree,
            loading: loading,
        });
    };
    /**
     * 获取子集
     */
    ContactConfigService.prototype.getChildren = function (parent, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getChildren,
            data: {
                parent: parent,
            },
            loading: loading,
        });
    };
    /**
     * 根据ID获取配置信息
     */
    ContactConfigService.prototype.getContactConfigById = function (id, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getContactConfigById,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 新增联络配置
     */
    ContactConfigService.prototype.addContactConfig = function (data, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.addContactConfig,
            data: data,
            loading: loading,
        });
    };
    /**
     * 修改联络配置
     */
    ContactConfigService.prototype.modifyContactConfig = function (data, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.modifyContactConfig,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除联络配置
     */
    ContactConfigService.prototype.deleteContactConfig = function (id, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.deleteContactConfig,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 查询没有联络配置的委托方
     */
    ContactConfigService.prototype.getPrincipalNoConfig = function (laoding) {
        return this.netService.send({
            server: manageService.contactConfigController.getPrincipalNoConfig,
            laoding: laoding,
        });
    };
    /**
     * 查询跟进记录字段项
     */
    ContactConfigService.prototype.getFollowMarchRecord = function (loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getFollowMarchRecord,
            loading: loading,
        });
    };
    /**
     * 查询跟进记录映射值
     */
    ContactConfigService.prototype.getAllContactConfig = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getAllContactConfig,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询调记匹配字段
     */
    ContactConfigService.prototype.getFollowRecordFields = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getFollowRecordFields,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询联系人关系
     */
    ContactConfigService.prototype.getRelationShip = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getRelationShip,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询地址状态
     */
    ContactConfigService.prototype.getAddressStatus = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getAddressStatus,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询地址类型
     */
    ContactConfigService.prototype.getAddressType = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getAddressType,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 查询联络结果
     */
    ContactConfigService.prototype.getContactResult = function (principalId, loading) {
        return this.netService.send({
            server: manageService.contactConfigController.getContactResult,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    /**
     * 联络结果移动
     */
    ContactConfigService.prototype.moveContactConfig = function (data) {
        return this.netService.send({
            server: manageService.contactConfigController.moveContactConfig,
            data: data,
        });
    };
    __decorate([
        Inject(NetService)
    ], ContactConfigService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getContactConfigTree", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getChildren", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getContactConfigById", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "addContactConfig", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "modifyContactConfig", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "deleteContactConfig", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getPrincipalNoConfig", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getFollowMarchRecord", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getAllContactConfig", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getFollowRecordFields", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getRelationShip", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getAddressStatus", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getAddressType", null);
    __decorate([
        Debounce()
    ], ContactConfigService.prototype, "getContactResult", null);
    return ContactConfigService;
}(Service));
export { ContactConfigService };
