var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import AddressModify from "./address-query-base/address-modify.vue";
import ForeignVisits from "./address-query-base/foreign-visits.vue";
import ForeignVisitsDiffPlaces from "./address-query-base/foreign-visits-diff-places.vue";
import LetterModify from "./address-query-base/letter-modify.vue";
import AddressRemarkModify from "./address-query-base/address-remark-modify.vue";
import { Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { PersonalService } from "~/services/business-service/personal.service";
import { AssistCollectionCaseService } from "~/services/domain-service/assist-collection-case.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import { FlowConfigurationService } from "~/services/business-service/flow-configuration.service";
var collectionManageModule = namespace("collection-manage");
var AddressQuery = /** @class */ (function (_super) {
    __extends(AddressQuery, _super);
    function AddressQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentAddress = {};
        _this.dataSet = [];
        _this.selectionList = [];
        _this.markIds = [];
        _this.dialog = {
            addressModify: false,
            addressModifyUpdate: false,
            foreignVisits: false,
            foreignVisitsDiffPlaces: false,
            letterModify: false,
            addressRemarkModify: false,
        };
        _this.loading = {
            state: false,
        };
        _this.flowType = "VISIT_APPLY";
        _this.caseType = ""; //调解方式显示隐藏
        return _this;
    }
    AddressQuery.prototype.onPersonalIdChange = function (val) {
        val && this.refreshData();
    };
    AddressQuery.prototype.refreshData = function () {
        var _this = this;
        this.caseType = this.queryOtherData("caseType");
        this.personalService.findPersonalAddress(this.caseId).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    // 标记未知
    AddressQuery.prototype.markUnknown = function () {
        this.markCase("UNKNOWN");
    };
    // 标记有效
    AddressQuery.prototype.markEffective = function () {
        this.markCase("EFFECTIVE");
    };
    // 标记无效
    AddressQuery.prototype.markInvalid = function () {
        this.markCase("INVALID");
    };
    // 案件标记
    AddressQuery.prototype.markCase = function (data) {
        var _this = this;
        if (this.selectionList.length == 0) {
            this.$message.info("请选择要标记的联系人");
            return;
        }
        this.markIds = [];
        this.selectionList.forEach(function (x) {
            _this.markIds.push(x.id);
        });
        var params = {
            ids: this.markIds,
            flag: 1,
            status: data,
            caseId: this.caseId,
            caseType: this.otherData.caseType,
        };
        this.personalService.markStatus(params).subscribe(function (data) {
            _this.$message.success("标记成功");
            _this.refreshData();
            _this.selectionList = [];
        });
    };
    /**
     * 本地外访
     */
    AddressQuery.prototype.onForeignVisits = function (currentAddress) {
        this.currentAddress = currentAddress;
        this.dialog.foreignVisits = true;
    };
    /**
     * 异地外访
     */
    AddressQuery.prototype.onForeignVisitsDiff = function (currentAddress) {
        this.currentAddress = currentAddress;
        this.dialog.foreignVisitsDiffPlaces = true;
    };
    /**
     * 信函
     */
    AddressQuery.prototype.onLetter = function (currentAddress) {
        this.currentAddress = currentAddress;
        this.dialog.letterModify = true;
    };
    AddressQuery.prototype.addressRemarkModify = function (currentAddress) {
        this.currentAddress = currentAddress;
        this.dialog.addressRemarkModify = true;
    };
    AddressQuery.prototype.onRevert = function (rowData) {
        var _this = this;
        this.$confirm("是否确认撤回?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            // 撤回操作
            var assistObj = {
                personalAddressId: rowData.personalAddressId,
                personalContactId: rowData.id,
                assistAddressFlag: rowData.assistAddressFlag,
            };
            _this.assistCollectionCaseService.retractAssistCase(assistObj).subscribe(function (data) {
                _this.$message.success("撤回成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    /**
     * 地址状态改变
     */
    AddressQuery.prototype.phoneStateChanged = function (record) {
        var phoneStateModel = {
            caseId: this.caseId,
            // 联系人ID
            personalContactId: record.id,
            // 地址状态
            addressState: record.addressState,
            // 地址id
            personalAddressId: record.personalAddressId,
        };
        // 加载等待
        this.loading.state = true;
        this.personalService.markPhoneOrAddressStatus(phoneStateModel, this.loading).subscribe(function (data) { }, function (error) { });
    };
    AddressQuery.prototype.phoneStateDelete = function (row) {
        var _this = this;
        this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664\u672C\u6761\u8BB0\u5F55?", "提示", {
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.personalService.deletePersonalContact(row.id, 1, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    AddressQuery.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PersonalService)
    ], AddressQuery.prototype, "personalService", void 0);
    __decorate([
        Dependencies(AssistCollectionCaseService)
    ], AddressQuery.prototype, "assistCollectionCaseService", void 0);
    __decorate([
        Dependencies(FlowConfigurationService)
    ], AddressQuery.prototype, "flowConfigurationService", void 0);
    __decorate([
        Prop({
            required: true,
            default: "",
        })
    ], AddressQuery.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], AddressQuery.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.Getter
    ], AddressQuery.prototype, "queryOtherData", void 0);
    __decorate([
        Prop({
            required: true,
            default: "",
        })
    ], AddressQuery.prototype, "personalId", void 0);
    __decorate([
        Watch("caseId", {
            immediate: true,
        })
    ], AddressQuery.prototype, "onPersonalIdChange", null);
    AddressQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                AddressModify: AddressModify,
                ForeignVisits: ForeignVisits,
                ForeignVisitsDiffPlaces: ForeignVisitsDiffPlaces,
                LetterModify: LetterModify,
                AddressRemarkModify: AddressRemarkModify,
                SvgIcon: SvgIcon,
            },
        })
    ], AddressQuery);
    return AddressQuery;
}(Vue));
export default AddressQuery;
