var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { ExportTemplateService } from "~/services/dataimp-service/export-template.service";
var ApplyName = /** @class */ (function (_super) {
    __extends(ApplyName, _super);
    function ApplyName() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.searchModel = {
            name: "",
        };
        _this.selectCaseRelate = null;
        _this.selectFollowRelate = null;
        _this.selectOrderRelate = null;
        _this.selectContactRelate = null;
        _this.caseData = [];
        _this.followData = [];
        _this.orderData = [];
        _this.contactData = [];
        return _this;
    }
    ApplyName.prototype.close = function () { };
    ApplyName.prototype.backDate = function (val) { };
    Object.defineProperty(ApplyName.prototype, "isShowCase", {
        get: function () {
            return this.caseData && this.caseData.length !== 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApplyName.prototype, "isShowFollow", {
        get: function () {
            return this.followData && this.followData.length !== 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApplyName.prototype, "isShowOrder", {
        get: function () {
            return this.orderData && this.orderData.length !== 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApplyName.prototype, "isShowContact", {
        get: function () {
            return this.contactData && this.contactData.length !== 0;
        },
        enumerable: false,
        configurable: true
    });
    ApplyName.prototype.disStr = function (v) {
        return !!this.disabledItems.find(function (val) { return val.attribute === v.attribute && val.propertyType === v.propertyType; });
    };
    ApplyName.prototype.refreshData = function (model) {
        this.getCaseInfoProperty(model);
    };
    ApplyName.prototype.getCaseInfoProperty = function (model) {
        var _this = this;
        this.loading.state = true;
        this.exportTemplateService.getExportProperty(model.type).subscribe(function (data) {
            if (data) {
                _this.loading.state = false;
                _this.caseData = data.filter(function (x) { return x.propertyType === "CASE"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
                _this.followData = data.filter(function (x) { return x.propertyType === "FOLLOW"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
                _this.orderData = data.filter(function (x) { return x.propertyType === "WORK_ORDER"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
                _this.contactData = data.filter(function (x) { return x.propertyType === "PERSONAL"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            }
            else {
                _this.selectCaseRelate = null;
                _this.selectFollowRelate = null;
                _this.selectOrderRelate = null;
                _this.selectContactRelate = null;
            }
        }, function (_a) {
            var msg = _a.msg;
            _this.$message.error(msg);
        });
    };
    /**
     * 检索关键字
     */
    ApplyName.prototype.onQuery = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            if (_this.searchModel.name) {
                _this.caseData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.followData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.orderData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.contactData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
            }
            else {
                _this.caseData.map(function (v) { return (v.isShow = true); });
                _this.followData.map(function (v) { return (v.isShow = true); });
                _this.orderData.map(function (v) { return (v.isShow = true); });
                _this.contactData.map(function (v) { return (v.isShow = true); });
            }
        });
    };
    /**
     * 取消
     */
    ApplyName.prototype.cancelClick = function () {
        this.reset();
        this.close();
    };
    ApplyName.prototype.reset = function () {
        this.selectFollowRelate = "";
        this.selectCaseRelate = "";
        this.selectOrderRelate = "";
        this.selectContactRelate = "";
    };
    /**
     * 确定
     */
    ApplyName.prototype.saveClick = function () {
        if (this.selectFollowRelate) {
            this.backDate(this.selectFollowRelate);
        }
        else if (this.selectCaseRelate) {
            this.backDate(this.selectCaseRelate);
        }
        else if (this.selectOrderRelate) {
            this.backDate(this.selectOrderRelate);
        }
        else if (this.selectContactRelate) {
            this.backDate(this.selectContactRelate);
        }
        this.cancelClick();
    };
    __decorate([
        Dependencies(ExportTemplateService)
    ], ApplyName.prototype, "exportTemplateService", void 0);
    __decorate([
        Emit("close")
    ], ApplyName.prototype, "close", null);
    __decorate([
        Emit("backDate")
    ], ApplyName.prototype, "backDate", null);
    __decorate([
        Prop()
    ], ApplyName.prototype, "disabledItems", void 0);
    ApplyName = __decorate([
        Component({
            components: {},
        })
    ], ApplyName);
    return ApplyName;
}(Vue));
export default ApplyName;
