var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { VeRing } from "v-charts";
import { Prop } from "vue-property-decorator";
var PieEcharts = /** @class */ (function (_super) {
    __extends(PieEcharts, _super);
    function PieEcharts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.echartsData1 = [
            { name: "委案金额", value: "30" },
            { name: "回款金额", value: "70" }
        ];
        _this.optionData = {
            data: {
                columns: ["name", "value"],
                rows: _this.echartsData1
            },
            settings: {
                radius: ["40%", "65%"],
                offsetY: 150,
                labelLine: {
                    show: false
                },
                label: {
                    show: false
                }
            },
            theme: {
                color: ["#E0B353", "#7278EE"]
            },
            title: {
                text: "30",
                subtext: "回款率",
                textStyle: {
                    fontSize: 50,
                    color: "#242424"
                },
                subtextStyle: {
                    fontSize: 15,
                    color: "#999999"
                },
                textAlign: "center",
                x: "49%",
                y: "27% "
            },
            legend: {
                type: "scroll",
                bottom: "40",
                left: "center",
                textStyle: {
                    color: "#8C8C8C"
                },
                height: 250
            },
            grid: {
                top: "top",
                left: "center"
            }
        };
        return _this;
    }
    PieEcharts.prototype.mounted = function () {
        this.optionData.data.rows = this.echartsData.rows;
        this.optionData.title.text = this.echartsData.text;
        this.optionData.theme.color = this.echartsData.color;
    };
    __decorate([
        Prop()
    ], PieEcharts.prototype, "echartsData", void 0);
    PieEcharts = __decorate([
        Component({
            components: {
                VeRing: VeRing
            }
        })
    ], PieEcharts);
    return PieEcharts;
}(Vue));
export default PieEcharts;
