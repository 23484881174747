var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FollowDetailsQuery from "~/components/collection-manage/co-urging/follow-details-query.vue";
import { Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import { Watch } from "vue-property-decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { AssistCollectionCaseService } from "~/services/domain-service/assist-collection-case.service";
var RaiseForeignVisits = /** @class */ (function (_super) {
    __extends(RaiseForeignVisits, _super);
    function RaiseForeignVisits() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.caseId = "";
        _this.assistOutType = "";
        _this.queryParamsModel = {
            principalId: "",
            caseNumber: "",
            account: "",
            batchNumber: "",
            personalName: "",
            assistStatus: "",
            idCard: "",
            currentCollector: "",
            assistSearchFlag: "",
            assistFlag: "OFFSITE_OUT_ASSIST",
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.dialog = {
            followDetailsQuery: false,
        };
        _this.rules = {
            idCard: [{ min: 4, message: "请输入至少4位的身份证号", trigger: "blur" }],
        };
        _this.loading = {
            state: false,
        };
        return _this;
    }
    RaiseForeignVisits.prototype.routeChange = function (val) {
        if (!val)
            return;
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
    };
    RaiseForeignVisits.prototype.cellStyle = function (_a) {
        var row = _a.row;
        if (!row.color)
            return;
        return { color: row.color + " !important" };
    };
    RaiseForeignVisits.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.assistCollectionCaseService.searchOwnAssistCase(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], RaiseForeignVisits.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], RaiseForeignVisits.prototype, "sortService", void 0);
    __decorate([
        Dependencies(AssistCollectionCaseService)
    ], RaiseForeignVisits.prototype, "assistCollectionCaseService", void 0);
    __decorate([
        State
    ], RaiseForeignVisits.prototype, "principalList", void 0);
    __decorate([
        Watch("$route", { immediate: true })
    ], RaiseForeignVisits.prototype, "routeChange", null);
    RaiseForeignVisits = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FollowDetailsQuery: FollowDetailsQuery,
            },
        })
    ], RaiseForeignVisits);
    return RaiseForeignVisits;
}(Vue));
export default RaiseForeignVisits;
