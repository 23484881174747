var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { SensitiveWordService } from "~/services/manage-service/sensitive-word.service";
import WordsConfiguration from "~/components/system-manage/principal-manage/words-configuration.vue";
import AddWordConfiguration from "~/components/system-manage/principal-manage/add-word-configuration.vue";
var SensitiveWord = /** @class */ (function (_super) {
    __extends(SensitiveWord, _super);
    function SensitiveWord() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 搜索model
        _this.Model = {
            principalId: "",
            seriousWords: "",
            promptWords: ''
        };
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.dialog = {
            exportConfig: false,
            add: false
        };
        _this.modifyData = {};
        return _this;
    }
    SensitiveWord.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新数据
     */
    SensitiveWord.prototype.refreshData = function () {
        var _this = this;
        this.sensitiveWordService.findAllSensitiveWord(this.Model, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data.map(function (v) {
                v.promptWords = v.promptWords || [];
                v.seriousWords = v.seriousWords || [];
                return v;
            });
        }, function (err) {
            _this.$message.error(err.msg);
        });
    };
    /**
     * 修改模版
     */
    SensitiveWord.prototype.updateClick = function (row) {
        this.modifyData = __assign({}, row);
        this.dialog.exportConfig = true;
    };
    /**
     * 删除敏感词
     */
    SensitiveWord.prototype.deleteClick = function (id) {
        var _this = this;
        this.$confirm("是否确定删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.loading.state = true;
            _this.sensitiveWordService.deleteSensitiveWord(id, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], SensitiveWord.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SensitiveWord.prototype, "sortService", void 0);
    __decorate([
        Dependencies(SensitiveWordService)
    ], SensitiveWord.prototype, "sensitiveWordService", void 0);
    __decorate([
        State
    ], SensitiveWord.prototype, "principalList", void 0);
    SensitiveWord = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                WordsConfiguration: WordsConfiguration,
                AddWordConfiguration: AddWordConfiguration
            }
        })
    ], SensitiveWord);
    return SensitiveWord;
}(Vue));
export default SensitiveWord;
