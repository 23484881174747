// import injector from 'vue-inject';
var Service = /** @class */ (function () {
    function Service() {
    }
    Service.getInstance = function () {
        return new this;
    };
    return Service;
}());
export { Service };
