var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Layout, Dependencies } from '~/core/decorator';
import DataForm from '~/components/common/data-form.vue';
import RepairFileUpload from "~/components/common/repair-file-upload.vue";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
//@Auth(105)
var ImportCompare = /** @class */ (function (_super) {
    __extends(ImportCompare, _super);
    function ImportCompare() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.importLoading = false;
        _this.loading = {
            state: false
        };
        _this.importModel = {
            importContentType: "",
            fileId: ""
        };
        return _this;
    }
    /**
     * 表单重置
     */
    ImportCompare.prototype.reset = function () {
        this.importLoading = false;
        var upload = this.$refs['upload_case_import'];
        upload.reset();
        this.importModel.importContentType = null;
    };
    ImportCompare.prototype.submit = function () {
        var _this = this;
        this.loading.state = true;
        if (this.fileList.length === 0) {
            this.$message('请上传导入文件');
            return;
        }
        this.importModel.fileId = this.fileList[0].response.id;
        this.dataRepairImportService
            .importCompare(this.importModel, this.loading).subscribe(function (data) {
            _this.$message.success('操作成功');
            _this.refreshData();
        });
    };
    ImportCompare.prototype.refreshData = function () {
        this.reset();
    };
    __decorate([
        Dependencies(DataRepairImportService)
    ], ImportCompare.prototype, "dataRepairImportService", void 0);
    ImportCompare = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                RepairFileUpload: RepairFileUpload
            }
        })
    ], ImportCompare);
    return ImportCompare;
}(Vue));
export default ImportCompare;
