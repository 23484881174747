var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileUpload from '~/components/common/file-upload.vue';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { Emit } from 'vue-property-decorator';
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
var collectionManageModule = namespace("collection-manage");
var StayCaseApp = /** @class */ (function (_super) {
    __extends(StayCaseApp, _super);
    function StayCaseApp() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            flowType: 'LEAVE_CASE_APPLY',
            caseId: _this.caseId,
            applyRemark: '',
            endCaseDate: '',
            caseType: ''
        };
        _this.userData = [];
        _this.rules = {
            applyRemark: [
                { required: true, message: '请输入留案说明', trigger: 'blur' },
            ],
            endCaseDate: [
                { required: true, message: '请输入留案到期日期', trigger: 'blur' },
            ]
        };
        // 控制不能选择当前时间之前的日期
        _this.pickerOptions = {
            disabledDate: function (time) {
                var nowData = new Date();
                nowData = new Date(nowData.setDate(nowData.getDate() - 1));
                return time < nowData;
            }
        };
        return _this;
    }
    StayCaseApp.prototype.close = function () {
        var form = this.$refs['form'];
        form.resetFields();
    };
    /**
     * 留案申请提交
     */
    StayCaseApp.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            var model = {
                caseId: _this.caseId,
                flowType: _this.model.flowType,
                applyRemark: _this.model.applyRemark,
                endCaseDate: _this.model.endCaseDate,
                caseType: _this.otherData.caseType
            };
            _this.caseApplyManageService.caseApplyOperated(model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('案件留案申请成功!');
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], StayCaseApp.prototype, "caseApplyManageService", void 0);
    __decorate([
        collectionManageModule.State
    ], StayCaseApp.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], StayCaseApp.prototype, "otherData", void 0);
    __decorate([
        Emit('close')
    ], StayCaseApp.prototype, "close", null);
    StayCaseApp = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], StayCaseApp);
    return StayCaseApp;
}(Vue));
export default StayCaseApp;
