var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { ValidatorService } from "~/utils/validator.service";
import { PersonalService } from "~/services/business-service/personal.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { CommonService } from "~/utils/common.service";
var collectionManageModule = namespace("collection-manage");
var AddressModify = /** @class */ (function (_super) {
    __extends(AddressModify, _super);
    function AddressModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            id: '',
            name: "",
            certificateNo: "",
            relation: "",
            principalId: '',
            source: "",
            remark: "",
            workAddress: '',
            homeAddress: '',
            censusAddress: '',
            addressDetail: '',
            caseId: '',
            caseType: ''
        };
        _this.rules = {
            name: [{ required: true, message: "请输入关系人姓名", trigger: "blur" },
                { validator: ValidatorService.chineseName }
            ],
        };
        return _this;
    }
    AddressModify.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
        this.model.id = "";
        this.model.relation = "";
        this.model.source = "";
    };
    AddressModify.prototype.emitSuccess = function () {
        this.$message.success("操作成功");
        this.close();
    };
    AddressModify.prototype.onChange = function () {
        if (!this.model.relation)
            return;
        if (this.model.relation == '本人') {
            this.model.name = this.currentCase.personalName;
            this.model.certificateNo = this.currentCase.certificateNo;
        }
    };
    /**
     * 补款申请提交
     */
    AddressModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.model.caseId = _this.caseId;
            _this.model.principalId = _this.currentCase.principalId;
            _this.model.caseType = _this.otherData.caseType;
            if (_this.model.id) {
                // 案件id
                _this.personalServiceAddressModify
                    .modifyPersonalAddress(_this.model, _this.loading)
                    .subscribe(_this.emitSuccess);
            }
            else {
                _this.personalServiceAddressModify
                    .addPersonalAddress(_this.model, _this.loading)
                    .subscribe(_this.emitSuccess);
            }
        });
    };
    AddressModify.prototype.updatedModel = function (currentAddress) {
        CommonService.revertData(this.model, currentAddress);
        this.model.certificateNo = currentAddress.certificateNo;
    };
    __decorate([
        Dependencies(PersonalService)
    ], AddressModify.prototype, "personalServiceAddressModify", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], AddressModify.prototype, "custConfigService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], AddressModify.prototype, "personalInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], AddressModify.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], AddressModify.prototype, "personalId", void 0);
    __decorate([
        collectionManageModule.State
    ], AddressModify.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], AddressModify.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], AddressModify.prototype, "caseId", void 0);
    __decorate([
        Emit("close")
    ], AddressModify.prototype, "close", null);
    __decorate([
        Emit('success')
    ], AddressModify.prototype, "emitSuccess", null);
    AddressModify = __decorate([
        Component({
            components: {}
        })
    ], AddressModify);
    return AddressModify;
}(Vue));
export default AddressModify;
