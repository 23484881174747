var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Getter } from "vuex-class";
import { FilterService } from "~/utils/filter.service";
import { UserService } from "~/services/manage-service/user.service";
import { DayCollectionStatisticService } from "~/services/report-service/day-collection-statistic.service";
import legalWanRequest from "~/utils/legal.wan.request";
var DayCollectionStatistic = /** @class */ (function (_super) {
    __extends(DayCollectionStatistic, _super);
    function DayCollectionStatistic() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.pageNo = 1;
        _this.pageIndex = 10;
        _this.total = 0;
        _this.searchModel = {
            startDate: _this.getDate(),
            endDate: "",
            callee: "",
            agentCode: "",
            agentName: "",
            maxTime: "",
            minTime: "",
            name: "",
            orgName: "",
        };
        _this.callResultMap = {
            0: "坐席接听",
            2: "客户接听",
            5: "坐席未接听",
            6: "客户未接听",
            7: "IVR放弃",
            8: "转接外部",
            9: "语音留言",
        };
        _this.callTypeMap = {
            0: "呼入",
            1: "呼出",
            2: "预测",
            3: "转接",
            4: "内部呼叫",
            5: "内部求组",
            6: "IVR呼叫",
        };
        _this.activeName = "1";
        _this.dataSet = [];
        _this.callTotalData = [];
        _this.exportList = [];
        _this.deptIds = []; //部门列表
        _this.collectorFromList = []; //调解员列表
        _this.validStart = function (rule, value, callback) {
            var startTime = new Date(value).getTime();
            var endTime = new Date(_this.searchModel.endDate).getTime();
            if (endTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("起始时间不能大于截止时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.validEnd = function (rule, value, callback) {
            var startTime = new Date(_this.searchModel.startDate).getTime();
            var endTime = new Date(value).getTime();
            if (startTime != 0) {
                if (startTime > endTime) {
                    callback(new Error("截止时间不能小于起始时间"));
                }
                else {
                    callback();
                }
            }
        };
        _this.rules = {
            startDate: [
                { required: true, message: "请选择起始时间", trigger: "change" },
                { validator: _this.validStart, trigger: "change" },
            ],
            endDate: [
                { required: true, message: "请选择截止时间", trigger: "change" },
                { validator: _this.validEnd, trigger: "change" },
            ],
        };
        return _this;
    }
    DayCollectionStatistic.prototype.getDate = function () {
        var now = new Date();
        var year = now.getFullYear();
        var month = (now.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始
        var day = now.getDate().toString().padStart(2, "0");
        return year + "-" + month + "-" + day;
    };
    DayCollectionStatistic.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 根据部门code查询调解员
     */
    DayCollectionStatistic.prototype.orgChange = function (valueArray) {
        var _this = this;
        this.searchModel.userIds = ""; // 清空调解员选中
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.departCode = valueArray[valueArray.length - 1];
        this.userService.getSimpleUser(this.searchModel.departCode).subscribe(function (data) {
            _this.collectorFromList = data;
        });
    };
    DayCollectionStatistic.prototype.handleResetForm = function () {
        this.deptIds = [];
        this.searchModel.departCode = "";
    };
    /**
     * 起止到截止时间间隔
     */
    DayCollectionStatistic.prototype.timeLong = function () {
        var start = new Date(this.searchModel.startDate);
        var end = new Date(this.searchModel.endDate);
        var long = FilterService.dateLong(start, end);
        return long;
    };
    /**
     * 刷新列表
     */
    DayCollectionStatistic.prototype.refreshData = function () {
        /* 查询日期超过3个月不查询 */
        // if (this.timeLong() > 90) {
        //   this.$message("请选择三个月内数据进行查询，大于三个月数据请使用导出功能导出查看");
        //   return;
        // }
        if (this.activeName === "1") {
            this.getCallList();
        }
        else if (this.activeName === "2") {
            this.getCallTotalList();
        }
        else if (this.activeName === "3") {
            this.getExportList();
        }
        // this.loading.state = true;
        // this.dayCollectionStatisticService.dailyCollectionStatistics(this.searchModel, this.loading).subscribe((data) => {
        //   this.dataSet = data;
        // });
    };
    DayCollectionStatistic.prototype.getCallList = function () {
        var _this = this;
        this.loading.state = true;
        legalWanRequest.get("/tCallRecord/list", __assign({ pageNo: this.pageNo, pageIndex: this.pageIndex }, this.searchModel)).then(function (resp) {
            _this.loading.state = false;
            if (resp["code"] === 0) {
                _this.dataSet = resp.data.records;
                _this.total = resp.data.total;
            }
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DayCollectionStatistic.prototype.getCallTotalList = function () {
        var _this = this;
        this.loading.state = true;
        legalWanRequest.get("/tCallRecord/total", __assign({}, this.searchModel)).then(function (resp) {
            _this.loading.state = false;
            if (resp["code"] === 0) {
                _this.callTotalData = resp.data;
            }
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DayCollectionStatistic.prototype.getExportList = function () {
        var _this = this;
        this.loading.state = true;
        legalWanRequest.get("/tExport/list", __assign({ pageNo: this.pageNo, pageIndex: this.pageIndex }, this.searchModel)).then(function (resp) {
            _this.loading.state = false;
            if (resp["code"] === 0) {
                _this.exportList = resp.data.records;
                _this.total = resp.data.total;
            }
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DayCollectionStatistic.prototype.download = function (path) {
        window.open(path);
    };
    ;
    DayCollectionStatistic.prototype.handleTabClick = function (tab, event) {
        this.searchModel.startDate = this.getDate();
        this.searchModel.endDate = "";
        this.dataSet = [];
        this.callTotalData = [];
        this.refreshData();
    };
    DayCollectionStatistic.prototype.handleSizeChange = function (val) {
        this.pageIndex = val;
        this.refreshData();
    };
    DayCollectionStatistic.prototype.handleCurrentChange = function (val) {
        this.pageNo = val;
        this.refreshData();
    };
    DayCollectionStatistic.prototype.tryRadio = function (fileName) {
        window.open("https://www.deskpro.cn/cinccmedia/media/" + fileName);
    };
    /**
     * 导出数据
     */
    DayCollectionStatistic.prototype.exportDataClick = function () {
        var _this = this;
        this.loading.state = true;
        legalWanRequest.get("/tCallRecord/exportRadio", __assign({}, this.searchModel)).then(function (resp) {
            _this.loading.state = false;
            if (resp["code"] === 0) {
                _this.$message.success("正在导出中...请稍后至导出列表下载");
            }
            else {
                _this.$message.error(resp["msg"] ? resp["msg"] : "系统异常");
            }
            // let blob = resp;
            // // 创建一个链接元素
            // const link = document.createElement("a");
            // link.href = URL.createObjectURL(blob);
            // link.download = '调解记录含录音文件.zip';
            //
            // // 将链接元素添加到 DOM 并点击它
            // document.body.appendChild(link);
            // link.click();
            //
            // // 完成后移除链接元素
            // document.body.removeChild(link);
            //
            // // 清理 URL 对象
            // URL.revokeObjectURL(link.href);
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    __decorate([
        Dependencies(UserService)
    ], DayCollectionStatistic.prototype, "userService", void 0);
    __decorate([
        Dependencies(DayCollectionStatisticService)
    ], DayCollectionStatistic.prototype, "dayCollectionStatisticService", void 0);
    __decorate([
        Getter
    ], DayCollectionStatistic.prototype, "departmentData", void 0);
    DayCollectionStatistic = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
            },
        })
    ], DayCollectionStatistic);
    return DayCollectionStatistic;
}(Vue));
export default DayCollectionStatistic;
