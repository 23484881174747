var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Dependencies } from '~/core/decorator';
import { State, namespace } from 'vuex-class';
import { WarningService } from '~/services/business-service/warning.service';
var collectionManageModule = namespace('collection-manage');
var AddWarning = /** @class */ (function (_super) {
    __extends(AddWarning, _super);
    function AddWarning() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            caseId: '',
            content: ''
        };
        _this.loading = {
            state: false
        };
        _this.rules = {
            content: [
                { required: true, message: '请输入警告信息' },
            ],
        };
        return _this;
    }
    AddWarning.prototype.close = function () {
        var form = this.$refs['form'];
        form.resetFields();
    };
    /**
     * 添加批注提交
     */
    AddWarning.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.model.caseId = _this.rowData.id;
            _this.loading.state = true;
            _this.warningService.addWarning(_this.model, _this.loading).subscribe(function (data) {
                _this.$message.success('操作成功');
                _this.close();
            });
        });
    };
    AddWarning.prototype.reset = function () {
        var noteForm = this.$refs['form'];
        noteForm.resetFields();
    };
    __decorate([
        Dependencies(WarningService)
    ], AddWarning.prototype, "warningService", void 0);
    __decorate([
        State
    ], AddWarning.prototype, "userData", void 0);
    __decorate([
        collectionManageModule.State
    ], AddWarning.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], AddWarning.prototype, "currentComment", void 0);
    __decorate([
        Prop()
    ], AddWarning.prototype, "rowData", void 0);
    __decorate([
        Emit('close')
    ], AddWarning.prototype, "close", null);
    AddWarning = __decorate([
        Component({
            components: {},
        })
    ], AddWarning);
    return AddWarning;
}(Vue));
export default AddWarning;
