var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { Emit, Prop } from "vue-property-decorator";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
var collectionManageModule = namespace("collection-manage");
var PublicCases = /** @class */ (function (_super) {
    __extends(PublicCases, _super);
    function PublicCases() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.userData = [];
        _this.model = {
            caseId: _this.caseId,
            flowType: "PUBLIC_CASE_APPLY",
            applyRemark: "",
            caseType: ""
        };
        _this.rules = {
            applyRemark: [{ required: true, message: "请输入案件说明", trigger: "blur" }]
        };
        return _this;
    }
    PublicCases.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    PublicCases.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            var model = {
                caseId: _this.caseId,
                flowType: _this.model.flowType,
                applyRemark: _this.model.applyRemark,
                caseType: _this.otherData.caseType
            };
            _this.caseApplyManageService.caseApplyOperated(model, _this.loading).subscribe(function (data) {
                _this.$message.success("公共案件申请成功!");
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], PublicCases.prototype, "caseApplyManageService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PublicCases.prototype, "principalInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], PublicCases.prototype, "otherData", void 0);
    __decorate([
        Prop({})
    ], PublicCases.prototype, "caseId", void 0);
    __decorate([
        Emit("close")
    ], PublicCases.prototype, "close", null);
    PublicCases = __decorate([
        Component({
            components: {}
        })
    ], PublicCases);
    return PublicCases;
}(Vue));
export default PublicCases;
