import { requestType } from "~/config/enum.config";
var SERVICE = "dataimp-service";
var CONTROLLER = "visitFollowRecordController";
export default {
    /**
     * 数据导入
     */
    importCollectionData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importCollectionData",
        type: requestType.Post,
    },
    /**
     * 取消导入
     */
    cancelImpVisitRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "cancelImpVisitRecord",
        type: requestType.Delete,
    },
    /**
     * 确认导入
     */
    confirmImpDate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "confirmImpDate",
        type: requestType.Post,
    },
    /**
     * 查询导入结果
     */
    getImportVisitCollectionRecords: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getImportVisitCollectionRecords",
        type: requestType.Get,
    },
    /**
     * 外访调记导入详情
     */
    getCollectionList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCollectionList",
        type: requestType.Get,
    },
    /**
     * 调记删除导入
     */
    importDeleteCollection: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "importDeleteCollection",
        type: requestType.Get,
    },
};
