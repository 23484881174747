import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "sysParamController";
export default {
    /**
     * 修改系统参数
     */
    modifySysParam: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifySysParam",
        type: requestType.Put
    },
    /**
     * 多条件查询系统参数
     */
    findAllSysParam: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllSysParam",
        type: requestType.Get
    }
};
