var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import CaseApply from "~/components/collection-manage/all-case/case-apply.vue";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionData } from "~/config/types/collection-data";
import { CaseInfoService } from '~/services/business-service/case-info.service';
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var collectionManageModule = namespace("collection-manage");
//@Auth(113)
var PublicCase = /** @class */ (function (_super) {
    __extends(PublicCase, _super);
    function PublicCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.principalId = "";
        _this.loading = {
            state: false
        };
        _this.collectionStatus = [];
        _this.applyData = {};
        _this.selectionList = [];
        _this.dataSet = [];
        _this.searchModel = {
            principalId: '',
            departCode: "",
            batchNumber: '',
            caseNumber: '',
            city: '',
            delegationDate: [],
            endCaseDate: [],
            caseLeaveFlag: '',
            certificateNo: '',
            personalName: '',
            retireCaseDays: '',
            phone: "",
            stage: '',
            cardNo: "",
            address: "",
            employerName: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            caseType: "",
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
            // cardNo: [{ min: 6, message: '请输入至少6位的卡号', trigger: 'blur' }],
            account: [{ min: 6, message: '请输入至少6位的账号', trigger: 'blur' }]
        };
        _this.dialog = {
            detail: false,
            caseApply: false
        };
        return _this;
    }
    PublicCase.prototype.created = function () {
        this.getConfigByPrin();
    };
    PublicCase.prototype.mounted = function () {
        this.refreshData();
    };
    PublicCase.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.overdueAmtTotal = [];
        this.searchModel.leftAmt = [];
        this.searchModel.overduePeriods = [];
        this.searchModel.overdueDays = [];
    };
    PublicCase.prototype.getConfigByPrin = function () {
        var _this = this;
        if (!this.principalId)
            return;
        this.loading.state = true;
        this.custConfigService
            .getConfigByPrin(this.principalId, this.loading)
            .subscribe(function (data) {
            _this.collectionStatus = data.collectionStatus;
        });
    };
    PublicCase.prototype.refreshData = function () {
        var _this = this;
        // this.loading.state = true;
        this.caseInfoService.queryPublicCases(this.searchModel, this.pageService, this.sortService).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    PublicCase.prototype.onBatchNumberClick = function (currentCollection) {
        var otherData = {
            leftLogo: "common" // 从公共页面进入
        };
        var data = new CollectionData(currentCollection.principalId, currentCollection.caseId, this.searchModel, otherData);
        this.openExecutionPage(data);
    };
    __decorate([
        Dependencies(PageService)
    ], PublicCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PublicCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], PublicCase.prototype, "custConfigService", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], PublicCase.prototype, "caseInfoService", void 0);
    __decorate([
        State
    ], PublicCase.prototype, "principalList", void 0);
    __decorate([
        collectionManageModule.Action
    ], PublicCase.prototype, "openExecutionPage", void 0);
    PublicCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                CaseApply: CaseApply,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch
            }
        })
    ], PublicCase);
    return PublicCase;
}(Vue));
export default PublicCase;
