var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
var ComplianceCheckControllerService = /** @class */ (function () {
    function ComplianceCheckControllerService() {
    }
    /**
     * 新增配置
     */
    ComplianceCheckControllerService.prototype.createComplianceCheck = function (data) {
        return this.netService.send({
            server: businessService.complianceCheckController.createComplianceCheck,
            data: data
        });
    };
    /**
     * 配置查询
     */
    ComplianceCheckControllerService.prototype.getAllComplianceCheck = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.complianceCheckController.getAllComplianceCheck,
            data: data,
            page: page,
            sort: sort
        });
    };
    /**
     * 删除查询
     */
    ComplianceCheckControllerService.prototype.deleteComplianceCheck = function (id) {
        return this.netService.send({
            server: businessService.complianceCheckController.deleteComplianceCheck,
            append: [id]
        });
    };
    /**
     * 更改状态
     */
    ComplianceCheckControllerService.prototype.changeState = function (id) {
        return this.netService.send({
            server: businessService.complianceCheckController.changeState,
            append: [id]
        });
    };
    __decorate([
        Inject(NetService)
    ], ComplianceCheckControllerService.prototype, "netService", void 0);
    return ComplianceCheckControllerService;
}());
export { ComplianceCheckControllerService };
