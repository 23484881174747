var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Watch, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { messageState } from "~/config/enum.config";
import { WebSocketMsgService } from "~/services/common-service/websocket-msg.service";
var workspaceModule = namespace("workspace");
var MessageDetail = /** @class */ (function (_super) {
    __extends(MessageDetail, _super);
    function MessageDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MessageDetail.prototype.readChange = function () {
    };
    MessageDetail.prototype.onMessageChange = function (val, oldVal) {
        var _this = this;
        if (!val) {
            return;
        }
        if (val.readStatus === messageState.unRead.value.toUpperCase()) {
            var ids = [val.id];
            this.webSocketMsgService.updateMsgStatus(ids).subscribe(function () {
                val.readStatus = messageState.read.value.toUpperCase();
                _this.updateUnReadCount();
                // this.readChange();
            });
        }
    };
    __decorate([
        Dependencies(WebSocketMsgService)
    ], MessageDetail.prototype, "webSocketMsgService", void 0);
    __decorate([
        Prop({ required: true })
    ], MessageDetail.prototype, "message", void 0);
    __decorate([
        workspaceModule.Action
    ], MessageDetail.prototype, "updateUnReadCount", void 0);
    __decorate([
        Emit("readChange")
    ], MessageDetail.prototype, "readChange", null);
    __decorate([
        Watch("message")
    ], MessageDetail.prototype, "onMessageChange", null);
    MessageDetail = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem
            }
        })
    ], MessageDetail);
    return MessageDetail;
}(Vue));
export default MessageDetail;
