import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'caseInfoDistributeController';
export default {
    /**
     * 手动分案结果预览
     */
    caseDistributePreview: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'caseDistributePreview',
        type: requestType.Post
    },
    /**
     * 机构待分配手动分案预览
     */
    orgCasesDistributePreview: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'orgCasesDistributePreview',
        type: requestType.Post
    },
    /**
     * 待分配手动分案确认
     */
    waitCaseDistributeConfirm: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'waitCaseDistributeConfirm',
        type: requestType.Post
    },
    /**
     * 机构待分配手动分案确认
     */
    distributedCasesConfirm: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'distributedCasesConfirm',
        type: requestType.Post
    },
    /**
     * 获取待分配可分配的批次号数量以及金额
     */
    getWaitBatchNumberAndTotal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getWaitBatchNumberAndTotal',
        type: requestType.Get
    },
    /**
     * 获取机构待分配可分配的批次号以及金额
     */
    getBatchNumberAndTotal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getBatchNumberAndTotal',
        type: requestType.Get
    },
    /**
     * 获取结清案件的批次号
     */
    getSettleBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getSettleBatchNumber',
        type: requestType.Get
    }
};
