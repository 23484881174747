var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { CaseInfoService } from '~/services/business-service/case-info.service';
var UpdateBaseCase = /** @class */ (function (_super) {
    __extends(UpdateBaseCase, _super);
    function UpdateBaseCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.personalMessage = {};
        _this.loading = {
            state: false
        };
        _this.Rules = {
            fullName: [{ required: true, message: "请输入姓名" }],
        };
        return _this;
    }
    UpdateBaseCase.prototype.close = function () {
        this.reset();
    };
    UpdateBaseCase.prototype.success = function () {
        this.$message.success("信息修改成功!");
        this.close();
    };
    /**
      * 表单重置
      */
    UpdateBaseCase.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    UpdateBaseCase.prototype.getPersonalMessage = function () {
        var _this = this;
        this.caseInfoService.getPersonalById(this.caseRow.id, this.loading.state)
            .subscribe(function (data) {
            _this.personalMessage = data;
        });
    };
    UpdateBaseCase.prototype.cancel = function () {
        this.close();
    };
    UpdateBaseCase.prototype.submit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return false;
            _this.loading.state = true;
            var caseUpdateMessageRequest = {
                caseInfoMessage: _this.caseRow,
                personalMessage: _this.personalMessage
            };
            _this.caseInfoService.updateCaseMessage(caseUpdateMessageRequest, _this.loading.state)
                .subscribe(_this.success);
        });
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], UpdateBaseCase.prototype, "caseInfoService", void 0);
    __decorate([
        Prop()
    ], UpdateBaseCase.prototype, "caseRow", void 0);
    __decorate([
        Emit()
    ], UpdateBaseCase.prototype, "close", null);
    __decorate([
        Emit()
    ], UpdateBaseCase.prototype, "success", null);
    UpdateBaseCase = __decorate([
        Component({
            components: {}
        })
    ], UpdateBaseCase);
    return UpdateBaseCase;
}(Vue));
export default UpdateBaseCase;
