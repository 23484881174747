var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import WorkOrderExport from "~/components/case-manage/case-import/work-order-export.vue";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service";
var CaseInfoExport = /** @class */ (function (_super) {
    __extends(CaseInfoExport, _super);
    function CaseInfoExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rules = {};
        _this.searchModel = {
            followTime: [],
            batchNumber: "",
            principalId: "",
        };
        _this.exportData = {};
        _this.dialog = {
            caseExport: false,
        };
        return _this;
    }
    CaseInfoExport.prototype.created = function () {
        this.rules = {
            principalId: {
                required: true,
                message: "请选择委托方",
                trigger: "change",
            },
            followTime: {
                required: true,
                message: "请选择工单日期",
                trigger: "blur",
            },
        };
    };
    CaseInfoExport.prototype.orderExport = function () {
        if (!this.searchModel.principalId) {
            this.$message.warning("请选择委托方！");
        }
        else if (!this.searchModel.followTime || this.searchModel.followTime.length === 0) {
            this.$message.warning("请选择工单日期！");
        }
        else {
            this.exportData = this.searchModel;
            this.dialog.caseExport = true;
        }
    };
    CaseInfoExport.prototype.resetForm = function () {
        var dataForm = this.$refs["data-form"];
        dataForm.resetFields();
    };
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], CaseInfoExport.prototype, "exportAssistManagementService", void 0);
    __decorate([
        State
    ], CaseInfoExport.prototype, "principalList", void 0);
    CaseInfoExport = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                NumberRange: NumberRange,
                WorkOrderExport: WorkOrderExport,
            },
        })
    ], CaseInfoExport);
    return CaseInfoExport;
}(Vue));
export default CaseInfoExport;
