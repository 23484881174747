var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var ImportVisitFollowRecordCaseService = /** @class */ (function (_super) {
    __extends(ImportVisitFollowRecordCaseService, _super);
    function ImportVisitFollowRecordCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询导入结果
     */
    ImportVisitFollowRecordCaseService.prototype.getImportVisitCollectionRecords = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.getImportVisitCollectionRecords,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 取消导入
     */
    ImportVisitFollowRecordCaseService.prototype.cancelImpVisitRecord = function (id) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.cancelImpVisitRecord,
            append: [id],
        });
    };
    /**
     * 确认导入
     */
    ImportVisitFollowRecordCaseService.prototype.confirmImpDate = function (id) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.confirmImpDate,
            append: [id],
        });
    };
    /**
     * 数据导入
     */
    ImportVisitFollowRecordCaseService.prototype.importCollectionData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.importCollectionData,
            data: data,
            loading: loading,
        });
    };
    /**
     * 案件外访调记详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportVisitFollowRecordCaseService.prototype.getCollectionList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.getCollectionList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 数据导入
     */
    ImportVisitFollowRecordCaseService.prototype.importDeleteCollection = function (data) {
        return this.netService.send({
            server: dataimpService.visitFollowRecordController.importDeleteCollection,
            data: data,
        });
    };
    __decorate([
        Inject(NetService)
    ], ImportVisitFollowRecordCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ImportVisitFollowRecordCaseService.prototype, "cancelImpVisitRecord", null);
    __decorate([
        Debounce()
    ], ImportVisitFollowRecordCaseService.prototype, "confirmImpDate", null);
    __decorate([
        Debounce()
    ], ImportVisitFollowRecordCaseService.prototype, "importCollectionData", null);
    __decorate([
        Debounce()
    ], ImportVisitFollowRecordCaseService.prototype, "importDeleteCollection", null);
    return ImportVisitFollowRecordCaseService;
}(Service));
export { ImportVisitFollowRecordCaseService };
