var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var AssistCollectionCaseService = /** @class */ (function (_super) {
    __extends(AssistCollectionCaseService, _super);
    function AssistCollectionCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 协调管理模块中，协调管理查外访案件
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    AssistCollectionCaseService.prototype.searchAssistCase = function (data, page, sort, loading) {
        // 申请日期
        var applyDate = FilterService.dateRanageFormat(data.applyDate);
        return this.netService.send({
            server: domainService.assistCollectionCaseController.searchAssistCase,
            data: {
                principalName: data.principalName,
                personalName: data.personalName,
                idCard: data.idCard,
                applyRealName: data.applyRealName,
                batchNumber: data.batchNumber,
                currentCollector: data.currentCollector,
                applyDateStart: applyDate.start,
                applyDateEnd: applyDate.end,
                assistLocalFlag: data.assistLocalFlag,
                assistStatus: data.assistStatus,
                addressDetail: data.addressDetail,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 调收管理查询协调案件
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    AssistCollectionCaseService.prototype.searchAssistCollectionCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.assistCollectionCaseController.searchAssistCollectionCase,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 结束协调
     * @param data 实体
     */
    AssistCollectionCaseService.prototype.endAssistCaseCollection = function (data, loading) {
        return this.netService.send({
            server: domainService.assistCollectionCaseController.endAssistCaseCollection,
            append: [data.assistId],
            loading: loading,
        });
    };
    /**
     * 撤回协调
     */
    AssistCollectionCaseService.prototype.retractAssistCase = function (data, loading) {
        return this.netService.send({
            server: domainService.assistCollectionCaseController.retractAssistCase,
            data: {
                caseId: data.caseId,
                personalAddressId: data.personalAddressId,
                assistAddressFlag: data.assistAddressFlag,
                personalContactId: data.personalContactId,
                assistContactFlag: data.assistContactFlag,
            },
            loading: loading,
        });
    };
    /**
     * 查询当前用户协调案件
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     */
    AssistCollectionCaseService.prototype.searchOwnAssistCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.assistCollectionCaseController.searchOwnAssistCase,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], AssistCollectionCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], AssistCollectionCaseService.prototype, "endAssistCaseCollection", null);
    __decorate([
        Debounce()
    ], AssistCollectionCaseService.prototype, "retractAssistCase", null);
    return AssistCollectionCaseService;
}(Service));
export { AssistCollectionCaseService };
