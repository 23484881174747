var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var AreaService = /** @class */ (function () {
    function AreaService() {
    }
    AreaService.prototype.searchIdCardInformation = function (data) {
        return this.netService.send({
            server: repairService.areaController.searchAreaInformationByPid,
            data: data
        });
    };
    AreaService.prototype.searchAreaInformation = function (value) {
        return this.netService.send({
            server: repairService.areaController.searchAreaInformation,
            data: {
                pid: value
            }
        });
    };
    __decorate([
        Inject(NetService)
    ], AreaService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], AreaService.prototype, "searchIdCardInformation", null);
    __decorate([
        Debounce()
    ], AreaService.prototype, "searchAreaInformation", null);
    return AreaService;
}());
export { AreaService };
