var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataBox from '~/components/common/data-box.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Prop } from 'vue-property-decorator';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { CaseFollowRecordService } from '~/services/business-service/case-follow-record.service';
var collectionManageModule = namespace('collection-manage');
var FollowHistoryQuery = /** @class */ (function (_super) {
    __extends(FollowHistoryQuery, _super);
    function FollowHistoryQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.followQuery = {
            certificateNo: '',
        };
        _this.loading = { state: false };
        return _this;
    }
    FollowHistoryQuery.prototype.refreshData = function () {
        var _this = this;
        this.followQuery.certificateNo = this.certificateNo;
        this.loading.state = true;
        this.caseFollowRecordService.getAllCaseFollowRecord(this.followQuery, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        }, function (err) { return _this.$message.error(err.msg); });
    };
    __decorate([
        Dependencies(PageService)
    ], FollowHistoryQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FollowHistoryQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowHistoryQuery.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowHistoryQuery.prototype, "change", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FollowHistoryQuery.prototype, "certificateNo", void 0);
    FollowHistoryQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
            },
        })
    ], FollowHistoryQuery);
    return FollowHistoryQuery;
}(Vue));
export default FollowHistoryQuery;
