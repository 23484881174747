var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import AddContact from "~/components/system-manage/contact-way-config/add-contact.vue";
import ModifyContact from "~/components/system-manage/contact-way-config/modify-contact.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
import { EditType } from "~/config/enum.framework";
import DataTree from "~/components/common/data-tree.vue";
var systemManageModule = namespace("system-manage");
// @Auth(852)
var ContactWayConfig = /** @class */ (function (_super) {
    __extends(ContactWayConfig, _super);
    function ContactWayConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.contactDataSet = [];
        _this.contactModel = {
            pid: "",
        };
        _this.dialog = {
            modify: false,
            add: false,
        };
        _this.nodeValue = "";
        _this.editDataParentName = "";
        _this.editdata = {};
        _this.deptId = "";
        _this.dept = "";
        _this.pid = "";
        _this.editConfig = [];
        _this.level = "";
        return _this;
    }
    ContactWayConfig.prototype.updataOrganizationTree = function () {
        this.updateContactNameInfo();
        this.refreshData();
    };
    ContactWayConfig.prototype.onChange = function (value) {
        var _this = this;
        this.nodeValue = value.id;
        this.editDataParentName = value.name;
        if (value.level === 0) {
            this.editConfig = [EditType.ADD];
        }
        else if (value.level === 1) {
            this.editConfig = [EditType.MODIFY, EditType.ADD, EditType.DELETE, EditType.MOVE_UP, EditType.MOVE_DOWN];
        }
        else if (value.level === 2) {
            this.editConfig = [EditType.ADD, EditType.DELETE, EditType.MODIFY, EditType.MOVE_UP, EditType.MOVE_DOWN];
        }
        else {
            this.editConfig = [];
        }
        this.contactConfigService.getChildren(value.id, this.loading).subscribe(function (data) {
            _this.contactDataSet = data;
        });
    };
    ContactWayConfig.prototype.onEdit = function (value) {
        this.level = 3;
        this.dialog.modify = true;
        this.editdata = value;
    };
    ContactWayConfig.prototype.onTreeEdit = function (val) {
        this.onEdit(val);
    };
    ContactWayConfig.prototype.moveUp = function (node) {
        var nodeBrotherList = [];
        if (node.level == 2) {
            nodeBrotherList = this.contactTreeData[0].children.filter(function (x) { return x.id === node.parent; })[0].children;
        }
        else {
            nodeBrotherList = this.contactTreeData[0].children;
        }
        var min = Math.min.apply(null, nodeBrotherList.map(function (x) { return x.sort; }));
        if (node.sort == min) {
            this.$message.warning("当前节点不能再向上移动!");
            return;
        }
        var index = nodeBrotherList.findIndex(function (x) { return x.id === node.id; });
        var moveRequest = {
            id: node.id,
            contiguousId: nodeBrotherList[index - 1].id,
        };
        this.move(moveRequest);
    };
    ContactWayConfig.prototype.moveDown = function (node) {
        var nodeBrotherList = [];
        if (node.level == 2) {
            nodeBrotherList = this.contactTreeData[0].children.filter(function (x) { return x.id === node.parent; })[0].children;
        }
        else {
            nodeBrotherList = this.contactTreeData[0].children;
        }
        var min = Math.max.apply(null, nodeBrotherList.map(function (x) { return x.sort; }));
        if (node.sort == min) {
            this.$message.warning("当前节点不能再向下移动!");
            return;
        }
        var index = nodeBrotherList.findIndex(function (x) { return x.id === node.id; });
        var moveRequest = {
            id: node.id,
            contiguousId: nodeBrotherList[index + 1].id,
        };
        this.move(moveRequest);
    };
    ContactWayConfig.prototype.move = function (moveRequest) {
        var _this = this;
        this.contactConfigService.moveContactConfig(moveRequest).subscribe(function (data) {
            _this.updateContactNameInfo();
            _this.$message.success("移动成功!");
        });
    };
    ContactWayConfig.prototype.onAdd = function (value) {
        this.level = value.level;
        this.editdata = value;
        this.dialog.add = true;
    };
    ContactWayConfig.prototype.onRemove = function (value) {
        var _this = this;
        this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.contactConfigService.deleteContactConfig(value.id, _this.loading).subscribe(function (data) {
                _this.updateContactNameInfo();
                _this.refreshData();
                _this.$message.success("删除成功!");
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message.error("已取消删除");
        });
    };
    ContactWayConfig.prototype.refresh = function (formName) {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs[formName];
            form.refresh();
        });
    };
    /**
     * 修改联络配置打开窗口
     */
    ContactWayConfig.prototype.modifyContact = function () {
        var _this = this;
        this.$nextTick(function () {
            var edit = _this.$refs["edit-contact"];
            edit.refresh(_this.editdata);
        });
        document.documentElement.style.overflow = "hidden";
    };
    /**
     * 新增联络配置打开窗口
     */
    ContactWayConfig.prototype.addContact = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["add-contact"];
            form.refresh(_this.dept);
        });
    };
    ContactWayConfig.prototype.refreshData = function () {
        var _this = this;
        this.contactConfigService.getChildren(this.nodeValue, this.loading).subscribe(function (data) {
            _this.contactDataSet = data;
        });
    };
    ContactWayConfig.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    ContactWayConfig.prototype.mounted = function () {
        this.updateContactNameInfo();
    };
    __decorate([
        Dependencies(ContactConfigService)
    ], ContactWayConfig.prototype, "contactConfigService", void 0);
    __decorate([
        systemManageModule.Getter
    ], ContactWayConfig.prototype, "contactTreeData", void 0);
    __decorate([
        systemManageModule.Action
    ], ContactWayConfig.prototype, "updateContactNameInfo", void 0);
    ContactWayConfig = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddContact: AddContact,
                ModifyContact: ModifyContact,
                DataTree: DataTree,
            },
        })
    ], ContactWayConfig);
    return ContactWayConfig;
}(Vue));
export default ContactWayConfig;
