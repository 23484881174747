var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { VillageCommitteeDataService } from "~/services/repair-service/village-committee-data.service";
import clone from "clone";
var EditCountryData = /** @class */ (function (_super) {
    __extends(EditCountryData, _super);
    function EditCountryData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.kosekiModel = {
            address: "",
            linkman: "",
            queryMan: "",
            position: "",
            officePhone: "",
            mobile: "",
            queryDate: "",
            homePhone: "",
            areaCode: "",
            remark: "",
            fileIdList: []
        };
        _this.loading = {
            state: false
        };
        _this.rulesEdit = {
            address: [{ required: true, message: "请输入村委地址", trigger: "blur" }],
            linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }]
        };
        return _this;
    }
    EditCountryData.prototype.success = function () {
        this.close();
    };
    EditCountryData.prototype.close = function () {
        this.reset();
    };
    // 保存户籍信息
    EditCountryData.prototype.EditDetail = function () {
        var _this = this;
        var editform = this.$refs["edit-form"];
        editform.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            if (_this.fileList && _this.fileList.length)
                _this.kosekiModel.fileIdList = _this.fileList.map(function (v) { return v.response.id; });
            _this.villageCommitteeDataService
                .update(_this.kosekiModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("修改成功!");
                _this.success();
            }, function (_a) {
                var msg = _a.msg;
                _this.$message.error(msg);
            });
        });
    };
    /**
     * keep-alive生命周期钩子函数
     */
    EditCountryData.prototype.activated = function () {
        // 加载数据
    };
    EditCountryData.prototype.refresh = function (obj) {
        this.kosekiModel = clone(obj);
    };
    EditCountryData.prototype.onUploadSuccess = function (id, data) {
        this.kosekiModel.fileIdList = data.id;
    };
    EditCountryData.prototype.reset = function () {
        var add = this.$refs["edit-form"];
        add.resetFields();
        //let fileForm: any = this.$refs["file-upload"];
        //fileForm.reset();
    };
    __decorate([
        Dependencies(VillageCommitteeDataService)
    ], EditCountryData.prototype, "villageCommitteeDataService", void 0);
    __decorate([
        Emit("success")
    ], EditCountryData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], EditCountryData.prototype, "close", null);
    EditCountryData = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], EditCountryData);
    return EditCountryData;
}(Vue));
export default EditCountryData;
