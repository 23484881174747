var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Getter } from "vuex-class";
import { DepartmentService } from "~/services/manage-service/department.service";
import { Prop } from "vue-property-decorator";
var ModifyOrganize = /** @class */ (function (_super) {
    __extends(ModifyOrganize, _super);
    function ModifyOrganize() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.submitLoading = false;
        // 修改机构参数
        _this.editOrgModel = {
            departId: "",
            name: "",
            parentId: "",
            status: "",
            type: "",
            collectionType: "",
            address: "",
            contact: "",
            contactNo: "",
        };
        _this.isOrigin = false;
        /**
         * 添加机构信息校验规则
         */
        _this.editOrganizeRules = {
            name: { required: true, message: "请输入机构名称" },
            type: { required: true, message: "请选择组织机构" },
            collectionType: { required: true, message: "请选择调解类型" },
            status: { required: true, message: "请选择机构状态" }
        };
        _this.disabled = false;
        return _this;
    }
    ModifyOrganize.prototype.originalType = function (value) {
        if (value === 'BRANCH') {
            this.isOrigin = true;
        }
        else {
            this.isOrigin = false;
        }
    };
    /**
     * 获取要修改的当前行数据
     */
    ModifyOrganize.prototype.refresh = function () {
        var _this = this;
        Object.keys(this.editOrgModel).forEach(function (x) {
            if (_this.editData[x] !== undefined) {
                _this.editOrgModel[x] = _this.editData[x];
            }
        });
        this.disabled = this.editData.level > 8;
        this.editOrgModel.parentId = this.editData.parent;
        this.editOrgModel.departId = this.editData.id;
        this.editOrgModel.parentName = this.editData.parentName;
        this.editOrgModel.collectionType = this.editData.collectionType;
        this.editOrgModel.status = this.editData.status;
        this.editOrgModel.name = this.editData.name;
        this.editOrgModel.address = this.editData.address;
        this.editOrgModel.contact = this.editData.contact;
        this.editOrgModel.contactNo = this.editData.contactNo;
    };
    /**
     * 修改机构确认
     */
    ModifyOrganize.prototype.editOrganizeCommit = function () {
        var _this = this;
        var form = this.$refs["editOrg"];
        form.validate(function (valid) {
            if (valid) {
                _this.submitLoading = true;
                _this.departmentService.modifyDepartment(_this.editOrgModel).subscribe(function (data) {
                    _this.$message.success("修改机构成功!");
                    // 刷新机构树
                    _this.$emit("refresh");
                    _this.submitLoading = false;
                    //   关闭dialog
                    _this.close();
                }, function (err) {
                    _this.submitLoading = false;
                    _this.close();
                });
            }
        });
    };
    /**
     * 关闭窗口
     */
    ModifyOrganize.prototype.close = function () {
        var form = this.$refs["editOrg"];
        form.resetFields();
        this.isOrigin = false;
        this.$emit("close");
    };
    Object.defineProperty(ModifyOrganize.prototype, "deptIds", {
        get: function () {
            return this.getAllParentId(this.editOrgModel.parentId);
        },
        set: function (val) {
            this.editOrgModel.parentId = val.length ? val[val.length - 1] : "";
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Dependencies(DepartmentService)
    ], ModifyOrganize.prototype, "departmentService", void 0);
    __decorate([
        Getter
    ], ModifyOrganize.prototype, "departmentData", void 0);
    __decorate([
        Getter
    ], ModifyOrganize.prototype, "getAllParentId", void 0);
    __decorate([
        Prop()
    ], ModifyOrganize.prototype, "editData", void 0);
    ModifyOrganize = __decorate([
        Component({
            components: {}
        })
    ], ModifyOrganize);
    return ModifyOrganize;
}(Vue));
export default ModifyOrganize;
