import { requestType } from '~/config/enum.config';
var SERVICE = 'datasync-service';
var CONTROLLER = 'accRepaymentRemindController';
export default {
    /**
     * 案件导入手工同步案件
     */
    getErpRepaymentRemind: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getErpRepaymentRemind',
        type: requestType.Get
    }
};
