import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "caseCollectorController";
export default {
    /**
     * 查询登录人相关的委托方
     */
    findPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findPrincipal",
        type: requestType.Get,
    },
    /**
     * 在调案件查询
     */
    findAllInCollectCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllInCollectCase",
        type: requestType.Get,
    },
    /**
     * 查询委托方案件
     */
    findAllCaseByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllCaseByPrincipal",
        type: requestType.Get,
    },
    /**
     * 退案案件查询
     */
    findAllRetireCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllRetireCase",
        type: requestType.Get,
    },
    /**
     * 查询委托方下全部批次案件
     */
    findAllBatchCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllBatchCase",
        type: requestType.Get,
    },
    /**
     * 查询委托方下全部案件
     */
    findAllCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findAllCase",
        type: requestType.Get,
    },
    /**
     * 根据案件ID获取案件
     */
    getCaseInfoById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseInfoById",
        type: requestType.Get,
    },
    /**
     * 获取客户账号卡号
     */
    getCardInfoByIdCard: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCardInfoByIdCard",
        type: requestType.Get,
    },
    /**
     * 查询案件记事本内容
     */
    getComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getComment",
        type: requestType.Get,
    },
    /**
     * 修改记事本内容
     */
    modifyComment: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "modifyComment",
        type: requestType.Put,
    },
    /**
     * 查询案件状态
     */
    getCaseStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCaseStatus",
        type: requestType.Get,
    },
    /**
     * 更新案件状态
     */
    updateStatus: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateStatus",
        type: requestType.Put,
    },
    /**
     * 查询委托方下批次号
     */
    getBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getBatchNumber",
        type: requestType.Get,
    },
};
