var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
import { CaseDistributeService } from "~/services/dataimp-service/case-distribute.service";
import { StrategyConfigService } from "~/services/management-service/strategy-config.service";
var OrgCaseStrategyAllot = /** @class */ (function (_super) {
    __extends(OrgCaseStrategyAllot, _super);
    function OrgCaseStrategyAllot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.batchNumberList = [];
        _this.strategyList = [];
        _this.cityList = [];
        _this.departList = [];
        _this.loading = {
            state: false
        };
        // 地区待分配
        _this.caseIssuedFlag = "AREA_UN_DIS";
        _this.model = {
            batchNumber: "",
            principalId: "",
            strategyIds: []
        };
        _this.rules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            batchNumber: [{ required: true, message: "请选择批次号", trigger: "blur" }]
        };
        return _this;
    }
    OrgCaseStrategyAllot.prototype.close = function () {
        var form = this.$refs["form"];
        if (form) {
            form.resetFields();
        }
    };
    OrgCaseStrategyAllot.prototype.created = function () { };
    OrgCaseStrategyAllot.prototype.mounted = function () {
    };
    OrgCaseStrategyAllot.prototype.save = function () {
        var _this = this;
        var form = this.$refs["form"];
        if (form) {
            form.validate(function (valid) {
                if (!valid)
                    return;
                _this.caseDistributeService
                    .strategyDistributeCase(_this.model, _this.loading)
                    .subscribe(function (data) {
                    _this.$message.success("策略分案成功");
                    _this.reset();
                    _this.close();
                });
            });
        }
    };
    OrgCaseStrategyAllot.prototype.cancel = function () {
        this.close();
    };
    OrgCaseStrategyAllot.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    OrgCaseStrategyAllot.prototype.principalChange = function (value) {
        var _this = this;
        this.model.principalId = value;
        this.strategyConfigService.searchByPrincipal(this.model).subscribe(function (data) {
            (_this.strategyList = data), (_this.loading = false);
        }, function (err) {
            _this.loading = false;
        });
        var searchModel = {
            request: this.caseIssuedFlag,
            principalId: value
        };
        // 地区待分配批次号
        this.distributeCaseService
            .getBatchNumberByCaseIssuedFlag(searchModel, value)
            .subscribe(function (data) {
            (_this.batchNumberList = data), (_this.loading = false);
        }, function (err) {
            _this.loading = false;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], OrgCaseStrategyAllot.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseDistributeService)
    ], OrgCaseStrategyAllot.prototype, "caseDistributeService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], OrgCaseStrategyAllot.prototype, "distributeCaseService", void 0);
    __decorate([
        Dependencies(StrategyConfigService)
    ], OrgCaseStrategyAllot.prototype, "strategyConfigService", void 0);
    __decorate([
        State
    ], OrgCaseStrategyAllot.prototype, "userData", void 0);
    __decorate([
        State
    ], OrgCaseStrategyAllot.prototype, "principalList", void 0);
    __decorate([
        Emit("close")
    ], OrgCaseStrategyAllot.prototype, "close", null);
    OrgCaseStrategyAllot = __decorate([
        Component({
            components: {}
        })
    ], OrgCaseStrategyAllot);
    return OrgCaseStrategyAllot;
}(Vue));
export default OrgCaseStrategyAllot;
