import { requestType } from "~/config/enum.config";
var SERVICE = "manage-service";
var CONTROLLER = "sensitiveWordController";
export default {
    /**
     * 查询全部敏感词
     */
    findAllSensitiveWord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findAllSensitiveWord',
        type: requestType.Get
    },
    /**
     * 新增敏感词
     */
    addSensitiveWord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addSensitiveWord',
        type: requestType.Post
    },
    /**
     * 修改敏感词
     */
    modifySensitiveWord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifySensitiveWord',
        type: requestType.Put
    },
    /**
     * 删除敏感词
     */
    deleteSensitiveWord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteSensitiveWord',
        type: requestType.Delete
    },
    /**
     * 查询未配置敏感词的委托方
     */
    getPrincipalNoWordConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getPrincipalNoWordConfig',
        type: requestType.Get
    }
};
