var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { TemplateService } from "~/services/manage-service/template.service";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { CaseInfoService } from '~/services/business-service/case-info.service';
var LetterSinglePrint = /** @class */ (function (_super) {
    __extends(LetterSinglePrint, _super);
    function LetterSinglePrint() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.printData = null;
        _this.templateDataSet = [];
        _this.currentLetterContent = "";
        _this.currentTemplate = '';
        _this.loading = {
            state: false
        };
        return _this;
    }
    LetterSinglePrint.prototype.refreshData = function () { };
    LetterSinglePrint.prototype.onClose = function () {
        this.currentLetterContent = '';
        this.currentTemplate = '';
    };
    LetterSinglePrint.prototype.getPrintMessage = function () {
        var _this = this;
        var caseIds = new Array();
        caseIds.push(this.caseId);
        this.caseInfoService.getPrintMessage({ "caseIds": caseIds }, this.loading).subscribe(function (data) {
            _this.printData = data;
        });
    };
    LetterSinglePrint.prototype.mounted = function () {
        var _this = this;
        this.templateService.getTemplateByType('LETTER').subscribe(function (data) {
            _this.templateDataSet = data;
        });
    };
    LetterSinglePrint.prototype.templateChange = function (value) {
        var _this = this;
        this.currentLetterContent = "";
        var item = this.templateDataSet.find(function (x) { return x.id === value; });
        if (!item)
            return;
        var content = item.content;
        this.currentLetterContent = content;
        if (!this.printData)
            return;
        Object.entries(this.printData[0]).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (content.indexOf(key) < 0)
                return;
            if (key === 'promiseTime') {
                content = content.replace("$" + key, _this.format(value) || "_______________");
            }
            else {
                content = content.replace("$" + key, value || "_______________");
            }
        });
        this.currentLetterContent = content;
    };
    LetterSinglePrint.prototype.format = function (shijianchuo) {
        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(shijianchuo);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        return y + '-' + this.add0(m) + '-' + this.add0(d);
    };
    LetterSinglePrint.prototype.add0 = function (m) { return m < 10 ? '0' + m : m; };
    LetterSinglePrint.prototype.print = function () {
        if (!this.currentLetterContent) {
            this.$message('没有要打印的内容');
            return;
        }
        var newWindow = window.open("");
        newWindow.document.write(this.currentLetterContent);
        if (this.panelName === 'wait-print') {
            newWindow.addEventListener("afterprint", this.afterPrint);
        }
        newWindow.print();
        newWindow.close();
        this.onClose();
    };
    LetterSinglePrint.prototype.afterPrint = function () {
        var _this = this;
        var processApplicationIds = [];
        processApplicationIds.push(this.processApplicationId);
        this.caseApplyManageService.changePrintStatus(processApplicationIds).subscribe(function () {
            _this.refreshData();
        });
    };
    __decorate([
        Dependencies(TemplateService)
    ], LetterSinglePrint.prototype, "templateService", void 0);
    __decorate([
        Prop()
    ], LetterSinglePrint.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], LetterSinglePrint.prototype, "processApplicationId", void 0);
    __decorate([
        Prop()
    ], LetterSinglePrint.prototype, "panelName", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], LetterSinglePrint.prototype, "caseApplyManageService", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], LetterSinglePrint.prototype, "caseInfoService", void 0);
    __decorate([
        Emit('refreshData')
    ], LetterSinglePrint.prototype, "refreshData", null);
    __decorate([
        Emit('close')
    ], LetterSinglePrint.prototype, "onClose", null);
    LetterSinglePrint = __decorate([
        Component({
            components: {}
        })
    ], LetterSinglePrint);
    return LetterSinglePrint;
}(Vue));
export default LetterSinglePrint;
