var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from '~/utils/filter.service';
var PublicCollectionCaseService = /** @class */ (function (_super) {
    __extends(PublicCollectionCaseService, _super);
    function PublicCollectionCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 查询公共案件
     */
    PublicCollectionCaseService.prototype.queryPublicCase = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.publicCollectionCaseController.queryPublicCase,
            data: {
                flag: data.flag,
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                cardNo: data.cardNo,
                city: data.city,
                deptCode: data.departCode,
                collectionStatus: data.collectionStatus,
                caseStatus: data.caseStatus,
                caseLeaveFlag: data.caseLeaveFlag,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                handsNumber: data.handsNumber,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                account: data.account
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
  * 查看欠款总额
  */
    PublicCollectionCaseService.prototype.queryPublicCaseAmt = function (data, loading) {
        return this.netService.send({
            server: domainService.publicCollectionCaseController.queryPublicCaseAmt,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], PublicCollectionCaseService.prototype, "netService", void 0);
    return PublicCollectionCaseService;
}(Service));
export { PublicCollectionCaseService };
