var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Watch } from "vue-property-decorator";
import SvgIcon from "~/components/common/svg-icon.vue";
import { namespace } from "vuex-class";
import CommonTitle from "~/components/common/common-title.vue";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
var collectionManageModule = namespace("collection-manage");
var FollowRecordNote = /** @class */ (function (_super) {
    __extends(FollowRecordNote, _super);
    function FollowRecordNote() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.commentContent = "";
        _this.showInput = false;
        _this.loading = {
            state: false
        };
        return _this;
    }
    Object.defineProperty(FollowRecordNote.prototype, "operateText", {
        get: function () {
            return !this.showInput ? "展开" : "收起";
        },
        enumerable: false,
        configurable: true
    });
    FollowRecordNote.prototype.onCaseIdChange = function (val) {
        val && this.getSearchComment();
    };
    FollowRecordNote.prototype.updatebook = function () {
        var _this = this;
        var params = {
            caseId: this.caseId,
            comment: this.commentContent,
            caseType: this.otherData.caseType
        };
        this.caseCollectorService.modifyComment(params, this.loading).subscribe(function (data) {
            _this.$message.success('操作成功');
        });
    };
    FollowRecordNote.prototype.getSearchComment = function () {
        var _this = this;
        this.caseCollectorService.getComment(this.caseId, this.loading).subscribe(function (data) {
            _this.commentContent = data || "";
            _this.showInput = !!data;
        });
    };
    __decorate([
        collectionManageModule.State
    ], FollowRecordNote.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordNote.prototype, "otherData", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], FollowRecordNote.prototype, "caseCollectorService", void 0);
    __decorate([
        Watch('caseId', { immediate: true })
    ], FollowRecordNote.prototype, "onCaseIdChange", null);
    FollowRecordNote = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                CommonTitle: CommonTitle
            }
        })
    ], FollowRecordNote);
    return FollowRecordNote;
}(Vue));
export default FollowRecordNote;
