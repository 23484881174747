import Vue from 'vue';
import Vuex from 'vuex';
import state from '~/store/state';
import mutations from '~/store/mutations';
import actions from '~/store/actions';
import getters from '~/store/getters';
import createPersistedState from 'vuex-persistedstate';
// 引入模块
import workspace from '~/store/modules/workspace.store';
import message from '~/store/modules/message.store';
import inrushCase from '~/store/modules/inrush-case.store';
import taticManage from '~/store/modules/tatic-manage.store';
import caseManage from '~/store/modules/case-manage.store';
import systemManage from '~/store/modules/system-manage.store';
import letterAcc from '~/store/modules/letter-acc.store';
import collectionManage from "~/store/modules/collection-manage.store";
import outboundManage from "~/store/modules/outbound-manage.store";
Vue.use(Vuex);
var filterList = ['ready', "layout"];
var store = new Vuex.Store({
    // 子模块
    modules: {
        "workspace": workspace,
        "message": message,
        "inrush-case": inrushCase,
        "tatic-manage": taticManage,
        "case-manage": caseManage,
        "letter-acc": letterAcc,
        "system-manage": systemManage,
        "collection-manage": collectionManage,
        "outbound-manage": outboundManage
    },
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    plugins: [
        // 持久化存储插件
        createPersistedState({
            key: "vuex",
            reducer: function (state, paths) {
                return Object.assign({}, state, { ready: false, layout: "default" });
            },
            storage: localStorage,
            filter: function (_a) {
                var type = _a.type, payload = _a.payload;
                return !filterList.includes(type);
            }
        })
    ]
});
export default store;
