import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'familyPlanningData';
export default {
    /**
     * 查询计生资料
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    /**
     * 增加计生资料
     */
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    /**
     *删除计生资料
     */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Get
    },
    /**
     * 修改计生资料
     */
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    },
    /**
     * 添加计生凭证
     */
    addCredential: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addCredential',
        type: requestType.Post
    }
};
