var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import FileInfoModify from "~/components/collection-manage/collection-application/file-info-modify.vue";
import FileInfoDetails from "~/components/collection-manage/collection-application/file-info-details.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { ArchiveDataService } from "~/services/repair-service/archive-data.service";
import DataForm from "~/components/common/data-form.vue";
import { namespace } from "vuex-class";
import { Watch, Prop } from "vue-property-decorator";
import AppInfo from "~/components/collection-manage/collection-application/app-info.vue";
import CensusDetail from "~/components/data-repair/census-data/census-detail.vue";
import CommunicateDetail from "~/components/data-repair/communicate-data/communicate-detail.vue";
import CensusRemarkDetail from "~/components/data-repair/census-remark/census-remark-detail.vue";
import SpecialDetail from "~/components/data-repair/special-data/special-detail.vue";
import RelationDetail from "~/components/data-repair/relevance-relation/relation-detail.vue";
import SocialSecurityDetail from "~/components/data-repair/social-security-data/social-security-detail.vue";
var collectionManageModule = namespace("collection-manage");
var FileInfoQuery = /** @class */ (function (_super) {
    __extends(FileInfoQuery, _super);
    function FileInfoQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rowData = {};
        _this.loading = { state: false };
        _this.dataSet = [];
        _this.dialog = {
            fileInfoModify: false,
            fileInfoDetails: false,
            communicate: false,
            census: false,
            special: false,
            remark: false,
            relation: false,
            social: false
        };
        return _this;
    }
    FileInfoQuery.prototype.onCertificateNoChange = function (val) {
        val && this.refreshData();
    };
    /**
   * 查看通讯资料详情
   */
    FileInfoQuery.prototype.viewDetails = function (row) {
        this.rowData = row;
        switch (this.rowData.channel) {
            case "通讯资料":
                this.dialog.communicate = true;
                break;
            case "户籍资料":
                this.dialog.census = true;
                break;
            case "备注":
                this.dialog.remark = true;
                break;
            case "社保资料":
                this.dialog.social = true;
                break;
            case "关联关系":
                this.dialog.relation = true;
                break;
            default:
                this.dialog.special = true;
                break;
        }
    };
    FileInfoQuery.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.archiveDataService
            .detailList(this.idCardNo, this.pageService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], FileInfoQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FileInfoQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(ArchiveDataService)
    ], FileInfoQuery.prototype, "archiveDataService", void 0);
    __decorate([
        Prop()
    ], FileInfoQuery.prototype, "isHistory", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FileInfoQuery.prototype, "caseId", void 0);
    __decorate([
        Prop()
    ], FileInfoQuery.prototype, "idCardNo", void 0);
    __decorate([
        Watch("idCardNo", { immediate: true })
    ], FileInfoQuery.prototype, "onCertificateNoChange", null);
    FileInfoQuery = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FileInfoModify: FileInfoModify,
                FileInfoDetails: FileInfoDetails,
                AppInfo: AppInfo,
                CensusDetail: CensusDetail,
                CommunicateDetail: CommunicateDetail,
                CensusRemarkDetail: CensusRemarkDetail,
                SpecialDetail: SpecialDetail,
                RelationDetail: RelationDetail,
                SocialSecurityDetail: SocialSecurityDetail
            }
        })
    ], FileInfoQuery);
    return FileInfoQuery;
}(Vue));
export default FileInfoQuery;
