import caseStrategyController from '~/config/server/dataimp-service/case-strategy-controller';
import dataInfoExcelController from '~/config/server/dataimp-service/data-info-excel-controller';
import accImportExcelDataController from '~/config/server/dataimp-service/acc-import-excel-data-controller';
import scoreStrategyController from '~/config/server/dataimp-service/score-strategy-controller';
import dataExcelCaseController from '~/config/server/dataimp-service/data-excelcase.controller';
// 导入服务
import importExcelCaseController from '~/config/server/dataimp-service/import-excel-case.controller';
import importDataExcelRecordController from '~/config/server/dataimp-service/import-data-excel-record.controller';
import importOthersDataExcelRecordController from '~/config/server/dataimp-service/import-others-data-excel-record.controller';
import caseSearchRecordController from '~/config/server/dataimp-service/case-search-record.controller';
import collectionCaseStrategyConfigController from '~/config/server/dataimp-service/collection-case-strategy-config.controller';
import caseDistributeController from '~/config/server/dataimp-service/case-distribute.controller';
import exportAssistManagementController from '~/config/server/dataimp-service/export-assist-management.controller';
import approvalExportManageController from '~/config/server/dataimp-service/approval-export-manage.controller';
import homeManagerController from '~/config/server/dataimp-service/home-manager.controller';
import homeCollectorController from '~/config/server/dataimp-service/home-collector.controller';
import homeNoticeController from '~/config/server/dataimp-service/home-notice.controller';
import importAllocationCasesController from "./import-allocation-case.controller";
import caseChangeController from '~/config/server/dataimp-service/case-change-controller';
import visitFollowRecordController from "./import-visit-follow-record-controller";
import importTemplateController from "~/config/server/dataimp-service/import-template.controller";
import dataImportController from "~/config/server/dataimp-service//data-import.controller";
import exportConfigController from "~/config/server/dataimp-service/export-config.controller";
import strategyDistributeController from "~/config/server/dataimp-service/strategy-distribute.controller";
import userExportController from "~/config/server/dataimp-service/user-export.controller";
import exportTemplateController from "~/config/server/dataimp-service/export-template.controller";
import dataExportController from "~/config/server/dataimp-service/data-export.controller";
export var dataimpService = {
    caseStrategyController: caseStrategyController,
    dataInfoExcelController: dataInfoExcelController,
    accImportExcelDataController: accImportExcelDataController,
    scoreStrategyController: scoreStrategyController,
    dataExcelCaseController: dataExcelCaseController,
    importExcelCaseController: importExcelCaseController,
    importDataExcelRecordController: importDataExcelRecordController,
    importOthersDataExcelRecordController: importOthersDataExcelRecordController,
    caseSearchRecordController: caseSearchRecordController,
    collectionCaseStrategyConfigController: collectionCaseStrategyConfigController,
    caseDistributeController: caseDistributeController,
    exportAssistManagementController: exportAssistManagementController,
    homeManagerController: homeManagerController,
    homeCollectorController: homeCollectorController,
    homeNoticeController: homeNoticeController,
    importAllocationCasesController: importAllocationCasesController,
    caseChangeController: caseChangeController,
    visitFollowRecordController: visitFollowRecordController,
    importTemplateController: importTemplateController,
    dataImportController: dataImportController,
    exportConfigController: exportConfigController,
    strategyDistributeController: strategyDistributeController,
    userExportController: userExportController,
    exportTemplateController: exportTemplateController,
    approvalExportManageController: approvalExportManageController,
    dataExportController: dataExportController
};
