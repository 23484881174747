var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Getter, namespace } from "vuex-class";
import { Emit } from "vue-property-decorator";
import { UserService } from "~/services/manage-service/user.service";
var systemManageModule = namespace("system-manage");
var CheckUser = /** @class */ (function (_super) {
    __extends(CheckUser, _super);
    function CheckUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        //人员Data
        _this.userDataList = [];
        _this.userModel = {
            userName: "",
            realName: "",
            departCode: ""
        };
        _this.selectionRows = [];
        return _this;
    }
    CheckUser.prototype.closeDialog = function () {
        this.$refs['user-data-box'].clearSelection();
        this.resetForm();
        this.userDataList = [];
    };
    CheckUser.prototype.onSelected = function () {
        var selectedUsers = __spreadArrays(this.selectionRows);
        this.closeDialog();
        return selectedUsers;
    };
    CheckUser.prototype.handleNodeClick = function (node) {
        this.userModel.departCode = node.departCode;
        this.refreshUserData();
    };
    CheckUser.prototype.resetForm = function () {
        var form = this.$refs['user-form'];
        form.resetFields();
    };
    CheckUser.prototype.refreshUserData = function () {
        var _this = this;
        this.userService
            .findUserByDepartmentCode(this.userModel, this.sortService)
            .subscribe(function (data) {
            _this.userDataList = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    CheckUser.prototype.commit = function () {
        if (!this.selectionRows.length) {
            this.$message("未选择任何信息，请选择审批人!");
            return;
        }
        this.onSelected();
    };
    __decorate([
        Dependencies(PageService)
    ], CheckUser.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CheckUser.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserService)
    ], CheckUser.prototype, "userService", void 0);
    __decorate([
        Getter
    ], CheckUser.prototype, "orgTreeData", void 0);
    __decorate([
        Emit("closeDialog")
    ], CheckUser.prototype, "closeDialog", null);
    __decorate([
        Emit("selected")
    ], CheckUser.prototype, "onSelected", null);
    CheckUser = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], CheckUser);
    return CheckUser;
}(Vue));
export default CheckUser;
