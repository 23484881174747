var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import SvgIcon from "~/components/common/svg-icon.vue";
import FollowRecordPhone from "./follow-record-phone.vue";
import FollowRecordForeign from "./follow-record-foreign.vue";
import FollowRecordSearch from "./follow-record-search.vue";
import FollowRecordOther from "./follow-record-other.vue";
var collectionManageModule = namespace("collection-manage");
var FollowRecordInsert = /** @class */ (function (_super) {
    __extends(FollowRecordInsert, _super);
    function FollowRecordInsert() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hiddenPanel = false;
        return _this;
    }
    Object.defineProperty(FollowRecordInsert.prototype, "caseType", {
        get: function () {
            return this.queryOtherData("caseType");
        },
        enumerable: false,
        configurable: true
    });
    ;
    Object.defineProperty(FollowRecordInsert.prototype, "operateText", {
        get: function () {
            return this.hiddenPanel ? "展开" : "收起";
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        collectionManageModule.Getter
    ], FollowRecordInsert.prototype, "queryOtherData", void 0);
    FollowRecordInsert = __decorate([
        Component({
            components: {
                FollowRecordPhone: FollowRecordPhone,
                FollowRecordSearch: FollowRecordSearch,
                FollowRecordOther: FollowRecordOther,
                FollowRecordForeign: FollowRecordForeign,
                SvgIcon: SvgIcon
            }
        })
    ], FollowRecordInsert);
    return FollowRecordInsert;
}(Vue));
export default FollowRecordInsert;
