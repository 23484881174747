var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import FollowRecord from "~/components/approval-manage/follow-record.vue";
import PubliccaseManagePending from "~/components/assistant-manage/publiccase-manage/publiccase-manage-pending.vue";
import PubliccaseManageHandled from "~/components/assistant-manage/publiccase-manage/publiccase-manage-handled.vue";
import ProcessApprove from '~/components/assistant-manage/process-approve.vue';
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
//@Auth(742)
var PublicCaseManage = /** @class */ (function (_super) {
    __extends(PublicCaseManage, _super);
    function PublicCaseManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.publicCaseModel = {
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            idCard: "",
            applyDate: [],
            caseNumber: ""
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "publiccase-pending";
        _this.approvalRules = {
            result: [{
                    required: true,
                    message: "请选择审批结果",
                    trigger: "change"
                }]
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.userDataSet = [];
        _this.dialog = {
            approvedialog: false
        };
        _this.publiccaseRowData = {};
        return _this;
    }
    PublicCaseManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    PublicCaseManage.prototype.mounted = function () {
        this.refreshData();
    };
    //切换tab页面刷新对应的页面
    PublicCaseManage.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.refreshData();
    };
    //刷新数据
    PublicCaseManage.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    PublicCaseManage.prototype.handleResetForm = function () {
        this.publicCaseModel.principalId = '';
        this.publicCaseModel.applyDate = [];
    };
    //弹出框
    PublicCaseManage.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.publiccaseRowData = rowdata;
    };
    PublicCaseManage.prototype.bitchOperateMeth = function () {
        var tabdom = this.$refs[this.activeName];
        var selectedList = tabdom.publiccaseselectionList;
        if (selectedList.length === 0) {
            this.$message("请选择案件！");
            return false;
        }
        else {
            this.publiccaseRowData = selectedList;
            this.dialog.approvedialog = true;
        }
    };
    __decorate([
        Dependencies(PageService)
    ], PublicCaseManage.prototype, "pageService", void 0);
    PublicCaseManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowRecord: FollowRecord,
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
                PubliccaseManagePending: PubliccaseManagePending,
                PubliccaseManageHandled: PubliccaseManageHandled,
                ProcessApprove: ProcessApprove
            }
        })
    ], PublicCaseManage);
    return PublicCaseManage;
}(Vue));
export default PublicCaseManage;
