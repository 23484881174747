var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SelectMappingField from "~/components/system-manage/contact-way-config/select-mapping-field.vue";
import { State } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { CaseStatusService } from "~/services/management-service/case-status.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
var AddContact = /** @class */ (function (_super) {
    __extends(AddContact, _super);
    function AddContact() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.principals = [];
        _this.disabled = false;
        _this.editData = "";
        _this.addContactModel = {
            name: "",
            principalId: "",
            principalName: "",
            sort: "",
            isExtension: "NO",
            hideList: [],
            // isRecord: "", //是否录入调记
            isNecessary: "NO",
            limit: "",
            attribute: "",
            propertyType: "",
            level: "",
            parent: "",
            fieldDataType: "",
        };
        /**
         * 添加机构信息校验规则
         */
        _this.addContactRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            name: [{ required: true, message: "请输入配置名称", trigger: "blur" }],
            isExtension: [{ required: true, message: "请选择是否可扩展", trigger: "blur" }],
            isNecessary: [{ required: true, message: "请选择是否必填", trigger: "blur" }],
        };
        _this.primaryFields = [];
        _this.dialog = {
            selectMapping: false,
        };
        _this.mapping = {};
        _this.submitLoading = false;
        _this.principalDisabled = false;
        return _this;
    }
    AddContact.prototype.success = function () { };
    AddContact.prototype.close = function () {
        this.reset();
    };
    AddContact.prototype.emitSuccess = function () {
        this.selectedMapping();
        this.dialog.selectMapping = false;
    };
    AddContact.prototype.onCheckChange = function (val) {
        this.addContactModel.attribute = val.attribute;
        this.addContactModel.propertyType = val.propertyType;
        this.addContactModel.fieldDataType = val.propertyType;
    };
    AddContact.prototype.fresh = function (data) {
        var _this = this;
        if (this.level !== 1) {
            this.disabled = false;
        }
        else {
            this.disabled = true;
        }
        this.editData = data;
        if (data.level === 0) {
            this.contactConfigService.getPrincipalNoConfig().subscribe(function (data) {
                _this.principals = data;
            });
            this.addContactModel.isExtension = "NO";
            this.addContactModel.isRecord = "NO";
            this.addContactModel.isNecessary = "NO";
            this.addContactModel.sort = 1;
        }
        else {
            this.principals = this.principalList;
            this.addContactModel.principalId = data.principalId;
            this.addContactModel.principalName = data.principalName;
            this.contactConfigService.getAllContactConfig(data.principalId).subscribe(function (data) {
                _this.primaryFields = data;
            });
        }
        this.addContactModel.level = data.level + 1;
        this.addContactModel.parent = data.id;
    };
    /**
     * 确认
     */
    AddContact.prototype.addContactanizeCommit = function () {
        var _this = this;
        // if (this.addContactModel.level === 2 && !this.addContactModel.attribute) {
        //   this.$message.warning("请选择联动字段");
        //   return;
        // }
        var form = this.$refs["addContact"];
        form.validate(function (valid) {
            if (!valid)
                return;
            if (_this.principalList && _this.principalList.length !== 0) {
                var principal = _this.principals.filter(function (e) { return e.id === _this.addContactModel.principalId; });
                _this.addContactModel.principalName = principal[0].name;
            }
            _this.contactConfigService.addContactConfig(_this.addContactModel).subscribe(function (data) {
                _this.$message.success("新增联系方式成功");
                _this.success();
                _this.close();
            }, function (err) { return (_this.submitLoading = false); });
        });
    };
    /**
     * 关闭窗口
     */
    AddContact.prototype.reset = function () {
        var form = this.$refs["addContact"];
        form.resetFields();
        this.mapping = {};
    };
    AddContact.prototype.selectedMapping = function () {
        this.addContactModel.attribute = this.mapping.attribute ? this.mapping.attribute : "";
        this.addContactModel.propertyType = this.mapping.propertyType ? this.mapping.propertyType : "";
        this.addContactModel.isMustInput = this.mapping.isMustInput ? this.mapping.isMustInput : "";
        this.addContactModel.fieldDataType = this.mapping.fieldDataType ? this.mapping.fieldDataType : "";
        if (this.addContactModel.attribute) {
            var form = this.$refs["addContact"];
            form.clearValidate("attribute");
        }
    };
    AddContact.prototype.getPrimaryFields = function (principalId) {
        var _this = this;
        this.custConfigService.getPrimaryFields({ principalId: principalId }).subscribe(function (data) {
            _this.primaryFields = data;
        });
    };
    __decorate([
        Dependencies(CaseStatusService)
    ], AddContact.prototype, "caseStatusService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], AddContact.prototype, "custConfigService", void 0);
    __decorate([
        Dependencies(ContactConfigService)
    ], AddContact.prototype, "contactConfigService", void 0);
    __decorate([
        Prop({})
    ], AddContact.prototype, "level", void 0);
    __decorate([
        State
    ], AddContact.prototype, "principalList", void 0);
    __decorate([
        Emit("success")
    ], AddContact.prototype, "success", null);
    __decorate([
        Emit("close")
    ], AddContact.prototype, "close", null);
    AddContact = __decorate([
        Component({
            components: {
                SelectMappingField: SelectMappingField,
            },
        })
    ], AddContact);
    return AddContact;
}(Vue));
export default AddContact;
