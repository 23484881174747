var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
// 加载图标库文件
var requireAll = function (requireContext) { return requireContext.keys().map(requireContext); };
var req = require.context('../../assets/icons', false, /\.svg$/);
requireAll(req);
var SvgIcon = /** @class */ (function (_super) {
    __extends(SvgIcon, _super);
    function SvgIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SvgIcon.prototype, "iconClass", {
        get: function () {
            return "#icon-" + this.iconName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SvgIcon.prototype, "iconStyle", {
        get: function () {
            return {
                fontSize: this.iconSize + "px",
                color: "" + this.iconColor
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], SvgIcon.prototype, "iconName", void 0);
    __decorate([
        Prop({ default: 18 })
    ], SvgIcon.prototype, "iconSize", void 0);
    __decorate([
        Prop()
    ], SvgIcon.prototype, "iconColor", void 0);
    SvgIcon = __decorate([
        Component({
            components: {}
        })
    ], SvgIcon);
    return SvgIcon;
}(Vue));
export default SvgIcon;
