var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyManageService } from "~/services/business-service/case-apply-manage.service";
import { Emit, Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import ApproveDetails from "~/components/collection-manage/collection-application/approve-details.vue";
import { SortService } from "~/utils/sort.service";
import DataBox from "~/components/common/data-box.vue";
var VisitDoEnd = /** @class */ (function (_super) {
    __extends(VisitDoEnd, _super);
    function VisitDoEnd() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.visitDataSet = [];
        _this.SearchModel = {
            flowType: "VISIT_APPLY",
        };
        _this.rowData = {};
        _this.dialog = {
            approveDetail: false,
        };
        return _this;
    }
    VisitDoEnd.prototype.refreshList = function () { };
    VisitDoEnd.prototype.close = function () { };
    VisitDoEnd.prototype.approvedMeth = function (rowData) { };
    VisitDoEnd.prototype.approveDetail = function (row) {
        this.dialog.approveDetail = true;
        this.rowData = row;
    };
    VisitDoEnd.prototype.refreshData = function () {
        var _this = this;
        var SearchModel = Object.assign(this.SearchModel, this.visitModel);
        SearchModel.assistCategory = "VISIT_ASSIST";
        SearchModel.approvalStatus = "APPROVED_COMPLETED"; //办结
        SearchModel.applyDateStart = this.visitModel.applyDate[0];
        SearchModel.applyDateEnd = this.visitModel.applyDate[1];
        SearchModel.applyDate = "";
        this.caseApplyManageService.getProcessApproveMessage(this.SearchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.visitDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    //审批操作
    VisitDoEnd.prototype.approvedVsisttMeth = function (scope) {
        var rowData = scope.row;
        this.approvedMeth(rowData);
    };
    __decorate([
        Dependencies(SortService)
    ], VisitDoEnd.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], VisitDoEnd.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], VisitDoEnd.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("refreshList")
    ], VisitDoEnd.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], VisitDoEnd.prototype, "close", null);
    __decorate([
        Emit("approvedMeth")
    ], VisitDoEnd.prototype, "approvedMeth", null);
    __decorate([
        Prop()
    ], VisitDoEnd.prototype, "visitModel", void 0);
    VisitDoEnd = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                ApproveDetails: ApproveDetails,
            },
        })
    ], VisitDoEnd);
    return VisitDoEnd;
}(Vue));
export default VisitDoEnd;
