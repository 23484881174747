import { requestType } from '~/config/enum.config';
var SERVICE = 'business-service';
var CONTROLLER = 'flowConfigurationController';
export default {
    /**
     * 新增流程配置
     */
    createFlowConfiguration: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'createFlowConfiguration',
        type: requestType.Post
    },
    /**
       * 删除流程配置
       */
    deleteFlowConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteFlowConfig',
        type: requestType.Delete
    },
    /**
       * 查询所有流程配置
       */
    getAllFlowConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getAllFlowConfig',
        type: requestType.Get
    },
    /**
       * 修改配置流程
       */
    modifyFlowConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'modifyFlowConfig',
        type: requestType.Post
    },
};
