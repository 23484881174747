var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import FileUpload from "~/components/common/file-upload.vue";
import SvgIcon from "~/components/common/svg-icon.vue";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
import { ExportConfigService } from "~/services/dataimp-service/export-config.service";
import { ExportTemplateService } from "~/services/dataimp-service/export-template.service";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service";
import ApplyName from "~/components/system-manage/principal-manage/apply-name.vue";
var ExportConfig = /** @class */ (function (_super) {
    __extends(ExportConfig, _super);
    function ExportConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.disabledItems = [];
        _this.tableData = [];
        _this.fileData = {};
        _this.exportTypeList = [];
        _this.Model = {
            items: [],
            name: "",
            type: "",
            principalId: "",
            principalName: "",
            titleStartCol: 1,
            titleStartRow: 1,
            dataStartRow: 2,
            dataStartCol: 1,
            publicField: "",
            fileId: "",
            sheetNumber: 1
        };
        _this.rules = {
            principalId: [{ required: true, message: "请选择委托方" }],
            type: [{ required: true, message: "请选择模板类型" }],
            name: [{ required: true, message: "请输入模板名称" }],
            publicField: [{ required: true, message: "请选择公共字段设置" }],
            fileType: [{ required: true, message: "请选择导出格式设置" }]
        };
        _this.dialog = {
            applyName: false
        };
        _this.tableIndex = ''; //存当前点击行的下标
        return _this;
    }
    // @Watch("modifyData", { immediate: true })
    // modifyDataChange(value) {
    //   if (value.id !== '') {
    //     this.Model = value;
    //     this.tableData = value.items
    //   }
    // }
    ExportConfig.prototype.close = function () {
        this.reset();
        this.fileData = {};
    };
    ExportConfig.prototype.success = function () {
        this.close();
    };
    ExportConfig.prototype.dialogOpen = function (i, v, templateType) { };
    ExportConfig.prototype.mounted = function () {
    };
    ExportConfig.prototype.refresh = function (modifyData) {
        if (modifyData.id) {
            this.Model = modifyData;
            this.tableData = modifyData.items;
        }
    };
    /**
     * 提交
     */
    ExportConfig.prototype.submit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.tableData.length === 0) {
                _this.$message.warning("请上传模板！");
                return;
            }
            var items = _this.tableData.map(function (v) {
                var item = Object.assign({}, v);
                return {
                    titleName: item.titleName,
                    attribute: item.attribute,
                    col: item.col,
                    name: item.name,
                    propertyType: item.propertyType,
                    keyFlag: item.keyFlag,
                    relationFlag: item.relationFlag,
                    hideFlag: item.hideFlag,
                    sort: item.sort,
                    blankFlag: item.blankFlag,
                    sheetNumber: item.sheetNumber,
                    fileId: item.fileId,
                    remark: item.remark,
                    content: item.content,
                };
            });
            if (_this.Model.principalId) {
                _this.Model.principalName = _this.principalList.find(function (v) { return v.id === _this.Model.principalId; }).name;
            }
            _this.Model.items = items;
            _this.loading.state = true;
            if (_this.Model.id) {
                _this.exportTemplateService.modifyExportTemplate(_this.Model, _this.loading).subscribe(function (data) {
                    _this.$message.success("修改成功!");
                    _this.success();
                }, function (err) {
                    if (!err.object) {
                        _this.$message({
                            type: "info",
                            message: "修改失败！Excel数据格式错误"
                        });
                        return;
                    }
                });
            }
            else {
                _this.exportTemplateService.addExportTemplate(_this.Model, _this.loading).subscribe(function (data) {
                    _this.$message.success("新增成功!");
                    _this.success();
                }, function (err) {
                });
            }
        });
    };
    ExportConfig.prototype.uploadTemplate = function (templateHeaderModel) {
        var _this = this;
        this.importTemplateService
            .analyzeExcelTitle(templateHeaderModel, this.loading)
            .subscribe(function (data) {
            _this.tableData = data.analyzeExcelResponse.map(function (v) { return Object.assign({ name: '' }, v); });
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 上传成功
     */
    ExportConfig.prototype.onUploadSuccess = function (id, data) {
        var _this = this;
        if (data) {
            this.fileData = data;
            var templateHeaderModel = {
                titleStartRow: this.Model.titleStartRow,
                titleStartCol: this.Model.titleStartCol,
                sheetTotals: 1,
                sheetNumber: 1,
                fileId: this.fileData.id,
                id: this.Model.id,
                exportTemplateType: this.Model.type
            };
            this.Model.fileId = data.id;
            // 新增
            this.loading.state = true;
            if (!this.Model.id) {
                this.uploadTemplate(templateHeaderModel);
            }
            else {
                this.exportTemplateService.checkExportTemplate(templateHeaderModel, this.loading).subscribe(function (data) {
                    if (data.resultList.length || data.insertResultList.length)
                        _this.$alert("<div style=\"width: 100%; max-height: 200px; overflow: auto;\"><el-row><el-col style=\"display:block;\">\u65B0\u65E7\u6A21\u677F\u91CD\u590D\u9879\uFF1A</el-col>" + data.resultList
                            .map(function (item) {
                            return '<el-col :span="24" style="display:block;">' +
                                item +
                                "</el-col>";
                        })
                            .join("") + "</el-row><el-row><el-col style=\"display:block;\">\u6A21\u677F\u65B0\u589E\u9879\uFF1A</el-col>" + data.insertResultList
                            .map(function (item) {
                            return '<el-col :span="24" style="display:block;">' +
                                item +
                                "</el-col>";
                        })
                            .join("") + "</el-row></div>", {
                            dangerouslyUseHTMLString: true,
                            customClass: "confirm-message-box"
                        }).catch(function (ex) { });
                    _this.tableData = data.exportItems;
                });
            }
        }
    };
    /**
   * 查看映射字段
   * @param scope
   */
    ExportConfig.prototype.relateNameClick = function (scope) {
        this.disabledItems = this.tableData.filter(function (v) { return v.name !== "" && v.name !== null; }).map(function (item) { return ({
            attribute: item.attribute,
            propertyType: item.propertyType
        }); });
        if (!this.Model.type) {
            this.$message.error("请选择导入模板类型");
            return;
        }
        this.tableIndex = scope.$index;
        this.dialog.applyName = true;
    };
    ExportConfig.prototype.reSetClick = function (scope) {
        scope.row.name = null;
    };
    /**
     * 模板类型选择事件
     */
    ExportConfig.prototype.exportTypeChanged = function (val) {
        var _this = this;
        if (this.fileData.id) {
            var templateHeaderModel = {
                titleStartRow: this.Model.titleStartRow,
                titleStartCol: this.Model.titleStartCol,
                sheetTotals: 1,
                sheetNumber: 1,
                fileId: this.fileData.id,
                id: this.Model.id,
                exportTemplateType: this.Model.type
            };
            this.uploadTemplate(templateHeaderModel);
        }
        if (!this.Model.principalId) {
            this.Model.items = [];
            return;
        }
        this.Model.principalName = this.principalList.find(function (v) { return v.id === _this.Model.principalId; }).name;
    };
    /**
     * 表单重置
     */
    ExportConfig.prototype.reset = function () {
        this.tableData = [];
        delete this.Model.id;
        var form = this.$refs["form"];
        form.resetFields();
        var download = this.$refs["upload_case_import"];
        download.reset();
    };
    ExportConfig.prototype.backDate = function (val) {
        if (val.length === 0) {
            this.tableData[this.tableIndex].name = null;
            this.tableData[this.tableIndex].attribute = null;
            this.tableData[this.tableIndex].isShow = null;
            this.tableData[this.tableIndex].propertyType = null;
        }
        else {
            this.tableData[this.tableIndex].name = val.name;
            this.tableData[this.tableIndex].attribute = val.attribute;
            this.tableData[this.tableIndex].isShow = val.isShow;
            this.tableData[this.tableIndex].propertyType = val.propertyType;
        }
    };
    __decorate([
        Dependencies(ImportTemplateService)
    ], ExportConfig.prototype, "importTemplateService", void 0);
    __decorate([
        Dependencies(ExportConfigService)
    ], ExportConfig.prototype, "exportConfigService", void 0);
    __decorate([
        Dependencies(ExportTemplateService)
    ], ExportConfig.prototype, "exportTemplateService", void 0);
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], ExportConfig.prototype, "exportAssistManagementService", void 0);
    __decorate([
        State
    ], ExportConfig.prototype, "principalList", void 0);
    __decorate([
        Prop()
    ], ExportConfig.prototype, "modifyData", void 0);
    __decorate([
        Emit()
    ], ExportConfig.prototype, "close", null);
    __decorate([
        Emit()
    ], ExportConfig.prototype, "success", null);
    __decorate([
        Emit("dialogOpen")
    ], ExportConfig.prototype, "dialogOpen", null);
    ExportConfig = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                FileUpload: FileUpload,
                SvgIcon: SvgIcon,
                ApplyName: ApplyName
            }
        })
    ], ExportConfig);
    return ExportConfig;
}(Vue));
export default ExportConfig;
