var importTemplateConfig = function () { return import("~/pages/system-manage/principal-manange/excel-template.vue"); };
var PrincipalManagement = function () { return import("~/pages/system-manage/principal-manange/principal-management.vue"); };
var exportConfig = function () { return import("~/pages/system-manage/principal-manange/coll-export-config.vue"); };
var applicableConversion = function () { return import("~/pages/system-manage/principal-manange/applicable-conversion.vue"); };
var sensitiveWord = function () { return import("~/pages/system-manage/principal-manange/sensitive-word.vue"); };
export default [
    {
        // 委托方管理
        path: '/principal-manange/principal-management',
        name: 'principal-management',
        component: PrincipalManagement
    },
    {
        // 导入模板配置
        path: '/principal-manange/excel-template',
        name: 'excel-template',
        component: importTemplateConfig
    },
    {
        // 导出模板配置
        path: '/principal-manange/coll-export-config',
        name: 'coll-export-config',
        component: exportConfig
    },
    {
        // 手别转换配置
        path: '/principal-manange/applicable-conversion',
        name: 'applicable-conversion',
        component: applicableConversion
    },
    {
        // 敏感词汇配置
        path: '/principal-manange/sensitive-word',
        name: 'sensitive-word',
        component: sensitiveWord
    },
];
