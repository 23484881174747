var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Model } from "vue-property-decorator";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
var SelectMappingField = /** @class */ (function (_super) {
    __extends(SelectMappingField, _super);
    function SelectMappingField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        return _this;
    }
    SelectMappingField.prototype.emitClose = function () { };
    SelectMappingField.prototype.emitSuccess = function () { };
    SelectMappingField.prototype.emitCheckChange = function (val) { };
    Object.defineProperty(SelectMappingField.prototype, "checkedValue", {
        // private checkedValue = []
        get: function () {
            if (this.value && this.value.attribute) {
                return [this.value.attribute];
            }
            else {
                return [];
            }
        },
        set: function (val) {
            var checkeds = this.dataSet.filter(function (v) { return val.includes(v.attribute); });
            var result = checkeds.length ? checkeds[0] : {};
            this.emitCheckChange(result);
        },
        enumerable: false,
        configurable: true
    });
    SelectMappingField.prototype.created = function () {
        var _this = this;
        this.contactConfigService.getFollowMarchRecord().subscribe(function (data) { return (_this.dataSet = data); });
    };
    __decorate([
        Dependencies(ContactConfigService)
    ], SelectMappingField.prototype, "contactConfigService", void 0);
    __decorate([
        Model("check-change", { type: Object })
    ], SelectMappingField.prototype, "value", void 0);
    __decorate([
        Emit("close")
    ], SelectMappingField.prototype, "emitClose", null);
    __decorate([
        Emit("emitSuccess")
    ], SelectMappingField.prototype, "emitSuccess", null);
    __decorate([
        Emit("check-change")
    ], SelectMappingField.prototype, "emitCheckChange", null);
    SelectMappingField = __decorate([
        Component({
            components: {}
        })
    ], SelectMappingField);
    return SelectMappingField;
}(Vue));
export default SelectMappingField;
