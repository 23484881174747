var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { SortService } from "~/utils/sort.service";
import { Emit, Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import DataBox from "~/components/common/data-box.vue";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import LetterSinglePrint from "~/components/assistant-manage/letter-print/letter-single-print.vue";
var WaitPrint = /** @class */ (function (_super) {
    __extends(WaitPrint, _super);
    function WaitPrint() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processApplicationId = '';
        _this.selectionList = [];
        _this.caseId = null;
        _this.dialog = {
            singlePrint: false,
        };
        _this.loading = {
            state: false
        };
        _this.letterDataSet = [];
        _this.SearchModel = {};
        return _this;
    }
    WaitPrint.prototype.refreshList = function () { };
    WaitPrint.prototype.close = function () {
    };
    WaitPrint.prototype.letterPrintClick = function (row) {
        this.caseId = row.caseId;
        this.processApplicationId = row.processApplicationId;
        this.dialog.singlePrint = true;
    };
    WaitPrint.prototype.refreshData = function () {
        var _this = this;
        this.SearchModel = Object.assign(this.letterModel);
        this.SearchModel.flowType = "LETTER_PRINT_APPLY";
        this.SearchModel.exportState = "WAIT_EXPORT";
        this.SearchModel.applyDateStart = this.letterModel.applyDate[0];
        this.SearchModel.applyDateEnd = this.letterModel.applyDate[1];
        this.SearchModel.applyDate = '';
        this.loading.state = true;
        this.caseApplyManageService.getLetterPrint(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.letterDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(SortService)
    ], WaitPrint.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], WaitPrint.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], WaitPrint.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("refreshList")
    ], WaitPrint.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], WaitPrint.prototype, "close", null);
    __decorate([
        Prop()
    ], WaitPrint.prototype, "letterModel", void 0);
    WaitPrint = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                LetterSinglePrint: LetterSinglePrint
            }
        })
    ], WaitPrint);
    return WaitPrint;
}(Vue));
export default WaitPrint;
