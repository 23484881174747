var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataBox from '~/components/common/data-box.vue';
import DataForm from '~/components/common/data-form.vue';
import OrgAllocate from '~/components/case-info/case-allot/org-allocate.vue';
import CollectorAllocate from '~/components/case-info/case-allot/collector-allocate.vue';
import { Dependencies } from '~/core/decorator';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Getter, namespace } from 'vuex-class';
import { DataGrid, DataGridItem } from '@zct1989/vue-component';
import { Prop, Emit } from 'vue-property-decorator';
import { CaseInfoDistributeService } from '~/services/business-service/caseinfo-distribute.service';
import { UserService } from "~/services/manage-service/user.service";
import clone from 'clone';
var systemManageModule = namespace("system-manage");
var CaseBatchAllot = /** @class */ (function (_super) {
    __extends(CaseBatchAllot, _super);
    function CaseBatchAllot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        //是否显示多选框
        _this.showSelectBox = true;
        //选择的人员
        _this.selectionUserList = [];
        //人员Data
        _this.userDataList = [];
        //分配类型：部门 调解员
        _this.allotType = "DEPARTMENT";
        _this.batchMessage = {};
        _this.distributeWay = 'SYSTEM_AUTO';
        _this.loading = false;
        _this.nextStep = false;
        _this.distributeConfigModel = {};
        _this.batchModel = {
            batchNumber: '',
            caseType: ""
        };
        _this.statisticalData = [];
        _this.batchNumberList = [];
        _this.userModel = {
            userName: "",
            realName: "",
            departCode: ''
        };
        _this.organizationDataSet = [];
        _this.dialog = {
            regionManualDivisionNext: false,
            collectorManualDivisionNext: false
        };
        _this.model = {
            // 案件Ids
            caseIds: [],
            // 案件总数
            caseNumTotal: 0,
            // 案件总金额
            caseAmtTotal: 0,
            // 区域总数
            distributeTotal: 0,
            // 分配规则
            distributeConfigModels: [],
            // 分配方式
            distributeWay: _this.distributeWay
        };
        _this.batchNumberRules = {
            batchNumber: [{ required: true, message: '请选择批次号', trigger: 'blur' }],
            caseType: [{ required: true, message: '请选择调解方式', trigger: 'blur' }]
        };
        return _this;
    }
    Object.defineProperty(CaseBatchAllot.prototype, "totalTarget", {
        // get orgDataSet() {
        //   let items: Array<any> = [];
        //   return this.organizationDataSet.map(item =>
        //     Object.assign({ limitNum: 0, limitAmt: 0 }, item)
        //   );
        // }
        get: function () {
            if (this.allotType === "DEPARTMENT") {
                return this.organizationDataSet.length;
            }
            else {
                return this.selectionUserList.length;
            }
        },
        enumerable: false,
        configurable: true
    });
    CaseBatchAllot.prototype.close = function () {
        this.distributeWay = 'SYSTEM_AUTO';
        this.dialog.regionManualDivisionNext = false;
        this.dialog.collectorManualDivisionNext = false;
        this.batchModel.batchNumber = '';
        this.showSelectBox = true;
        this.allotType = "DEPARTMENT";
        this.organizationDataSet = [];
        this.batchModel.caseType = '';
        this.batchMessage = {};
        this.nextStep = false;
        this.$refs.elTree.setCheckedKeys([]);
    };
    Object.defineProperty(CaseBatchAllot.prototype, "divisionIsEdited", {
        get: function () {
            return this.distributeWay == 'SYSTEM_AUTO' ? true : false;
        },
        enumerable: false,
        configurable: true
    });
    CaseBatchAllot.prototype.allotTypeChange = function (value) {
        if (value === 'DEPARTMENT') {
            this.showSelectBox = true;
        }
        else {
            this.showSelectBox = false;
        }
    };
    CaseBatchAllot.prototype.getBatchNumberList = function () {
        var _this = this;
        this.caseInfoDistributeService.getWaitBatchNumberAndTotal().subscribe(function (data) {
            if (!data)
                return;
            _this.statisticalData = data;
            _this.batchNumberList = data.map(function (x) { return x.batchNumber; });
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    CaseBatchAllot.prototype.queryCountAndTotalByBatchNumber = function (batchNumber) {
        this.batchMessage = this.statisticalData.find(function (x) { return x.batchNumber === batchNumber; });
        if (this.batchMessage.totalCount > 10000) {
            this.nextStep = true;
            this.$message.warning('批次案件数量大于10000条请使用策略分案或者手动分案功能!');
        }
        else {
            this.nextStep = false;
        }
    };
    CaseBatchAllot.prototype.batchNumberChange = function (value) {
        this.queryCountAndTotalByBatchNumber(value);
    };
    CaseBatchAllot.prototype.refreshUserData = function () {
        var _this = this;
        this.userService.findUserByDepartmentCode(this.userModel, this.sortService).subscribe(function (data) {
            _this.userDataList = data.map(function (item) {
                return Object.assign({ limitNum: 0, limitAmt: 0 }, item);
            });
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    CaseBatchAllot.prototype.resetForm = function () {
        var form = this.$refs['user-form'];
        form.resetFields();
    };
    CaseBatchAllot.prototype.submit = function () {
        var _this = this;
        if (!this.batchModel.batchNumber) {
            this.$message.warning('请选择要分配的批次号!');
            return;
        }
        if (!this.batchModel.caseType) {
            this.$message.warning('请选择调解方式!');
            return;
        }
        if (this.allotType === 'DEPARTMENT' && this.organizationDataSet.length == 0) {
            this.$message.warning('请选择要分配的机构!');
            return;
        }
        if (this.allotType === 'COLLECTOR' && this.selectionUserList.length == 0) {
            this.$message.warning('请选择要分配的人员!');
            return;
        }
        var distributeConfigModels = {};
        var distributeTotal = 0;
        var targetName = '';
        if (this.allotType === 'DEPARTMENT') {
            distributeTotal = this.organizationDataSet.length;
            distributeConfigModels = this.organizationDataSet.map(function (x) {
                var distributeConfigModel = {
                    //当前分配到的ID
                    id: x.id,
                    // 当前分配到的名称
                    name: x.name,
                    // 分配数量上限
                    limitNum: x.limitNum,
                    // 分配金额上限
                    limitAmt: x.limitAmt,
                    // 分配数量
                    caseNumTotal: 0,
                    // 分配金额
                    leftAmtTotal: 0,
                    // 百分比
                    perNum: 0,
                    // 分配的案件ID
                    resultMap: [],
                };
                return distributeConfigModel;
            });
        }
        else {
            distributeConfigModels = this.selectionUserList.map(function (x) {
                distributeTotal = _this.selectionUserList.length;
                var distributeConfigModel = {
                    //当前分配到的ID
                    id: x.id,
                    // 当前分配到的名称
                    name: x.realName,
                    // 分配数量上限
                    limitNum: x.limitNum,
                    // 分配金额上限
                    limitAmt: x.limitAmt,
                    // 分配数量
                    caseNumTotal: 0,
                    // 分配金额
                    leftAmtTotal: 0,
                    // 百分比
                    perNum: 0,
                    // 分配的案件ID
                    resultMap: [],
                };
                return distributeConfigModel;
            });
        }
        this.model = {
            // 案件Ids
            caseIds: [],
            // 案件总数
            caseNumTotal: this.batchMessage.totalCount,
            // 案件总金额
            caseAmtTotal: this.batchMessage.totalAmount,
            // 机构总数
            distributeTotal: distributeTotal,
            // 分配规则
            distributeConfigModels: distributeConfigModels,
            // 分配方式
            distributeWay: this.distributeWay,
            //分配类别
            distributeType: this.allotType,
            batchNumber: this.batchModel.batchNumber,
            caseType: this.batchModel.caseType
        };
        this.loading = true;
        this.caseInfoDistributeService.caseDistributedPreview(this.model).subscribe(function (data) {
            _this.distributeConfigModel = data;
            if (_this.allotType === 'DEPARTMENT') {
                _this.dialog.regionManualDivisionNext = true;
                _this.dialog.collectorManualDivisionNext = false;
            }
            else {
                _this.dialog.regionManualDivisionNext = false;
                _this.dialog.collectorManualDivisionNext = true;
            }
            _this.loading = false;
        });
    };
    CaseBatchAllot.prototype.handleNodeClick = function (node) {
        if (this.allotType === 'COLLECTOR') {
            this.userModel.departCode = node.departCode;
            this.refreshUserData();
        }
    };
    CaseBatchAllot.prototype.handleCheckChange = function (data, isChecked) {
        var params = clone(data);
        if (params.children)
            delete params.children;
        var findIndex = this.organizationDataSet.findIndex(function (x) { return x.id === params.id; });
        if (isChecked) {
            this.organizationDataSet.push(Object.assign({ limitNum: 0, limitAmt: 0 }, params));
        }
        else {
            if (findIndex > -1)
                this.organizationDataSet.splice(findIndex, 1);
        }
    };
    CaseBatchAllot.prototype.divisionTypeChanged = function (data) {
        this.organizationDataSet.forEach(function (x) {
            x.limitNum = 0;
            x.limitAmt = 0;
        });
        this.userDataList.forEach(function (x) {
            x.limitNum = 0;
            x.limitAmt = 0;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CaseBatchAllot.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CaseBatchAllot.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserService)
    ], CaseBatchAllot.prototype, "userService", void 0);
    __decorate([
        Dependencies(CaseInfoDistributeService)
    ], CaseBatchAllot.prototype, "caseInfoDistributeService", void 0);
    __decorate([
        Getter
    ], CaseBatchAllot.prototype, "orgTreeData", void 0);
    __decorate([
        Prop({})
    ], CaseBatchAllot.prototype, "selectionCases", void 0);
    __decorate([
        Emit('close')
    ], CaseBatchAllot.prototype, "close", null);
    CaseBatchAllot = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                OrgAllocate: OrgAllocate,
                CollectorAllocate: CollectorAllocate
            },
        })
    ], CaseBatchAllot);
    return CaseBatchAllot;
}(Vue));
export default CaseBatchAllot;
