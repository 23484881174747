var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import CommonTitle from "~/components/common/common-title.vue";
import LabelContainer from "~/components/common/label-container.vue";
import LabelItem from "~/components/common/label-item.vue";
var CollectionManageModule = namespace("collection-manage");
var CardOtherInfo = /** @class */ (function (_super) {
    __extends(CardOtherInfo, _super);
    function CardOtherInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CollectionManageModule.State
    ], CardOtherInfo.prototype, "cardModel", void 0);
    CardOtherInfo = __decorate([
        Component({
            components: {
                LabelContainer: LabelContainer,
                LabelItem: LabelItem,
                CommonTitle: CommonTitle
            }
        })
    ], CardOtherInfo);
    return CardOtherInfo;
}(Vue));
export default CardOtherInfo;
