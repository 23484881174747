var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Prop, Emit } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
var workspaceModule = namespace("workspace");
var WorkMenu = /** @class */ (function (_super) {
    __extends(WorkMenu, _super);
    function WorkMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WorkMenu.prototype.onSelectSubmenu = function (path, pathArray) { };
    Object.defineProperty(WorkMenu.prototype, "iconSpan", {
        get: function () {
            return !this.menuCollapse ? 6 : 24;
        },
        enumerable: false,
        configurable: true
    });
    WorkMenu.prototype.mounted = function () {
    };
    /**
     * 目录菜单点击处理
     */
    WorkMenu.prototype.onSubmenuClick = function (event) {
        // 仅在菜单折叠状态有效
        if (!this.menuCollapse || !this.data) {
            return;
        }
        else {
            event.preventDefault();
            event.stopPropagation();
        }
        // 如果打开为同一个submenu则不处理
        if (!this.currentTab || this.currentTab.startsWith(this.data.url)) {
            return;
        }
        // 如果不含有子路由则不处理
        if (!this.data.children || this.data.children.length === 0) {
            return;
        }
        // 获取目标路由
        var target = this.data.children[0];
        if (target) {
            this.onSelectSubmenu(target.url, [this.data.url, target.url]);
        }
    };
    __decorate([
        Prop()
    ], WorkMenu.prototype, "data", void 0);
    __decorate([
        State
    ], WorkMenu.prototype, "menuCollapse", void 0);
    __decorate([
        workspaceModule.State
    ], WorkMenu.prototype, "currentTab", void 0);
    __decorate([
        Emit("select")
    ], WorkMenu.prototype, "onSelectSubmenu", null);
    WorkMenu = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], WorkMenu);
    return WorkMenu;
}(Vue));
export default WorkMenu;
