import { CollectionData } from "~/config/types/collection-data";
export default {
    namespaced: true,
    state: {
        caseId: "",
        principalId: "",
        queryData: "",
        otherData: "",
        currentCase: {},
        phoneInvertData: {},
        // add-follow-recordCount
        recordCount: 0,
        // 计数器，用于更新协调记录
        assistCount: 0,
        cardInfo: {},
        accountNumber: "",
        accountInfo: [],
        cardModel: {},
        cardNo: "",
    },
    mutations: {
        phoneInvert: function (state, data) {
            state.phoneInvertData = data;
        },
        resetState: function (state) {
            state.caseId = "";
            state.queryData = "";
            state.otherData = "";
            state.phoneInvertData = {};
            state.currentCase = {};
            state.recordCount = 0;
            state.assistCount = 0;
            state.cardInfo = {};
            state.overdueAmtTotalInfo = "";
            state.leftAmtTotalInfo = "";
            state.accountNumber = "";
            state.accountInfo = [];
            state.currentCardNo = {};
        },
        updateCurrentCondition: function (state, item) {
            // 切换按钮状态
            if (item.type !== "primary") {
                state.filterConditions.forEach(function (x) { return (x.type = ""); });
                item.type = "primary";
            }
        },
        /**
         * 更新案件id
         * @param state
         * @param caseId 当前案件id
         */
        updateCaseId: function (state, caseId) {
            state.caseId = caseId;
        },
        /**
         * 更新更新当前案件信息
         * @param state
         * @param currentCase
         */
        updateCurrentCase: function (state, currentCase) {
            state.currentCase = currentCase;
        },
        /**
         * 更新执行页面所需要的参数
         * @param state
         * @param data
         */
        updateCollectionData: function (state, data) {
            state.caseId = data.caseId;
            state.principalId = data.principalId;
            state.queryData = data.queryData;
            state.otherData = data.otherData;
        },
        /**
         * 更新案件基本信息
         * @param state
         * @param data
         */
        updateCaseInfo: function (state, data) {
            state.currentCase = data;
        },
        /**
         * 更新案件类型
         */
        updateOtherData: function (state, data) {
            state.otherData = data;
        },
        /**
         * 更新客户账号卡号信息
         */
        updateCardInfo: function (state, data) {
            state.cardInfo = data;
        },
        /**
         * 更新客户账号金额汇总信息
         */
        updateOverdueAmtTotalInfo: function (state, data) {
            state.overdueAmtTotalInfo = data;
        },
        updateLeftAmtTotalInfo: function (state, data) {
            state.leftAmtTotalInfo = data;
        },
        updateCardNo: function (state, data) {
            state.currentCardNo = data;
        },
        updateAccountNumber: function (state, data) {
            state.accountNumber = data;
        },
        updateAccountInfo: function (state, data) {
            state.accountInfo = data;
        },
        updateCardModel: function (state, data) {
            state.cardModel = data;
        },
        /**
         * 更新刷新状态
         * @param state
         */
        updateRecordCount: function (state) {
            state.recordCount += 1;
        },
        updateAssistCount: function (state) {
            state.assistCount += 1;
        },
    },
    getters: {
        /*
         * 获取调收执行页面基础数据
         * @param state
         */
        collectionData: function (state) {
            return new CollectionData(state.principalId, state.caseId, state.queryData, state.otherData);
        },
        /**
         * 客户详情
         */
        personalInfo: function (state) {
            return state.currentCase.personal || {};
        },
        /**
         * 委托方详情
         * @param state
         */
        principalInfo: function (state) {
            return state.currentCase.principal || {};
        },
        /**
         * 其他详情信息
         */
        remarkMapInfo: function (state) {
            return state.currentCase.remarkMap || [];
        },
        /**
         * 账户信息
         */
        accountInfos: function (state) {
            return state.currentCase.accountInfos || [];
        },
        personalId: function (state, getters) {
            return getters.personalInfo.id;
        },
        caseId: function (state) {
            return state.currentCase.caseId;
        },
        /**
         * 查询其他数据里面的值
         * @param state
         */
        queryOtherData: function (state) {
            return function (key) {
                if (!state.otherData)
                    return undefined;
                if (typeof state.otherData === "object") {
                    return state.otherData[key];
                }
                else {
                    return undefined;
                }
            };
        },
    },
    actions: {
        /**
         * 打开调收执行页面
         * @param param0
         * @param data 调收执行页面所需的数据
         */
        openExecutionPage: function (_a, data) {
            var commit = _a.commit, dispatch = _a.dispatch;
            commit("resetState");
            commit("updateCollectionData", data);
            var path = "/acc-manage/collection-execution/" + data.caseId;
            window.open(path, data.caseId);
        },
    },
};
