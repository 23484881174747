var SystemLog = function () { return import('~/pages/system-manage/logs-manage/system-log.vue'); };
var LoginLog = function () { return import('~/pages/system-manage/logs-manage/login-log.vue'); };
export default [
    {
        // 系统日志
        path: '/logs-manage/system-log',
        name: 'system-log',
        component: SystemLog
    },
    {
        //登陆日志
        path: '/logs-manage/login-log',
        name: 'login-log',
        component: LoginLog
    },
];
