var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State, Getter } from "vuex-class";
import NumberRange from "~/components/common/number-range.vue";
import FollowRecord from "~/components/approval-manage/follow-record.vue";
import SupplementManagePending from "~/components/assistant-manage/supplement-manage/supplement-manage-pending.vue";
import SupplementManageHandled from "~/components/assistant-manage/supplement-manage/supplement-manage-handled.vue";
import SupplementApproved from "~/components/assistant-manage/supplement-manage/supplement-approved.vue";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service.ts";
//@Auth(742)
var SupplementMoneyManage = /** @class */ (function (_super) {
    __extends(SupplementMoneyManage, _super);
    function SupplementMoneyManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supplementModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            idCard: "",
            applyDate: null
        };
        _this.loading = {
            state: false
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "supplement-wait";
        _this.approvalRules = {
            result: [{ required: true, message: "请选择审批结果", trigger: "change" }]
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.supplemenRowData = {};
        _this.dialog = {
            approvedialog: false
        };
        _this.selectedList = [];
        _this.rules = {
            idCard: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }]
        };
        return _this;
    }
    Object.defineProperty(SupplementMoneyManage.prototype, "companyCode", {
        /**
         * 考虑到非管理员不显示公司选择控件
         * get 操作的时候强制设置非管理员所在公司码
         */
        get: function () {
            if (!this.isSupperAdmin) {
                this.supplementModel.companyCode = this.userData.companyCode;
            }
            return this.supplementModel.companyCode;
        },
        set: function (value) {
            this.supplementModel.companyCode = value;
        },
        enumerable: false,
        configurable: true
    });
    SupplementMoneyManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    SupplementMoneyManage.prototype.activated = function () {
        if (!this.isSupperAdmin) {
            this.refreshData();
        }
    };
    SupplementMoneyManage.prototype.mounted = function () {
        this.refreshData();
    };
    //切换tab页面刷新对应的页面
    SupplementMoneyManage.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.resetSearchFelid();
        this.refreshData();
    };
    //刷新数据
    SupplementMoneyManage.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    //弹出框
    SupplementMoneyManage.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.supplemenRowData = rowdata;
    };
    //reset搜索框
    SupplementMoneyManage.prototype.resetSearchFelid = function () {
        this.supplementModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            idCard: "",
            applyDate: null
        };
    };
    SupplementMoneyManage.prototype.batchApprovedClick = function () {
        var tabdom = this.$refs[this.activeName];
        this.selectedList = tabdom.supplementselectionList;
        if (this.selectedList.length === 0) {
            this.$message("请选择案件！");
            return false;
        }
        else {
            this.dialog.approvedialog = true;
            this.supplemenRowData = this.selectedList;
        }
    };
    SupplementMoneyManage.prototype.exportCase = function () {
        var _this = this;
        var pendingOperate = this.$refs["supplement-handled"];
        if (pendingOperate.supplementselectionList.length < 1 && this.supplementModel.batchNumber === "" && this.supplementModel.principalName === "") {
            this.$message("请搜索或勾选要导出的案件!");
            return;
        }
        if (pendingOperate.supplementselectionList.find(function (x) { return x.approvedResult === "APPROVED_REJECT"; })) {
            this.$message("所勾选案件存在已拒绝案件，请重新勾选!");
            return;
        }
        var exportModel = {};
        if (this.supplementModel.batchNumber)
            exportModel.batchNumber = this.supplementModel.batchNumber;
        if (this.supplementModel.principalName)
            exportModel.principalId = this.principalList.find(function (v) { return v.principalName === _this.supplementModel.principalName; }).id;
        if (pendingOperate.supplementselectionList.length > 0)
            exportModel.applyIds = pendingOperate.supplementselectionList.map(function (v) { return v.id; });
        exportModel.applyType = pendingOperate.SearchModel.applyType;
        this.$confirm("您确定要导出所选案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.exportAssistManagementService
                .exportAssistManagement(exportModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("导出成功"), _this.refreshData();
            }, function (err) {
                _this.$message.error(err.message);
            });
        })
            .catch(function () { });
    };
    __decorate([
        Dependencies(PageService)
    ], SupplementMoneyManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], SupplementMoneyManage.prototype, "exportAssistManagementService", void 0);
    __decorate([
        State
    ], SupplementMoneyManage.prototype, "principalList", void 0);
    __decorate([
        State
    ], SupplementMoneyManage.prototype, "userData", void 0);
    __decorate([
        State
    ], SupplementMoneyManage.prototype, "companyList", void 0);
    __decorate([
        Getter
    ], SupplementMoneyManage.prototype, "isSupperAdmin", void 0);
    SupplementMoneyManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowRecord: FollowRecord,
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                SupplementManagePending: SupplementManagePending,
                SupplementManageHandled: SupplementManageHandled,
                SupplementApproved: SupplementApproved
            }
        })
    ], SupplementMoneyManage);
    return SupplementMoneyManage;
}(Vue));
export default SupplementMoneyManage;
