var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { RoleService } from "~/services/manage-service/role.service";
import { Emit, Prop } from "vue-property-decorator";
var BatchAllotRole = /** @class */ (function (_super) {
    __extends(BatchAllotRole, _super);
    function BatchAllotRole() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            roleName: "",
            status: ''
        };
        _this.selectionRow = [];
        // 设置角色对象
        _this.roleSetModel = {
            userIds: [],
            roleIds: []
        };
        _this.dataSet = null;
        return _this;
    }
    BatchAllotRole.prototype.refreshList = function () { };
    BatchAllotRole.prototype.close = function () {
    };
    BatchAllotRole.prototype.refresh = function () {
        this.model.roleName = "";
        this.model.status = "";
        this.selectionRow = [];
        this.refreshData();
    };
    BatchAllotRole.prototype.confirm = function () {
        var _this = this;
        if (this.selectionRow == 0) {
            this.$message.info("请选择角色");
            return;
        }
        this.roleSetModel.roleIds = this.selectionRow.map(function (x) { return x.id; });
        this.roleSetModel.userIds = this.userList.map(function (v) { return v.id; });
        this.roleService.batchDistributeRole(this.roleSetModel).subscribe(function (data) {
            _this.$message.success("分配成功");
            _this.close();
            _this.refreshList();
        });
    };
    BatchAllotRole.prototype.refreshData = function () {
        var _this = this;
        this.roleService
            .findRoles(this.model, this.pageService)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], BatchAllotRole.prototype, "pageService", void 0);
    __decorate([
        Dependencies(RoleService)
    ], BatchAllotRole.prototype, "roleService", void 0);
    __decorate([
        Emit("refreshList")
    ], BatchAllotRole.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], BatchAllotRole.prototype, "close", null);
    __decorate([
        Prop()
    ], BatchAllotRole.prototype, "userList", void 0);
    BatchAllotRole = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], BatchAllotRole);
    return BatchAllotRole;
}(Vue));
export default BatchAllotRole;
