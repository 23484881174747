var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import SvgIcon from "~/components/common/svg-icon.vue";
import { StorageService } from "~/utils/storage.service";
import UUID from "uuidjs";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
var collectionManageModule = namespace("collection-manage");
var outboundManageModule = namespace("outbound-manage");
var CollectionAgentPhone = /** @class */ (function (_super) {
    __extends(CollectionAgentPhone, _super);
    function CollectionAgentPhone() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isForm = false; //外呼时显示电话号码输入框
        _this.companyid = '18'; //企业ID
        _this.phoneNumber = '';
        _this.seatLocalStorage = '';
        _this.zuoxi = '';
        _this.seatState = '';
        _this.hidden = true;
        _this.hover = false;
        return _this;
    }
    Object.defineProperty(CollectionAgentPhone.prototype, "leftLogo", {
        get: function () {
            return this.queryOtherData('leftLogo');
        },
        enumerable: false,
        configurable: true
    });
    CollectionAgentPhone.prototype.mounted = function () {
        var _this = this;
        window.onstorage = function (e) {
            if (e.key === 'seatInformation') {
                _this.seatLocalStorage = JSON.parse(e.newValue);
            }
            if (e.key === 'seatState') {
                _this.seatState = e.newValue.toString();
                _this.zuoxi = _this.$outbound.stateTransition(_this.seatState);
            }
        };
        this.seatLocalStorage = StorageService.getItem("seatInformation"); //初始化呼叫坐席信息
        this.seatState = StorageService.getItem("seatState").toString(); //初始化坐席状态
        this.zuoxi = this.$outbound.stateTransition(this.seatState);
    };
    CollectionAgentPhone.prototype.search = function () {
        if (!StorageService.getItem("isSignin")) {
            this.$message.warning("请先签入");
            return;
        }
        var seatInformation = StorageService.getItem("seatInformation");
        var baseModel = {
            companyid: this.companyid,
            method: 'manualCallout',
            agentno: seatInformation.seatNumber,
            pstnnumber: seatInformation.callPhones.find(function (x) { return x.defaultNo === 'YES'; }).phoneNo,
            callee: this.phoneNumber,
            cusData: UUID.generate() //uuID
        };
        this.updateUUid(baseModel.cusData); //当前电话对应uuid存入store
        this.$outbound.sendRequest(baseModel);
    };
    CollectionAgentPhone.prototype.outboundPhone = function () {
        if (StorageService.getItem("isSignin")) {
            this.isForm = true;
        }
        else {
            this.$message.warning("请先签入");
        }
    };
    CollectionAgentPhone.prototype.hangup = function () {
        if (!StorageService.getItem("isSignin")) {
            this.$message.warning("请先签入");
        }
        else {
            var seatInformation = StorageService.getItem("seatInformation");
            var baseModelHang = {
                companyid: this.companyid,
                agentno: seatInformation.seatNumber,
                password: seatInformation.callPsw,
                exten: seatInformation.exten,
                agentstatus: '0',
                pstnnumber: seatInformation.callPhones.find(function (x) { return x.defaultNo === 'YES'; }).phoneNo,
                method: 'hangup',
                callee: ''
            };
            this.$outbound.sendRequest(baseModelHang);
            var baseModelHangChange = {
                companyid: this.companyid,
                agentno: seatInformation.seatNumber,
                password: seatInformation.callPsw,
                exten: seatInformation.exten,
                agentstatus: '0',
                pstnnumber: seatInformation.callPhones.find(function (x) { return x.defaultNo === 'YES'; }).phoneNo,
                method: 'setAcsType',
                callee: ''
            };
            this.$outbound.sendRequest(baseModelHangChange);
        }
    };
    __decorate([
        collectionManageModule.State
    ], CollectionAgentPhone.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionAgentPhone.prototype, "updateCaseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], CollectionAgentPhone.prototype, "queryOtherData", void 0);
    __decorate([
        outboundManageModule.Mutation
    ], CollectionAgentPhone.prototype, "updateUUid", void 0);
    CollectionAgentPhone = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem
            }
        })
    ], CollectionAgentPhone);
    return CollectionAgentPhone;
}(Vue));
export default CollectionAgentPhone;
