var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
var CaseApply = /** @class */ (function (_super) {
    __extends(CaseApply, _super);
    function CaseApply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.submitLoading = false;
        _this.Rules = {
            applyRemark: [
                {
                    required: true,
                    message: "请输入申请说明"
                }
            ]
        };
        _this.applyModel = {
            applyRemark: "",
            flowType: "PUBLIC_DISTRIBUTE_CASE_APPLY",
            caseId: ""
        };
        return _this;
    }
    CaseApply.prototype.close = function () {
        this.reset();
    };
    /**
     * 确定
     */
    CaseApply.prototype.submit = function () {
        var _this = this;
        var applyForm = this.$refs["apply-from"];
        applyForm.validate(function (valid) {
            if (!valid)
                return;
            _this.applyModel.caseId = _this.applyData.id;
            _this.submitLoading = true;
            _this.caseApplyManageService.caseApplyOperated(_this.applyModel)
                .subscribe(function (data) {
                _this.$message.success("申请成功！");
                _this.submitLoading = false;
                _this.close();
            }, function (err) { return (_this.submitLoading = false); });
        });
    };
    CaseApply.prototype.reset = function () {
        this.applyModel.applyRemark = "";
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], CaseApply.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("close")
    ], CaseApply.prototype, "close", null);
    __decorate([
        Prop({ required: true })
    ], CaseApply.prototype, "applyData", void 0);
    CaseApply = __decorate([
        Component({
            components: {}
        })
    ], CaseApply);
    return CaseApply;
}(Vue));
export default CaseApply;
