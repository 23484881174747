var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { Emit, Prop } from "vue-property-decorator";
import { SortService } from "~/utils/sort.service";
import ProcessApprove from '~/components/assistant-manage/process-approve.vue';
import { PageService } from "~/utils/page.service";
import DataBox from "~/components/common/data-box.vue";
var LetterPrintPending = /** @class */ (function (_super) {
    __extends(LetterPrintPending, _super);
    function LetterPrintPending() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.letterDataSet = [];
        _this.SearchModel = {};
        _this.rowData = {};
        _this.loading = {
            state: false
        };
        _this.dialog = {
            approvedialog: false
        };
        return _this;
    }
    LetterPrintPending.prototype.refreshList = function () { };
    LetterPrintPending.prototype.close = function () { };
    //弹出框
    LetterPrintPending.prototype.approvedMethod = function (rowdata) {
        this.dialog.approvedialog = true;
        this.rowData = rowdata;
    };
    LetterPrintPending.prototype.refreshData = function () {
        var _this = this;
        this.SearchModel = this.letterModel;
        this.SearchModel.flowType = "LETTER_PRINT_APPLY";
        this.SearchModel.approvalStatus = "WAIT_APPROVAL"; //未审核
        this.SearchModel.applyDateStart = this.letterModel.applyDate[0];
        this.SearchModel.applyDateEnd = this.letterModel.applyDate[1];
        this.SearchModel.applyDate = '';
        this.caseApplyManageService.getProcessApproveMessage(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.letterDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(SortService)
    ], LetterPrintPending.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], LetterPrintPending.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], LetterPrintPending.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("refreshList")
    ], LetterPrintPending.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], LetterPrintPending.prototype, "close", null);
    __decorate([
        Prop()
    ], LetterPrintPending.prototype, "letterModel", void 0);
    LetterPrintPending = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                ProcessApprove: ProcessApprove
            }
        })
    ], LetterPrintPending);
    return LetterPrintPending;
}(Vue));
export default LetterPrintPending;
