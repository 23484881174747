var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { CommunicationDataService } from "~/services/repair-service/communication-data.service";
var AddCommunicateData = /** @class */ (function (_super) {
    __extends(AddCommunicateData, _super);
    function AddCommunicateData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kosekiModel = {
            name: '',
            idNo: '',
            bank: '',
            applyTransferArea: '',
            landLinePhone: '',
            type: '',
            mobile: '',
            address: '',
            remark: ''
        };
        _this.loading = {
            state: false
        };
        _this.rulesAdd = {
            name: [
                { required: true, message: '请输入姓名', trigger: 'blur' }
            ],
            idNo: [
                { required: true, message: '请输入证件号', trigger: 'blur' },
            ]
        };
        return _this;
    }
    AddCommunicateData.prototype.success = function () {
        this.close();
    };
    AddCommunicateData.prototype.close = function () {
        this.reset();
    };
    AddCommunicateData.prototype.mounted = function () {
    };
    // 保存户籍信息
    AddCommunicateData.prototype.save = function () {
        var _this = this;
        var addform = this.$refs["add-form"];
        addform.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.communicationDataService
                .insert(_this.kosekiModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('添加成功!');
                _this.success();
            });
        });
    };
    AddCommunicateData.prototype.reset = function () {
        var add = this.$refs['add-form'];
        add.resetFields();
    };
    __decorate([
        Dependencies(CommunicationDataService)
    ], AddCommunicateData.prototype, "communicationDataService", void 0);
    __decorate([
        Emit("success")
    ], AddCommunicateData.prototype, "success", null);
    __decorate([
        Emit("close")
    ], AddCommunicateData.prototype, "close", null);
    AddCommunicateData = __decorate([
        Component({
            components: {}
        })
    ], AddCommunicateData);
    return AddCommunicateData;
}(Vue));
export default AddCommunicateData;
