import { requestType } from "~/config/enum.config";
var SERVICE = "common-service";
var CONTROLLER = "uploadFile";
export default {
    /**
     * 文件上传
     */
    upload: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "upload",
        type: requestType.Post
    },
    /**
     * 按id查询上传文件
     */
    getUploadFileById: {
        service: SERVICE,
        controller: CONTROLLER,
        type: requestType.Post
    },
    /**
     * 通过文件id下载文件
     */
    getFileById: {
        service: SERVICE,
        controller: CONTROLLER,
        type: requestType.Post
    },
    /**
     * 通过文件名下载文件
     */
    getFileByFileName: {
        service: SERVICE,
        controller: CONTROLLER,
        type: requestType.Post
    },
    /**
     * 根据文件id获取文件流
     */
    getFileStreamById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFileStreamById",
        type: requestType.Get
    },
    /**
     * 根据文件名称获取文件
     */
    getFile: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFile",
        type: requestType.Get
    },
    /**
     * 根据文件id获取文件url
     */
    getFileUrlById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getFileUrlById",
        type: requestType.Get
    },
    /**
     * 根据文件ids获取文件信息
     */
    findUploadByFileIds: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findUploadByFileIds",
        type: requestType.Get
    },
    /**
     * 获取私有文件地址
     */
    getOssSignUrl: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getOssSignUrl?url=",
        type: requestType.Post
    }
};
