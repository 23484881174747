/**
 * 编辑类型
 */
var EditType;
(function (EditType) {
    /**
     * 增加
     */
    EditType[EditType["ADD"] = 0] = "ADD";
    /**
     * 编辑
     */
    EditType[EditType["MODIFY"] = 1] = "MODIFY";
    /**
     * 删除
     */
    EditType[EditType["DELETE"] = 2] = "DELETE";
    /**
     * 向上移动
     */
    EditType[EditType["MOVE_UP"] = 3] = "MOVE_UP";
    /**
     * 向下移动
     */
    EditType[EditType["MOVE_DOWN"] = 4] = "MOVE_DOWN";
})(EditType || (EditType = {}));
export { 
/**
 * 操作类型
 */
EditType };
