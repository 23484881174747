var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
import FileUpload from "~/components/common/file-upload.vue";
import { ImpDataToSysDataService } from "~/services/management-service/imp-data-to-sys-data.service";
var ExcelMerge = /** @class */ (function (_super) {
    __extends(ExcelMerge, _super);
    function ExcelMerge() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.dialog = false;
        _this.uploadIndex = -1;
        _this.fileList = [];
        _this.loading = {
            state: false
        };
        return _this;
    }
    ExcelMerge.prototype.emitClose = function () { };
    ExcelMerge.prototype.createNewRow = function () {
        return {
            fileId: "",
            titleStartRow: 0,
            titleStartCol: 0,
            colNo: [],
            fileName: "",
            optionList: []
        };
    };
    ExcelMerge.prototype.addNew = function () {
        this.dataSet.push(this.createNewRow());
    };
    ExcelMerge.prototype.deleteRow = function (index) {
        if (index > -1) {
            this.dataSet.splice(index, 1);
        }
    };
    ExcelMerge.prototype.uploadFile = function (index) {
        if (this.uploadIndex > -1) {
            this.$message("有文件正在处理，请稍后...");
        }
        else {
            this.fileList = [];
            this.uploadIndex = index;
            this.dialog = true;
        }
    };
    ExcelMerge.prototype.onUploadSuccess = function (id, data) {
        var row = this.dataSet[this.uploadIndex];
        row.fileName = data.originalName;
        row.fileId = data.id;
        this.uploadIndex = -1;
        this.dialog = false;
    };
    ExcelMerge.prototype.getFileCols = function (index) {
        var _this = this;
        var row = this.dataSet[index];
        this.impDataToSysDataService.parseExcelCols(row).subscribe(function (data) { return row.optionList = data; }, function (err) { return _this.$message.error(err.msg); });
    };
    // 提交合并请求
    ExcelMerge.prototype.submitClick = function () {
        var _this = this;
        this.checkDataSet().then(function (v) {
            if (!v)
                return;
            var requestData = _this.dataSet.map(function (v, index) {
                return {
                    fileId: v.fileId,
                    seqNo: index,
                    titleStartRow: v.titleStartRow,
                    titleStartCol: v.titleStartCol,
                    relationColNos: v.colNo
                };
            });
            _this.loading.state = true;
            _this.impDataToSysDataService.impDataToSysData(requestData, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功，请关注任务盒子通知");
                _this.dataSet = [];
                _this.emitClose();
            }, function (err) { return _this.$message.error(err.msg); });
        });
    };
    ExcelMerge.prototype.checkDataSet = function () {
        var _this = this;
        var rules = {
            fileId: { required: true, message: "请选择要上传的文件" },
            colNo: { required: true, message: "请选择要关联的列", type: 'array' }
        };
        var validateResults = this.dataSet.map(function (v) { return _this.$validator.validate(v, rules); });
        return Promise.all(validateResults).then(function (v) { return Promise.resolve(true); }).catch(function (reason) {
            var msg = reason.errors[0].message;
            _this.$message(msg);
            Promise.resolve(false);
        });
    };
    __decorate([
        Dependencies(ImpDataToSysDataService)
    ], ExcelMerge.prototype, "impDataToSysDataService", void 0);
    __decorate([
        Emit("close")
    ], ExcelMerge.prototype, "emitClose", null);
    ExcelMerge = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SvgIcon: SvgIcon,
                FileUpload: FileUpload
            }
        })
    ], ExcelMerge);
    return ExcelMerge;
}(Vue));
export default ExcelMerge;
