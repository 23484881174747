var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyManageService } from "~/services/business-service/case-apply-manage.service";
import { Emit, Prop } from "vue-property-decorator";
import { SortService } from "~/utils/sort.service";
import { PageService } from "~/utils/page.service";
import { UserService } from "~/services/manage-service/user.service";
import DataBox from "~/components/common/data-box.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var DerateManagePending = /** @class */ (function (_super) {
    __extends(DerateManagePending, _super);
    function DerateManagePending() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.derateDataSet = [];
        _this.SearchModel = {
            flowType: "DERATE_APPLY",
            approvalStatus: "WAIT_APPROVAL",
            exportState: "WAIT_EXPORT"
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    DerateManagePending.prototype.refreshList = function () { };
    DerateManagePending.prototype.close = function () { };
    DerateManagePending.prototype.approvedMeth = function (rowData) { };
    DerateManagePending.prototype.refreshData = function () {
        var _this = this;
        this.SearchModel = Object.assign(this.SearchModel, this.derateModel);
        this.SearchModel.applyDateStart = this.derateModel.applyDate[0];
        this.SearchModel.applyDateEnd = this.derateModel.applyDate[1];
        this.SearchModel.applyDate = "";
        this.caseApplyManageService
            .getProcessApproveMessage(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.derateDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    DerateManagePending.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.fileId, row.fileName)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    //审批操作
    DerateManagePending.prototype.approvedSupplementMeth = function (scope) {
        var rowData = scope.row;
        this.approvedMeth(rowData);
    };
    __decorate([
        Dependencies(SortService)
    ], DerateManagePending.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], DerateManagePending.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], DerateManagePending.prototype, "userService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], DerateManagePending.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], DerateManagePending.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("refreshList")
    ], DerateManagePending.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], DerateManagePending.prototype, "close", null);
    __decorate([
        Emit("approvedMeth")
    ], DerateManagePending.prototype, "approvedMeth", null);
    __decorate([
        Prop()
    ], DerateManagePending.prototype, "derateModel", void 0);
    DerateManagePending = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], DerateManagePending);
    return DerateManagePending;
}(Vue));
export default DerateManagePending;
