var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var customConfig = require("app-config");
var env = process.env;
var appConfig = {
    name: "调解系统",
    version: "V4.5.0",
    url: {
        server: env.VUE_APP_SERVER,
        attach: env.VUE_APP_ATTACH,
    },
    rabbitmq: {
        socket: env.VUE_APP_RABBITMQ_SOCKET,
        vhost: env.VUE_APP_RABBITMQ_VHOST,
        username: env.VUE_APP_RABBITMQ_USERNAME,
        password: env.VUE_APP_RABBITMQ_PASSWORD,
        exchange: env.VUE_APP_RABBITMQ_EXCHANGE,
    },
    timeout: 30000,
    debug: false,
};
// 如果存在自定义配置则启用自定义配置
if (customConfig && customConfig.enable) {
    appConfig = __assign(__assign({}, appConfig), customConfig.config);
}
export default appConfig;
