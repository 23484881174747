var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import FileUpload from "~/components/common/file-upload.vue";
import { ExportLetterService } from "~/services/management-service/export-letter.service";
var ComplianceLetterConfig = /** @class */ (function (_super) {
    __extends(ComplianceLetterConfig, _super);
    function ComplianceLetterConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.fileList = [];
        _this.exportTypeList = [];
        _this.Model = {
            templateName: "",
            type: "",
            principalId: "",
            principalName: "",
            modelFileId: "",
        };
        _this.rules = {
            principalId: [{ required: true, message: "请选择委托方" }],
            type: [{ required: true, message: "请选择模板类型" }],
            templateName: [{ required: true, message: "请输入模板名称" }]
        };
        return _this;
    }
    ComplianceLetterConfig.prototype.modifyDataChange = function (value) {
        if (!value.id)
            return;
        this.Model = value;
    };
    ComplianceLetterConfig.prototype.close = function () { };
    ComplianceLetterConfig.prototype.submit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return false;
            _this.loading.state = true;
            if (_this.Model.id) {
                _this.exportLetterService.letterTemplateModify(_this.Model, _this.loading).subscribe(function (data) {
                    _this.$message.success("修改成功!");
                    _this.close();
                }, function (err) { });
            }
            else {
                _this.exportLetterService.createLetterTemplate(_this.Model, _this.loading).subscribe(function (data) {
                    _this.$message.success("新增成功!");
                    _this.close();
                }, function (err) { });
            }
        });
    };
    ComplianceLetterConfig.prototype.getFollowConfigItems = function (val) {
        var _this = this;
        // 清空模板选中
        this.Model.type = "";
        this.Model.principalName = this.principalList.find(function (v) { return v.id === _this.Model.principalId; }).principalName;
    };
    ComplianceLetterConfig.prototype.onUploadSuccess = function (id, data) {
        this.Model.modelFileId = data.id;
    };
    ComplianceLetterConfig.prototype.reset = function () {
        delete this.Model.id;
        var form = this.$refs["form"];
        form.resetFields();
        this.Model = {};
        var download = this.$refs["upload_case_import"];
        download.reset();
    };
    __decorate([
        Dependencies(ExportLetterService)
    ], ComplianceLetterConfig.prototype, "exportLetterService", void 0);
    __decorate([
        State
    ], ComplianceLetterConfig.prototype, "principalList", void 0);
    __decorate([
        Prop()
    ], ComplianceLetterConfig.prototype, "modifyData", void 0);
    __decorate([
        Watch("modifyData", { immediate: true })
    ], ComplianceLetterConfig.prototype, "modifyDataChange", null);
    __decorate([
        Emit()
    ], ComplianceLetterConfig.prototype, "close", null);
    ComplianceLetterConfig = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                FileUpload: FileUpload,
            }
        })
    ], ComplianceLetterConfig);
    return ComplianceLetterConfig;
}(Vue));
export default ComplianceLetterConfig;
