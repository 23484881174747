import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'strategyConfigController';
export default {
    /*
     * 新增策略
     */
    addStrategyConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'addStrategyConfig',
        type: requestType.Post
    },
    /*
     * 根据ID获取策略详情
     */
    getStrategyConfigById: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getStrategyConfigById',
        type: requestType.Get
    },
    /*
     * 修改策略
     */
    updateStrategyConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'updateStrategyConfig',
        type: requestType.Post
    },
    /*
     * 查询策略列表
     */
    searchStrategy: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchStrategy',
        type: requestType.Get
    },
    /**
     * 删除策略
     */
    deletetSrategyConfig: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deletetSrategyConfig',
        type: requestType.Delete
    },
    /**
  * 导入策略
  */
    strategyImport: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'strategyImport',
        type: requestType.Post
    },
    /**
    * 查询策略
    */
    searchByPrincipal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchByPrincipal',
        type: requestType.Get
    }
};
