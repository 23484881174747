import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "assistCaseController";
export default {
    /**
     * 协调案件查询
     */
    searchAssistCollectionCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "searchAssistCollectionCase",
        type: requestType.Get,
    },
    /**
     * 外访协调分案预览
     */
    assistCaseDistributePre: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistCaseDistributePre",
        type: requestType.Post,
    },
    /**
     * 外访协调分案确认
     */
    assistConfirmDistributeCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "assistConfirmDistributeCase",
        type: requestType.Post,
    },
    /**
     * 结束协调
     */
    endAssistCaseCollecting: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "endAssistCaseCollecting",
        type: requestType.Put,
    },
};
