import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'importAllocationCasesController';
export default {
    /**
     * 取消导入
     */
    cancelExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'cancelExcelImpCase',
        type: requestType.Delete,
    },
    /**
     * 确认导入
     */
    confirmExcelImpCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'confirmExcelImpCase',
        type: requestType.Put,
    },
    /**
     * 查询导入结果
     */
    getImportDataExcelList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getImportDataExcelList',
        type: requestType.Get,
    },
    /**
     * 案件导入
     */
    importExcelData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'importExcelData',
        type: requestType.Post,
    }
};
