import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'userDevice';
export default {
    /**
     * 删除设备
     */
    deleteDevice: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteDevice",
        type: requestType.Get
    },
    /**
     * 获取所有设备
     */
    getUserDevices: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getUserDevices",
        type: requestType.Get
    },
    /**
     * 启停设备
     */
    updateState: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateState",
        type: requestType.Post
    },
};
