import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'kosekiRemark';
export default {
    /**
     * 查询户籍资料
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Get
    },
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    },
    /**
     * 按导入批次删除户籍资料
     */
    deleteByBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteByBatch',
        type: requestType.Get
    }
};
