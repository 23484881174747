var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Watch, Prop } from "vue-property-decorator";
import { ApprovalExportManageService } from '~/services/dataimp-service/approval-export-manage.service.ts';
var CommonFileUpload = /** @class */ (function (_super) {
    __extends(CommonFileUpload, _super);
    function CommonFileUpload() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.submitLoading = false;
        _this.loading = {
            state: false
        };
        _this.model = {
            fileId: "",
            flowType: ""
        };
        return _this;
    }
    CommonFileUpload.prototype.applyTypeChange = function (val) {
        this.model.flowType = val;
    };
    CommonFileUpload.prototype.onUploadSuccess = function (id, data) {
        this.model.fileId = data.id;
    };
    CommonFileUpload.prototype.submit = function () {
        var _this = this;
        if (this.fileList.length === 0) {
            this.$message("请上传文件");
            return;
        }
        this.submitLoading = true;
        this.loading.state = true;
        this.approvalExportManageService.approvalByImportData(this.model, this.loading).subscribe(function (data) {
            _this.$message.success("导入成功!");
            _this.success();
            _this.submitLoading = false;
        }, function (err) { return (_this.submitLoading = false); });
    };
    CommonFileUpload.prototype.close = function () {
        this.reset();
    };
    CommonFileUpload.prototype.success = function () {
        this.close();
    };
    CommonFileUpload.prototype.reset = function () {
        var fileupload = this.$refs["file-upload"];
        fileupload.reset();
    };
    __decorate([
        Dependencies(ApprovalExportManageService)
    ], CommonFileUpload.prototype, "approvalExportManageService", void 0);
    __decorate([
        Prop({ required: true })
    ], CommonFileUpload.prototype, "flowType", void 0);
    __decorate([
        Watch("flowType", { immediate: true })
    ], CommonFileUpload.prototype, "applyTypeChange", null);
    __decorate([
        Emit()
    ], CommonFileUpload.prototype, "close", null);
    __decorate([
        Emit()
    ], CommonFileUpload.prototype, "success", null);
    CommonFileUpload = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], CommonFileUpload);
    return CommonFileUpload;
}(Vue));
export default CommonFileUpload;
