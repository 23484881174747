var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import AssistCaseAllot from "~/components/case-info/assist-case-allot.vue";
import { State, Getter } from "vuex-class";
import AssistManageAudit from "~/components/assistant-manage/assist-manage/assist-manage-audit.vue";
import AssistDoEnd from "~/components/assistant-manage/assist-manage/assist-do-end.vue";
import AssistManageCollecting from "~/components/assistant-manage/assist-manage/assist-manage-collecting.vue";
import AssistManageFixed from "~/components/assistant-manage/assist-manage/assist-manage-fixed.vue";
import AssistManageArray from "~/components/assistant-manage/assist-manage/assist-manage-array.vue";
import FollowRecord from "~/components/approval-manage/follow-record.vue";
import AssistApproved from "~/components/assistant-manage/assist-manage/assist-approved.vue";
import VisitBatchApproved from "~/components/assistant-manage/visit-manage/visit-batch-approved.vue";
//@Auth(742)
var AccFlowApproval = /** @class */ (function (_super) {
    __extends(AccFlowApproval, _super);
    function AccFlowApproval() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.assistModel = {
            companyCode: "",
            principalName: "",
            personalName: "",
            idCard: "",
            applyRealName: "",
            batchNumber: "",
            assistSearchFlag: "",
            currentCollector: "",
            assistLocalFlag: "OFFSITE_OUT_ASSIST",
            assistFlag: "",
            addressDetail: "",
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "pending-audit";
        _this.approvalRules = {
            result: [{ required: true, message: "请选择审批结果", trigger: "change" }],
        };
        _this.rules = {
            idCard: [{ min: 4, message: "请输入至少4位的身份证号", trigger: "blur" }],
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.userDataSet = [];
        _this.dialog = {
            approvedialog: false,
            assistCaseAllot: false,
            batchApproveDialog: false,
        };
        _this.selectionList = [];
        _this.assistRowData = {};
        return _this;
    }
    Object.defineProperty(AccFlowApproval.prototype, "companyCode", {
        /**
         * 考虑到非管理员不显示公司选择控件
         * get 操作的时候强制设置非管理员所在公司码
         */
        get: function () {
            if (!this.isSupperAdmin) {
                this.assistModel.companyCode = this.userData.companyCode;
            }
            return this.assistModel.companyCode;
        },
        set: function (value) {
            this.assistModel.companyCode = value;
        },
        enumerable: false,
        configurable: true
    });
    AccFlowApproval.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    AccFlowApproval.prototype.activated = function () {
        if (!this.isSupperAdmin) {
            this.refreshData();
        }
    };
    AccFlowApproval.prototype.mounted = function () {
        this.refreshData();
    };
    //切换tab页面刷新对应的页面
    AccFlowApproval.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.resetSearchFelid();
        this.refreshData();
    };
    //刷新数据
    AccFlowApproval.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    AccFlowApproval.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.assistRowData = rowdata;
    };
    AccFlowApproval.prototype.batchApproved = function (value) {
        this.validSectionList(value);
        if (this.selectionList.length === 0)
            return;
        this.dialog.batchApproveDialog = true;
    };
    AccFlowApproval.prototype.validSectionList = function (value) {
        var component = this.$refs[value];
        if (component.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.selectionList = component.selectionList;
    };
    // 外访分配
    AccFlowApproval.prototype.onForeignVisits = function (value) {
        this.validSectionList(value);
        this.dialog.assistCaseAllot = true;
    };
    AccFlowApproval.prototype.onUserCaseAllotOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["assist-case-allot"];
            form.refreshData();
        });
    };
    //reset搜索框条件
    AccFlowApproval.prototype.resetSearchFelid = function () {
        this.assistModel = {
            companyCode: "",
            principalName: "",
            personalName: "",
            idCard: "",
            applyRealName: "",
            batchNumber: "",
            assistSearchFlag: "",
            currentCollector: "",
            assistLocalFlag: "OFFSITE_OUT_ASSIST",
            assistFlag: "",
            addressDetail: "",
        };
    };
    __decorate([
        Dependencies(PageService)
    ], AccFlowApproval.prototype, "pageService", void 0);
    __decorate([
        State
    ], AccFlowApproval.prototype, "userData", void 0);
    __decorate([
        State
    ], AccFlowApproval.prototype, "principalList", void 0);
    __decorate([
        Getter
    ], AccFlowApproval.prototype, "isSupperAdmin", void 0);
    AccFlowApproval = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowRecord: FollowRecord,
                DataForm: DataForm,
                DataBox: DataBox,
                AssistCaseAllot: AssistCaseAllot,
                AssistManageAudit: AssistManageAudit,
                AssistManageFixed: AssistManageFixed,
                AssistApproved: AssistApproved,
                AssistManageCollecting: AssistManageCollecting,
                AssistManageArray: AssistManageArray,
                AssistDoEnd: AssistDoEnd,
                VisitBatchApproved: VisitBatchApproved,
            },
        })
    ], AccFlowApproval);
    return AccFlowApproval;
}(Vue));
export default AccFlowApproval;
