import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'userExportController';
export default {
    /**
     * 导入人员花名册
     */
    exportUserList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'exportUserList',
        type: requestType.Post
    },
    /**
     * 下载导入花名册模板
     */
    downloadUserTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'downloadUserTemplate',
        type: requestType.Get
    },
    /**
     * 导入花名册
     */
    uploadUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'uploadUser',
        type: requestType.Get
    }
};
