var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
var ImportCase = /** @class */ (function (_super) {
    __extends(ImportCase, _super);
    function ImportCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.importModel = {
            commissionDate: '',
            endDate: '',
            fileId: '',
            principalId: '',
            templateId: '' //模板ID
        };
        _this.importRules = {
            principalId: [
                { required: true, message: '请选择委托方', trigger: 'blur' }
            ],
            templateId: [
                { required: true, message: '请选择导入模板', trigger: 'blur' }
            ],
            commissionDate: [{ required: true, message: '请选择委案日期', trigger: 'blur' }],
            endDate: [{ required: true, message: '请选择结案日期', trigger: 'blur' }]
        };
        _this.fileList = [];
        _this.templateList = []; //模板列表
        return _this;
    }
    ImportCase.prototype.close = function () { };
    ImportCase.prototype.success = function () { };
    /**
     * 表单重置
     */
    ImportCase.prototype.reset = function () {
        this.templateList = [];
        var form = this.$refs['form'];
        var upload = this.$refs['upload_case_import'];
        form.resetFields();
        upload.reset();
    };
    /**
     * 取消
     */
    ImportCase.prototype.cancel = function () {
        this.close();
    };
    /**
     * 表单提交
     */
    ImportCase.prototype.submit = function () {
        var _this = this;
        if (this.fileList.length === 0) {
            this.$message('请上传导入文件');
            return;
        }
        // 文件ID
        this.importModel.fileId = this.fileList[0].response.id;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.dataImportService.importCase(_this.importModel, _this.loading).subscribe(function (data) {
                _this.$message.success('案件导入成功');
                _this.success();
            }, function (err) {
                // this.$message.error(err.message);
            });
        });
    };
    /**
     * 委托方ID变化
     */
    ImportCase.prototype.principalIdChange = function (val) {
        var _this = this;
        //根据委托方ID查找委托方Obj并且获取模板名称
        var principalObj = this.principalList.find(function (v) { return v.id === _this.importModel.principalId; });
        //重置模板列表并获取选取委托方对应的模板列表
        this.templateList = [];
        this.importModel.templateId = '';
        var excelModel = {
            principalId: this.importModel.principalId,
            type: 'CASE_IMPORT'
        };
        this.loading.state = true;
        this.importTemplateService.getTemplateByPrincipal(excelModel, this.loading).subscribe(function (data) { return _this.templateList = data; });
    };
    /**
     * 退案日期加三个月
     */
    ImportCase.prototype.endCaseDateChange = function () {
        if (this.importModel.commissionDate && (this.importModel.commissionDate instanceof Date)) {
            var commissionDate = new Date(this.importModel.commissionDate.getTime());
            this.importModel.endDate = new Date(commissionDate.setMonth(commissionDate.getMonth() + 3));
        }
    };
    __decorate([
        Dependencies(DataImportService)
    ], ImportCase.prototype, "dataImportService", void 0);
    __decorate([
        Dependencies(ImportTemplateService)
    ], ImportCase.prototype, "importTemplateService", void 0);
    __decorate([
        Emit('close')
    ], ImportCase.prototype, "close", null);
    __decorate([
        Emit('success')
    ], ImportCase.prototype, "success", null);
    __decorate([
        State
    ], ImportCase.prototype, "principalList", void 0);
    ImportCase = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], ImportCase);
    return ImportCase;
}(Vue));
export default ImportCase;
