import { requestType } from "~/config/enum.config";
// 行调业绩表相关
var SERVICE = "business-service";
var CONTROLLER = "gradesProductController";
export default {
    /**
     * 行调业绩列表
     */
    findProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesProduct",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    getProductByDeptBatchMonth: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getProductByDeptBatchMonth",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    findGradesProductTotal: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesProductTotal",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    listProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesProductList",
        type: requestType.Post,
    },
    /**
     * 导出行调业绩列表
     */
    exportProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportGradesProduct",
        type: requestType.Post,
    },
    /**
     * 删除行调业绩列表
     */
    delProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "delGradesProduct",
        type: requestType.Post,
    },
    /**
     * 更新行调业绩列表
     */
    updateProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateGradesProduct",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    addProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addGradesProduct",
        type: requestType.Post,
    },
};
