var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
var collectionManageModule = namespace("collection-manage");
var FollowRecordForeign = /** @class */ (function (_super) {
    __extends(FollowRecordForeign, _super);
    function FollowRecordForeign() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.certificates = [];
        _this.videos = [];
        _this.records = [];
        _this.contactResults = [];
        _this.contactResultOption = [];
        _this.saveModel = {
            caseId: "",
            content: "",
            name: "",
            relation: "",
            informationUpdate: 'NO',
            contactResult: "",
            contactResultCode: "",
            type: "ADDR",
            addrStatus: "",
            addrType: "",
            detail: "",
            certificate: [],
            video: [],
            record: [],
            outCollector: "",
            promiseAmt: "",
            promiseDate: "",
            collectionWay: 1,
            payDate: '',
            payAmount: '',
            files: [],
            caseType: ""
        };
        _this.dataSet = [
            {
                level: "提示",
                sensitiveWord: ""
            },
            {
                level: "严重",
                sensitiveWord: ""
            }
        ];
        _this.dialog = {
            sensitiveWord: false
        };
        _this.rules = {
            outCollector: [{ required: true, message: "请输入外访人员", trigger: "blur" }],
            targetName: [{ required: true, message: "请输入外访对象", trigger: "blur" }],
            detail: [
                { required: true, message: "请输入详细地址", trigger: "blur" }
            ],
            promiseAmt: [{ required: true, message: "请输入承诺还款金额", trigger: "blur" }],
            promiseDate: [{ required: true, message: "请选择承诺还款日期", trigger: "blur" }],
        };
        _this.relations = [];
        _this.addressStatus = [];
        _this.addressType = [];
        _this.loading = {
            state: false
        };
        /**
         * 日期选择范围仅限今天及之后
         */
        _this.pickerOptions = {
            disabledDate: function (time) {
                return time.getTime() < Date.now() - 8.64e7;
            }
        };
        return _this;
    }
    FollowRecordForeign.prototype.mounted = function () {
        this.refreshData();
    };
    FollowRecordForeign.prototype.refreshData = function () {
        this.getRelationShip();
        this.getAddrressStatus();
        this.getAddrressType();
        this.getContactResult();
    };
    /**
   * 获取关系
   */
    FollowRecordForeign.prototype.getRelationShip = function () {
        var _this = this;
        if (!this.principalId)
            return;
        this.contactConfigService.getRelationShip(this.principalId).subscribe(function (data) {
            _this.relations = data;
        });
    };
    /**
    * 获取地址状态
    */
    FollowRecordForeign.prototype.getAddrressStatus = function () {
        var _this = this;
        if (!this.principalId)
            return;
        this.contactConfigService.getAddressStatus(this.principalId).subscribe(function (data) {
            _this.addressStatus = data;
        });
    };
    /**
    * 获取地址类型
    */
    FollowRecordForeign.prototype.getContactResult = function () {
        var _this = this;
        if (!this.principalId)
            return;
        this.contactConfigService.getContactResult(this.principalId).subscribe(function (data) {
            _this.contactResultOption = data;
        });
    };
    /**
    * 获取地址类型
    */
    FollowRecordForeign.prototype.getAddrressType = function () {
        var _this = this;
        if (!this.principalId)
            return;
        this.contactConfigService.getAddressType(this.principalId).subscribe(function (data) {
            _this.addressType = data;
        });
    };
    FollowRecordForeign.prototype.onChange = function () {
        if (!this.saveModel.target)
            return;
        if (this.saveModel.target == '本人') {
            this.saveModel.targetName = this.currentCase.personalName;
        }
    };
    FollowRecordForeign.prototype.refresh = function () {
        this.reset();
    };
    FollowRecordForeign.prototype.setFile = function () {
        if (this.certificates) {
            this.saveModel.certificate = this.certificates.map(function (data) {
                return data.response;
            });
        }
        if (this.videos) {
            this.saveModel.video = this.videos.map(function (data) {
                return data.response;
            });
        }
        if (this.records) {
            this.saveModel.record = this.records.map(function (data) {
                return data.response;
            });
        }
    };
    FollowRecordForeign.prototype.saveRecord = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            var params = {
                principalId: _this.principalId,
                str: Object.values(_this.saveModel).map(function (v) { return v; }).join("&")
            };
            _this.loading.state = true;
            _this.caseFollowRecordService
                .checkString(params, _this.loading)
                .subscribe(function (data) {
                if (data) {
                    if (data.prompt.length || data.serious.length) {
                        _this.dialog.sensitiveWord = true;
                        var a = ""; // 敏感词提示
                        var b = ""; // 敏感词严重
                        if (data.prompt.length > 0) {
                            for (var _i = 0, _a = data.prompt; _i < _a.length; _i++) {
                                var i = _a[_i];
                                a += i;
                            }
                        }
                        if (data.serious.length > 0) {
                            for (var _b = 0, _c = data.serious; _b < _c.length; _b++) {
                                var i = _c[_b];
                                b += i;
                            }
                        }
                        _this.dataSet[0].sensitiveWord = a;
                        _this.dataSet[1].sensitiveWord = b;
                    }
                    else {
                        _this.conserve();
                    }
                }
                else {
                    _this.conserve();
                }
            });
        });
    };
    FollowRecordForeign.prototype.conserve = function () {
        var _this = this;
        var form = this.$refs["form"];
        if (form) {
            form.validate(function (valid) {
                if (!valid) {
                    return;
                }
                _this.saveModel.caseId = _this.caseId;
                _this.saveModel.caseType = _this.otherData.caseType;
                _this.setFile(); //获取上传文件
                _this.loading.state = true;
                _this.caseFollowRecordService
                    .addCaseFollowRecord(_this.saveModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("添加外访记录成功");
                    _this.dialog.sensitiveWord = false;
                    _this.reset();
                    _this.updateRecordCount();
                });
            });
        }
    };
    FollowRecordForeign.prototype.getContactResultIds = function (id) {
        var contactResult = this.contactResultOption.find(function (x) { return x.id === id; });
        if (contactResult) {
            this.contactResults.unshift(id);
            if (contactResult.pid) {
                this.getContactResultIds(contactResult.pid);
            }
        }
    };
    /**
     * 机构改变
     */
    FollowRecordForeign.prototype.orgChange = function (valueArray) {
        if (this.contactResults.length < 1) {
            return;
        }
        this.saveModel.contactResult = valueArray[valueArray.length - 1];
    };
    /**
   * 表单重置
   */
    FollowRecordForeign.prototype.reset = function () {
        var form = this.$refs['form'];
        form.resetFields();
        this.certificates = [];
        this.videos = [];
        this.records = [];
        this.contactResults = [];
        this.saveModel.certificate = [];
        this.saveModel.video = [];
        this.saveModel.record = [];
    };
    __decorate([
        Dependencies(CaseFollowService)
    ], FollowRecordForeign.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(ContactConfigService)
    ], FollowRecordForeign.prototype, "contactConfigService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowRecordForeign.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordForeign.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordForeign.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.Getter
    ], FollowRecordForeign.prototype, "personalInfo", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordForeign.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], FollowRecordForeign.prototype, "updateRecordCount", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordForeign.prototype, "otherData", void 0);
    FollowRecordForeign = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
                SvgIcon: SvgIcon,
                DataBox: DataBox
            }
        })
    ], FollowRecordForeign);
    return FollowRecordForeign;
}(Vue));
export default FollowRecordForeign;
