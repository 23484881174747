var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { CaseApplyOperateService } from '~/services/domain-service/case-apply-operate.service';
import FileUpload from '~/components/common/file-upload.vue';
var AssistApproved = /** @class */ (function (_super) {
    __extends(AssistApproved, _super);
    function AssistApproved() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.assistModel = {
            approveMemo: '',
            approveResult: 0,
            reason: '',
            applyRemark: '',
        };
        _this.submitLoading = false;
        _this.rules = {
            approveMemo: [
                {
                    required: true,
                    message: '请输入审批意见',
                    trigger: 'blur',
                },
            ],
            approveResult: [
                {
                    required: true,
                    message: '请选择审批结果',
                    trigger: 'blur',
                },
            ],
        };
        return _this;
    }
    AssistApproved.prototype.refreshList = function () { };
    AssistApproved.prototype.close = function () { };
    /**
     * 重置
     */
    AssistApproved.prototype.reset = function () {
        var supplementForm = this.$refs['assist-form'];
        supplementForm.resetFields();
    };
    /**
     * 取消
     */
    AssistApproved.prototype.cancel = function () {
        //   关闭新增版本dialog
        this.$emit('close');
        var supplementForm = this.$refs['assist-form'];
        supplementForm.resetFields();
    };
    AssistApproved.prototype.confirmApproved = function () {
        var _this = this;
        var assistForm = this.$refs['assist-form'];
        assistForm.validate(function (val) {
            if (!val)
                return;
            var idList = [];
            if (_this.rowData.id) {
                idList = idList.concat(_this.rowData.id);
            }
            else {
                idList = _this.rowData.map(function (v) { return v.id; });
            }
            var approvedModel = {
                idList: idList,
                approveResult: _this.assistModel.approveResult === 0
                    ? 'APPROVED_PASS'
                    : 'APPROVED_REJECT',
                approveMemo: _this.assistModel.approveMemo,
                applyRemark: _this.rowData.applyRemark,
            };
            _this.submitLoading = true;
            _this.loading.state = true;
            _this.caseApplyOperateService.assistApplyApprove(approvedModel, _this.loading).subscribe(function (data) {
                _this.$message.success('审批成功!');
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], AssistApproved.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Emit('refreshList')
    ], AssistApproved.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], AssistApproved.prototype, "close", null);
    __decorate([
        Prop()
    ], AssistApproved.prototype, "rowData", void 0);
    AssistApproved = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], AssistApproved);
    return AssistApproved;
}(Vue));
export default AssistApproved;
