var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Dependencies } from "~/core/decorator";
import { HomeNoticeService } from "~/services/dataimp-service/home-notice.service";
import AddRemark from "~/components/dashboard/announcement-page/add-remark.vue";
var VueCalendar = /** @class */ (function (_super) {
    __extends(VueCalendar, _super);
    function VueCalendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isAdd = true;
        _this.config = {
            // 默认渲染月
            defaultView: 'dayGridMonth',
            // 中文
            locale: 'zh-cn',
            calendarPlugins: ['dayGridPlugin'],
            // 更多
            eventLimit: true,
            views: {
                timeGrid: {
                    eventLimit: 3
                }
            },
            eventLimitText: '更多',
            indexNum: 0,
            // 右边自定义按钮
            header: {
                right: 'myCustomButton',
                left: 'prev,next',
                center: 'title',
            },
            customButtons: {
                myCustomButton: {
                    text: '新增备注',
                    click: function () {
                        _this.addRemarkClick();
                    }
                },
                prev: {
                    click: function () {
                        var dom = _this.$refs['ccc'];
                        var api = dom.getApi();
                        api.prev();
                        _this.config.indexNum = _this.config.indexNum - 1;
                        _this.refeshData();
                    }
                },
                next: {
                    click: function () {
                        var dom = _this.$refs['ccc'];
                        var api = dom.getApi();
                        api.next();
                        _this.config.indexNum = _this.config.indexNum + 1;
                        _this.refeshData();
                    }
                }
            },
            // 默认星期日开始
            firstDay: 0,
            // 禁止拖动
            editable: false,
        };
        _this.calendarPlugins = [dayGridPlugin];
        _this.addRemark = false;
        _this.events = [];
        return _this;
    }
    VueCalendar.prototype.created = function () {
        this.refeshData();
    };
    VueCalendar.prototype.refeshData = function () {
        var _this = this;
        this.homeNoticeService.getComment(this.config.indexNum).subscribe(function (data) {
            _this.events = [];
            data.map(function (v) {
                v.responses.map(function (i) {
                    _this.events.push({
                        title: i.commentContent,
                        start: _this.$filter.dateFormat(i.reminderTime),
                        id: i.id,
                        classNames: ['current-popup']
                    });
                });
            });
        });
    };
    /**
     * 添加备忘录
     */
    VueCalendar.prototype.addRemarkClick = function () {
        var _this = this;
        this.addRemark = true;
        this.isAdd = true;
        this.$nextTick(function () {
            var add = _this.$refs['add-remark'];
            add.model.id = '';
            add.model.commentContent = '';
            add.model.reminderTime = '';
        });
    };
    VueCalendar.prototype.eventClick = function (_a) {
        var _this = this;
        var event = _a.event;
        this.addRemark = true;
        this.isAdd = false;
        this.$nextTick(function () {
            var add = _this.$refs['add-remark'];
            add.model.commentContent = event.title;
            add.model.reminderTime = new Date(event.start);
            add.model.id = event.id;
        });
    };
    VueCalendar.prototype.eventMouseEnter = function (event, el, jsEvent, view) {
        var _this = this;
        var elem = event.el;
        var div = elem;
        var popup = document.createElement("div");
        popup.classList.add("popup");
        div.appendChild(popup);
        popup.addEventListener("click", function (e) {
            e.stopPropagation();
            _this.popupClick(event.event, e);
        });
    };
    VueCalendar.prototype.popupClick = function (event, e) {
        var _this = this;
        e.stopPropagation();
        this.$confirm("确定删除该备忘录吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.deleteRemark(event.id);
        })
            .catch(function () { });
    };
    VueCalendar.prototype.deleteRemark = function (id) {
        var _this = this;
        this.homeNoticeService.deleteRemark(id).subscribe(function (data) {
            _this.$message.success("删除成功！");
            _this.refeshData();
        });
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], VueCalendar.prototype, "homeNoticeService", void 0);
    VueCalendar = __decorate([
        Component({
            components: {
                FullCalendar: FullCalendar,
                AddRemark: AddRemark
            }
        })
    ], VueCalendar);
    return VueCalendar;
}(Vue));
export default VueCalendar;
