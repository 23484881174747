var TemplateConfig = function () { return import('~/pages/system-manage/template-manage/template-config.vue'); };
var LetterTemplate = function () { return import('~/pages/system-manage/template-manage/letter-template.vue'); };
export default [
    {
        path: '/template-manage/template-config',
        name: 'template-config',
        component: TemplateConfig
    }, {
        path: '/template-manage/letter-template',
        name: 'letter-template',
        component: LetterTemplate
    }
];
