var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DelCaseBatchNumber from "~/components/case-manage/case-adjustment/del-case-batch-number.vue";
import { HistoryCasesService } from "~/services/business-service/history-cases.service";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import HistoryWorkOrder from "~/components/case-manage/case-all-manage/history-work-order.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import RetireCase from "~/components/case-manage/case-all-manage/retire-case.vue";
var ReturnCasePool = /** @class */ (function (_super) {
    __extends(ReturnCasePool, _super);
    function ReturnCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.searchModel = {
            principalId: '',
            personalName: "",
            certificateNo: "",
            batchNumber: "",
            caseNumber: "",
            payStatus: "",
            city: "",
            caseType: '',
            delegationDate: [],
            endCaseDate: [],
            stage: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            } //逾期天数
        };
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.dialog = {
            workerOrder: false,
            batchNumberDel: false,
        };
        _this.currentDate = {};
        _this.caseIds = [];
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
        };
        return _this;
    }
    ReturnCasePool.prototype.mounted = function () {
        this.refreshData();
    };
    ReturnCasePool.prototype.refreshData = function () {
        var _this = this;
        this.historyCasesService.queryReturnCases(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) { _this.dataSet = data; });
    };
    ReturnCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.payStatus = '';
        this.searchModel.leftAmt = {};
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    /**
     * 删除案件
     */
    ReturnCasePool.prototype.delCase = function () {
        var _this = this;
        if (this.selectionList.length) {
            var personalIds_1 = this.selectionList.map(function (v) { return v.id; });
            this.$confirm("确定删除已选择的案件吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                _this.loading.state = true;
                _this.historyCasesService.deleteCases(personalIds_1, '').subscribe(function (data) {
                    _this.$message.success('操作成功！');
                    _this.refreshData();
                }, function (_a) {
                    var msg = _a.msg;
                });
            })
                .catch(function () { });
        }
        else {
            this.$message("请选择要删除的案件！");
            return;
        }
    };
    ReturnCasePool.prototype.onBatchNumberDelOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["del-case-batch-number"];
            form.refreshData();
        });
    };
    ReturnCasePool.prototype.appendWorkorder = function (row) {
        this.dialog.workerOrder = true;
        this.currentDate = row;
    };
    /**
     * 退案恢复
     */
    ReturnCasePool.prototype.regressCase = function () {
        if (!this.selectionList.length) {
            this.$message('请选择要恢复的案件！');
            return;
        }
        this.caseIds = this.selectionList.map(function (v) { return v.id; });
        this.dialog.regresscase = true;
    };
    __decorate([
        Dependencies(PageService)
    ], ReturnCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], ReturnCasePool.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], ReturnCasePool.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(HistoryCasesService)
    ], ReturnCasePool.prototype, "historyCasesService", void 0);
    ReturnCasePool = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
                DelCaseBatchNumber: DelCaseBatchNumber,
                HistoryWorkOrder: HistoryWorkOrder,
                RetireCase: RetireCase
            }
        })
    ], ReturnCasePool);
    return ReturnCasePool;
}(Vue));
export default ReturnCasePool;
