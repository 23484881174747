import { requestType } from '~/config/enum.config';
var SERVICE = 'report-service';
var CONTROLLER = 'caseInfoDistributeController';
export default {
    /**
     * 案件导入待分配
     */
    findCaseInfoDistribute: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'findCaseInfoDistribute',
        type: requestType.Post
    },
    get_case_info_distributed_details: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCaseInfoDistributedDetails',
        type: requestType.Get
    }
};
