import { requestType } from '~/config/enum.config';
var SERVICE = 'dataimp-service';
var CONTROLLER = 'importExcelCaseController';
export default {
    /**
     * 获取案件信息内置字段
     */
    getCaseInfoProperty: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCaseInfoProperty',
        type: requestType.Get
    },
    /**
     * 案件导入
     */
    importExcelData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'importExcelData',
        type: requestType.Post
    },
    /**
     * 通过批次号删除
     */
    deleteCaseInfoByBatchNumber: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteCaseInfoByBatchNumber',
        type: requestType.Delete
    },
    /**
     * 获取默认导入模板
     */
    getSystemTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getSystemTemplate',
        type: requestType.Get
    },
};
