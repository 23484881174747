var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { CaseInfoService } from "~/services/business-service/case-info.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import DataForm from "~/components/common/data-form.vue";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import DebtFollowRecords from "./co-debt-query-components/debt-follow-records.vue";
import DebtCardInfo from "./co-debt-query-components/debt-card-info.vue";
import DebtBankReconciliation from "./co-debt-query-components/debt-bank-reconciliation.vue";
import { PaymentRecordService } from "~/services/business-service/payment-record.service";
var collectionManageModule = namespace("collection-manage");
var CoDebtQuery = /** @class */ (function (_super) {
    __extends(CoDebtQuery, _super);
    function CoDebtQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.queryParamsModel = {
            certificateNo: "",
            type: "INPOOLCASE",
            batchNumber: "",
            batchList: "",
            caseId: ""
        };
        _this.currentAccount = "";
        _this.currentCard = "";
        _this.tabActiveName = "2";
        _this.batchList = [];
        _this.followRecord = [];
        _this.paymentRecord = [];
        _this.currentMessage = {};
        _this.currentBatchNumber = [];
        _this.accountMap = new Map();
        _this.cards = [];
        _this.accounts = [];
        return _this;
    }
    /**
     * 查询类型变动
     */
    CoDebtQuery.prototype.onTypeChange = function (type) {
        var _this = this;
        this.queryParamsModel.batchNumber = "";
        this.queryParamsModel.batchList = "";
        this.currentMessage = {};
        this.accounts = [];
        this.cards = [];
        this.currentBatchNumber = [];
        this.batchList = [];
        this.currentAccount = "";
        this.currentCard = "";
        (this.queryParamsModel.certificateNo = this.currentCase.certificateNo),
            (this.queryParamsModel.type = type);
        this.queryParamsModel.caseId = this.currentCase.caseId;
        this.caseInfoService
            .searchDebtBatch(this.queryParamsModel)
            .subscribe(function (data) {
            _this.batchList = data.deptInfo;
        });
        this.caseFollowRecordService
            .getDebtFollowRecord(this.queryParamsModel)
            .subscribe(function (data) {
            _this.followRecord = data;
        });
        this.paymentRecordService
            .caseBillRecord(this.queryParamsModel)
            .subscribe(function (data) {
            _this.paymentRecord = data;
        });
    };
    CoDebtQuery.prototype.close = function () {
        this.currentMessage = {};
        this.currentBatchNumber = [];
        this.followRecord = [];
        this.batchList = [];
        this.currentCard = "";
        this.accounts = [];
        this.paymentRecord = [];
        this.cards = [];
        this.currentAccount = "";
        this.queryParamsModel.batchNumber = "";
        this.queryParamsModel.batchList = "";
        this.queryParamsModel.certificateNo = "";
        this.queryParamsModel.type = "INPOOLCASE";
        this.currentCase.caseId = "";
    };
    CoDebtQuery.prototype.accountChange = function (account) {
        this.currentCard = "";
        this.currentMessage = {};
        if (!account)
            return;
        this.cards = this.accountMap.get(account);
    };
    CoDebtQuery.prototype.cardChange = function (card) {
        this.currentMessage = this.cards.filter(function (x) { return x.cardNo === card; })[0];
    };
    /**
     * 查询批次信息
     */
    CoDebtQuery.prototype.queryBatchNumberInfos = function (principalName) {
        this.queryParamsModel.batchNumber = "";
        this.accountMap.clear();
        this.currentAccount = "";
        this.currentCard = "";
        this.accounts = [];
        this.cards = [];
        this.currentMessage = {};
        if (!principalName)
            return;
        this.currentBatchNumber = this.batchList.find(function (x) { return x.principalName == principalName; }).batchNumber;
    };
    /**
     * 查询账户信息
     */
    CoDebtQuery.prototype.queryAccountInfos = function (batch) {
        var _this = this;
        this.currentMessage = {};
        this.currentAccount = "";
        this.accounts = [];
        this.cards = [];
        this.currentCard = "";
        if (!batch)
            return;
        (this.queryParamsModel.certificateNo = this.currentCase.certificateNo),
            this.caseInfoService
                .searchDebtCardInfo(this.queryParamsModel)
                .subscribe(function (data) {
                if (data) {
                    data.forEach(function (x) {
                        if (!_this.accountMap.has(x.account)) {
                            if (x.account !== _this.currentCase.account &&
                                _this.queryParamsModel.type === "INPOOLCASE") {
                                _this.accountMap.set(x.account, [x]);
                            }
                            if (_this.queryParamsModel.type === "HISCASE") {
                                _this.accountMap.set(x.account, [x]);
                            }
                        }
                        else {
                            x.account, _this.accountMap.get(x.account).push(x);
                        }
                    });
                }
                _this.accounts = Array.from(_this.accountMap.keys());
            });
    };
    __decorate([
        Dependencies(CaseInfoService)
    ], CoDebtQuery.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(PaymentRecordService)
    ], CoDebtQuery.prototype, "paymentRecordService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], CoDebtQuery.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], CoDebtQuery.prototype, "currentCase", void 0);
    __decorate([
        Emit("close")
    ], CoDebtQuery.prototype, "close", null);
    CoDebtQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DebtFollowRecords: DebtFollowRecords,
                DebtCardInfo: DebtCardInfo,
                DebtBankReconciliation: DebtBankReconciliation
            }
        })
    ], CoDebtQuery);
    return CoDebtQuery;
}(Vue));
export default CoDebtQuery;
