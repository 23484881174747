var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { components, hotKeysConfig } from "./collection-hotkey/";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Prop, Watch } from "vue-property-decorator";
var collectionManageModule = namespace("collection-manage");
var CollectionHotkey = /** @class */ (function (_super) {
    __extends(CollectionHotkey, _super);
    function CollectionHotkey() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.config = hotKeysConfig;
        _this.currentComponent = {};
        _this.dialog = false;
        _this.hidden = true;
        _this.hover = false;
        return _this;
    }
    CollectionHotkey.prototype.isApperChange = function (val) {
        this.isApper = val;
    };
    CollectionHotkey.prototype.currentComponentClick = function (item) {
        this.currentComponent = item;
    };
    Object.defineProperty(CollectionHotkey.prototype, "personalId", {
        get: function () {
            return this.currentCase.personalId;
        },
        enumerable: false,
        configurable: true
    });
    CollectionHotkey.prototype.open = function () {
        var _this = this;
        if (this.currentComponent.noRefresh)
            return;
        this.$nextTick(function () {
            var componentCase = _this.$refs[_this.currentComponent.component];
            componentCase.refreshData();
        });
    };
    __decorate([
        collectionManageModule.State
    ], CollectionHotkey.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], CollectionHotkey.prototype, "currentCase", void 0);
    __decorate([
        Prop()
    ], CollectionHotkey.prototype, "isHistory", void 0);
    __decorate([
        Prop()
    ], CollectionHotkey.prototype, "isApper", void 0);
    __decorate([
        Watch('isApper')
    ], CollectionHotkey.prototype, "isApperChange", null);
    CollectionHotkey = __decorate([
        Component({
            components: __assign(__assign({}, components), { SvgIcon: SvgIcon })
        })
    ], CollectionHotkey);
    return CollectionHotkey;
}(Vue));
export default CollectionHotkey;
