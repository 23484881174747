var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import FollowRecord from "~/components/approval-manage/follow-record.vue";
import LeaveCaseManagePending from "~/components/assistant-manage/leave-case-manage/leave-case-manage-pending.vue";
import LeaveCaseManageResolve from "~/components/assistant-manage/leave-case-manage/leave-case-manage-resolve.vue";
import LeaveCaseManageHandled from "~/components/assistant-manage/leave-case-manage/leave-case-manage-handled.vue";
import ProcessApprove from "~/components/assistant-manage/process-approve.vue";
import { ApprovalExportManageService } from "~/services/dataimp-service/approval-export-manage.service.ts";
import CommonFileUpload from "~/components/assistant-manage/common-file-upload.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var LeaveCaseApply = /** @class */ (function (_super) {
    __extends(LeaveCaseApply, _super);
    function LeaveCaseApply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rowData = {};
        _this.leaveModel = {
            principalName: "",
            applyRealName: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            applyDate: [],
            caseNumber: "",
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "pending-operate";
        _this.approvalRules = {
            result: [
                {
                    required: true,
                    message: "请选择审批结果",
                    trigger: "change",
                },
            ],
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.userDataSet = [];
        _this.dialog = {
            approvedialog: false,
            caseImport: false,
        };
        _this.loading = {
            state: false,
        };
        _this.rules = {
            certificateNo: [
                {
                    min: 4,
                    message: "请输入至少4位的身份证号",
                    trigger: "blur",
                },
            ],
        };
        return _this;
    }
    LeaveCaseApply.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    LeaveCaseApply.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 切换tab页面刷新对应的页面
     */
    LeaveCaseApply.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.restSearchFelid();
        this.refreshData();
    };
    /**
     * 刷新数据
     */
    LeaveCaseApply.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    /**
     * 弹出框
     */
    LeaveCaseApply.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.rowData = rowdata;
    };
    /**
     * reset搜索框
     */
    LeaveCaseApply.prototype.restSearchFelid = function () {
        this.leaveModel = {
            principalName: "",
            applyRealName: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            applyDate: [],
        };
    };
    LeaveCaseApply.prototype.handleResetForm = function () {
        this.leaveModel.principalId = "";
    };
    LeaveCaseApply.prototype.exportCase = function () {
        var _this = this;
        var pendingOperate = this.$refs["pending-operate"];
        if (pendingOperate.selectionList.length == 0) {
            this.$message("请选择要导出的案件!");
            return;
        }
        var processApplicationIds = pendingOperate.selectionList.map(function (v) { return v.processApplicationId; });
        var exportModel = {
            flowType: "LEAVE_CASE_APPLY",
            processApplicationIds: processApplicationIds,
        };
        this.$confirm("您确定要导出所选案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.approvalExportManageService.approvalMessageExport(exportModel, _this.loading).subscribe(function (data) {
                _this.$message.success("导出成功"), _this.refreshData();
            }, function (err) { });
        })
            .catch(function () { });
    };
    /**
     * 案件导入
     */
    LeaveCaseApply.prototype.importCase = function () {
        this.dialog.caseImport = true;
    };
    __decorate([
        Dependencies(PageService)
    ], LeaveCaseApply.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ApprovalExportManageService)
    ], LeaveCaseApply.prototype, "approvalExportManageService", void 0);
    LeaveCaseApply = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowRecord: FollowRecord,
                DataForm: DataForm,
                DataBox: DataBox,
                PrincipalBatch: PrincipalBatch,
                NumberRange: NumberRange,
                LeaveCaseManagePending: LeaveCaseManagePending,
                LeaveCaseManageResolve: LeaveCaseManageResolve,
                LeaveCaseManageHandled: LeaveCaseManageHandled,
                ProcessApprove: ProcessApprove,
                CommonFileUpload: CommonFileUpload,
            },
        })
    ], LeaveCaseApply);
    return LeaveCaseApply;
}(Vue));
export default LeaveCaseApply;
