var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { RelationshipService } from "~/services/repair-service/relationship.service";
import SocialDetail from "./social-query-base/social-detail.vue";
var SocialQuery = /** @class */ (function (_super) {
    __extends(SocialQuery, _super);
    function SocialQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.dialog = {
            socialQuery: false
        };
        return _this;
    }
    SocialQuery.prototype.onIdCardNoChange = function (val) {
        val && this.refreshData();
    };
    SocialQuery.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.relationshipService
            .search({ idNo: this.idCardNo }, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    SocialQuery.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], SocialQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SocialQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(RelationshipService)
    ], SocialQuery.prototype, "relationshipService", void 0);
    __decorate([
        Prop()
    ], SocialQuery.prototype, "isHistory", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], SocialQuery.prototype, "idCardNo", void 0);
    __decorate([
        Watch("idCardNo", { immediate: true })
    ], SocialQuery.prototype, "onIdCardNoChange", null);
    SocialQuery = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SocialDetail: SocialDetail
            }
        })
    ], SocialQuery);
    return SocialQuery;
}(Vue));
export default SocialQuery;
