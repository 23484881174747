var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
import { HomeNoticeService } from "~/services/dataimp-service/home-notice.service";
import { NoticeService } from "~/services/manage-service/notice.service";
import RichEditor from "~/components/common/rich-editor.vue";
var Notice = /** @class */ (function (_super) {
    __extends(Notice, _super);
    function Notice() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editorOption = {
            theme: "bubble",
            placeholder: ""
        };
        _this.activeNames = '';
        _this.noteDataSet = [];
        _this.imgList = "";
        _this.imgShow = "";
        return _this;
    }
    Notice.prototype.handleChange = function () { };
    Notice.prototype.created = function () {
        this.refreshData();
    };
    Notice.prototype.refreshData = function () {
        var _this = this;
        this.noticeService.getNoticeList().subscribe(function (data) {
            _this.noteDataSet = data;
        });
    };
    Notice.prototype.commit = function () {
        var path = "/acc-manage/repay-info";
        window.open(path);
    };
    __decorate([
        Dependencies(HomeNoticeService)
    ], Notice.prototype, "homeNoticeService", void 0);
    __decorate([
        Dependencies(NoticeService)
    ], Notice.prototype, "noticeService", void 0);
    Notice = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                SvgIcon: SvgIcon,
                DataBox: DataBox,
                RichEditor: RichEditor
            }
        })
    ], Notice);
    return Notice;
}(Vue));
export default Notice;
