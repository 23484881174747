var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { State } from "vuex-class";
import { UserService } from "~/services/manage-service/user.service";
import { RoleService } from "~/services/manage-service/role.service";
import { Emit, Prop } from "vue-property-decorator";
var AllotRole = /** @class */ (function (_super) {
    __extends(AllotRole, _super);
    function AllotRole() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            name: '',
            status: ''
        };
        _this.selectionRow = [];
        // 设置角色对象
        _this.roleSetModel = {
            userIds: [],
            roleIds: []
        };
        _this.dataSet = [];
        return _this;
    }
    AllotRole.prototype.refreshList = function () { };
    AllotRole.prototype.close = function () {
        var databox = this.$refs['data-box'];
        databox.clearSelection();
        this.reset();
    };
    AllotRole.prototype.mounted = function () {
    };
    AllotRole.prototype.confirm = function () {
        var _this = this;
        if (this.selectionRow == 0) {
            this.$message.info('请选择角色');
            return;
        }
        this.roleSetModel.roleIds = this.selectionRow.map(function (x) { return x.id; });
        this.roleSetModel.userIds = [this.user.id];
        this.loading.state = true;
        this.roleService.distributeRole(this.roleSetModel, this.loading).subscribe(function (data) {
            _this.$message.success('分配成功');
            _this.close();
            _this.reset();
            _this.refreshList();
        });
    };
    // 刷新数据
    AllotRole.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.roleService.findAllRole(this.model, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
            _this.userService.getRoles(_this.user.id).subscribe(function (data) {
                var select = [];
                _this.dataSet.map(function (item) {
                    if (data.includes(item.id)) {
                        select.push(item);
                    }
                });
                _this.selectionRow = select;
            });
        });
        this.reset();
    };
    AllotRole.prototype.reset = function () {
        delete this.model.name;
        this.selectionRow = [];
    };
    __decorate([
        Dependencies(PageService)
    ], AllotRole.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], AllotRole.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserService)
    ], AllotRole.prototype, "userService", void 0);
    __decorate([
        Dependencies(RoleService)
    ], AllotRole.prototype, "roleService", void 0);
    __decorate([
        State
    ], AllotRole.prototype, "companyCode", void 0);
    __decorate([
        Prop()
    ], AllotRole.prototype, "user", void 0);
    __decorate([
        Emit('refreshList')
    ], AllotRole.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], AllotRole.prototype, "close", null);
    AllotRole = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], AllotRole);
    return AllotRole;
}(Vue));
export default AllotRole;
