var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout } from '~/core/decorator';
import StatementImportList from "~/components/case-manage/statement-import/statement-import-list.vue";
import StatementImportDetails from "~/components/case-manage/statement-import/statement-import-details.vue";
import StatementImportSingleDetails from "~/components/case-manage/statement-import/statement-import-single-details.vue";
var StatementImport = /** @class */ (function (_super) {
    __extends(StatementImport, _super);
    function StatementImport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentComponent = "StatementImportList";
        _this.batchNumber = "";
        _this.id = "";
        return _this;
    }
    /**
     * 点击案件详情
     */
    StatementImport.prototype.onClickCaseDetail = function (rowData) {
        this.batchNumber = rowData.batchNumber;
        this.currentComponent = "StatementImportDetails";
    };
    StatementImport.prototype.onClickCaseSingleDetail = function (rowData) {
        this.batchNumber = rowData.batchNumber;
        this.id = rowData.id;
        this.currentComponent = "StatementImportSingleDetails";
    };
    /**
     * 返回案件导入列表
     */
    StatementImport.prototype.onBackCaseList = function () {
        this.currentComponent = "StatementImportList";
    };
    StatementImport = __decorate([
        Layout('workspace'),
        Component({
            components: {
                StatementImportList: StatementImportList,
                StatementImportDetails: StatementImportDetails,
                StatementImportSingleDetails: StatementImportSingleDetails
            }
        })
    ], StatementImport);
    return StatementImport;
}(Vue));
export default StatementImport;
