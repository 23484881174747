var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
var letterAccModule = namespace("letter-acc");
var LetterTempThree = /** @class */ (function (_super) {
    __extends(LetterTempThree, _super);
    function LetterTempThree() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 展示模板的div默认不显示
        _this.model = "none";
        // 设置table中td的样式
        _this.m2_td = "1px solid black";
        _this.m2_h = "50px";
        // 设置模板中是否显示
        _this.op = "1.0";
        // 设置第一列的样式
        _this.m2_w = "200px";
        _this.m2_dis = "0px 10px";
        // 两端对齐
        _this.m2_align = "justify";
        // 第二列文字居中
        _this.m2_cen = "center";
        return _this;
    }
    /**
     * 显示信函模板
     */
    LetterTempThree.prototype.lt3ShowTemp = function () {
        this.model = "block";
    };
    __decorate([
        Prop()
    ], LetterTempThree.prototype, "printDataArr", void 0);
    __decorate([
        Prop()
    ], LetterTempThree.prototype, "PrintInfo", void 0);
    __decorate([
        letterAccModule.State
    ], LetterTempThree.prototype, "someSymbol", void 0);
    __decorate([
        letterAccModule.State
    ], LetterTempThree.prototype, "ymdDate", void 0);
    LetterTempThree = __decorate([
        Component({
            components: {},
        })
    ], LetterTempThree);
    return LetterTempThree;
}(Vue));
export default LetterTempThree;
