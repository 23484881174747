var CensusData = function () { return import('~/pages/data-repair/census-data.vue'); };
var CensusRemark = function () { return import('~/pages/data-repair/census-remark.vue'); };
var RelevanceRelation = function () { return import('~/pages/data-repair/relevance-relation.vue'); };
var SocialSecurityData = function () { return import('~/pages/data-repair/social-security-data.vue'); };
var CountryData = function () { return import('~/pages/data-repair/country-data.vue'); };
var CommunicateData = function () { return import('~/pages/data-repair/communicate-data.vue'); };
var AlipayData = function () { return import('~/pages/data-repair/alipay-data.vue'); };
var SpecialData = function () { return import('~/pages/data-repair/special-data.vue'); };
var PlanLifeData = function () { return import('~/pages/data-repair/plan-life-data.vue'); };
var ImportCompare = function () { return import('~/pages/data-repair/import-compare.vue'); };
var ExamineStatus = function () { return import('~/pages/data-repair/examine-status.vue'); };
export default [
    {
        path: '/data-repair/census-data',
        name: 'census-data',
        component: CensusData
    },
    {
        path: '/data-repair/census-remark',
        name: 'census-remark',
        component: CensusRemark
    },
    {
        path: '/data-repair/relevance-relation',
        name: 'relevance-relation',
        component: RelevanceRelation
    },
    {
        path: '/data-repair/social-security-data',
        name: 'social-security-data',
        component: SocialSecurityData
    },
    {
        path: '/data-repair/country-data',
        name: 'country-data',
        component: CountryData
    },
    {
        path: '/data-repair/communicate-data',
        name: 'communicate-data',
        component: CommunicateData
    },
    {
        path: '/data-repair/alipay-data',
        name: 'alipay-data',
        component: AlipayData
    },
    {
        path: '/data-repair/special-data',
        name: 'special-data',
        component: SpecialData
    },
    {
        path: '/data-repair/plan-life-data',
        name: 'plan-life-data',
        component: PlanLifeData
    },
    {
        path: '/data-repair/import-compare',
        name: 'import-compare',
        component: ImportCompare
    },
    {
        path: '/data-repair/examine-status',
        name: 'examine-status',
        component: ExamineStatus
    },
];
