var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { CaseApplyOperateService } from '~/services/domain-service/case-apply-operate.service';
import { OrganizationService } from '~/services/management-service/organization.service';
import FileUpload from '~/components/common/file-upload.vue';
var SupplementApproved = /** @class */ (function (_super) {
    __extends(SupplementApproved, _super);
    function SupplementApproved() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.fileList = [];
        _this.supplementModel = {
            approveRemark: '',
            approvalResult: '',
            reason: '',
            applyRemark: '',
            usernames: '',
            applyType: 'SUPPLEMENT_APPLY',
        };
        _this.rules = {
            approveRemark: [{ required: true, message: '请输入审批意见' }],
            approvalResult: [{ required: true, message: '请选择审批结果' }],
            usernames: [{ required: true, message: "请输入下级处理人员", trigger: "change" }]
        };
        _this.isNext = false;
        _this.userData = [];
        return _this;
    }
    SupplementApproved.prototype.refreshList = function () { };
    SupplementApproved.prototype.close = function () { };
    /**
     * 重置
     */
    SupplementApproved.prototype.reset = function () {
        var supplementForm = this.$refs['supplement-form'];
        supplementForm.resetFields();
    };
    SupplementApproved.prototype.refreshData = function () {
        var _this = this;
        if (this.rowData.currentApprovalLevel === this.rowData.configFlowApprovalLevel)
            return;
        if (this.rowData.configModel.applyState === 'PERSONAL_APPLY') {
            this.isNext = true;
        }
        else {
            this.isNext = false;
        }
        this.organizationService.findChooseOperatorList()
            .subscribe(function (data) {
            _this.userData = data.map(function (x) {
                return { value: x.showName, usernames: x.username };
            });
        });
    };
    SupplementApproved.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.userData.filter(this.createFilter(queryString)) : this.userData;
        cb(results);
    };
    SupplementApproved.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    };
    /**
     * 取消
     */
    SupplementApproved.prototype.cancel = function () {
        //   关闭新增版本dialog
        this.$emit('close');
        var supplementForm = this.$refs['supplement-form'];
        supplementForm.resetFields();
    };
    SupplementApproved.prototype.confirmApproved = function () {
        var _this = this;
        var Form = this.$refs['supplement-form'];
        Form.validate(function (valid) {
            if (!valid)
                return;
            var idList = [];
            if (_this.rowData.id) {
                idList = idList.concat(_this.rowData.id);
            }
            else {
                idList = _this.rowData.map(function (v) { return v.id; });
            }
            if (_this.isNext) {
                if (!_this.userData.find(function (x) { return x.value === _this.supplementModel.usernames; })) {
                    _this.$message.warning('请选择下级处理人员');
                    return;
                }
            }
            var approvedModel = {
                idList: idList,
                approvalResult: _this.supplementModel.approvalResult == 0 ? 'APPROVED_PASS' : 'APPROVED_REJECT',
                applyType: 'SUPPLEMENT_APPLY',
                approveRemark: _this.supplementModel.approveRemark,
                applyRemark: _this.supplementModel.applyRemark,
                usernames: _this.isNext ? [_this.userData.find(function (x) { return x.value === _this.supplementModel.usernames; }).usernames] : []
            };
            _this.loading.state = true;
            _this.caseApplyOperateService.caseApplyApprove(approvedModel, _this.loading).subscribe(function (data) {
                _this.$message.success('审批成功!');
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], SupplementApproved.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], SupplementApproved.prototype, "organizationService", void 0);
    __decorate([
        Emit('refreshList')
    ], SupplementApproved.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], SupplementApproved.prototype, "close", null);
    __decorate([
        Prop()
    ], SupplementApproved.prototype, "rowData", void 0);
    SupplementApproved = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], SupplementApproved);
    return SupplementApproved;
}(Vue));
export default SupplementApproved;
