import AddressFollowQuery from "./address-follow-query.vue";
import FindInformation from "./find-information.vue";
import FollowAllQueryMini from "./follow-all-query-mini.vue";
import Other from "./other.vue";
import PhoneFollowQuery from "./phone-follow-query.vue";
import PreCommitteeReminders from "./pre-committee-reminders.vue";
export var components = {
    AddressFollowQuery: AddressFollowQuery,
    FindInformation: FindInformation,
    FollowAllQueryMini: FollowAllQueryMini,
    Other: Other,
    PhoneFollowQuery: PhoneFollowQuery,
    PreCommitteeReminders: PreCommitteeReminders
};
export var config = [
    {
        label: "全部",
        name: "FollowAllQueryMini",
    },
    {
        label: "电话联络",
        name: "PhoneFollowQuery",
    },
    {
        label: "地址联络",
        name: "AddressFollowQuery",
    },
    {
        label: "查找信息",
        name: "FindInformation",
    },
    {
        label: "其他",
        name: "Other",
    },
    {
        label: "委前调记",
        name: "PreCommitteeReminders",
    },
];
