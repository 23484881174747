export default {
    //启动状态
    ready: false,
    // 页面布局
    layout: "default",
    // 字典数据
    dictData: "",
    // 用户token
    userToken: "",
    // token是否过期
    tokenExpire: false,
    // 用户数据
    userData: {},
    // 公司编号
    companyCode: "",
    //公司名称
    companyName: "",
    // 菜单资源
    menuResource: [],
    // 控件资源
    controlResource: [],
    // 菜单折叠状态
    menuCollapse: false,
    // 当前顶级菜单
    currentTopMenu: "",
    // 当前主题样式
    theme: "theme-default",
    // 受托方列表
    outSourceList: [],
    // 委托方列表
    principalList: [],
    // 部门列表
    departmentList: [],
    // 打标颜色
    colorList: [],
    // 用户委托方列表
    userPrincipalList: [],
    // 资源按钮
    resourcePower: [],
    // 是否启用身份证脱敏
    idCardEncryptionFlag: false,
    //获取当前登陆人下的调收员
    collectorList: [],
    orgInfo: [],
    dynamicMenus: [],
};
