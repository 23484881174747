var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import WorkMenuTop from "~/components/workspace/work-menu-top.vue";
import WorkUser from "~/components/workspace/work-user.vue";
import WorkTask from "~/components/workspace/work-task.vue";
import WorkMessage from "~/components/workspace/work-message.vue";
import { State, Mutation, Getter } from "vuex-class";
var WorkHeader = /** @class */ (function (_super) {
    __extends(WorkHeader, _super);
    function WorkHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WorkHeader.prototype, "menuCollapse", {
        get: function () {
            return this._menuCollapse;
        },
        set: function (value) {
            this.updateMenuCollapse(value);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Getter
    ], WorkHeader.prototype, "workTopMenu", void 0);
    __decorate([
        State("menuCollapse")
    ], WorkHeader.prototype, "_menuCollapse", void 0);
    __decorate([
        Mutation
    ], WorkHeader.prototype, "updateMenuCollapse", void 0);
    WorkHeader = __decorate([
        Component({
            components: {
                WorkUser: WorkUser,
                WorkTask: WorkTask,
                SvgIcon: SvgIcon,
                WorkMenuTop: WorkMenuTop,
                WorkMessage: WorkMessage,
            },
        })
    ], WorkHeader);
    return WorkHeader;
}(Vue));
export default WorkHeader;
