import { StorageService } from "~/utils/storage.service";
export default {
    /**
     * 更新菜单折叠状态
     * @param state
     * @param collapse
     */
    updateMenuCollapse: function (state, collapse) {
        state.menuCollapse = collapse;
    },
    /**
     * 更新当前顶级菜单
     * @param state
     * @param key
     */
    updateCurrentTopMenu: function (state, item) {
        state.currentTopMenu = item;
    },
    /**
     * 更新页面布局
     * @param state
     * @param layout
     */
    updateLayout: function (state, layout) {
        state.layout = layout || "default";
    },
    /**
     * 更新字典数据
     * @param state
     * @param data
     */
    updateDictData: function (state, data) {
        state.dictData = data;
    },
    /**
     * 更新用户token
     * @param state
     * @param token
     */
    updateUserToken: function (state, token) {
        if (!token) {
            state.tokenExpire = false;
        }
        state.userToken = token;
        StorageService.setItem("userToken", token);
    },
    /**
     * 更新用户数据
     * @param state
     * @param user
     */
    updateUserData: function (state, user) {
        state.userData = user;
    },
    /**
     * 更新用户角色信息
     * @param state
     * @param role
     */
    updateUserRole: function (state, role) {
        state.userRole = role;
    },
    /**
     * 更新用户菜单资源
     * @param state
     * @param rescource
     */
    updateUserMenuResource: function (state, rescource) {
        state.menuResource = rescource;
    },
    /**
     * 更新用户资源信息
     * @param state
     * @param rescource
     */
    updateUserControlResource: function (state, rescource) {
        state.controlResource = rescource;
    },
    /**
     * 更新委托方数据
     * @param state
     * @param data
     */
    updatePrincipalList: function (state, data) {
        state.principalList = data;
    },
    updateOutSourceList: function (state, data) {
        state.outSourceList = data;
    },
    /**
     * 更新部门数据
     * @param state
     * @param data
     */
    updateDepartmentList: function (state, data) {
        state.departmentList = data;
    },
    /**
     * 更新公司编号
     * @param state
     * @param data
     */
    updateCompanyCode: function (state, data) {
        state.companyCode = data;
    },
    /**
     * 修改token过期标识
     * @param state
     * @param data
     */
    updateTokenExpire: function (state, data) {
        state.tokenExpire = data;
    },
    /**
     * 更新初始化状态
     * @param state
     */
    ready: function (state) {
        state.ready = true;
    },
    /**
     * 更新打标颜色列表
     * @param state
     */
    updateColorList: function (state, data) {
        state.colorList = data;
    },
    updateCall: function (state, data) {
        state.phone = data;
    },
    /**
     * 更新用户委托方资源
     * @param state
     * @param data
     */
    // updateUserPrincipalList(state, data) {
    //   state.userPrincipalList = data
    // },
    updateResourcePower: function (state, data) {
        state.resourcePower = data;
    },
    /**
     * 修改身份证脱敏标记
     * @param state
     * @param value
     */
    // updateIdCardEncryFlag(state, value: boolean) {
    //   state.idCardEncryptionFlag = value
    // },
    /**
     * 更新调收员
     */
    updateCollector: function (state, data) {
        state.collectorList = data;
    },
    /**
     * 更新组织名称信息
     * @param {*} state
     * @param {*} value
     */
    OrgNameInfo: function (state, value) {
        state.orgInfo = value;
    },
    /**
     * 动态菜单，（委托方）
     * @param state
     * @param data
     */
    updateDynamicMenus: function (state, data) {
        state.dynamicMenus = data;
    },
};
