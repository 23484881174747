import caseInfoInquiryController from '~/config/server/report-service/case-info-inquiry.controller';
import caseInfoDistributeController from '~/config/server/report-service/case-info-distribute.controller';
import exportCaseInfoController from '~/config/server/report-service/export-case-info.controller';
import CaseInfoReportController from '~/config/server/report-service/case-info-report.controller';
import exportFollowupController from '~/config/server/report-service/export-followup.controller';
import queryOutsourcePoolController from '~/config/server/report-service/query-outsource-pool.controller';
import exportOutsourceFollowupController from '~/config/server/report-service/export-outsource-followup.controller';
import strateDataManualController from '~/config/server/report-service/strate-data-manual.controller';
import homePageController from '~/config/server/report-service/home-page.controller';
import reportController from '~/config/server/report-service/report.controller';
import repayController from '~/config/server/report-service/repay.controller';
export var reportService = {
    strateDataManualController: strateDataManualController,
    queryOutsourcePoolController: queryOutsourcePoolController,
    caseInfoInquiryController: caseInfoInquiryController,
    caseInfoDistributeController: caseInfoDistributeController,
    exportCaseInfoController: exportCaseInfoController,
    CaseInfoReportController: CaseInfoReportController,
    exportFollowupController: exportFollowupController,
    exportOutsourceFollowupController: exportOutsourceFollowupController,
    homePageController: homePageController,
    reportController: reportController,
    repayController: repayController
};
