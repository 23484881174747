var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import NumberRange from "~/components/common/number-range.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { RepayService } from "~/services/business-service/repay.service";
import ftRequest from "~/utils/ft.request";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { DepartmentService } from "~/services/manage-service/department.service";
import { UserService } from "~/services/manage-service/user.service";
var collectionManageModule = namespace("collection-manage");
var DetentionCase = /** @class */ (function (_super) {
    __extends(DetentionCase, _super);
    function DetentionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.selectionList = [];
        _this.selectionUserList = [];
        _this.fileList = [];
        _this.productList = [];
        _this.userList = [];
        _this.groupDataSet = [];
        _this.leaderDataSet = [];
        _this.queryParamsModel = {
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: "",
            repayBatch: "",
            realRepayDateBegin: "",
            realRepayDateEnd: "",
            batchMonth: "",
            dept: "",
            name: "",
            productName: "",
            startDate: _this.formattedStartDate(),
            endDate: _this.formattedEndDate(),
            groupName: "",
            searchType: 1,
        };
        _this.activeName = "1";
        _this.dataSet = [];
        _this.deptUserList = [];
        _this.deptList = [];
        _this.groupNameList = [];
        _this.formModel = {
            id: undefined,
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: "",
            dept: "",
            repayBatch: "",
        };
        return _this;
    }
    DetentionCase.prototype.mounted = function () {
        this.getDeptList();
        this.getGroupNameList();
        this.getProductList();
        this.getDeptUserList();
        this.refreshData();
        console.log(1, this.isManager());
    };
    DetentionCase.prototype.isManager = function () {
        return this.userData.realName === "王辉" || this.userData.isManager === "YES";
    };
    DetentionCase.prototype.getDeptList = function () {
        // this.departmentService.findAllDepartmentNotAuth(this.loading).subscribe((data) => {
        //   this.deptList = [];
        //   data.forEach(item => {
        //     if (item.name !== "技术部" && item.name !== "测试" && item.name !== "离职人员" && item.name !== "在职人员" && item.name !== "在职人员" && item.name !== "国调科技") {
        //       this.deptList.push(item);
        //     }
        //   });
        // });
    };
    DetentionCase.prototype.getGroupNameList = function () {
        var _this = this;
        this.repayService.findGroupNameList(this.loading).subscribe(function (data) {
            _this.groupNameList = data;
        });
    };
    DetentionCase.prototype.getDeptUserList = function () {
        var _this = this;
        this.userService.findUserByDepartmentCode({}, this.sortService).subscribe(function (data) {
            _this.deptUserList = data;
        });
    };
    DetentionCase.prototype.formattedStartDate = function () {
        var currentDate = new Date();
        var year = currentDate.getFullYear(); //获取当前年
        var month = String(currentDate.getMonth() + 1).padStart(2, "0"); //获取当前月
        var firstDay = "01"; //日
        return year + "-" + month + "-" + firstDay;
    };
    DetentionCase.prototype.formattedEndDate = function () {
        // 获取当前日期
        var currentDate = new Date();
        // 获取当前年份
        var year = currentDate.getFullYear();
        // 获取下个月的月份，使用 padStart 在月份小于 10 时在前面补零
        var month = String(currentDate.getMonth() + 2).padStart(2, "0");
        // 如果月份为 '13'，说明当前是12月，需要更新年份并将月份设置为 '01'
        if (month === "13") {
            year = year + 1;
            month = "01";
        }
        // 设置每个月的第一天为 '01'
        var firstDay = "01";
        // 构建下个月的第一天的日期字符串，格式为 'YYYY-MM-DD'
        var firstDayOfNextMonth = year + "-" + month + "-" + firstDay;
        // 计算当前月的最后一天，通过减去一天的毫秒数来得到
        var lastDayOfMonth = new Date(new Date(firstDayOfNextMonth).getTime() - 86400000);
        // 将最后一天的日期转换为 ISO 格式，并提取日期部分
        return lastDayOfMonth.toISOString().split("T")[0];
    };
    DetentionCase.prototype.getProductList = function () {
        var _this = this;
        this.repayService.listProduct({}, this.loading).subscribe(function (data) {
            _this.productList = data;
        });
    };
    DetentionCase.prototype.handleTabClick = function (tab, event) {
        this.dataSet = [];
        this.refreshData();
    };
    DetentionCase.prototype.handleResetForm = function () {
        this.formModel = {};
        this.refreshData();
    };
    DetentionCase.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    DetentionCase.prototype.batchDelGrades = function () {
        var _this = this;
        this.$confirm("确定删除选中记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            var ids = _this.selectionList.map(function (item) { return item.id; }).join(",");
            _this.repayService.batchDelGrades({ id: ids }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.refreshData = function () {
        var _this = this;
        if (this.activeName === "1") {
            this.repayService.getCommissionList(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = data;
            });
        }
        else if (this.activeName === "2") {
            this.repayService.getGroupNameCommissionList(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.groupDataSet = data;
            });
        }
        else if (this.activeName === "3") {
            this.repayService.getLeaderCommissionList(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.leaderDataSet = data;
            });
        }
    };
    DetentionCase.prototype.exportList = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesUserController/exportUserCommissionList", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "个人提成表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DetentionCase.prototype.exportGroupList = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesUserController/exportGroupCommissionList", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "组长提成表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DetentionCase.prototype.exportLeaderList = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesUserController/exportLeaderCommissionList", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "主管提成表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], DetentionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DetentionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(RepayService)
    ], DetentionCase.prototype, "repayService", void 0);
    __decorate([
        Dependencies(DepartmentService)
    ], DetentionCase.prototype, "departmentService", void 0);
    __decorate([
        Dependencies(UserService)
    ], DetentionCase.prototype, "userService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], DetentionCase.prototype, "uploadFileService", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "userToken", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "userData", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "departmentList", void 0);
    DetentionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                PrincipalBatch: PrincipalBatch,
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
            },
        })
    ], DetentionCase);
    return DetentionCase;
}(Vue));
export default DetentionCase;
