var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import ModifyDataComplianceConfig from "~/components/system-manage/data-compliance-config/modify-data-compliance-config.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { ComplianceCheckControllerService } from "~/services/business-service/compliance-check-controller.service";
var DataComplianceConfig = /** @class */ (function (_super) {
    __extends(DataComplianceConfig, _super);
    function DataComplianceConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.right = 'ADD';
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            configName: "",
            status: ""
        };
        _this.configData = {};
        _this.configId = '';
        _this.dataSet = [];
        _this.dialog = {
            exportConfig: false
        };
        _this.complianceCheck = '';
        return _this;
    }
    DataComplianceConfig.prototype.mounted = function () {
        this.refreshData();
    };
    DataComplianceConfig.prototype.refreshData = function () {
        var _this = this;
        this.complianceCheckControllerService
            .getAllComplianceCheck(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
            _this.loading.state = false;
        });
    };
    /**
     * 修改模版
     */
    DataComplianceConfig.prototype.updateConfigClick = function (row) {
        this.right = 'EDIT';
        this.configId = row.id;
        this.configData = row;
        this.dialog.exportConfig = true;
    };
    DataComplianceConfig.prototype.newConfigClick = function () {
        this.right = 'ADD';
        this.dialog.exportConfig = true;
    };
    DataComplianceConfig.prototype.updateStatusClick = function (id) {
        var _this = this;
        this.complianceCheckControllerService.changeState(id).subscribe(function (datat) {
            _this.$message.success("操作成功!");
            _this.refreshData();
        });
    };
    DataComplianceConfig.prototype.deleteClick = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5220\u9664 " + row.configName + " \u5417\uFF1F", "提示", {
            type: "warning"
        }).then(function () {
            _this.loading.state = true;
            _this.complianceCheckControllerService.deleteComplianceCheck(row.id).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], DataComplianceConfig.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DataComplianceConfig.prototype, "sortService", void 0);
    __decorate([
        Dependencies(ComplianceCheckControllerService)
    ], DataComplianceConfig.prototype, "complianceCheckControllerService", void 0);
    DataComplianceConfig = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ModifyDataComplianceConfig: ModifyDataComplianceConfig
            }
        })
    ], DataComplianceConfig);
    return DataComplianceConfig;
}(Vue));
export default DataComplianceConfig;
