var RoleManage = function () { return import('~/pages/system-manage/role-manage.vue'); };
var UserManage = function () { return import('~/pages/system-manage/user-manage.vue'); };
var SystemParameterManage = function () { return import('~/pages/system-manage/system-parameter-manage.vue'); };
// 合规检查配置
var DataComplianceConfig = function () { return import('~/pages/system-manage/data-compliance-config.vue'); };
var sensitiveWords = function () { return import('~/pages/system-manage/sensitive-words.vue'); };
export default [
    {
        // 角色管理
        path: '/system-manage/role-manage',
        name: 'role-manage',
        component: RoleManage
    }, {
        // 用户机构管理
        path: '/system-manage/user-manage',
        name: 'user-manage',
        component: UserManage
    }, {
        // 配置管理--系统参数管理
        path: '/system-manage/system-parameter-manage',
        name: 'system-parameter-manage',
        component: SystemParameterManage
    },
    {
        // 合规检查管理--数据合规检查配置
        path: '/system-manage/data-compliance-config',
        name: 'data-compliance-config',
        component: DataComplianceConfig
    },
    {
        // 合规检查管理--数据合规检查配置
        path: '/system-manage/sensitive-words',
        name: 'sensitive-words',
        component: sensitiveWords
    }
];
