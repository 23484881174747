import { requestType } from "~/config/enum.config";
// 行调业绩表相关
var SERVICE = "business-service";
var CONTROLLER = "gradesController";
export default {
    /**
     * 行调业绩列表
     */
    findGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGrades",
        type: requestType.Post,
    },
    /**
     * 导出行调业绩列表
     */
    exportGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportGrades",
        type: requestType.Post,
    },
    /**
     * 删除行调业绩列表
     */
    delGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "delGrades",
        type: requestType.Post,
    },
    /**
     * 删除行调业绩列表
     */
    batchDelGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "batchDelGrades",
        type: requestType.Post,
    },
    /**
     * 更新行调业绩列表
     */
    updateGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateGrades",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    addGrades: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addGrades",
        type: requestType.Post,
    },
};
