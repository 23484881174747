var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var ImportExcelCaseService = /** @class */ (function (_super) {
    __extends(ImportExcelCaseService, _super);
    function ImportExcelCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取案件信息内置字段
     * @param templateType 模板类型
     */
    ImportExcelCaseService.prototype.getCaseInfoProperty = function (templateType, loading) {
        return this.netService.send({
            server: dataimpService.importExcelCaseController.getCaseInfoProperty,
            data: {
                templateType: templateType
            },
            loading: loading
        });
    };
    /**
     * 案件导入
     */
    ImportExcelCaseService.prototype.importExcelData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.importExcelCaseController.importExcelData,
            data: {
                batchNumber: data.batchNumber,
                delegationDate: FilterService.dateFormat(data.delegationDate),
                endCaseDate: FilterService.dateFormat(data.endCaseDate),
                fileId: data.fileId,
                principalId: data.principalId,
                principalName: data.principalName,
                templateId: data.templateId //模板ID
            },
            loading: loading
        });
    };
    /**
     * 批次号删除
     */
    ImportExcelCaseService.prototype.deleteCaseInfoByBatchNumber = function (batchNumber, loading) {
        return this.netService.send({
            server: dataimpService.importExcelCaseController.deleteCaseInfoByBatchNumber,
            append: [batchNumber],
            loading: loading
        });
    };
    /**
     * 获取默认导入模板
     * @param data 查询参数实体
     * @param loading 加载等待框
     */
    ImportExcelCaseService.prototype.getSystemTemplate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.importExcelCaseController.getSystemTemplate,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ImportExcelCaseService.prototype, "netService", void 0);
    return ImportExcelCaseService;
}(Service));
export { ImportExcelCaseService };
