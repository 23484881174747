var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Dependencies } from '~/core/decorator';
import { PageService } from '~/utils/page.service';
import { CaseDistributeService } from '~/services/dataimp-service/case-distribute.service';
import { DistributeCaseService } from '~/services/domain-service/distribute-case.service';
var UserCaseBatchDistribute = /** @class */ (function (_super) {
    __extends(UserCaseBatchDistribute, _super);
    function UserCaseBatchDistribute() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.batchNumberList = [];
        _this.cityList = [];
        _this.collectorList = [];
        _this.departList = [];
        _this.loading = {
            state: false
        };
        _this.model = {
            batchNumber: '',
            cities: [],
            departs: [],
            collectors: []
        };
        _this.rules = {
            batchNumber: [{ required: true, message: '请选择批次号', trigger: 'blur' }],
        };
        return _this;
    }
    UserCaseBatchDistribute.prototype.close = function () {
        var form = this.$refs['form'];
        if (form) {
            form.resetFields();
        }
    };
    UserCaseBatchDistribute.prototype.created = function () { };
    UserCaseBatchDistribute.prototype.mounted = function () {
        this.refreshData();
    };
    UserCaseBatchDistribute.prototype.save = function () {
        var _this = this;
        var form = this.$refs['form'];
        if (form) {
            form.validate(function (valid) {
                if (!valid)
                    return;
                var model = {
                    // 批次号
                    batchNumber: _this.model.batchNumber,
                    // 案件状态
                    issuedFlag: _this.caseIssuedFlag,
                    // 城市数据
                    citys: _this.model.cities,
                    // 所属部门
                    departs: _this.model.departs,
                    // 调解员
                    collectors: _this.model.collectors
                };
                _this.loading.state = true;
                _this.distributeCaseService
                    .issuedPersonalCaseBatch(model, _this.loading)
                    .subscribe(function (data) {
                    _this.$message.success('批量下发成功');
                    _this.onSuccess();
                });
            });
        }
    };
    UserCaseBatchDistribute.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        // 个人未下发
        this.distributeCaseService
            .getBatchNumberByCaseIssuedFlag(this.caseIssuedFlag, this.loading)
            .subscribe(function (data) { return (_this.batchNumberList = data); });
    };
    /**
     * 批次号选中改变事件
     */
    UserCaseBatchDistribute.prototype.batchNumberChange = function (value) {
        var _this = this;
        this.model.cities = [];
        this.model.departs = [];
        this.model.collectors = [];
        // 地区待分配批次号
        this.distributeCaseService
            .getCaseCommonPro(value, this.caseIssuedFlag)
            .subscribe(function (data) {
            if (data) {
                // 城市数据
                _this.cityList = data.citys;
                // 所属部门
                _this.departList = data.departs;
                // 调解员
                _this.collectorList = data.collectors;
            }
        }, function (err) { return _this.$message.error(err.msg); });
    };
    UserCaseBatchDistribute.prototype.onSuccess = function () {
        this.close();
    };
    __decorate([
        Dependencies(PageService)
    ], UserCaseBatchDistribute.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseDistributeService)
    ], UserCaseBatchDistribute.prototype, "caseDistributeService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], UserCaseBatchDistribute.prototype, "distributeCaseService", void 0);
    __decorate([
        State
    ], UserCaseBatchDistribute.prototype, "userData", void 0);
    __decorate([
        Prop({ default: "PERSONAL_UN_ISSUED" })
    ], UserCaseBatchDistribute.prototype, "caseIssuedFlag", void 0);
    __decorate([
        Emit('close')
    ], UserCaseBatchDistribute.prototype, "close", null);
    UserCaseBatchDistribute = __decorate([
        Component({
            components: {},
        })
    ], UserCaseBatchDistribute);
    return UserCaseBatchDistribute;
}(Vue));
export default UserCaseBatchDistribute;
