var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { commonService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import axios from "axios";
import { StorageService } from "~/utils/storage.service";
import appConfig from "~/config/app.config";
import { requestType } from "~/config/enum.config";
var UploadFileService = /** @class */ (function (_super) {
    __extends(UploadFileService, _super);
    function UploadFileService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 文件上传
     */
    UploadFileService.prototype.upload = function () {
        return this.netService.send({
            server: commonService.uploadController.upload
        });
    };
    /**
     * 按id查询上传文件
     */
    UploadFileService.prototype.getUploadFileById = function (data) {
        return this.netService.send({
            server: commonService.uploadController.getUploadFileById,
            data: {
                id: data
            }
        });
    };
    /**
     * 通过文件id下载文件
     */
    UploadFileService.prototype.getFileById = function (data) {
        return this.netService.send({
            server: commonService.uploadController.getFileById,
            data: {
                id: data.id
            }
        });
    };
    /**
     * 通过文件id下载文件流
     * @param id 文件id
     */
    UploadFileService.prototype.getFileStreamById = function (id, fileName) {
        return this.netService.send({
            server: commonService.uploadController.getFileStreamById,
            append: [id],
            download: true,
            fileAliase: fileName
        });
    };
    /**
     * 通过文件名下载文件
     */
    UploadFileService.prototype.getFileByFileName = function (data) {
        return this.netService.send({
            server: commonService.uploadController.getFileByFileName,
            data: {
                fileName: data.fileName
            }
        });
    };
    /**
     * 根据文件名称下载文件
     * @param fileName 文件名
     */
    UploadFileService.prototype.getFile = function (fileName) {
        return new Promise(function (resolve, reject) {
            var downloadUrl = NetService.generateRequestUrl(commonService.uploadController.getFile) +
                "/" +
                fileName;
            axios
                .create({
                baseURL: appConfig.url.server
            })
                .request({
                url: downloadUrl,
                method: "get",
                responseType: "arraybuffer",
                headers: {
                    "X-UserToken": StorageService.getItem("userToken")
                }
            })
                .then(function (res) {
                var blob = new Blob([res.data]);
                resolve(URL.createObjectURL(blob));
            })
                .catch(function (ex) { return reject(ex); });
        });
    };
    /**
     * 根据文件id获取文件url
     * @param id
     */
    UploadFileService.prototype.getFileUrlById = function (id) {
        return this.netService.send({
            server: commonService.uploadController.getFileUrlById,
            append: [id]
        });
    };
    /**
     * 根据文件ids获取文件信息
     * @param id
     */
    UploadFileService.prototype.findUploadByFileIds = function (id) {
        return this.netService.send({
            server: commonService.uploadController.findUploadByFileIds,
            data: {
                fileIds: id
            }
        });
    };
    /**
     * 获取私有文件地址
     * params
     * */
    // getOssSignUrl(data): any {
    //   return this.netService.send({
    //     server: commonService.uploadController.getOssSignUrl,
    //     append: [data],
    //   });
    // }
    /**
     * 获取私有文件地址
     * params
     * */
    UploadFileService.prototype.getOssSignUrl = function (data) {
        return this.netService.send({
            server: {
                service: "common-service",
                controller: "uploadFile",
                action: "getOssSignUrl?url=" + data,
                type: requestType.Post,
            },
        });
    };
    __decorate([
        Inject(NetService)
    ], UploadFileService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], UploadFileService.prototype, "upload", null);
    __decorate([
        Debounce(2000)
    ], UploadFileService.prototype, "getFileStreamById", null);
    return UploadFileService;
}(Service));
export { UploadFileService };
