var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { Emit, Prop } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from '~/utils/sort.service';
import { UserService } from "~/services/manage-service/user.service";
import DataBox from "~/components/common/data-box.vue";
var ReportcaseManagePending = /** @class */ (function (_super) {
    __extends(ReportcaseManagePending, _super);
    function ReportcaseManagePending() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.reportcaseDataSet = [];
        _this.SearchModel = {
            flowType: "REPORT_CASE_APPLY",
            approvalStatus: "WAIT_APPROVAL",
            exportState: "WAIT_EXPORT"
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    ReportcaseManagePending.prototype.refreshList = function () { };
    ReportcaseManagePending.prototype.approvedMeth = function (rowData) { };
    ReportcaseManagePending.prototype.refreshData = function () {
        var _this = this;
        this.SearchModel = Object.assign(this.SearchModel, this.reportcaseModel);
        this.SearchModel.applyDateStart = this.reportcaseModel.applyDate[0];
        this.SearchModel.applyDateEnd = this.reportcaseModel.applyDate[1];
        this.SearchModel.applyDate = '';
        this.caseApplyManageService.getProcessApproveMessage(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.reportcaseDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    //审批操作
    ReportcaseManagePending.prototype.approvedSupplementMeth = function (scope) {
        var rowData = scope.row;
        this.approvedMeth(rowData);
    };
    __decorate([
        Dependencies(SortService)
    ], ReportcaseManagePending.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], ReportcaseManagePending.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], ReportcaseManagePending.prototype, "userService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], ReportcaseManagePending.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit("refreshList")
    ], ReportcaseManagePending.prototype, "refreshList", null);
    __decorate([
        Emit("approvedMeth")
    ], ReportcaseManagePending.prototype, "approvedMeth", null);
    __decorate([
        Prop()
    ], ReportcaseManagePending.prototype, "reportcaseModel", void 0);
    ReportcaseManagePending = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], ReportcaseManagePending);
    return ReportcaseManagePending;
}(Vue));
export default ReportcaseManagePending;
