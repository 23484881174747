var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { PaymentCheckService } from "~/services/business-service/payment-check.service";
import FileUpload from '~/components/common/file-upload.vue';
var RepaymentReply = /** @class */ (function (_super) {
    __extends(RepaymentReply, _super);
    function RepaymentReply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repaymentModel = {
            id: '',
            remark: '',
            fallBackAmount: ''
        };
        _this.submitLoading = false;
        _this.rules = {
            // fallBackAmount: [{ required: true, message: '请输入还款金额' }, { message: '请输入正确的金额', pattern: /^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/, }],
            remark: [{ required: true, message: '请输入回复意见' }],
        };
        return _this;
    }
    RepaymentReply.prototype.refreshList = function () { };
    RepaymentReply.prototype.close = function () { };
    /**
     * 重置
     */
    RepaymentReply.prototype.reset = function () {
        var repaymentForm = this.$refs['repayment-form'];
        repaymentForm.resetFields();
    };
    /**
     * 取消
     */
    RepaymentReply.prototype.cancel = function () {
        //   关闭新增版本dialog
        this.$emit('close');
        var repaymentForm = this.$refs['repayment-form'];
        repaymentForm.resetFields();
    };
    RepaymentReply.prototype.confirmApproved = function () {
        var _this = this;
        var addForm = this.$refs['repayment-form'];
        addForm.validate(function (valid) {
            if (!valid)
                return false;
            _this.submitLoading = true;
            _this.repaymentModel.id = _this.rowData.id;
            _this.repaymentModel.fallBackAmount = _this.rowData.hasPaymentAmt;
            _this.paymentCheckService.replyPaymentCheck(_this.repaymentModel).subscribe(function (data) {
                _this.$message.success('回复成功!');
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    __decorate([
        Dependencies(PaymentCheckService)
    ], RepaymentReply.prototype, "paymentCheckService", void 0);
    __decorate([
        Emit('refreshList')
    ], RepaymentReply.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], RepaymentReply.prototype, "close", null);
    __decorate([
        Prop()
    ], RepaymentReply.prototype, "rowData", void 0);
    RepaymentReply = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], RepaymentReply);
    return RepaymentReply;
}(Vue));
export default RepaymentReply;
