var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.defulatConfig = {
            column: 1,
            labelWidth: 80
        };
        return _this;
    }
    Object.defineProperty(default_1.prototype, "itemStyle", {
        get: function () {
            var column = this.container.column;
            var span = Math.min(this.span, column);
            var width = (Math.round((this.span / column) * 10000) / 100).toFixed(2) + '%';
            return {
                width: width,
                maxWidth: width,
                minWidth: width
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(default_1.prototype, "labelMinWidth", {
        get: function () {
            return this.labelWidth || this.container.labelWidth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(default_1.prototype, "valueWidth", {
        get: function () {
            return "calc(100% - " + (this.labelMinWidth + 5) + "px)";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(default_1.prototype, "container", {
        get: function () {
            var parent = this.$parent;
            while (parent &&
                parent.$options &&
                parent.$options.name !== 'LabelContainer') {
                parent = parent.$parent;
            }
            return parent && parent.$options.name === 'LabelContainer'
                ? parent
                : this.defulatConfig;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: '标签名称:' })
    ], default_1.prototype, "label", void 0);
    __decorate([
        Prop({ default: '' })
    ], default_1.prototype, "value", void 0);
    __decorate([
        Prop({ default: 1 })
    ], default_1.prototype, "span", void 0);
    __decorate([
        Prop({ default: true })
    ], default_1.prototype, "noWarp", void 0);
    __decorate([
        Prop({ default: true })
    ], default_1.prototype, "showTitle", void 0);
    __decorate([
        Prop()
    ], default_1.prototype, "labelWidth", void 0);
    default_1 = __decorate([
        Component({
            components: {}
        })
    ], default_1);
    return default_1;
}(Vue));
export default default_1;
