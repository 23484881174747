import { requestType } from '~/config/enum.config';
var SERVICE = 'management-service';
var CONTROLLER = 'exportLetterController';
export default {
    /**
     * 新建模板
     */
    createLetterTemplate: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'createLetterTemplate',
        type: requestType.Post,
    },
    /**
     * 信函模板列表查询
     */
    getTemplateByPrin: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getTemplateByPrin',
        type: requestType.Get,
    },
    /**
     * 修改模板
     */
    letterTemplateModify: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'letterTemplateModify',
        type: requestType.Post,
    },
    /**
     * 删除模板
     */
    deleteLetter: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteLetter',
        type: requestType.Delete,
    },
    /**
     * 根据委托方查询模板
     */
    getTemplateByPrincipalId: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getTemplateByPrincipalId',
        type: requestType.Get,
    }
};
