var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { PaymentCheckService } from "~/services/business-service/payment-check.service";
import { Emit, Prop } from 'vue-property-decorator';
import { SortService } from "~/utils/sort.service";
import { PageService } from '~/utils/page.service';
import DataBox from '~/components/common/data-box.vue';
var RepaymentManageResolve = /** @class */ (function (_super) {
    __extends(RepaymentManageResolve, _super);
    function RepaymentManageResolve() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.repaymentDataSet = [];
        _this.selectionList = [];
        _this.searchModel = {
            isReply: "NO",
            exportState: 'EXPORTED',
        };
        return _this;
    }
    RepaymentManageResolve.prototype.refreshList = function () { };
    RepaymentManageResolve.prototype.close = function () { };
    /**
     * 刷新用户列表
     */
    RepaymentManageResolve.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.searchModel = Object.assign(this.searchModel, this.repaymentModel);
        this.paymentCheckService.queryReplyRecord(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.repaymentDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(SortService)
    ], RepaymentManageResolve.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], RepaymentManageResolve.prototype, "pageService", void 0);
    __decorate([
        Dependencies(PaymentCheckService)
    ], RepaymentManageResolve.prototype, "paymentCheckService", void 0);
    __decorate([
        Emit('refreshList')
    ], RepaymentManageResolve.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], RepaymentManageResolve.prototype, "close", null);
    __decorate([
        Prop()
    ], RepaymentManageResolve.prototype, "repaymentModel", void 0);
    RepaymentManageResolve = __decorate([
        Component({
            components: {
                DataBox: DataBox
            },
        })
    ], RepaymentManageResolve);
    return RepaymentManageResolve;
}(Vue));
export default RepaymentManageResolve;
