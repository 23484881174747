var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { reportService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var DayCollectionStatisticService = /** @class */ (function () {
    function DayCollectionStatisticService() {
    }
    /**
     * 每日调收统计
     */
    DayCollectionStatisticService.prototype.dailyCollectionStatistics = function (data, loading) {
        var model = Object.assign({}, data);
        var userIds = (model.userIds || []).join();
        return this.netService.send({
            server: reportService.reportController.dailyCollectionStatistics,
            data: {
                departCode: data.departCode,
                userIds: userIds,
                startDate: data.startDate,
                endDate: data.endDate,
            },
            loading: loading,
        });
    };
    /**
     * 每日调收统计
     */
    DayCollectionStatisticService.prototype.exportDailyStatistics = function (data, loading) {
        var model = Object.assign({}, data);
        var userIds = (model.userIds || []).join();
        return this.netService.send({
            server: reportService.reportController.exportDailyStatistics,
            data: {
                departCode: data.departCode,
                userIds: userIds,
                startDate: data.startDate,
                endDate: data.endDate,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], DayCollectionStatisticService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DayCollectionStatisticService.prototype, "dailyCollectionStatistics", null);
    __decorate([
        Debounce()
    ], DayCollectionStatisticService.prototype, "exportDailyStatistics", null);
    return DayCollectionStatisticService;
}());
export { DayCollectionStatisticService };
