var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
var CountryModify = /** @class */ (function (_super) {
    __extends(CountryModify, _super);
    function CountryModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {};
        _this.rules = {};
        return _this;
    }
    CountryModify.prototype.close = function () { };
    CountryModify.prototype.mounted = function () {
    };
    CountryModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        if (form) {
            form.validate(function (valid) {
                if (!valid) {
                    return;
                }
                // 业务处理
                _this.close();
            });
        }
    };
    __decorate([
        Emit("close")
    ], CountryModify.prototype, "close", null);
    CountryModify = __decorate([
        Component({})
    ], CountryModify);
    return CountryModify;
}(Vue));
export default CountryModify;
