var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State, Getter } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { SysParamService } from "~/services/manage-service/sys-param.service";
import ModifyParameter from "~/components/system-manage/param-manage/modify-parameter.vue";
import telaiRequest from "~/utils/telai.request";
//@Auth(2016)
var SystemParameterManage = /** @class */ (function (_super) {
    __extends(SystemParameterManage, _super);
    function SystemParameterManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.queryModel = {
            code: "",
            status: "",
        };
        _this.formRight = {
            id: undefined,
            words: "",
            prompt: "",
            remark: "",
        };
        _this.dataSet = [];
        _this.dialog = {
            modifyDialog: false,
        };
        _this.modifyData = {};
        _this.page = 1;
        _this.size = 10;
        _this.total = 0;
        return _this;
    }
    SystemParameterManage.prototype.mounted = function () {
        this.refreshData();
    };
    SystemParameterManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    SystemParameterManage.prototype.handleSizeChange = function (val) {
        this.size = val;
        this.refreshData();
    };
    SystemParameterManage.prototype.handleCurrentChange = function (val) {
        this.page = val;
        this.refreshData();
    };
    SystemParameterManage.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        var formData = new FormData();
        formData.append("page", this.page.toString());
        formData.append("size", this.size.toString());
        telaiRequest.post("/querySensitiveWordsPage", formData).then(function (resp) {
            _this.loading.state = false;
            // if (resp["code"] === 0) {
            _this.dataSet = resp["wordsVOs"];
            _this.total = resp["total"];
            // this.total = resp.data.total;
            // }
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    SystemParameterManage.prototype.addOrUpdate = function () {
        var _this = this;
        this.loading.state = true;
        var url = "addSensitiveWord";
        if (this.formRight.id !== undefined && this.formRight.id !== null && this.formRight.id !== "") {
            url = "updateSensitiveWord";
        }
        var formData = new FormData();
        formData.append("id", this.formRight.id);
        formData.append("word", this.formRight.words);
        formData.append("prompt", this.formRight.prompt);
        formData.append("remark", this.formRight.remark);
        telaiRequest.post(url, formData).then(function (resp) {
            _this.loading.state = false;
            if (resp["code"] === 0) {
                _this.$message.success("操作成功");
                _this.dialog.modifyDialog = false;
                _this.formRight = {};
                _this.refreshData();
            }
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    SystemParameterManage.prototype.deleteCommit = function (id) {
        var _this = this;
        this.$confirm("确认是否删除？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            var formData = new FormData();
            formData.append("id", id);
            telaiRequest.post("deleteSensitiveWord", formData).then(function (resp) {
                _this.loading.state = false;
                if (resp["code"] === 0) {
                    _this.$message.success("操作成功");
                    _this.refreshData();
                }
            }).catch(function (error) {
                console.log(error);
                _this.loading.state = false;
            });
        })
            .catch(function () {
        });
    };
    /**
     * 修改
     */
    SystemParameterManage.prototype.modifyClick = function (scope) {
        this.modifyData = scope.row;
        this.dialog.modifysysparamterDialog = true;
    };
    /**
     * 修改open事件 ，获取要修改的当前行数据
     */
    SystemParameterManage.prototype.getmodifyData = function () {
        var _this = this;
        this.$nextTick(function () {
            var refresh = _this.$refs["refresh"];
            refresh.refresh(_this.modifyData);
        });
    };
    __decorate([
        State
    ], SystemParameterManage.prototype, "companyList", void 0);
    __decorate([
        Getter
    ], SystemParameterManage.prototype, "isSupperAdmin", void 0);
    __decorate([
        Dependencies(SysParamService)
    ], SystemParameterManage.prototype, "sysParamService", void 0);
    __decorate([
        Dependencies(PageService)
    ], SystemParameterManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SystemParameterManage.prototype, "sortService", void 0);
    __decorate([
        State
    ], SystemParameterManage.prototype, "userData", void 0);
    SystemParameterManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ModifyParameter: ModifyParameter,
            },
        })
    ], SystemParameterManage);
    return SystemParameterManage;
}(Vue));
export default SystemParameterManage;
