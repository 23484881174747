var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Emit, Prop } from "vue-property-decorator";
import { State, namespace, Getter } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionData } from "~/config/types/collection-data";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
var collectionManageModule = namespace("collection-manage");
var BatchCase = /** @class */ (function (_super) {
    __extends(BatchCase, _super);
    function BatchCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handSet = [];
        // 个人案件和小组案件
        _this.collectionCount = {};
        _this.deptIds = [];
        _this.switchCaseType = "personal";
        _this.collectionStatus = [];
        _this.queryParamsModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            cardNo: "",
            phone: "",
            caseNumber: "",
            stage: "",
            leftAmt: [],
            collectStatus: "",
            contactStatus: "",
            employerName: "",
            address: "",
            overdueAmtTotal: [],
            payStatus: "",
            isAssist: "",
            delegationDate: [],
            comment: "",
            collectorName: "",
            departCode: "",
            overduePeriods: [],
            overdueDays: [],
            telRecordCount: "",
            city: "",
            endCaseDate: [],
            retireCaseDays: "",
            caseType: "",
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.dialog = {
            collectionExecution: false,
            caseAllot: false,
            detail: false,
        };
        _this.buttonStatus = [];
        _this.loading = {
            state: false,
        };
        _this.currentCase = {};
        _this.collectorDate = [];
        _this.contact = [];
        _this.collect = [];
        _this.rules = {
            certificateNo: [
                {
                    min: 6,
                    message: "请输入至少6位的身份证号",
                    trigger: "blur",
                },
            ],
            phone: [
                {
                    min: 4,
                    message: "请输入至少4位的手机号",
                    trigger: "blur",
                },
            ],
            cardNo: [
                {
                    min: 6,
                    message: "请输入至少6位的卡号",
                    trigger: "blur",
                },
            ],
        };
        return _this;
    }
    BatchCase.prototype.onBackCollection = function () { };
    BatchCase.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    BatchCase.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    BatchCase.prototype.cellStyle = function (_a) {
        var _this = this;
        var row = _a.row;
        if (this.collectionStatus && row.collectStatus) {
            var statusArr = row.collectStatus.split(",");
            var findArr_1 = [];
            statusArr.forEach(function (x) {
                var a = _this.collectionStatus.find(function (status) { return x === status.name; });
                if (a) {
                    findArr_1.push(a);
                }
            });
            if (findArr_1.length > 0) {
                row.color = findArr_1.reduce(function (a, b) {
                    return b.sort > a.sort ? b : a;
                }).color;
            }
        }
        if (!row.color)
            return;
        return {
            color: row.color + " !important",
        };
    };
    BatchCase.prototype.getConfigByPrin = function () {
        var _this = this;
        this.loading.state = true;
        var param = "COLLECT_STATUS";
        this.statusConfigService.findAllConfigNoPage(param, this.loading).subscribe(function (data) {
            _this.collectionStatus = data;
        });
    };
    BatchCase.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.caseCollectorService.findAllCase(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
        this.getConfigByPrin();
    };
    BatchCase.prototype.mounted = function () {
        var _this = this;
        this.queryParamsModel.groupType = this.groupType;
        this.queryParamsModel.principalId = this.principalId;
        this.queryParamsModel.batchNumber = this.batchNumber;
        // this.collectorDate = this.collectorList.userNameList.map(x => {
        //   return { value: x.name, userName: x.userName }
        // })
        this.statusConfigService.getStatusConfig(this.queryParamsModel.principalId, this.loading).subscribe(function (data) {
            _this.contact = data.contactStatus;
            _this.collect = data.collectStatus;
        });
        this.refreshData();
    };
    BatchCase.prototype.onFilterSearch = function (val) {
        this.queryParamsModel.caseFiller = val.caseFiller;
        // 查询处理
        this.refreshData();
    };
    /**
     * 机构改变
     */
    BatchCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.queryParamsModel.departCode = valueArray[valueArray.length - 1];
    };
    BatchCase.prototype.handleResetForm = function () {
        this.queryParamsModel.departCode = "";
        this.deptIds = [];
    };
    BatchCase.prototype.onBatchNumberClick = function (currentCollection) {
        var otherData = {
            sort: this.sortService.clone(),
        };
        this.queryParamsModel.casePool = "INNER"; //在调案件池
        var data = new CollectionData(this.principalId, currentCollection.id, this.queryParamsModel, otherData);
        this.openExecutionPage(data);
    };
    BatchCase.prototype.backToList = function () {
        // 展示弹框
        this.dialog.collectionExecution = false;
    };
    __decorate([
        Dependencies(PageService)
    ], BatchCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], BatchCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], BatchCase.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(StatusConfigService)
    ], BatchCase.prototype, "statusConfigService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], BatchCase.prototype, "caseCollectorService", void 0);
    __decorate([
        collectionManageModule.Action
    ], BatchCase.prototype, "openExecutionPage", void 0);
    __decorate([
        Prop({
            required: true,
        })
    ], BatchCase.prototype, "batchNumber", void 0);
    __decorate([
        Getter
    ], BatchCase.prototype, "departmentData", void 0);
    __decorate([
        Prop({
            required: true,
            default: "",
        })
    ], BatchCase.prototype, "principalId", void 0);
    __decorate([
        Prop()
    ], BatchCase.prototype, "groupType", void 0);
    __decorate([
        State
    ], BatchCase.prototype, "userData", void 0);
    __decorate([
        State
    ], BatchCase.prototype, "principalList", void 0);
    __decorate([
        State
    ], BatchCase.prototype, "collectorList", void 0);
    __decorate([
        Emit("onBackCollection")
    ], BatchCase.prototype, "onBackCollection", null);
    BatchCase = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                NumberRange: NumberRange,
                FollowAllQuery: FollowAllQuery,
            },
        })
    ], BatchCase);
    return BatchCase;
}(Vue));
export default BatchCase;
