var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import AddCaseStatus from "~/components/system-manage/case-status/add-case-status.vue";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
var CaseStatusConfig = /** @class */ (function (_super) {
    __extends(CaseStatusConfig, _super);
    function CaseStatusConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            type: "",
            principalId: "" //委托方ID
        };
        _this.dataSet = [];
        _this.dialog = {
            addCaseConfig: false
        };
        _this.rowData = {};
        return _this;
    }
    CaseStatusConfig.prototype.mounted = function () {
        this.refreshData();
    };
    CaseStatusConfig.prototype.refreshData = function () {
        var _this = this;
        this.statusConfigService
            .findAllConfig(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) { return (_this.dataSet = data); });
    };
    CaseStatusConfig.prototype.getColorName = function (color) {
        if (!color) {
            return "";
        }
        var colorInfo = this.colorList.find(function (v) { return v.value === color; });
        return colorInfo.name + "(" + colorInfo.value + ")";
    };
    CaseStatusConfig.prototype.cellStyle = function (_a) {
        var row = _a.row, column = _a.column, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        if (!row.color || column.label !== "颜色")
            return;
        return {
            color: row.color + " !important"
        };
    };
    /**
     * 新增配置
     */
    CaseStatusConfig.prototype.addCaseConfig = function () {
        this.dialog.addCaseConfig = true;
    };
    /**
     * 修改配置
     */
    CaseStatusConfig.prototype.modifyCaseConfig = function (row) {
        this.rowData = Object.assign({}, row);
        this.dialog.addCaseConfig = true;
    };
    /**
     * 删除配置
     */
    CaseStatusConfig.prototype.deleteCaseConfig = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5220\u9664" + row.name + "\u5417?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.statusConfigService.deleteConfig(row.id).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CaseStatusConfig.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CaseStatusConfig.prototype, "sortService", void 0);
    __decorate([
        Dependencies(StatusConfigService)
    ], CaseStatusConfig.prototype, "statusConfigService", void 0);
    __decorate([
        State
    ], CaseStatusConfig.prototype, "principalList", void 0);
    __decorate([
        State
    ], CaseStatusConfig.prototype, "colorList", void 0);
    CaseStatusConfig = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddCaseStatus: AddCaseStatus
            }
        })
    ], CaseStatusConfig);
    return CaseStatusConfig;
}(Vue));
export default CaseStatusConfig;
