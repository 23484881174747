var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
var DragDialog = /** @class */ (function (_super) {
    __extends(DragDialog, _super);
    function DragDialog() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DragDialog.prototype.onChange = function (val) {
        val && this.autosize();
        if (val)
            this.$emit("open");
    };
    DragDialog.prototype.mounted = function () {
        var _this = this;
        this.$nextTick(function () {
            _this.drag(_this.$refs.title, _this.$refs.popup);
        });
    };
    Object.defineProperty(DragDialog.prototype, "show", {
        get: function () {
            return this.value;
        },
        enumerable: false,
        configurable: true
    });
    DragDialog.prototype.drag = function (dragBox, moveBox) {
        if (moveBox === void 0) { moveBox = dragBox; }
        dragBox.onmousedown = function (e) {
            var disX = e.clientX - moveBox.offsetLeft;
            var disY = e.clientY - moveBox.offsetTop;
            document.onmousemove = function (e) {
                e.preventDefault();
                var l = e.clientX - disX;
                var t = e.clientY - disY;
                var x = document.documentElement.clientWidth - moveBox.offsetWidth;
                var y = document.documentElement.clientHeight - moveBox.offsetHeight;
                l = l < 0 ? 0 : (l > x ? x : l);
                t = t < 0 ? 0 : (t > y ? y : t);
                moveBox.style.left = l + 'px';
                moveBox.style.top = t + 'px';
                return false;
            };
            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
                return false;
            };
            return false;
        };
    };
    // 弹框适配屏幕居中
    DragDialog.prototype.autosize = function () {
        var _this = this;
        this.$nextTick(function () {
            var dom = _this.$refs.popup;
            var main = _this.$refs.main;
            var CHeight = document.documentElement.clientHeight;
            var CWidth = document.documentElement.clientWidth;
            var PHeight = dom.offsetHeight;
            var PWidth = dom.offsetWidth;
            main.style.maxHeight = CHeight - 100 + 'px';
            dom.style.top = (CHeight > PHeight ? (CHeight - PHeight) / 2 : 0) + 'px';
            dom.style.left = (CWidth - PWidth) / 2 + 'px';
        });
    };
    DragDialog.prototype.setEvent = function (btn) {
        this.$emit(btn.clickEvent);
        this.$emit('onbtnclick', btn);
    };
    DragDialog.prototype.close = function () {
        this.$emit('update:value', false);
        this.$emit('input', false);
    };
    __decorate([
        Prop()
    ], DragDialog.prototype, "title", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DragDialog.prototype, "value", void 0);
    __decorate([
        Prop({ type: Array, default: function () { return []; } })
    ], DragDialog.prototype, "buttons", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DragDialog.prototype, "hiddelClose", void 0);
    __decorate([
        Watch('show')
    ], DragDialog.prototype, "onChange", null);
    DragDialog = __decorate([
        Component({
            components: {}
        })
    ], DragDialog);
    return DragDialog;
}(Vue));
export default DragDialog;
