import smaController from '~/config/server/common-service/sma-controller';
import appVersionController from '~/config/server/common-service/app-version.controller';
import dataDictController from '~/config/server/common-service/data-dict.controller';
import uploadController from '~/config/server/common-service/upload-file.controller';
import repairUploadController from '~/config/server/common-service/repair-upload-file.controller';
import webSocketMsgController from '~/config/server/common-service/websocket-msg.controller';
import taskBoxController from '~/config/server/common-service/task-box.controller';
import caseRecodingController from '~/config/server/common-service/case-recoding.controller';
import beauPhoneController from '~/config/server/common-service/beau-phone.controller';
import OutboundController from '~/config/server/common-service/outbound.controller';
export var commonService = {
    smaController: smaController,
    appVersionController: appVersionController,
    dataDictController: dataDictController,
    uploadController: uploadController,
    repairUploadController: repairUploadController,
    webSocketMsgController: webSocketMsgController,
    taskBoxController: taskBoxController,
    caseRecodingController: caseRecodingController,
    beauPhoneController: beauPhoneController,
    OutboundController: OutboundController
};
