var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import DelCaseBatchNumber from "~/components/case-manage/case-adjustment/del-case-batch-number.vue";
import DelCaseBatchNumberForever from "~/components/case-manage/case-adjustment/del-case-batch-number-forever.vue";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
import AppendWorkOrder from "~/components/case-manage/case-adjustment/append-work-order.vue";
import UpdateBaseCase from "~/components/case-manage/case-all-manage/update-base-case.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { MENU_CHANNEL } from "~/config/enum.business";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { State, Getter, namespace } from "vuex-class";
var collectionManageModule = namespace('collection-manage');
var CaseAllmanage = /** @class */ (function (_super) {
    __extends(CaseAllmanage, _super);
    function CaseAllmanage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.channel = MENU_CHANNEL.ALL_CASE;
        _this.loading = {
            state: false
        };
        _this.deptIds = [];
        _this.currentCase = {};
        _this.money = '';
        _this.rowBaseCase = {};
        _this.searchModel = {
            principalId: "",
            personalName: "",
            caseDataStatus: "",
            certificateNo: "",
            batchNumber: "",
            deptCode: "",
            city: "",
            delegationDate: [],
            endCaseDate: [],
            leaveFlag: "",
            selfPhoneNo: "",
            currentCollectorName: "",
            phone: "",
            cardNo: "",
            address: "",
            account: "",
            employerName: "",
            delegationMonth: "",
            cardNoType: "",
            unhealthyFlag: "",
            receiveName: "",
            batchNo: "",
            extensionInfo: "",
            extensionAgent: "",
            payStatus: "",
            followTime: [],
            leftAmt: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            userName: ""
        };
        _this.dialog = {
            batchNumberDel: false,
            batchNumberDelForever: false,
            workerOrder: false,
            detail: false,
            updateCase: false
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.currentDate = {};
        _this.collectorDate = [];
        _this.operateId = "";
        return _this;
    }
    CaseAllmanage.prototype.mounted = function () {
        this.collectorDate = this.collectorList.userNameList.map(function (x) {
            return { value: x.name, userName: x.userName };
        });
        this.refreshData();
    };
    CaseAllmanage.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    CaseAllmanage.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    };
    CaseAllmanage.prototype.refreshData = function () {
        var _this = this;
        this.searchModel.phone = this.phone;
        this.loading.state = true;
        this.collectionCaseService
            .queryAllCollectionCase(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
        this.amount();
    };
    CaseAllmanage.prototype.amount = function () {
        var _this = this;
        this.collectionCaseService.queryAllCollectionCaseAmt(this.searchModel)
            .subscribe(function (data) {
            _this.money = data;
        });
    };
    /**
     * 机构改变
     */
    CaseAllmanage.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.deptCode = valueArray[valueArray.length - 1];
    };
    CaseAllmanage.prototype.onBatchNumberDelOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["del-case-batch-number"];
            form.refreshData();
        });
    };
    CaseAllmanage.prototype.onBatchNumberDelOpenForever = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["del-case-batch-number-forever"];
            form.refreshData();
        });
    };
    CaseAllmanage.prototype.appendWorkorder = function (scope) {
        this.currentDate = scope.row;
        this.dialog.workerOrder = true;
    };
    __decorate([
        Dependencies(PageService)
    ], CaseAllmanage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CaseAllmanage.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], CaseAllmanage.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], CaseAllmanage.prototype, "exportAssistManagementService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], CaseAllmanage.prototype, "custConfigService", void 0);
    __decorate([
        State
    ], CaseAllmanage.prototype, "phone", void 0);
    __decorate([
        Getter
    ], CaseAllmanage.prototype, "departmentData", void 0);
    __decorate([
        State
    ], CaseAllmanage.prototype, "collectorList", void 0);
    CaseAllmanage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                DelCaseBatchNumber: DelCaseBatchNumber,
                PrincipalBatch: PrincipalBatch,
                DelCaseBatchNumberForever: DelCaseBatchNumberForever,
                AppendWorkOrder: AppendWorkOrder,
                FollowAllQueryBatch: FollowAllQueryBatch,
                UpdateBaseCase: UpdateBaseCase
            }
        })
    ], CaseAllmanage);
    return CaseAllmanage;
}(Vue));
export default CaseAllmanage;
