var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Prop, Emit } from "vue-property-decorator";
import DataBox from "~/components/common/data-box.vue";
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import CommonTitle from "~/components/common/common-title.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var ApproveDetails = /** @class */ (function (_super) {
    __extends(ApproveDetails, _super);
    function ApproveDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processFlowNodes = [];
        _this.fileData = [];
        return _this;
    }
    ApproveDetails.prototype.close = function () {
        this.fileData = [];
    };
    ApproveDetails.prototype.transfer = function (item) {
        return item.approverNames;
    };
    Object.defineProperty(ApproveDetails.prototype, "stepSort", {
        get: function () {
            return this.processFlowNodes.sort(function (a, b) { return a.nodeValue - b.nodeValue; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApproveDetails.prototype, "active", {
        get: function () {
            return this.processFlowNodes.filter(function (v) { return v.approvalStatus === 'APPROVED_COMPLETED'; }).length + 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApproveDetails.prototype, "approvalHistoryData", {
        get: function () {
            return this.processFlowNodes.filter(function (x) { return x.approvalStatus === 'APPROVED_COMPLETED'; }).sort(function (a, b) { return a.nodeValue - b.nodeValue; });
        },
        enumerable: false,
        configurable: true
    });
    ApproveDetails.prototype.getProcessFlowNodes = function () {
        var _this = this;
        this.caseApplyManageService.getProcessFlowNodes(this.rowData.processApplicationId)
            .subscribe(function (data) {
            _this.processFlowNodes = data;
        });
        if (this.rowData.fileId) {
            this.uploadFileService.findUploadByFileIds(this.rowData.fileId)
                .subscribe(function (data) {
                _this.fileData = data;
            });
        }
    };
    ApproveDetails.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.id)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], ApproveDetails.prototype, "caseApplyManageService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], ApproveDetails.prototype, "uploadFileService", void 0);
    __decorate([
        Prop()
    ], ApproveDetails.prototype, "rowData", void 0);
    __decorate([
        Emit()
    ], ApproveDetails.prototype, "close", null);
    ApproveDetails = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                CommonTitle: CommonTitle
            }
        })
    ], ApproveDetails);
    return ApproveDetails;
}(Vue));
export default ApproveDetails;
