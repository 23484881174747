//全部案件
// const CaseAllmanage = () =>
//   import('~/pages/case-manage/case-allmanage/case-allmanage.vue');
//个案调整
var CaseAdjustment = function () { return import("~/pages/case-manage/case-adjustment/case-adjustment.vue"); };
//案件状态
var CommonCasePool = function () { return import("~/pages/case-manage/case-status/common-case-pool.vue"); };
//在调案件池
var CollectingCasePool = function () { return import("~/pages/case-manage/case-status/collecting-case-pool.vue"); };
//结清案件池
var SettleCasePool = function () { return import("~/pages/case-manage/case-status/settle-case-pool.vue"); };
var StopCasePool = function () { return import("~/pages/case-manage/case-status/stop-case-pool.vue"); };
var DeleteCasePool = function () { return import("~/pages/case-manage/case-status/delete-case-pool.vue"); };
var ReturnCasePool = function () { return import("~/pages/case-manage/case-status/return-case-pool.vue"); };
//导出管理
var CaseInfoExport = function () { return import("~/pages/case-manage/export-manage/case-info-export.vue"); };
var RecordFilesExport = function () { return import("~/pages/case-manage/export-manage/record-files-export.vue"); };
var OrderInfoExport = function () { return import("~/pages/case-manage/export-manage/order-info-export.vue"); };
var CollectionRecordExport = function () { return import("~/pages/case-manage/export-manage/collection-record-export.vue"); };
var DistributeCaseInfoExport = function () { return import("~/pages/case-manage/export-manage/distribute-case-info-export.vue"); };
//导入管理
var AdjustCityImport = function () { return import("~/pages/case-manage/import-manage/adjust-city-import.vue"); };
var BeforeOutImport = function () { return import("~/pages/case-manage/import-manage/before-out-import.vue"); };
var CaseImport = function () { return import("~/pages/case-manage/import-manage/case-import.vue"); };
var CaseUpdateImport = function () { return import("~/pages/case-manage/import-manage/case-update-import.vue"); };
var LeaveCaseImport = function () { return import("~/pages/case-manage/import-manage/leave-case-import.vue"); };
var StatementImport = function () { return import("~/pages/case-manage/import-manage/statement-import.vue"); };
var StopCaseImport = function () { return import("~/pages/case-manage/import-manage/stop-case-import.vue"); };
var WarnInfoImport = function () { return import("~/pages/case-manage/import-manage/warn-info-import.vue"); };
var WorkListImport = function () { return import("~/pages/case-manage/import-manage/work-list-import.vue"); };
var AnnexImport = function () { return import("~/pages/case-manage/import-manage/annex-import.vue"); };
var OutCollectionImport = function () { return import("~/pages/case-manage/import-manage/out-collection-import.vue"); };
//个人分案
var PersonAlreadyDistributeCase = function () { return import("~/pages/case-manage/personal-division/already-distribute-case.vue"); };
var PersonAlreadyDistributeWaitIssue = function () { return import("~/pages/case-manage/personal-division/already-distribute-wait-issue.vue"); };
var PersonWaitDistributeCase = function () { return import("~/pages/case-manage/personal-division/wait-distribute-case.vue"); };
//机构待分配
var OrganizationDistributeCase = function () { return import("~/pages/case-manage/distribute-manage/organization-distribute-case.vue"); };
var WaitDistributeCase = function () { return import("~/pages/case-manage/distribute-manage/wait-distribute-case.vue"); };
var CaseDivisionImport = function () { return import("~/pages/case-manage/import-manage/case-division-import.vue"); };
var CirculationDistributeCase = function () { return import("~/pages/case-manage/distribute-manage/circulation-distribute-case.vue"); };
export default [
    {
        // 个案调整-个案调整
        path: "/case-adjustment/case-adjustment",
        name: "case-adjustment",
        component: CaseAdjustment,
    },
    {
        // 案件状态-公共案件池
        path: "/case-status/common-case-pool",
        name: "common-case-pool",
        component: CommonCasePool,
    },
    {
        // 案件状态-删除案件池
        path: "/case-status/delete-case-pool",
        name: "delete-case-pool",
        component: DeleteCasePool,
    },
    {
        // 案件状态-退案案件池
        path: "/case-status/return-case-pool",
        name: "return-case-pool",
        component: ReturnCasePool,
    },
    {
        // 导出管理-案件信息导出
        path: "/export-manage/case-info-export",
        name: "case-info-export",
        component: CaseInfoExport,
    },
    {
        // 导出管理-案件信息导出
        path: "/export-manage/record-files-export",
        name: "record-files-export",
        component: RecordFilesExport,
    },
    {
        // 导出管理-工单导出
        path: "/export-manage/order-info-export",
        name: "order-info-export",
        component: OrderInfoExport,
    },
    {
        // 导出管理-分案导出
        path: "/export-manage/distribute-case-info-export",
        name: "distribute-case-info-export",
        component: DistributeCaseInfoExport,
    },
    {
        // 导出管理-调记导出
        path: "/export-manage/collection-record-export",
        name: "collection-record-export",
        component: CollectionRecordExport,
    },
    {
        // 导入管理-委前调记导入
        path: "/import-manage/before-out-import",
        name: "before-out-import",
        component: BeforeOutImport,
    },
    {
        // 导入管理-案件导入
        path: "/import-manage/case-import",
        name: "case-import",
        component: CaseImport,
    },
    {
        // 分案导入
        path: "/import-manage/case-division-import",
        name: "case-division-import",
        component: CaseDivisionImport,
    },
    {
        // 导入管理-外访调记导入
        path: "/import-manage/out-collection-import",
        name: "out-collection-import",
        component: OutCollectionImport,
    },
    {
        // 导入管理-案件更新导入
        path: "/import-manage/case-update-import",
        name: "case-update-import",
        component: CaseUpdateImport,
    },
    {
        // 导入管理-留案案件导入
        path: "/import-manage/leave-case-import",
        name: "leave-case-import",
        component: LeaveCaseImport,
    },
    {
        // 导入管理-对账单导入
        path: "/import-manage/statement-import",
        name: "statement-import",
        component: StatementImport,
    },
    {
        // 导入管理-停调案件导入
        path: "/import-manage/stop-case-import",
        name: "stop-case-import",
        component: StopCaseImport,
    },
    {
        // 导入管理-警告信息导入
        path: "/import-manage/warn-info-import",
        name: "warn-info-import",
        component: WarnInfoImport,
    },
    {
        // 导入管理-工单导入
        path: "/import-manage/work-list-import",
        name: "work-list-import",
        component: WorkListImport,
    },
    {
        // 导入管理-城市导入
        path: "/import-manage/adjust-city-import",
        name: "adjust-city-import",
        component: AdjustCityImport,
    },
    {
        path: "/import-manage/annex-import",
        name: "annex-import",
        component: AnnexImport,
    },
    {
        // 个人分案-已分配案件
        path: "/personal-division/already-distribute-case",
        name: "already-distribute-case",
        component: PersonAlreadyDistributeCase,
    },
    {
        // 个人分案-已分配待下发
        path: "/personal-division/already-distribute-wait-issue",
        name: "person-already-distribute-wait-issue",
        component: PersonAlreadyDistributeWaitIssue,
    },
    {
        // 个人分案-待分配案件
        path: "/personal-division/wait-distribute-case",
        name: "person-wait-distribute-case",
        component: PersonWaitDistributeCase,
    },
    {
        //机构待分配
        path: "/distribute-manage/organization-distribute-case",
        name: "organization-distribute-case",
        component: OrganizationDistributeCase,
    },
    {
        // 案件状态-停调案件池
        path: "/case-status/stop-case-pool",
        name: "stop-case-pool",
        component: StopCasePool,
    },
    {
        // 案件状态-在调案件池
        path: "/case-status/collecting-case-pool",
        name: "collecting-case-pool",
        component: CollectingCasePool,
    },
    {
        //待分配案件
        path: "/distribute-manage/wait-distribute-case",
        name: "wait-distribute-case",
        component: WaitDistributeCase,
    },
    {
        //结清案件池
        path: "/case-status/settle-case-pool",
        name: "settle-case-pool",
        component: SettleCasePool,
    },
    {
        //流转待分配案件池
        path: "/distribute-manage/circulation-distribute-case",
        name: "circulation-distribute-case",
        component: CirculationDistributeCase,
    },
];
