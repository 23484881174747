var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { VeHistogram } from "v-charts";
var BarEcharts = /** @class */ (function (_super) {
    __extends(BarEcharts, _super);
    function BarEcharts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.optionData = {
            data: {
                columns: ["i", "name", "val", "item", "v"],
                rows: [
                    { i: "M1", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M2", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M3", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M4", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M5", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M6", name: 233, val: 179, item: 939, v: 467 },
                    { i: "M6+", name: 233, val: 179, item: 939, v: 467 },
                ],
            },
            settings: {
                labelMap: {
                    name: "案件总数",
                    val: "当日新增案件数",
                    item: "待调解案件数",
                    v: "出调案件数",
                },
                xAxisType: "category",
            },
            colors: ["#E0B353", "#7292EE", "#54A0EE", "#4CC99B"],
            yAxis: [
                {
                    type: "value",
                    name: "单位：万元",
                    axisLabel: {
                        formatter: "{value}",
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#4C4C4C",
                            width: 1,
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dotted",
                            color: "#cccccc",
                        },
                    },
                },
            ],
            tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                left: "center",
                align: "left",
                bottom: "4%",
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 35,
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "15%",
                top: "10%",
                containLabel: true,
            },
        };
        return _this;
    }
    BarEcharts.prototype.mounted = function () { };
    BarEcharts = __decorate([
        Component({
            components: {
                VeHistogram: VeHistogram,
            },
        })
    ], BarEcharts);
    return BarEcharts;
}(Vue));
export default BarEcharts;
