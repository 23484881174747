var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var RelationshipService = /** @class */ (function () {
    function RelationshipService() {
    }
    /**
     * 关联关系查询
     */
    RelationshipService.prototype.search = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.relationshipController.search,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 关联关系创建
     */
    RelationshipService.prototype.insert = function (data, loading) {
        return this.netService.send({
            server: repairService.relationshipController.insert,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除关联关系
     */
    RelationshipService.prototype.delete = function (data, loading) {
        return this.netService.send({
            server: repairService.relationshipController.delete,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改关联关系
     */
    RelationshipService.prototype.update = function (data, loading) {
        return this.netService.send({
            server: repairService.relationshipController.update,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改关联关系
     */
    RelationshipService.prototype.insertBatch = function (data, loading) {
        return this.netService.send({
            server: repairService.relationshipController.insertBatch,
            data: {
                createRelationshipRequestList: data
            },
            loading: loading
        });
    };
    /**
     * 按导入批次删除户籍资料
     */
    RelationshipService.prototype.deleteByBatch = function (batchNumber, loading) {
        return this.netService.send({
            server: repairService.relationshipController.deleteByBatch,
            data: {
                batchNumber: batchNumber
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], RelationshipService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], RelationshipService.prototype, "search", null);
    return RelationshipService;
}());
export { RelationshipService };
