var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { PersonalService } from "~/services/business-service/personal.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { CommonService } from "~/utils/common.service";
import { ValidatorService } from "~/utils/validator.service";
import { FilterService } from '~/utils/filter.service';
var collectionManageModule = namespace("collection-manage");
var PhoneModify = /** @class */ (function (_super) {
    __extends(PhoneModify, _super);
    function PhoneModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            id: "",
            // personalPerId: "",
            name: "",
            certificateNo: "",
            caseId: '',
            principalId: '',
            mobile: "",
            relation: "",
            phoneNo: "",
            source: "",
            remark: "",
            workNo: '',
            caseType: ''
        };
        _this.rules = {
            name: [{ required: true, message: "请输入关系人姓名", trigger: "blur" },
                { validator: ValidatorService.chineseName }
            ],
            relation: [{ required: true, message: "请输入关系" }],
            banState: [{ required: true, message: "请选择调解类型" }],
            mobile: [
                { required: true, message: "请输入电话号码" },
                { validator: ValidatorService.callNumber }
            ]
        };
        return _this;
    }
    PhoneModify.prototype.close = function () {
        var form = this.$refs["form"];
        form.resetFields();
        this.model.id = "";
        this.model.relation = "";
        this.model.source = "";
    };
    PhoneModify.prototype.emitSuccess = function () {
        this.$message.success("操作成功");
        this.close();
    };
    PhoneModify.prototype.updatedModel = function (currentRelation) {
        if (!currentRelation.id)
            return;
        CommonService.revertData(this.model, currentRelation);
        this.model.phoneNo = FilterService.encryptPhoneNumber(currentRelation.phoneNo);
    };
    PhoneModify.prototype.onChange = function () {
        if (!this.model.relation)
            return;
        if (this.model.relation == '本人') {
            this.model.name = this.currentCase.personalName;
            this.model.certificateNo = this.currentCase.certificateNo;
        }
    };
    /**
     * 新增联系人
     */
    PhoneModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        this.model.caseId = this.caseId;
        this.model.principalId = this.currentCase.principalId;
        this.model.caseType = this.otherData.caseType;
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            if (_this.model.id) {
                delete _this.model.phoneNo;
                // 案件id
                _this.personalServicePhoneModify
                    .modifyPersonalContact(_this.model, _this.loading)
                    .subscribe(_this.emitSuccess);
            }
            else {
                _this.personalServicePhoneModify
                    .addPersonalContact(_this.model, _this.loading)
                    .subscribe(_this.emitSuccess);
            }
        });
    };
    __decorate([
        Dependencies(PersonalService)
    ], PhoneModify.prototype, "personalServicePhoneModify", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], PhoneModify.prototype, "custConfigService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PhoneModify.prototype, "personalInfo", void 0);
    __decorate([
        collectionManageModule.Getter
    ], PhoneModify.prototype, "personalId", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneModify.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneModify.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], PhoneModify.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], PhoneModify.prototype, "caseId", void 0);
    __decorate([
        Emit("close")
    ], PhoneModify.prototype, "close", null);
    __decorate([
        Emit('success')
    ], PhoneModify.prototype, "emitSuccess", null);
    PhoneModify = __decorate([
        Component({
            components: {}
        })
    ], PhoneModify);
    return PhoneModify;
}(Vue));
export default PhoneModify;
