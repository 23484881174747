var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var ExportAssistManagementService = /** @class */ (function (_super) {
    __extends(ExportAssistManagementService, _super);
    function ExportAssistManagementService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 协调管理导出
     */
    ExportAssistManagementService.prototype.exportAssistManagement = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportAssistManagement,
            data: {
                applyIds: data.applyIds,
                applyType: data.applyType,
                principalId: data.principalId,
                batchNumber: data.batchNumber,
            },
            loading: loading,
        });
    };
    /**
     * 导出调记
     */
    ExportAssistManagementService.prototype.exportFolllowRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportFolllowRecord,
            data: {
                configId: data.configId,
                caseIds: data.caseIds,
                caseDataStatus: data.caseDataStatus,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                certificateNo: data.certificateNo,
                city: data.city,
                currentCollectorName: data.currentCollectorName,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                leaveFlag: data.leaveFlag,
                leftAmtDollarEnd: data.leftAmtDollarEnd,
                leftAmtDollarStart: data.leftAmtDollarStart,
                leftAmtEnd: data.leftAmtEnd,
                leftAmtStart: data.leftAmtStart,
                personalName: data.personalName,
                principalName: data.principalName,
                selfPhoneNo: data.selfPhoneNo,
                principalId: data.principalId,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                certificateNos: data.certificateNos,
                citys: data.citys,
                receiveName: data.receiveName,
                account: data.account,
                cardNo: data.cardNo,
                handsNumber: data.handsNumber,
                unhealthyFlag: data.unhealthyFlag,
                departCode: data.departCode,
                isSettle: data.isSettle,
            },
            loading: loading,
        });
    };
    /**
     * 案件导出
     */
    ExportAssistManagementService.prototype.exportInPoolCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportInPoolCase,
            data: {
                configId: data.configId,
                caseIds: data.caseIds,
                caseDataStatus: data.caseDataStatus,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                certificateNo: data.certificateNo,
                city: data.city,
                currentCollectorName: data.currentCollectorName,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                leaveFlag: data.leaveFlag,
                leftAmtDollarEnd: data.leftAmtDollarEnd,
                leftAmtDollarStart: data.leftAmtDollarStart,
                leftAmtEnd: data.leftAmtEnd,
                leftAmtStart: data.leftAmtStart,
                personalName: data.personalName,
                principalId: data.principalId,
                selfPhoneNo: data.selfPhoneNo,
            },
            loading: loading,
        });
    };
    // 案件导出2
    ExportAssistManagementService.prototype.exportHisCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportHisCase,
            data: {
                configId: data.configId,
                caseIds: data.caseIds,
                caseDataStatus: data.caseDataStatus,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                certificateNo: data.certificateNo,
                city: data.city,
                currentCollectorName: data.currentCollectorName,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                leaveFlag: data.leaveFlag,
                leftAmtDollarEnd: data.leftAmtDollarEnd,
                leftAmtDollarStart: data.leftAmtDollarStart,
                leftAmtEnd: data.leftAmtEnd,
                leftAmtStart: data.leftAmtStart,
                personalName: data.personalName,
                principalName: data.principalName,
                selfPhoneNo: data.selfPhoneNo,
            },
            loading: loading,
        });
    };
    /**
     * 协调管理导入
     */
    ExportAssistManagementService.prototype.importExcelAssistManagementData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.importExcelAssistManagementData,
            data: {
                applyType: data.applyType,
                fileId: data.fileId,
            },
            loading: loading,
        });
    };
    /**
     * 获取模板导出属性列表
     * @param data
     * @param loading
     */
    ExportAssistManagementService.prototype.getExportProperty = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.getExportProperty,
            data: {
                exportType: data.exportType,
                principalId: data.principalId,
            },
            loading: loading,
        });
    };
    /**
     * 导出历史案件调记
     * @param data
     * @param loading
     */
    ExportAssistManagementService.prototype.exportHisCaseFolllowRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportHisCaseFolllowRecord,
            data: {
                leftAmtEnd: data.leftAmtEnd,
                leftAmtStart: data.leftAmtStart,
                principalId: data.principalId,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                certificateNos: data.certificateNos,
                citys: data.citys,
                batchNumber: data.batchNumber,
                configId: data.configId,
                receiveName: data.receiveName,
                account: data.account,
                cardNo: data.cardNo,
                handsNumber: data.handsNumber,
                unhealthyFlag: data.unhealthyFlag,
                departCode: data.departCode,
                isSettle: data.isSettle,
            },
            loading: loading,
        });
    };
    /**
     * 导出调收电话
     * @param data
     * @param loading
     */
    ExportAssistManagementService.prototype.exportPersonalPhone = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportPersonalPhone,
            data: {
                leftAmtEnd: data.leftAmtEnd,
                leftAmtStart: data.leftAmtStart,
                principalId: data.principalId,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                certificateNos: data.certificateNos,
                citys: data.citys,
                batchNumber: data.batchNumber,
                receiveName: data.receiveName,
                account: data.account,
                cardNo: data.cardNo,
                handsNumber: data.handsNumber,
                unhealthyFlag: data.unhealthyFlag,
                departCode: data.departCode,
                isSettle: data.isSettle,
            },
            loading: loading,
        });
    };
    /**
     * 导出工单
     * @param data
     * @param loading
     */
    ExportAssistManagementService.prototype.exportOrderInfo = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportOrderInfo,
            data: {
                principalId: data.principalId,
                orderTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                orderTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
            },
            loading: loading,
        });
    };
    /**
     *获取批次号
     */
    ExportAssistManagementService.prototype.getBatchNumbers = function (data) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.getBatchNumbers,
            data: {
                type: data.type,
                principalId: data.principalId,
            },
        });
    };
    /**
     *补全调记
     */
    ExportAssistManagementService.prototype.suppleCollRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.suppleCollRecord,
            data: {
                principalId: data.principalId,
                operBatchNumber: data.operBatchNumber,
                type: data.type,
                num: data.num,
                caseStatus: data.caseStatus,
                startDate: FilterService.dateRanageFormat(data.applyDate, "yyyy-MM-dd").start,
                endDate: FilterService.dateRanageFormat(data.applyDate, "yyyy-MM-dd").end,
            },
            loading: loading,
        });
    };
    /**
     *分案导出
     */
    ExportAssistManagementService.prototype.exportCaseDisInfo = function (data) {
        return this.netService.send({
            server: dataimpService.exportAssistManagementController.exportCaseDisInfo,
            data: {
                type: data.type,
                principalId: data.principalId,
                departCode: data.departCode,
                batchNumber: data.batchNumber,
                citys: data.citys,
            },
        });
    };
    __decorate([
        Inject(NetService)
    ], ExportAssistManagementService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ExportAssistManagementService.prototype, "exportAssistManagement", null);
    __decorate([
        Debounce()
    ], ExportAssistManagementService.prototype, "exportFolllowRecord", null);
    __decorate([
        Debounce()
    ], ExportAssistManagementService.prototype, "importExcelAssistManagementData", null);
    return ExportAssistManagementService;
}(Service));
export { ExportAssistManagementService };
