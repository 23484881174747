var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
var collectionManageModule = namespace("collection-manage");
var FollowRecordOther = /** @class */ (function (_super) {
    __extends(FollowRecordOther, _super);
    function FollowRecordOther() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.rules = {
            findTime: [
                { required: true, message: "请选择查找时间", trigger: "blur" }
            ],
        };
        _this.dataSet = [
            {
                level: "提示",
                sensitiveWord: ""
            },
            {
                level: "严重",
                sensitiveWord: ""
            }
        ];
        _this.dialog = {
            sensitiveWord: false
        };
        _this.otherModel = {
            caseId: "",
            record: "",
            findTime: "",
            otherFollowType: "",
            isUpdate: "NO",
            principalId: '',
            principalName: '',
            caseType: ""
        };
        return _this;
    }
    FollowRecordOther.prototype.refresh = function () {
        this.reset();
    };
    FollowRecordOther.prototype.saveRecord = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            var params = {
                principalId: _this.principalId,
                str: Object.values(_this.otherModel).map(function (v) { return v; }).join("&")
            };
            _this.loading.state = true;
            _this.caseFollowRecordService.checkString(params, _this.loading).subscribe(function (data) {
                if (data) {
                    if (data.prompt.length || data.serious.length) {
                        _this.dialog.sensitiveWord = true;
                        var a = ""; // 敏感词提示
                        var b = ""; // 敏感词严重
                        if (data.prompt.length > 0) {
                            for (var _i = 0, _a = data.prompt; _i < _a.length; _i++) {
                                var i = _a[_i];
                                a += i;
                            }
                        }
                        if (data.serious.length > 0) {
                            for (var _b = 0, _c = data.serious; _b < _c.length; _b++) {
                                var i = _c[_b];
                                b += i;
                            }
                        }
                        _this.dataSet[0].sensitiveWord = a;
                        _this.dataSet[1].sensitiveWord = b;
                    }
                    else {
                        _this.conserve();
                    }
                }
                else {
                    _this.conserve();
                }
            });
        });
    };
    FollowRecordOther.prototype.conserve = function () {
        var _this = this;
        var form = this.$refs["form"];
        if (form) {
            form.validate(function (valid) {
                if (!valid) {
                    return;
                }
                _this.otherModel.caseId = _this.caseId;
                _this.otherModel.caseId = _this.currentCase.caseId;
                _this.otherModel.principalId = _this.currentCase.principalId;
                _this.otherModel.principalName = _this.currentCase.principalName;
                _this.otherModel.caseType = _this.otherData.caseType;
                _this.loading.state = true;
                _this.caseFollowRecordService
                    .addOtherRecord(_this.otherModel, _this.loading)
                    .subscribe(function (data) {
                    _this.$message.success("添加其他跟进记录成功");
                    _this.dialog.sensitiveWord = false;
                    _this.reset();
                });
            });
        }
    };
    FollowRecordOther.prototype.reset = function () {
        var form = this.$refs["form"];
        this.otherModel.record = "";
        form.resetFields();
    };
    __decorate([
        Dependencies(CaseFollowService)
    ], FollowRecordOther.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowRecordOther.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State,
        collectionManageModule.State
    ], FollowRecordOther.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordOther.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordOther.prototype, "otherData", void 0);
    FollowRecordOther = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                DataBox: DataBox
            }
        })
    ], FollowRecordOther);
    return FollowRecordOther;
}(Vue));
export default FollowRecordOther;
