var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { RoleService } from "~/services/manage-service/role.service";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import AddRole from "~/components/system-manage/role-manage/add-role.vue";
import ModifyRole from "~/components/system-manage/role-manage/modify-role.vue";
import UserList from "~/components/system-manage/role-manage/user-list.vue";
import ModulePermission from "~/components/system-manage/role-manage/module-permission.vue";
import BatchUpdateRole from "~/components/system-manage/role-manage/batch-update-role.vue";
import { State, Getter } from "vuex-class";
// @Auth(854)
var RoleManage = /** @class */ (function (_super) {
    __extends(RoleManage, _super);
    function RoleManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.role = {};
        _this.roleModel = {
            name: "",
            status: "",
            companyCode: "",
            operator: ""
        };
        _this.roleDataSet = [];
        _this.selectionList = [];
        _this.selectionIds = [];
        _this.roleObj = {};
        _this.dialog = {
            add: false,
            modify: false,
            module: false,
            list: false,
            update: false
        };
        return _this;
    }
    Object.defineProperty(RoleManage.prototype, "companyCode", {
        /**
         * 考虑到非管理员不显示公司选择控件
         * get 操作的时候强制设置非管理员所在公司码
         */
        get: function () {
            if (!this.isSupperAdmin) {
                this.roleModel.companyCode = this.userData.companyCode;
            }
            return this.roleModel.companyCode;
        },
        set: function (value) {
            this.roleModel.companyCode = value;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 页面激活
     */
    RoleManage.prototype.activated = function () {
        if (!this.isSupperAdmin) {
            this.refreshData();
        }
    };
    /**
     * 复制角色
     */
    RoleManage.prototype.copyRole = function (scope) {
        var _this = this;
        this.$confirm("您确认要复制改角色吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.roleService.copyRole(scope.row.id).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "复制成功!"
                });
                _this.refreshData();
            });
        }).catch(function () {
            _this.$message({
                type: "info",
                message: "已取消复制!"
            });
        });
    };
    /**
     * 删除
     */
    RoleManage.prototype.deleteClick = function (scope) {
        var _this = this;
        this.$confirm("您确认要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.roleService.deleteRole(scope.row.id, _this.loading).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                _this.pageService.reset();
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
                _this.refreshData();
            });
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消删除!"
            });
        });
    };
    RoleManage.prototype.mounted = function () {
        this.refreshData();
    };
    RoleManage.prototype.refreshData = function () {
        var _this = this;
        this.roleService.findAllRole(this.roleModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.roleDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    RoleManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    /**
   * 角色权限修改
   */
    RoleManage.prototype.updateRole = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要修改的角色");
            return;
        }
        this.selectionIds = this.selectionList.map(function (role) { return role.id; });
        this.role = this.selectionList[0];
        this.dialog.update = true;
    };
    __decorate([
        Dependencies(RoleService)
    ], RoleManage.prototype, "roleService", void 0);
    __decorate([
        Dependencies(PageService)
    ], RoleManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], RoleManage.prototype, "sortService", void 0);
    __decorate([
        State
    ], RoleManage.prototype, "companyList", void 0);
    __decorate([
        State
    ], RoleManage.prototype, "userData", void 0);
    __decorate([
        Getter
    ], RoleManage.prototype, "isSupperAdmin", void 0);
    RoleManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                ModulePermission: ModulePermission,
                BatchUpdateRole: BatchUpdateRole,
                AddRole: AddRole,
                ModifyRole: ModifyRole,
                UserList: UserList,
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], RoleManage);
    return RoleManage;
}(Vue));
export default RoleManage;
