var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import TurnApply from "~/components/collection-manage/collection-application/turn-apply.vue";
import ApproveDetails from "~/components/collection-manage/collection-application/approve-details.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseApplyManageService } from "~/services/business-service/case-apply-manage.service";
import { FlowConfigurationService } from "~/services/business-service/flow-configuration.service";
var collectionManageModule = namespace("collection-manage");
var TurnCasesApply = /** @class */ (function (_super) {
    __extends(TurnCasesApply, _super);
    function TurnCasesApply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.rowData = {};
        _this.dataSet = [];
        _this.dialog = {
            turnApply: false,
            approveDetail: false,
        };
        _this.queryParamsModel = {
            caseId: "",
            flowType: "TEL_TO_VISIT",
        };
        return _this;
    }
    TurnCasesApply.prototype.initiataApply = function () {
        this.dialog.turnApply = true;
    };
    TurnCasesApply.prototype.approveDetail = function (row) {
        this.dialog.approveDetail = true;
        this.rowData = row;
    };
    TurnCasesApply.prototype.refreshData = function () {
        var _this = this;
        this.queryParamsModel.caseId = this.caseId;
        this.loading.state = true;
        this.caseApplyManageService.getProcessApplicationByFlowType(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], TurnCasesApply.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], TurnCasesApply.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], TurnCasesApply.prototype, "caseApplyManageService", void 0);
    __decorate([
        Dependencies(FlowConfigurationService)
    ], TurnCasesApply.prototype, "flowConfigurationService", void 0);
    __decorate([
        collectionManageModule.State
    ], TurnCasesApply.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.State
    ], TurnCasesApply.prototype, "caseId", void 0);
    TurnCasesApply = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                TurnApply: TurnApply,
                ApproveDetails: ApproveDetails,
            },
        })
    ], TurnCasesApply);
    return TurnCasesApply;
}(Vue));
export default TurnCasesApply;
