import { requestType } from '~/config/enum.config';
var SERVICE = 'repair-service';
var CONTROLLER = 'kosekiData';
export default {
    /**
     * 查询户籍资料
     */
    search: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'search',
        type: requestType.Get
    },
    /**
     * 增加户籍资料
     */
    insert: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'insert',
        type: requestType.Post
    },
    /**
     * 删除户籍资料
     */
    delete: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'delete',
        type: requestType.Get
    },
    /**
     * 修改户籍资料
     */
    update: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'update',
        type: requestType.Post
    },
    /**
     * 按照elasticSearch的文档名称搜索导入批次结果
     */
    searchGroupResult: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchGroupResult',
        type: requestType.Get
    },
    /**
     * 按导入批次删除户籍资料
     */
    deleteByBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'deleteByBatch',
        type: requestType.Get
    }
};
