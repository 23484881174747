var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FollowDetailsQuery from "~/components/collection-manage/co-urging/follow-details-query.vue";
import { Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CollectionData } from "~/config/types/collection-data";
import { AssistCaseService } from "~/services/business-service/assist-case.service";
var collectionManageModule = namespace("collection-manage");
var ForeignVisits = /** @class */ (function (_super) {
    __extends(ForeignVisits, _super);
    function ForeignVisits() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rules = {
            certificateNo: [
                {
                    min: 6,
                    message: "请输入至少6位的身份证号",
                    trigger: "blur",
                },
            ],
        };
        _this.certificateNo = "";
        _this.assistOutType = "";
        _this.queryParamsModel = {
            principalId: "",
            batchNumber: "",
            caseNumber: "",
            personalName: "",
            assistStatus: "",
            certificateNo: "",
            assistCollector: "",
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.dialog = {
            followDetailsQuery: false,
        };
        _this.loading = {
            state: false,
        };
        return _this;
    }
    ForeignVisits.prototype.routeChange = function (val) {
        if (!val)
            return;
        this.handleResetForm();
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
    };
    ForeignVisits.prototype.cellStyle = function (_a) {
        var row = _a.row;
        if (!row.color)
            return;
        return {
            color: row.color + " !important",
        };
    };
    ForeignVisits.prototype.handleResetForm = function () {
        this.queryParamsModel.batchNumber = "";
        this.queryParamsModel.personalName = "";
        this.queryParamsModel.caseNumber = "";
        this.queryParamsModel.assistStatus = "";
        this.queryParamsModel.certificateNo = "";
        this.queryParamsModel.assistCollector = "";
    };
    ForeignVisits.prototype.refreshData = function () {
        var _this = this;
        this.assistCaseService.searchAssistCollectionCase(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    ForeignVisits.prototype.onBatchNumberClick = function (currentCollection) {
        var otherData = {
            sort: this.sortService.clone(),
            caseType: "TEL_CASE",
        };
        this.queryParamsModel.casePool = "ASSIST_CASE"; //协调案件池
        var data = new CollectionData(this.queryParamsModel.principalId, currentCollection.caseId, this.queryParamsModel, otherData);
        this.openExecutionPage(data);
    };
    ForeignVisits.prototype.onEnd = function (data) {
        var _this = this;
        this.$confirm("您确定结束协调吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            var assistId = data.id;
            _this.loading.state = true;
            _this.assistCaseService.endAssistCaseCollecting(assistId, _this.loading).subscribe(function (data) {
                _this.$message.success("结束协调成功!"), _this.refreshData();
            });
        })
            .catch(function () { });
    };
    __decorate([
        Dependencies(PageService)
    ], ForeignVisits.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], ForeignVisits.prototype, "sortService", void 0);
    __decorate([
        Dependencies(AssistCaseService)
    ], ForeignVisits.prototype, "assistCaseService", void 0);
    __decorate([
        collectionManageModule.Action
    ], ForeignVisits.prototype, "openExecutionPage", void 0);
    __decorate([
        State
    ], ForeignVisits.prototype, "principalList", void 0);
    __decorate([
        Watch("$route", {
            immediate: true,
        })
    ], ForeignVisits.prototype, "routeChange", null);
    ForeignVisits = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FollowDetailsQuery: FollowDetailsQuery,
            },
        })
    ], ForeignVisits);
    return ForeignVisits;
}(Vue));
export default ForeignVisits;
