var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { State, Getter } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { SystemLogService } from "~/services/manage-service/system-log.service";
// @Auth(858)
var SystemLog = /** @class */ (function (_super) {
    __extends(SystemLog, _super);
    function SystemLog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.loading = {
            state: false
        };
        _this.systemLogModel = {
            operator: '',
            remark: '',
            operatorTime: []
        };
        _this.dataSet = [];
        return _this;
    }
    /**
    * 页面激活
    */
    SystemLog.prototype.activated = function () {
        if (!this.isSupperAdmin) {
            this.refreshData();
        }
    };
    /**
     *
     */
    SystemLog.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新
     */
    SystemLog.prototype.refreshData = function () {
        var _this = this;
        this.systemLogService.findAllSystemLog(this.systemLogModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    SystemLog.prototype.onBatchDel = function () {
        var _this = this;
        var existValues = Object.values(this.systemLogModel).filter(function (v) {
            if (v)
                return true;
            return false;
        });
        if (this.selectionList.length === 0 && existValues.length === 0) {
            this.$message.info('请选择或输入查询条件再执行删除操作');
            return;
        }
        // 执行删除操作
        this.$confirm("确认要删除日志？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.loading.state = true;
            var ids = [];
            _this.selectionList.map(function (e) { return ids.push(e.id); });
            _this.systemLogService.batchDeleteSystemLog(ids, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        }).catch(function () { });
    };
    __decorate([
        State
    ], SystemLog.prototype, "userData", void 0);
    __decorate([
        Getter
    ], SystemLog.prototype, "isSupperAdmin", void 0);
    __decorate([
        Dependencies(SystemLogService)
    ], SystemLog.prototype, "systemLogService", void 0);
    __decorate([
        Dependencies(PageService)
    ], SystemLog.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SystemLog.prototype, "sortService", void 0);
    SystemLog = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], SystemLog);
    return SystemLog;
}(Vue));
export default SystemLog;
