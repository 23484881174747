var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
var RelateNameDialog = /** @class */ (function (_super) {
    __extends(RelateNameDialog, _super);
    function RelateNameDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.searchModel = {
            name: "",
        };
        _this.excelTemplateType = "";
        _this.selectCaseRelate = null;
        _this.selectPersonalRelate = null;
        _this.selectBillRelate = null;
        _this.selectPreRelate = null;
        _this.selectWorkRelate = null;
        _this.selectWarnRelate = null;
        _this.selectLeaveRelate = null;
        _this.selectStopRelate = null;
        _this.selectDistributeRelate = null;
        _this.disabled = false;
        _this.caseData = [];
        _this.personalData = [];
        _this.billData = [];
        _this.preData = [];
        _this.workData = [];
        _this.warnData = [];
        _this.leaveData = [];
        _this.stopData = [];
        _this.distributeData = [];
        _this.propertyResponse = {};
        return _this;
    }
    RelateNameDialog.prototype.close = function () { };
    Object.defineProperty(RelateNameDialog.prototype, "isShowCase", {
        get: function () {
            if ((this.billData && this.billData.length) ||
                (this.preData && this.preData.length) ||
                (this.workData && this.workData.length) ||
                (this.warnData && this.warnData.length) ||
                (this.leaveData && this.leaveData.length) ||
                (this.stopData && this.stopData.length) ||
                (this.distributeData && this.distributeData.length))
                return false;
            else
                return this.excelRowData.sheetNumber == 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowPerson", {
        get: function () {
            return (this.sheetLength == 1 || this.excelRowData.sheetNumber == 2) && this.personalData && this.personalData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowBill", {
        get: function () {
            return this.billData && this.billData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowPre", {
        get: function () {
            return this.preData && this.preData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowWork", {
        get: function () {
            return this.workData && this.workData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowWarn", {
        get: function () {
            return this.warnData && this.warnData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowLeave", {
        get: function () {
            return this.leaveData && this.leaveData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowStop", {
        get: function () {
            return this.stopData && this.stopData.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelateNameDialog.prototype, "isShowDistribute", {
        get: function () {
            return this.distributeData && this.distributeData.length;
        },
        enumerable: false,
        configurable: true
    });
    RelateNameDialog.prototype.mounted = function () { };
    RelateNameDialog.prototype.refreshData = function (templateType, propertyResponse) {
        if (propertyResponse === void 0) { propertyResponse = {}; }
        this.excelTemplateType = templateType;
        // 加载数据
        this.propertyResponse = propertyResponse;
        this.getCaseInfoProperty(propertyResponse, templateType);
    };
    /**
     * 获取案件信息内置字段
     */
    RelateNameDialog.prototype.getCaseInfoProperty = function (data, templateType) {
        var _this = this;
        if (data) {
            this.caseData = data.filter(function (v) { return v.propertyType === "CASE"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            if (templateType === "CASE_UPDATE") {
                this.personalData = data.filter(function (v) { return v.propertyType === "PERSONAL" && v.name !== "证件号"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            }
            else {
                this.personalData = data.filter(function (v) { return v.propertyType === "PERSONAL"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            }
            this.billData = data.filter(function (v) { return v.propertyType === "BILL"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.preData = data.filter(function (v) { return v.propertyType === "FOLLOW"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.workData = data.filter(function (v) { return v.propertyType === "WORK_ORDER"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.warnData = data.filter(function (v) { return v.propertyType === "WARNING"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.leaveData = data.filter(function (v) { return v.propertyType === "LEAVE"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.stopData = data.filter(function (v) { return v.propertyType === "STOP"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            this.distributeData = data.filter(function (v) { return v.propertyType === "DISTRIBUTE"; }).map(function (v) { return Object.assign({ isShow: true }, v); });
            // 设置当前选中项
            if (this.excelRowData) {
                if (this.excelRowData.propertyType) {
                    this.selectCaseRelate = this.caseData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "CASE"; });
                    this.selectPersonalRelate = this.personalData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "PERSONAL"; });
                    this.selectBillRelate = this.billData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "BILL"; });
                    this.selectPreRelate === this.preData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "FOLLOW"; });
                    this.selectWorkRelate === this.workData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "WORK_ORDER"; });
                    this.selectWarnRelate === this.warnData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "WARNING"; });
                    this.selectLeaveRelate === this.leaveData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "LEAVE"; });
                    this.selectStopRelate === this.stopData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "STOP"; });
                    this.selectDistributeRelate === this.distributeData.find(function (v) { return v.attribute === _this.excelRowData.attribute && _this.excelRowData.propertyType === "DISTRIBUTE"; });
                }
                else {
                    this.selectCaseRelate = null;
                    this.selectPersonalRelate = null;
                    this.selectBillRelate = null;
                    this.selectPreRelate = null;
                    this.selectWorkRelate = null;
                    this.selectWarnRelate = null;
                    this.selectLeaveRelate = null;
                    this.selectStopRelate = null;
                    this.selectDistributeRelate = null;
                }
            }
            else {
                this.selectCaseRelate = null;
                this.selectPersonalRelate = null;
                this.selectBillRelate = null;
                this.selectPreRelate = null;
                this.selectWorkRelate = null;
                this.selectWarnRelate = null;
                this.selectLeaveRelate = null;
                this.selectStopRelate = null;
                this.selectDistributeRelate = null;
            }
        }
    };
    RelateNameDialog.prototype.disStr = function (v) {
        return !!this.disableItems.find(function (val) { return val.attribute === v.attribute && val.propertyType === v.propertyType; });
    };
    RelateNameDialog.prototype.disBuss = function (item) {
        // 只有一个sheet页
        // 禁用个人信息中的 姓名[personalName] 证件号[certificateNo] 证件类型[certificateType] 案件编号[caseNumber] 卡号[cardNo1] 账号[cardNo]
        // let bussisDisabled = this.personalData.find(
        //   val =>
        //     this.isShowCase &&
        //     val.attribute === item.attribute &&
        //     val.propertyType === item.propertyType &&
        //     // 姓名
        //     ((val.attribute === "name" &&
        //       val.propertyType === item.propertyType) ||
        //       //  证件号
        //       (val.attribute === "certificateNo" &&
        //         val.propertyType === item.propertyType) ||
        //       // 证件类型
        //       (val.attribute === "certificateType" &&
        //         val.propertyType === item.propertyType) ||
        //       // 案件编号
        //       (val.attribute === "caseNumber" &&
        //         val.propertyType === item.propertyType) ||
        //       // 卡号
        //       (val.attribute === "cardNo" &&
        //         val.propertyType === item.propertyType) ||
        //       // 账号
        //       (val.attribute === "account" &&
        //         val.propertyType === item.propertyType))
        // );
        // return !!bussisDisabled;
    };
    /**
     * 取消
     */
    RelateNameDialog.prototype.cancelClick = function () {
        this.selectPersonalRelate = null;
        this.selectCaseRelate = null;
        this.selectBillRelate = null;
        this.selectPreRelate = null;
        this.selectWorkRelate = null;
        this.selectWarnRelate = null;
        this.selectLeaveRelate = null;
        this.selectStopRelate = null;
        this.selectDistributeRelate = null;
        this.close();
    };
    /**
     * 案件切换
     */
    RelateNameDialog.prototype.onCaseChange = function () {
        this.selectPersonalRelate = null;
    };
    /**
     * 个人切换
     */
    RelateNameDialog.prototype.onPersonalChange = function () {
        this.selectCaseRelate = null;
    };
    /**
     * 确定
     */
    RelateNameDialog.prototype.saveClick = function () {
        var currentSelected;
        if (this.selectCaseRelate) {
            currentSelected = this.selectCaseRelate;
        }
        else if (this.selectPersonalRelate) {
            currentSelected = this.selectPersonalRelate;
        }
        else if (this.selectBillRelate) {
            currentSelected = this.selectBillRelate;
        }
        else if (this.selectPreRelate) {
            currentSelected = this.selectPreRelate;
        }
        else if (this.selectWorkRelate) {
            currentSelected = this.selectWorkRelate;
        }
        else if (this.selectWarnRelate) {
            currentSelected = this.selectWarnRelate;
        }
        else if (this.selectLeaveRelate) {
            currentSelected = this.selectLeaveRelate;
        }
        else if (this.selectStopRelate) {
            currentSelected = this.selectStopRelate;
        }
        else if (this.selectDistributeRelate) {
            currentSelected = this.selectDistributeRelate;
        }
        if (this.excelRowData) {
            this.excelRowData.name = currentSelected.name;
            this.excelRowData.attribute = currentSelected.attribute;
            this.excelRowData.propertyType = currentSelected.propertyType;
        }
        this.close();
    };
    /**
     * 检索关键字
     */
    RelateNameDialog.prototype.onQuery = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            if (_this.searchModel.name) {
                _this.caseData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.personalData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.billData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.preData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.workData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.warnData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.leaveData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.stopData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
                _this.distributeData.map(function (v) {
                    if (v.name.indexOf(_this.searchModel.name) != -1)
                        v.isShow = true;
                    else
                        v.isShow = false;
                });
            }
            else {
                _this.caseData.map(function (v) { return (v.isShow = true); });
                _this.personalData.map(function (v) { return (v.isShow = true); });
                _this.billData.map(function (v) { return (v.isShow = true); });
                _this.preData.map(function (v) { return (v.isShow = true); });
                _this.workData.map(function (v) { return (v.isShow = true); });
                _this.warnData.map(function (v) { return (v.isShow = true); });
                _this.leaveData.map(function (v) { return (v.isShow = true); });
                _this.stopData.map(function (v) { return (v.isShow = true); });
                _this.distributeData.map(function (v) { return (v.isShow = true); });
            }
        });
    };
    RelateNameDialog.prototype.reset = function () {
        this.searchModel.name = "";
    };
    __decorate([
        Dependencies(ImportTemplateService)
    ], RelateNameDialog.prototype, "importTemplateService", void 0);
    __decorate([
        Prop()
    ], RelateNameDialog.prototype, "relateData", void 0);
    __decorate([
        Prop()
    ], RelateNameDialog.prototype, "excelRowData", void 0);
    __decorate([
        Prop()
    ], RelateNameDialog.prototype, "disableItems", void 0);
    __decorate([
        Prop()
    ], RelateNameDialog.prototype, "sheetLength", void 0);
    __decorate([
        Emit("close")
    ], RelateNameDialog.prototype, "close", null);
    RelateNameDialog = __decorate([
        Component({
            components: {},
        })
    ], RelateNameDialog);
    return RelateNameDialog;
}(Vue));
export default RelateNameDialog;
