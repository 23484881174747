import { requestType } from "~/config/enum.config";
// 行调业绩表相关
var SERVICE = "business-service";
var CONTROLLER = "gradesUserController";
export default {
    /**
     * 行调业绩列表
     */
    findUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesUser",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    getUserListByProduct: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getUserListByProduct",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    findUserGroup: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesUserGroup",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    findGradesUserGroupByBatchMonth: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesUserGroupByBatchMonth",
        type: requestType.Post,
    },
    /**
     * 行调业绩列表
     */
    listUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGradesUserList",
        type: requestType.Post,
    },
    /**
     * 导出行调业绩列表
     */
    exportUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "exportGradesUser",
        type: requestType.Post,
    },
    /**
     * 查询产品各职场总还款
     */
    queryProductTotalRepayAmount: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryProductTotalRepayAmount",
        type: requestType.Post,
    },
    /**
     * 查询产品各职场总还款
     */
    queryProductTotalRepayAmountByDept: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "queryProductTotalRepayAmountByDept",
        type: requestType.Post,
    },
    /**
     * 删除行调业绩列表
     */
    delUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "delGradesUser",
        type: requestType.Post,
    },
    /**
     * 删除行调业绩列表
     */
    batchDelGradesUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "batchDelGradesUser",
        type: requestType.Post,
    },
    /**
     * 更新行调业绩列表
     */
    updateUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateGradesUser",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    addUser: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addGradesUser",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    getLineData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getReportLine",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    getGroupBarData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getGroupBarData",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    getUserBarData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getUserBarData",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    getGroupData: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getGroupData",
        type: requestType.Post,
    },
    /**
     * 新增行调业绩列表
     */
    findGroupNameList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "findGroupNameList",
        type: requestType.Post,
    },
    /**
     * 调解员提成列表
     */
    getCommissionList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCommissionList",
        type: requestType.Post,
    },
    /**
     * 组长提成列表
     */
    getGroupNameCommissionList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getGroupNameCommissionList",
        type: requestType.Post,
    },
    /**
     * 主管提成列表
     */
    getLeaderCommissionList: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getLeaderCommissionList",
        type: requestType.Post,
    },
};
