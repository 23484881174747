var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import NumberRange from '~/components/common/number-range.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Layout, Dependencies } from '~/core/decorator';
import { CaseInfoService } from '~/services/business-service/case-info.service';
import { StrategyDistributeService } from "~/services/dataimp-service/strategy-distribute.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var CommonCasePool = /** @class */ (function (_super) {
    __extends(CommonCasePool, _super);
    function CommonCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: '',
            departCode: "",
            batchNumber: '',
            caseNumber: "",
            city: '',
            delegationDate: [],
            endCaseDate: [],
            certificateNo: '',
            personalName: '',
            caseType: '',
            retireCaseDays: '',
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            delegationMonth: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            stage: '',
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
        };
        _this.dataSet = [];
        return _this;
    }
    CommonCasePool.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 策略分配
     */
    CommonCasePool.prototype.onStrategyAllocation = function () {
        var _this = this;
        // this.dialog.strategyAllocation = true;
        this.$confirm('确定执行策略分案?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(function () {
            _this.loading.state = true;
            _this.strategyDistributeService.manualExecuteStrategy('PUBLIC', _this.loading).subscribe(function (data) {
                _this.$message({
                    type: 'success',
                    message: '正在进行策略分案，请稍后刷新页面!'
                });
            });
        }).catch(function () {
            _this.$message({
                type: 'info',
                message: '已取消策略分案'
            });
        });
    };
    CommonCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.retireCaseDays = '';
        this.searchModel.stage = '';
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.leftAmt = {};
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    CommonCasePool.prototype.refreshData = function () {
        var _this = this;
        //this.loading.state = true;
        this.caseInfoService.queryPublicCases(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CommonCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CommonCasePool.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], CommonCasePool.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(StrategyDistributeService)
    ], CommonCasePool.prototype, "strategyDistributeService", void 0);
    CommonCasePool = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch
            },
        })
    ], CommonCasePool);
    return CommonCasePool;
}(Vue));
export default CommonCasePool;
