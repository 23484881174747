var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
var FlowConfigurationService = /** @class */ (function () {
    function FlowConfigurationService() {
    }
    /**
     * 新建配置流程
     */
    FlowConfigurationService.prototype.createFlowConfiguration = function (data) {
        return this.netService.send({
            server: businessService.flowConfigurationController.createFlowConfiguration,
            data: data
        });
    };
    /**
     * 删除流程配置
     */
    FlowConfigurationService.prototype.deleteFlowConfig = function (flowConfigId) {
        return this.netService.send({
            server: businessService.flowConfigurationController.deleteFlowConfig,
            append: [flowConfigId]
        });
    };
    /**
     * 配置流程查询
     */
    FlowConfigurationService.prototype.getAllFlowConfig = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.flowConfigurationController.getAllFlowConfig,
            data: {
                configName: data.configName,
                flowType: data.flowType
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 修改配置流程
     */
    FlowConfigurationService.prototype.modifyFlowConfig = function (data) {
        return this.netService.send({
            server: businessService.flowConfigurationController.modifyFlowConfig,
            data: data
        });
    };
    __decorate([
        Inject(NetService)
    ], FlowConfigurationService.prototype, "netService", void 0);
    return FlowConfigurationService;
}());
export { FlowConfigurationService };
