var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import NumberRange from "~/components/common/number-range.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { RepayService } from "~/services/business-service/repay.service";
import ftRequest from "~/utils/ft.request";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { DepartmentService } from "~/services/manage-service/department.service";
import { UserService } from "~/services/manage-service/user.service";
// import callApiRequest from "~/utils/call.api.request";
var collectionManageModule = namespace("collection-manage");
var DetentionCase = /** @class */ (function (_super) {
    __extends(DetentionCase, _super);
    function DetentionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.tk = "7c71a956a20cdd211b959a505292eb4a";
        _this.selectionList = [];
        _this.selectionUserList = [];
        _this.fileList = [];
        _this.productList = [];
        _this.userList = [];
        _this.queryParamsModel = {
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: "",
            repayBatch: "",
            realRepayDateBegin: "",
            realRepayDateEnd: "",
            createDateBegin: "",
            createDateEnd: "",
            operator: "",
            batchMonth: "",
            dept: "",
            name: "",
            productName: "",
            // startDate: "",
            // endDate: "",
            startDate: _this.formattedStartDate(),
            endDate: _this.formattedEndDate(),
            groupName: "",
            searchType: 1,
            productType: 1,
        };
        _this.activeName = "7";
        _this.tableKey = 1;
        _this.dataSet = [];
        _this.deptUserList = [];
        _this.deptList = [{ id: 1, name: "名山调解中心" }];
        _this.groupNameList = [];
        _this.dialog = {
            detail: false,
            show: false,
            showUser: false,
            showProduct: false,
            showDept: false,
        };
        _this.deptFormModel = {
            name: "",
            parentId: "1",
            status: "ON",
        };
        _this.productFormModel = {
            id: undefined,
            productName: "",
            dept: "",
            batchMonth: "",
            divisionTotal: "",
            divisionAmount: "",
            commissionRate: "",
            repayRate: "",
            productType: 1,
        };
        _this.userFormModel = {
            id: undefined,
            name: "",
            dept: "",
            batchMonth: "",
            productName: "",
            agent: "",
            divisionTotal: "",
            divisionAmount: "",
            targetAmount: "",
            targetAmountToday: "",
            groupName: "",
        };
        _this.formModel = {
            id: undefined,
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            groupName: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: "",
            dept: "",
            repayBatch: "",
            repayStage: "",
            compensatory: "",
            compensatoryPerson: "",
            loanNo: "",
        };
        _this.rules = {
            loanName: [{ required: true, message: "请输入客户姓名", trigger: "blur" }],
            loanContractId: [{ required: true, message: "请输入案件编号", trigger: "blur" }],
            idNumber: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
            repayStage: [{ required: true, message: "请选择回款阶段", trigger: "change" }],
            compensatory: [{ required: true, message: "请选择是否代偿", trigger: "change" }],
            repayAmount: [{ required: true, message: "请输入还款金额", trigger: "blur" }],
            caseSource: [{ required: true, message: "请选择案源", trigger: "change" }],
            tiaoJieYuan: [{ required: true, message: "请选择调解员", trigger: "change" }],
            // groupName: [{required: true, message: "请选择组长", trigger: "change"}],
            repayType: [{ required: true, message: "请选择还款方式", trigger: "change" }],
            realRepayDate: [{ required: true, message: "请选择还款时间", trigger: "change" }],
            // dept: [{required: true, message: "请选择职场", trigger: "change"}],
            repayBatch: [{ required: true, message: "请选择还款批次", trigger: "change" }],
        };
        _this.userRules = {
            name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
            dept: [{ required: true, message: "请选择职场", trigger: "change" }],
            batchMonth: [{ required: true, message: "请选择批次月", trigger: "change" }],
            productName: [{ required: true, message: "请选择案源", trigger: "change" }],
            agent: [{ required: true, message: "请输入分机号", trigger: "blur" }],
            divisionTotal: [{ required: true, message: "请输入分案户数", trigger: "blur" }],
            divisionAmount: [{ required: true, message: "请输入分案金额", trigger: "blur" }],
            targetAmount: [{ required: true, message: "请输入创佣目标金额", trigger: "blur" }],
            targetAmountToday: [{ required: true, message: "请输入当天创佣目标金额", trigger: "blur" }],
            groupName: [{ required: true, message: "请输入小组名称", trigger: "blur" }],
        };
        _this.productRules = {
            productName: [{ required: true, message: "请输入案源名称", trigger: "blur" }],
            productType: [{ required: true, message: "请选择案源类型", trigger: "change" }],
            // dept: [{required: true, message: "请选择职场", trigger: "change"}],
            batchMonth: [{ required: true, message: "请选择批次月", trigger: "change" }],
            // divisionTotal: [{required: true, message: "请输入委案户数", trigger: "blur"}],
            // divisionAmount: [{required: true, message: "请输入委案金额", trigger: "blur"}],
            repayRate: [{ required: true, message: "请输入回收率目标", trigger: "blur" }],
            commissionRate: [{ required: true, message: "请输入佣金率", trigger: "blur" }],
        };
        _this.deptRules = {
            name: [{ required: true, message: "请输入职场名称", trigger: "blur" }],
        };
        return _this;
    }
    DetentionCase.prototype.mounted = function () {
        // this.getDeptList();
        this.getGroupNameList();
        this.getProductList();
        this.getDeptUserList();
        this.refreshData();
    };
    DetentionCase.prototype.loadProductChildren = function (tree, treeNode, resolve) {
        var params = __assign({}, this.queryParamsModel);
        params.dept = tree.dept;
        params.productName = tree.productName;
        // params.groupName = tree.groupName;
        this.repayService.getProductByDeptBatchMonth(this.removeEmptyProperties(params), this.loading).subscribe(function (data) {
            resolve(data);
        });
    };
    DetentionCase.prototype.loadGroupChildren = function (tree, treeNode, resolve) {
        var params = __assign({}, this.queryParamsModel);
        params.dept = tree.dept;
        params.productName = tree.productName;
        params.groupName = tree.groupName;
        this.repayService.findGradesUserGroupByBatchMonth(this.removeEmptyProperties(params), this.loading).subscribe(function (data) {
            resolve(data);
        });
    };
    DetentionCase.prototype.loadTotalChildren = function (tree, treeNode, resolve) {
        var params = __assign({}, this.queryParamsModel);
        params.productName = tree.productName;
        this.repayService.queryProductTotalRepayAmountByDept(this.removeEmptyProperties(params), this.loading).subscribe(function (data) {
            resolve(data);
        });
    };
    DetentionCase.prototype.isManager = function () {
        return this.userData.realName === "王辉" || this.userData.isManager === "YES";
    };
    DetentionCase.prototype.getDeptList = function () {
        // this.departmentService.findAllDepartmentNotAuth(this.loading).subscribe((data) => {
        //   this.deptList = [];
        //   data.forEach(item => {
        //     if (item.name !== "技术部" && item.name !== "测试" && !item.name.startsWith("离职") && !item.name.startsWith("在职")
        //       && !item.name.endsWith("离职") && !item.name.endsWith("在职") && item.name !== "国调科技" && item.name !== "自营诉讼" && !item.name.endsWith("大区")) {
        //       this.deptList.push(item);
        //     }
        //   });
        // });
    };
    DetentionCase.prototype.getGroupNameList = function () {
        var _this = this;
        this.repayService.findGroupNameList(this.loading).subscribe(function (data) {
            _this.groupNameList = data;
        });
    };
    DetentionCase.prototype.getAuth = function (createDate) {
        if (createDate === undefined || createDate === "" || createDate === null) {
            return false;
        }
        // 将字符串转换为 Date 对象
        var parsedCreateDate = new Date(createDate.replace(" ", "T"));
        // 计算是否超过 7 天
        var now = new Date();
        var sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        return !(parsedCreateDate < sevenDaysAgo);
    };
    DetentionCase.prototype.deptOnSubmit = function () {
        var _this = this;
        if (this.deptFormModel.id !== undefined) {
            this.departmentService.modifyDepartment({
                departId: this.deptFormModel.id,
                // departCode: this.deptFormModel.departCode,
                // level: this.deptFormModel.level,
                name: this.deptFormModel.name,
                parentId: this.deptFormModel.parent,
            }, this.loading).subscribe(function (data) {
                _this.dialog.showDept = false;
                _this.deptFormModel = {
                    id: undefined,
                    name: "",
                    parentId: "1",
                    status: "ON",
                };
                _this.getDeptList();
            });
        }
        else {
            this.departmentService.addDepartment(this.deptFormModel, this.loading).subscribe(function (data) {
                _this.dialog.showDept = false;
                _this.deptFormModel = {
                    id: undefined,
                    name: "",
                    parentId: "1",
                    status: "ON",
                };
                _this.getDeptList();
            });
        }
    };
    DetentionCase.prototype.getAgentsData = function (fData) {
        var agents = {};
        var isAgent = false;
        fData.forEach(function (item) {
            if (item.groupAgentList != undefined && item.groupAgentList.length > 0) {
                agents[item.groupName] = item.groupAgentList;
                isAgent = true;
            }
        });
        if (!isAgent) {
            return;
        }
        // callApiRequest.postJson("/collLogRecord/agentGroupTotalToday", agents)
        //   .then((res) => {
        //     if (res["status"] === 200) {
        //       const agentMap = res["data"];
        //       this.tableKey += 1;
        //       this.dataSet.forEach(u => {
        //         const item = agentMap[u.groupName];
        //         if (item) {
        //           u.callOutTotal = item.callOutTotal;
        //           u.callInTotal = item.callInTotal;
        //           u.callOutSuccessTotal = item.callOutSuccessTotal;
        //           u.callOutSuccessRate = item.callOutSuccessRateStr;
        //           u.totalTimeLengthStr = item.totalTimeLengthStr;
        //           u.totalTimeLength = item.totalTimeLength;
        //         }
        //       });
        //     }
        //   }).catch(err => {
        //   console.log(err);
        // });
    };
    DetentionCase.prototype.getAgentsUserData = function (agents, data) {
        if (agents.length === 0) {
            return;
        }
        // const uniqueAgents = agents.filter((value, index, self) => self.indexOf(value) === index);
        // callApiRequest.postJson("/collLogRecord/agentTotalToday", uniqueAgents)
        //   .then((res) => {
        //     if (res["status"] === 200) {
        //       const agentMap = res["data"].reduce((map, item) => {
        //         map[item.agent] = item;
        //         return map;
        //       }, {});
        //       this.tableKey += 1;
        //       // 遍历 dataSet，根据 agent 更新对应的值
        //       this.dataSet.forEach(u => {
        //         const item = agentMap[u.agent];
        //         if (item) {
        //           u.callOutTotal = item.callOutTotal;
        //           u.callInTotal = item.callInTotal;
        //           u.callOutSuccessTotal = item.callOutSuccessTotal;
        //           u.callOutSuccessRate = item.callOutSuccessRate;
        //           u.totalTimeLengthStr = item.totalTimeLengthStr;
        //           u.totalTimeLength = item.totalTimeLength;
        //         }
        //       });
        //     }
        //   }).catch(err => {
        //   console.log(err);
        // });
    };
    DetentionCase.prototype.aggregateData = function (data) {
        var aggregatedMap = new Map();
        data.forEach(function (item) {
            var key = item.productName + "-" + item.dept + "-" + item.groupName;
            if (!aggregatedMap.has(key)) {
                aggregatedMap.set(key, __assign(__assign({}, item), { repayAmount: parseFloat(item.repayAmount), targetAmount: parseFloat(item.targetAmount) }));
            }
            else {
                var existingItem = aggregatedMap.get(key);
                existingItem.repayAmount += parseFloat(item.repayAmount);
                existingItem.targetAmount += parseFloat(item.targetAmount);
                existingItem.commission += parseFloat(item.commission);
            }
        });
        var res = Array.from(aggregatedMap.values());
        res.forEach(function (item) {
            item.realRepayRate = item.targetAmount ? parseFloat((item.commission / item.targetAmount).toString()).toFixed(2) : 0;
            item.repayAmountDiff = item.commission - item.targetAmount;
        });
        return this.sortArr(res);
    };
    DetentionCase.prototype.sortArr = function (res) {
        return res.slice().sort(function (a, b) {
            if (a.productName < b.productName) {
                return -1;
            }
            if (a.productName > b.productName) {
                return 1;
            }
            // 如果 productName 相同，则按 realRepayRate 降序排序
            return b.realRepayRate - a.realRepayRate;
        });
    };
    DetentionCase.prototype.uploadSuccess = function (response, file, fileList) {
        if (response === "") {
            this.$message.success("导入成功");
            this.fileList = [];
            this.refreshData();
        }
    };
    DetentionCase.prototype.uploadError = function (response) {
        this.$message.error(response ? response : "导入失败");
    };
    DetentionCase.prototype.handlerAmount = function (amount) {
        if (amount === undefined || amount === null || amount === "") {
            return 0;
        }
        return parseFloat(amount).toFixed(0);
    };
    DetentionCase.prototype.uploadUserSuccess = function (response, file, fileList) {
        if (response === "") {
            this.$message.success("导入成功");
            this.fileList = [];
            this.refreshData();
        }
    };
    DetentionCase.prototype.uploadUserError = function (response) {
        this.$message.error(response ? response : "导入失败");
    };
    DetentionCase.prototype.getDeptUserList = function () {
        var _this = this;
        this.userService.findUserByDepartmentCode({}, this.sortService).subscribe(function (data) {
            _this.deptUserList = data;
        });
    };
    DetentionCase.prototype.objectSpanMethodProduct2 = function (_a) {
        var row = _a.row, column = _a.column, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
        if (columnIndex === 0) { // 合并第一列
            // 如果是第一行，初始化合并逻辑
            if (rowIndex === 0) {
                var count = 1;
                for (var i = rowIndex + 1; i < this.dataSet.length; i++) {
                    if (this.dataSet[i].productName === row.productName) {
                        count++;
                    }
                    else {
                        break;
                    }
                }
                return { rowspan: count, colspan: 1 }; // 返回合并的行数
            }
            // 如果前一行与当前行受托方名称相同，则不显示当前行
            if (row.productName === this.dataSet[rowIndex - 1].productName) {
                return { rowspan: 0, colspan: 0 }; // 隐藏当前行
            }
            else {
                // 当前行与前一行不相同时，重新计算 rowspan
                var count = 1;
                for (var i = rowIndex + 1; i < this.dataSet.length; i++) {
                    if (this.dataSet[i].productName === row.productName) {
                        count++;
                    }
                    else {
                        break;
                    }
                }
                return { rowspan: count, colspan: 1 }; // 返回合并的行数
            }
        }
        return { rowspan: 1, colspan: 1 }; // 其他列不合并
    };
    DetentionCase.prototype.getProductList = function () {
        var _this = this;
        this.repayService.listProduct({}, this.loading).subscribe(function (data) {
            _this.productList = data;
        });
    };
    DetentionCase.prototype.formattedStartDate = function () {
        var currentDate = new Date();
        var year = currentDate.getFullYear(); //获取当前年
        var month = String(currentDate.getMonth() + 1).padStart(2, "0"); //获取当前月
        var firstDay = "01"; //日
        return year + "-" + month + "-" + firstDay;
    };
    DetentionCase.prototype.formattedEndDate = function () {
        // 获取当前日期
        var currentDate = new Date();
        // 获取当前年份
        var year = currentDate.getFullYear();
        // 获取下个月的月份，使用 padStart 在月份小于 10 时在前面补零
        var month = String(currentDate.getMonth() + 2).padStart(2, "0");
        // 如果月份为 '13'，说明当前是12月，需要更新年份并将月份设置为 '01'
        if (month === "13") {
            year = year + 1;
            month = "01";
        }
        // 设置每个月的第一天为 '01'
        var firstDay = "01";
        // 构建下个月的第一天的日期字符串，格式为 'YYYY-MM-DD'
        var firstDayOfNextMonth = year + "-" + month + "-" + firstDay;
        // 计算当前月的最后一天，通过减去一天的毫秒数来得到
        var lastDayOfMonth = new Date(new Date(firstDayOfNextMonth).getTime() - 86400000);
        // 将最后一天的日期转换为 ISO 格式，并提取日期部分
        return lastDayOfMonth.toISOString().split("T")[0];
    };
    DetentionCase.prototype.handleTabClick = function (tab, event) {
        // if (this.activeName === "4" || this.activeName === "3") {
        //   this.queryParamsModel.startDate = this.formattedStartDate();
        //   this.queryParamsModel.endDate = this.formattedEndDate();
        // } else {
        //   this.queryParamsModel.startDate = "";
        //   this.queryParamsModel.endDate = "";
        // }
        this.dataSet = [];
        this.refreshData();
    };
    DetentionCase.prototype.handleResetForm = function () {
        this.formModel = {};
        this.refreshData();
    };
    DetentionCase.prototype.handleRemove = function (file) {
        console.log(file);
        this.formModel.pinZhengPath = "";
    };
    ;
    DetentionCase.prototype.handleSuccess = function (file) {
        if (file.url) {
            this.$message.success("上传成功");
            this.formModel.pinZhengPath = file.url;
        }
    };
    ;
    DetentionCase.prototype.onSubmit = function () {
        var _this = this;
        var formModel = this.$refs["formModel"];
        formModel.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.formModel.id !== undefined && _this.formModel.id !== "" && _this.formModel.id !== null) {
                _this.repayService.updateGrades(_this.formModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("更新成功");
                    _this.dialog.show = false;
                    _this.formModel = {};
                    _this.refreshData();
                });
            }
            else {
                _this.repayService.addGrades(_this.formModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("保存成功");
                    _this.dialog.show = false;
                    _this.formModel = {};
                    _this.refreshData();
                });
            }
        });
    };
    DetentionCase.prototype.productOnSubmit = function () {
        var _this = this;
        var formModel = this.$refs["productFormModel"];
        formModel.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.productFormModel.id !== undefined && _this.productFormModel.id !== "" && _this.productFormModel.id !== null) {
                _this.repayService.updateProduct(_this.productFormModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("更新成功");
                    _this.dialog.showProduct = false;
                    _this.productFormModel = {};
                    _this.refreshData();
                });
            }
            else {
                _this.repayService.addProduct(_this.productFormModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("保存成功");
                    _this.dialog.showProduct = false;
                    _this.productFormModel = {};
                    _this.refreshData();
                });
            }
        });
    };
    DetentionCase.prototype.userOnSubmit = function () {
        var _this = this;
        var formModel = this.$refs["userFormModel"];
        formModel.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.userFormModel.id !== undefined && _this.userFormModel.id !== "" && _this.userFormModel.id !== null) {
                _this.repayService.updateUser(_this.userFormModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("更新成功");
                    _this.dialog.showUser = false;
                    _this.userFormModel = {};
                    _this.refreshData();
                });
            }
            else {
                _this.repayService.addUser(_this.userFormModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("保存成功");
                    _this.dialog.showUser = false;
                    _this.userFormModel = {};
                    _this.refreshData();
                });
            }
        });
    };
    DetentionCase.prototype.showPic = function (url) {
        if (!url) {
            this.$message.warning("未上传凭证");
            return;
        }
        this.uploadFileService.getOssSignUrl(url).subscribe(function (data) {
            window.open(data);
        });
    };
    DetentionCase.prototype.del = function (id) {
        var _this = this;
        this.$confirm("确定删除该记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.repayService.delGrades({ id: id }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.delProduct = function (id) {
        var _this = this;
        this.$confirm("确定删除该记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.repayService.delProduct({ id: id }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.delUser = function (id) {
        var _this = this;
        this.$confirm("确定删除该记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.repayService.delUser({ id: id }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    DetentionCase.prototype.batchDelGrades = function () {
        var _this = this;
        this.$confirm("确定删除选中记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            var ids = _this.selectionList.map(function (item) { return item.id; }).join(",");
            _this.repayService.batchDelGrades({ id: ids }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.batchDelUserGrades = function () {
        var _this = this;
        this.$confirm("确定删除选中记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            var ids = _this.selectionUserList.map(function (item) { return item.id; }).join(",");
            _this.repayService.batchDelGradesUser({ id: ids }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.generateUniqueID = function (baseId, uniqueCounter) {
        return Math.floor(Math.random() * 10000) + "_" + Date.now() + "_" + uniqueCounter++;
    };
    DetentionCase.prototype.sortByProduct = function (data) {
        var _this = this;
        var specificProductNames = ["网商", "花呗", "借呗"];
        var specificItems = [];
        var otherItems = [];
        var uniqueCounter = 0;
        data.forEach(function (item) {
            item["hasChildren"] = true;
            item["children"] = [];
            item["id"] = _this.generateUniqueID(item["id"], uniqueCounter);
            // item["batchMonth"] = "";
            if (specificProductNames.includes(item.productName)) {
                specificItems.push(item);
            }
            else {
                otherItems.push(item);
            }
        });
        return __spreadArrays(otherItems, specificItems);
    };
    DetentionCase.prototype.sortByProductName = function (data) {
        var _this = this;
        var sData = this.sortByProduct(data);
        // 使用 reduce 分组并生成合计行
        var groupedWithSummary = Object.values(sData.reduce(function (acc, item) {
            // 初始化分组
            if (!acc[item.productName]) {
                acc[item.productName] = {
                    items: [],
                    summary: {
                        productName: "合计",
                        repayAmount: 0,
                        commission: 0,
                        commissionTodayDiff: 0,
                        commissionToday: 0,
                        divisionTotal: 0,
                        divisionAmount: 0,
                        targetAmountToday: 0,
                        repayAmountDiff: 0,
                        isSummary: true,
                    },
                };
            }
            _this.totalLineAmount(acc[item.productName], item);
            return acc;
        }, {}));
        return this.flatTotalLineMap(groupedWithSummary);
    };
    DetentionCase.prototype.totalLineAmount = function (acc, item) {
        acc.items.push(item);
        // 更新合计值
        acc.summary.repayAmount += item.repayAmount;
        acc.summary.commission += item.commission;
        acc.summary.commissionTodayDiff += item.commissionTodayDiff;
        acc.summary.commissionToday += item.commissionToday;
        acc.summary.divisionTotal += item.divisionTotal;
        acc.summary.divisionAmount += item.divisionAmount;
        acc.summary.targetAmountToday += item.targetAmountToday;
        acc.summary.repayAmountDiff += item.repayAmountDiff;
    };
    DetentionCase.prototype.flatTotalLineMap = function (groupedWithSummary) {
        // 将分组结果展开为最终数组
        var result = groupedWithSummary.flatMap(function (group) { return __spreadArrays(group["items"], [
            group["summary"],
        ]); });
        return result;
    };
    DetentionCase.prototype.tableRowClassName = function (_a) {
        var row = _a.row, rowIndex = _a.rowIndex;
        if (row.isSummary) {
            return "special-row";
        }
        return "";
    };
    DetentionCase.prototype.refreshData = function () {
        var _this = this;
        if (this.activeName === "1") {
            if (!this.isManager()) {
                this.queryParamsModel.tiaoJieYuan = this.userData.realName;
            }
            this.repayService.findGrades(this.removeEmptyProperties(this.queryParamsModel), this.sortService, this.pageService, this.loading).subscribe(function (data) {
                _this.dataSet = data;
            });
        }
        else if (this.activeName === "6") {
            this.queryParamsModel.searchType = 2;
            this.repayService.findProduct(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = [];
                data.forEach(function (item) {
                    if (!item.productName.endsWith("撤诉")) {
                        _this.dataSet.push(item);
                    }
                });
                // this.dataSet = this.sortByProductName(data);
            });
        }
        else if (this.activeName === "8") {
            this.queryParamsModel.searchType = 2;
            this.repayService.findProduct(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = [];
                data.forEach(function (item) {
                    if (item.productName.endsWith("撤诉")) {
                        _this.dataSet.push(item);
                    }
                });
                // this.dataSet = data;
                // this.dataSet = this.sortByProductName(data);
            });
        }
        else if (this.activeName === "4") {
            this.repayService.getUserListByProduct(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = data.sort(function (a, b) {
                    if (a.productName === b.productName) {
                        return b.repayAmount - a.repayAmount; // 同一案源下，按 repayAmount 降序排列
                    }
                    else {
                        return a.productName.localeCompare(b.productName); // 不同案源按 productName 升序排列
                    }
                });
                var agents = [];
                data.forEach(function (item) {
                    if (item.agent != undefined && item.agent != 0) {
                        agents.push(item.agent);
                    }
                });
                // this.getAgentsUserData(agents, data);
            });
        }
        else if (this.activeName === "3") {
            this.queryParamsModel.searchType = 2;
            this.repayService.findUserGroup(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                var fData = [];
                data.forEach(function (item) {
                    if (!item.productName.endsWith("撤诉")) {
                        fData.push(item);
                    }
                });
                fData.sort(function (a, b) {
                    if (a.productName < b.productName) {
                        return -1;
                    }
                    if (a.productName > b.productName) {
                        return 1;
                    }
                    return 0;
                });
                _this.sortByProduct(fData);
                _this.dataSet = fData;
                // this.getAgentsData(fData);
            });
        }
        else if (this.activeName === "5") {
            this.getDeptList();
        }
        else if (this.activeName === "2") {
            this.repayService.findGradesProductTotal(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = _this.sortByProduct(data);
            });
        }
        else if (this.activeName === "7") {
            this.repayService.queryProductTotalRepayAmount(this.removeEmptyProperties(this.queryParamsModel), this.loading).subscribe(function (data) {
                _this.dataSet = [];
                data.forEach(function (item) {
                    if (!item.productName.endsWith("撤诉")) {
                        var uniqueRandom = Date.now() + Math.floor(Math.random() * 10000);
                        item["hasChildren"] = true;
                        item["children"] = [];
                        item["id"] = item["id"] + uniqueRandom;
                        _this.dataSet.push(item);
                    }
                });
            });
        }
    };
    DetentionCase.prototype.exportList = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesController/exportGrades", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "行调业绩表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DetentionCase.prototype.exportProduct = function () {
        var _this = this;
        this.loading.state = true;
        this.queryParamsModel.searchType = this.activeName === "6" ? 2 : 1;
        ftRequest.postJsonDownload("/business-service/api/gradesProductController/exportGradesProduct", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = _this.queryParamsModel.searchType === 2 ? "项目完成情况表.xlsx" : "职场完成情况表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DetentionCase.prototype.exportUser = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesUserController/exportGradesUser", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "小组完成情况表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    DetentionCase.prototype.exportUserToday = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesUserController/exportGradesUserToday", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "个人完成情况表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], DetentionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DetentionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(RepayService)
    ], DetentionCase.prototype, "repayService", void 0);
    __decorate([
        Dependencies(DepartmentService)
    ], DetentionCase.prototype, "departmentService", void 0);
    __decorate([
        Dependencies(UserService)
    ], DetentionCase.prototype, "userService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], DetentionCase.prototype, "uploadFileService", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "userToken", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "userData", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "departmentList", void 0);
    DetentionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                PrincipalBatch: PrincipalBatch,
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
            },
        })
    ], DetentionCase);
    return DetentionCase;
}(Vue));
export default DetentionCase;
