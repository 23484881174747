var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { Prop, Emit } from "vue-property-decorator";
import { AssistCaseService } from "~/services/business-service/assist-case.service";
var AssistAllocate = /** @class */ (function (_super) {
    __extends(AssistAllocate, _super);
    function AssistAllocate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        return _this;
    }
    AssistAllocate.prototype.onPreStep = function () { };
    AssistAllocate.prototype.close = function () { };
    AssistAllocate.prototype.submit = function () {
        var _this = this;
        this.loading.state = true;
        this.assistCaseService
            .assistConfirmDistributeCase(this.distributeConfigModel, this.loading)
            .subscribe(function (data) {
            _this.loading.state = false;
            _this.$message.success("分案成功");
            _this.close();
        }, function (error) {
            _this.loading.state = false;
            _this.$message.error(error.msg);
        });
    };
    __decorate([
        Dependencies(AssistCaseService)
    ], AssistAllocate.prototype, "assistCaseService", void 0);
    __decorate([
        Prop()
    ], AssistAllocate.prototype, "distributeConfigModel", void 0);
    __decorate([
        Emit("preStep")
    ], AssistAllocate.prototype, "onPreStep", null);
    __decorate([
        Emit("close")
    ], AssistAllocate.prototype, "close", null);
    AssistAllocate = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem
            }
        })
    ], AssistAllocate);
    return AssistAllocate;
}(Vue));
export default AssistAllocate;
