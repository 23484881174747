import { requestType } from '~/config/enum.config';
var SERVICE = 'domain-service';
var CONTROLLER = 'distributeCase';
export default {
    /**
     * 地区分案统计查询
     */
    searchAreaCountDisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchAreaCountDisCase',
        type: requestType.Get
    },
    /**
     * 地区分案明细查询
     */
    searchDetalCountDisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchDetalCountDisCase',
        type: requestType.Get
    },
    /**
     * 个人分案统计查询
     */
    searchPersonalCountDisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchPersonalCountDisCase',
        type: requestType.Get
    },
    /**
     * 个人分案明细查询
     */
    searchDetalPersonalDisCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'searchDetalPersonalDisCase',
        type: requestType.Get
    },
    /**
     * 案件下发
     */
    issuedCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'issuedCase',
        type: requestType.Post
    },
    /**
     * 个人案件确认下发
     */
    issuedPersonalCase: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'issuedPersonalCase',
        type: requestType.Post
    },
    /**
     * 根据案件标识获取对应的批次号
     */
    getBatchNumberByCaseIssuedFlag: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getBatchNumberByCaseIssuedFlag',
        type: requestType.Get
    },
    /**
     * 批量分配条件筛选
     */
    getCaseCommonPro: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getCaseCommonPro',
        type: requestType.Get
    },
    /**
     * 根据条件获取案件数量及金额
     */
    getBaseCaseNumberAndAmt: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'getBaseCaseNumberAndAmt',
        type: requestType.Get
    },
    /**
     * 地区批量下发
     */
    issuedCaseBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'issuedCaseBatch',
        type: requestType.Post
    },
    /**
     * 个人批量下发
     */
    issuedPersonalCaseBatch: {
        service: SERVICE,
        controller: CONTROLLER,
        action: 'issuedPersonalCaseBatch',
        type: requestType.Post
    }
};
