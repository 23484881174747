var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "~/components/common/file-upload.vue";
import { State } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
var CaseUpdateImportConfig = /** @class */ (function (_super) {
    __extends(CaseUpdateImportConfig, _super);
    function CaseUpdateImportConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.importModel = {
            fileId: '',
            principalId: '',
            templateId: '' //模板ID
        };
        _this.importRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            templateId: [
                { required: true, message: "请选择导入模板", trigger: "blur" }
            ],
            batchNumber: [{ required: true, message: "请选择批次号", trigger: "blur" }]
        };
        _this.caseIssuedFlag = "";
        _this.batchNumberList = [];
        _this.fileList = [];
        _this.importLoading = false;
        _this.templateList = []; //模板列表
        _this.loading = {
            state: false
        };
        return _this;
    }
    CaseUpdateImportConfig.prototype.close = function () {
        this.reset();
    };
    CaseUpdateImportConfig.prototype.success = function () {
        this.close();
    };
    /**
     * 表单重置
     */
    CaseUpdateImportConfig.prototype.reset = function () {
        this.templateList = [];
        this.importLoading = false;
        var form = this.$refs["form"];
        var upload = this.$refs["upload_case_import"];
        form.resetFields();
        upload.reset();
    };
    /**
     * 取消
     */
    CaseUpdateImportConfig.prototype.cancel = function () {
        this.close();
    };
    /**
     * 表单提交
     */
    CaseUpdateImportConfig.prototype.submit = function () {
        var _this = this;
        if (this.fileList.length === 0) {
            this.$message("请上传导入文件");
            return;
        }
        // 文件ID
        this.importModel.fileId = this.fileList[0].response.id;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.dataImportService.importCaseUpdate(_this.importModel, _this.loading).subscribe(function (data) {
                _this.$message.success("案件导入成功");
                _this.success();
                _this.importLoading = false;
            }, function (err) {
                _this.$message.error(err.msg);
            });
        });
    };
    /**
     * 委托方ID变化
     */
    CaseUpdateImportConfig.prototype.principalIdChange = function (val) {
        var _this = this;
        //根据委托方ID查找委托方Obj并且获取模板名称
        var principalObj = this.principalList.find(function (v) { return v.id === _this.importModel.principalId; });
        this.importModel.principalName = principalObj.principalName;
        //重置模板列表并获取选取委托方对应的模板列表
        this.templateList = [];
        this.importModel.templateId = "";
        var excelModel = {
            principalId: this.importModel.principalId,
            type: "CASE_UPDATE"
        };
        this.loading.state = true;
        this.importTemplateService.getTemplateByPrincipal(excelModel, this.loading).subscribe(function (data) { return (_this.templateList = data); });
    };
    __decorate([
        Dependencies(ImportTemplateService)
    ], CaseUpdateImportConfig.prototype, "importTemplateService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], CaseUpdateImportConfig.prototype, "dataImportService", void 0);
    __decorate([
        State
    ], CaseUpdateImportConfig.prototype, "principalList", void 0);
    __decorate([
        Prop({ required: true })
    ], CaseUpdateImportConfig.prototype, "importType", void 0);
    __decorate([
        Emit()
    ], CaseUpdateImportConfig.prototype, "close", null);
    __decorate([
        Emit()
    ], CaseUpdateImportConfig.prototype, "success", null);
    CaseUpdateImportConfig = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], CaseUpdateImportConfig);
    return CaseUpdateImportConfig;
}(Vue));
export default CaseUpdateImportConfig;
