var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Auth, Layout, Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import NumberRange from '~/components/common/number-range.vue';
import AddAchieve from '~/components/assist-work/dia-log/add-achieve.vue';
import CompanyAchieveUpdate from '~/components/assist-work/dia-log/company-achieve-update.vue';
var inrushCaseModule = namespace('inrush-case');
var ComapnyAchieve = /** @class */ (function (_super) {
    __extends(ComapnyAchieve, _super);
    function ComapnyAchieve() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.achieveModel = {};
        _this.dialog = {
            addAchieve: false,
            update: false,
            delete: false
        };
        _this.companyObj = {};
        return _this;
    }
    ComapnyAchieve.prototype.addAchieveClick = function () {
        this.dialog.addAchieve = true;
    };
    ComapnyAchieve.prototype.data = function () {
        return {
            tableData: [{
                    appointCaseData: '2016-05-02',
                    cardNumber: '王小虎',
                    caseNumber: '上海市普陀区金沙江路 1518 弄',
                    batchNumber: '简介'
                }, {
                    appointCaseData: '2016-05-02',
                    cardNumber: '233',
                    caseNumber: '上海市普陀区金沙江路 1518 弄',
                    batchNumber: '简介'
                }]
        };
    };
    ComapnyAchieve.prototype.refreshData = function () { };
    ComapnyAchieve.prototype.caseAllotClick = function () { };
    __decorate([
        Dependencies(PageService)
    ], ComapnyAchieve.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], ComapnyAchieve.prototype, "sortService", void 0);
    ComapnyAchieve = __decorate([
        Auth(77),
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                AddAchieve: AddAchieve,
                CompanyAchieveUpdate: CompanyAchieveUpdate
            }
        })
    ], ComapnyAchieve);
    return ComapnyAchieve;
}(Vue));
export default ComapnyAchieve;
