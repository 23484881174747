var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Layout, Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { HistoryCasesService } from "~/services/business-service/history-cases.service";
import NumberRange from "~/components/common/number-range.vue";
import FollowHistoryQuery from "~/components/collection-manage/execution/follow-history-query.vue";
import { CollectionData } from "~/config/types/collection-data";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var collectionManageModule = namespace("collection-manage");
var inrushCaseModule = namespace('inrush-case');
var historicalQuery = /** @class */ (function (_super) {
    __extends(historicalQuery, _super);
    function historicalQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.dialog = {
            detail: false,
        };
        _this.detailObj = {};
        _this.mOtherData = {
            isHistory: true,
            isFast: true
        };
        _this.searchModel = {
            principalId: '',
            personalName: "",
            certificateNo: "",
            batchNumber: "",
            city: "",
            delegationDate: [],
            endCaseDate: [],
            payStatus: '',
            stage: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            caseType: "",
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
        };
        return _this;
    }
    historicalQuery.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.payStatus = '';
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.leftAmt = {};
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    historicalQuery.prototype.mounted = function () {
    };
    historicalQuery.prototype.refreshData = function () {
        var _this = this;
        var key = Object.values(this.searchModel).filter(function (x) {
            if (x instanceof Object) {
                return !!Object.values(x).filter(function (sub) { return !!sub; }).length;
            }
            else if (Array.isArray(x)) {
                return !!x.filter(function (array) { return !!array; }).length;
            }
            else {
                return !!x;
            }
        });
        if (key.length) {
            this.loading.state = true;
            this.historyCasesService.queryReturnCases(this.searchModel, this.pageService, this.sortService, this.loading)
                .subscribe(function (data) { _this.dataSet = data; });
        }
        else {
            this.$message("请至少填写一项查找条件");
        }
    };
    historicalQuery.prototype.onBatchNumberClick = function (currentCollection) {
        var data = new CollectionData(currentCollection.principalId, currentCollection.id, this.searchModel, this.mOtherData);
        this.openExecutionPage(data);
    };
    __decorate([
        Dependencies(PageService)
    ], historicalQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], historicalQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(HistoryCasesService)
    ], historicalQuery.prototype, "historyCasesService", void 0);
    __decorate([
        collectionManageModule.Action
    ], historicalQuery.prototype, "openExecutionPage", void 0);
    historicalQuery = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                FollowHistoryQuery: FollowHistoryQuery,
                PrincipalBatch: PrincipalBatch
            },
        })
    ], historicalQuery);
    return historicalQuery;
}(Vue));
export default historicalQuery;
