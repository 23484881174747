var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import clone from "clone";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NewExcelTemplate from "~/components/templete-manage/excel-template/new-excel-template.vue";
import RelateNameDialog from "~/components/templete-manage/excel-template/relate-name-dialog.vue";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { ImportTemplateService } from "~/services/dataimp-service/import-template.service";
import ExcelMerge from "~/components/templete-manage/excel-merge.vue";
import ExcelRowConvertCell from "~/components/templete-manage/excel-row-convert-cell.vue";
//@Auth(1795)
var ExcelTemplate = /** @class */ (function (_super) {
    __extends(ExcelTemplate, _super);
    function ExcelTemplate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.excelModel = {};
        _this.excelDataSet = [];
        _this.sheetLength = 0;
        _this.dialog = {};
        _this.excelRowData = {};
        _this.modifyData = {};
        _this.disableItems = [];
        _this.errMsgData = [];
        _this.propertyResponse = {};
        _this.loading = {
            state: false
        };
        return _this;
    }
    ExcelTemplate.prototype.created = function () {
        this.dialog = {
            createTemplate: false,
            relateNameOpen: false,
            errMsgOpen: false,
            updateExcel: false,
            mergeDialog: false,
            excelRowConvertCell: false
        };
        this.excelModel = {
            name: "",
            principalId: "",
            type: ""
        };
    };
    ExcelTemplate.prototype.mounted = function () {
        this.refreshData();
    };
    ExcelTemplate.prototype.handleResetForm = function () {
        this.excelModel.name = "";
    };
    ExcelTemplate.prototype.refreshData = function () {
        var _this = this;
        this.importTemplateService.findAllImportTemplate(this.excelModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.excelDataSet = data;
        }, function (err) {
            _this.$message.error(err.msg);
        });
    };
    /**
     *
     */
    ExcelTemplate.prototype.dialogOpen = function (excelRowData, disableItems, templateType, sheetLength, propertyResponse) {
        var _this = this;
        for (var i = 0; i < propertyResponse.length; i++) {
            if ('委案城市' == propertyResponse[i].name) {
                propertyResponse[i].name = '管辖权';
            }
        }
        this.excelRowData = excelRowData; // 行数据
        this.dialog.relateNameOpen = true;
        this.disableItems = disableItems;
        this.sheetLength = sheetLength;
        this.propertyResponse = propertyResponse;
        this.$nextTick(function () {
            var relateName = _this.$refs["relateName"];
            relateName.refreshData(templateType, propertyResponse);
        });
    };
    ExcelTemplate.prototype.modifyTemplateData = function () {
        var _this = this;
        this.$nextTick(function () {
            var change = _this.$refs["change"];
            change.refreshData(_this.modifyData);
        });
    };
    /**
     * 删除模板
     */
    ExcelTemplate.prototype.deleteClick = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            // 发送删除数据请求
            _this.importTemplateService.deleteTemplate(scope.row.id).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                // 更新数据信息
                _this.refreshData();
            }, function (_a) {
                var reason = _a.reason;
                _this.$message({
                    type: "info",
                    message: reason
                });
            });
        });
    };
    /**
     * 修改模版
     */
    ExcelTemplate.prototype.updateClick = function (scope) {
        this.dialog.updateExcel = true;
        this.modifyData = clone(scope.row);
    };
    /**
     * 错误信息弹出
     */
    ExcelTemplate.prototype.errMsgShow = function (data) {
        this.dialog.errMsgOpen = true;
        this.errMsgData = data;
    };
    __decorate([
        Dependencies(SortService)
    ], ExcelTemplate.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], ExcelTemplate.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ImportTemplateService)
    ], ExcelTemplate.prototype, "importTemplateService", void 0);
    __decorate([
        State
    ], ExcelTemplate.prototype, "principalList", void 0);
    __decorate([
        State
    ], ExcelTemplate.prototype, "userData", void 0);
    ExcelTemplate = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NewExcelTemplate: NewExcelTemplate,
                RelateNameDialog: RelateNameDialog,
                ExcelMerge: ExcelMerge,
                ExcelRowConvertCell: ExcelRowConvertCell
            }
        })
    ], ExcelTemplate);
    return ExcelTemplate;
}(Vue));
export default ExcelTemplate;
