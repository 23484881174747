var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import axios from "axios";
import Qs from "qs";
import app from "~/config/app.config";
import store from "~/store";
import { Observable, empty as ObservableEmpty } from "rxjs";
import { StorageService } from "~/utils/storage.service";
import { SortService } from "~/utils/sort.service";
import { Message, MessageBox, Notification } from "element-ui";
import router from "~/router";
import { CommonService } from "./common.service";
var getType = ["GET", "DELETE"]; // 使用GET请求类型
var errorMessage = {
    systemError: "系统异常，请稍后重试.",
    serverError: "服务端异常,请稍后重试.",
    netError: "服务端连接异常，请检查服务端状态.",
    serverException: "该服务接口处理异常,请联系管理员",
};
var awaitTimeout = function (timeout) {
    return new Promise(function (reslove) {
        setTimeout(reslove, timeout);
    });
};
var NetService = /** @class */ (function () {
    function NetService() {
        this.axiosInstance = axios.create({
            baseURL: app.url.server,
            timeout: app.timeout,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
    }
    NetService.generateRequestUrl = function (_a, append, sort) {
        var service = _a.service, controller = _a.controller, action = _a.action, url = _a.url;
        if (append === void 0) { append = []; }
        // 自定义url优先级最高
        if (url)
            return url;
        // 进行url拼接
        if (controller) {
            var targetUrl = __spreadArrays([service, "api", controller, action], append).filter(function (x) { return x; }).join("/");
            // 开启排序
            if (sort) {
                targetUrl += "?" + SortService.stringify(sort);
            }
            return targetUrl;
        }
        else {
            throw new Error("server配置异常,请检查对应server配置");
        }
    };
    /**
     * 生成头部信息
     */
    NetService.prototype.generateRequestHeader = function (headers) {
        var token = StorageService.getItem("userToken") || "";
        if (token) {
            return Object.assign({
                "X-UserToken": token,
            }, headers);
        }
        else {
            return headers || {};
        }
    };
    /**
     * 过滤空数据
     * @param data
     */
    NetService.prototype.filterEmptyData = function (data) {
        Object.entries(data)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            // 过滤空字符串
            if (value === undefined || value === "") {
                return true;
            }
            // 过滤空数组
            if (value instanceof Array && (value.length === 0 || value.every(function (x) { return x === ""; }))) {
                return true;
            }
        })
            .forEach(function (_a) {
            var key = _a[0], value = _a[1];
            delete data[key];
        });
        return data;
    };
    NetService.prototype.changeLoadingState = function (loading, state) {
        // 关闭等待
        if (loading && loading.state !== undefined) {
            loading.state = state;
        }
    };
    /**
     * 异常处理
     */
    NetService.prototype.catchHandle = function (options, observer) {
        var _this = this;
        return function (ex) {
            var error = {
                msg: "",
            };
            // 处理网络异常
            if (!ex.request) {
                error.msg = errorMessage.systemError;
                console.error("网络请求错误");
            }
            else if (!ex.response) {
                error.msg = errorMessage.netError;
                console.error("请检查服务状态");
            }
            // 关闭等待
            _this.changeLoadingState(options.loading, false);
            // 有网络异常则抛出错误信息
            if (error.msg) {
                Message.error(error.msg);
                return ObservableEmpty();
            }
            error.msg = errorMessage.serverError;
            // 错误类型检测
            switch (ex.response.status) {
                case 400: {
                    //如果返回特定错误，提示并关闭当前页面
                    if (ex.response.data.message === "case.type.is.change") {
                        MessageBox.alert("此案件当前调收方式或调收员已变更，您无权查看", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: function (action) {
                                window.close();
                                return;
                            },
                        });
                    }
                    else if (ex.response.data.message === "case.is.done") {
                        MessageBox.alert("此案件已退案", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: function (action) {
                                window.close();
                                return;
                            },
                        });
                    }
                    else if (ex.response.data.message === "case.is.stop") {
                        MessageBox.alert("此案件已停调", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: function (action) {
                                window.close();
                                return;
                            },
                        });
                    }
                    else if (ex.response.data.message === "case.is.public") {
                        MessageBox.alert("此案件当前是公共案件", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: function (action) {
                                window.close();
                                return;
                            },
                        });
                    }
                    else if (ex.response.data.message === "case.is.clear") {
                        MessageBox.alert("此案件已结清", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: function (action) {
                                window.close();
                                return;
                            },
                        });
                    }
                    else {
                        var data = ex.response.data || {};
                        error.msg = "";
                        data.errors.forEach(function (x) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (x.defaultMessage) {
                                    Notification.error(x.defaultMessage);
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        error.params = data.errors.join(";");
                    }
                    break;
                }
                case 403: {
                    if (!store.state.tokenExpire) {
                        store.commit("updateTokenExpire", true);
                    }
                    else {
                        break;
                    }
                    if (!options.skipAuth) {
                        MessageBox.alert("用户登录过期,请重新登录", "提示", {
                            confirmButtonText: "确定",
                            callback: function () {
                                router.push("/");
                            },
                        });
                    }
                    break;
                }
                case 500: {
                    Notification.error(errorMessage.serverException);
                    break;
                }
                default: {
                    Notification.error(errorMessage.systemError);
                    break;
                }
            }
            observer.error(error);
        };
    };
    /**
     * 发送网络请求信息
     * @param param0
     */
    NetService.prototype.send = function (options) {
        var _this = this;
        var data = Object.assign({}, options.data);
        var postData;
        var getData;
        var method = options.server.type || "GET";
        var url = NetService.generateRequestUrl(options.server, options.append, options.sort);
        var headers = this.generateRequestHeader(options.headers);
        var responseType = options.download ? "blob" : "";
        // 分页检测
        if (options.page) {
            data = Object.assign(data, options.page.getConfig());
        }
        // loading state 处理
        this.changeLoadingState(options.loading, true);
        // 判断参数类型
        getType.indexOf(method) > -1 ? (getData = this.filterEmptyData(data)) : (postData = data);
        // 创建待观察对象
        var observable = Observable.create(function (observer) {
            _this.axiosInstance
                .request({
                method: method,
                url: url,
                headers: headers,
                data: postData,
                params: getData,
                responseType: responseType,
                paramsSerializer: function (params) {
                    return Qs.stringify(params, {
                        arrayFormat: "repeat",
                        skipNulls: true,
                        allowDots: true,
                    });
                },
            })
                .then(function (_a) {
                var data = _a.data, responseHeader = _a.headers;
                // 关闭等待
                _this.changeLoadingState(options.loading, false);
                if (options.download) {
                    var fileName = options.fileAliase || _this.getDownloadFileName(responseHeader["content-disposition"]);
                    CommonService.downloadFileByStream(data, fileName);
                    return observer.next(fileName);
                }
                // 分页数据处理
                if (options.page && data.content) {
                    options.page.update(data);
                    data = data.content;
                }
                observer.next(data);
            })
                .catch(_this.catchHandle(options, observer));
        });
        return observable;
    };
    NetService.prototype.getDownloadFileName = function (disposition) {
        var _a = disposition.split(";"), fileName = _a[1];
        var fileExt = fileName.split(".").reverse()[0];
        fileName = decodeURI(fileName.slice(9, fileName.lastIndexOf(".")));
        fileName = [fileName, fileExt].join(".");
        return fileName;
    };
    return NetService;
}());
export { NetService };
