var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { ResourceService } from "~/services/manage-service/resource.service";
import { CommonService } from "~/utils/common.service";
import { Emit, Watch, Model, Prop } from "vue-property-decorator";
var ResourceTree = /** @class */ (function (_super) {
    __extends(ResourceTree, _super);
    function ResourceTree() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.treeData = [];
        _this.resourceDataSet = [];
        return _this;
    }
    ResourceTree.prototype.emitCheckedMenuIds = function (value) { };
    ResourceTree.prototype.emitCurrentKey = function (id) { };
    ResourceTree.prototype.onCheckedMenuIdsChange = function (val) {
        var tree = this.$refs.tree;
        tree.setCheckedKeys(val);
    };
    ResourceTree.prototype.onCurrentChange = function (data) {
        this.emitCurrentKey(data.id);
    };
    ResourceTree.prototype.onCheckChange = function () {
        var tree = this.$refs.tree;
        var nodes = tree.getCheckedNodes(true);
        this.emitCheckedMenuIds(nodes.map(function (v) { return v.id; }));
    };
    ResourceTree.prototype.createdTreeData = function (data) {
        var option = {
            keyName: 'id',
            parentKeyName: 'parent',
            sortKey: 'sort'
        };
        var tmpTreeData = CommonService.generateTreeData(data, option);
        tmpTreeData.forEach(function (v) { return v.parent = 'root'; });
        var root = [{
                id: 'root',
                parent: null,
                label: '贷后调解管理系统',
                type: 'ROOT',
                sort: 0,
                children: tmpTreeData
            }];
        this.treeData = root;
    };
    ResourceTree.prototype.mounted = function () {
        var _this = this;
        this.resourceService.findAllResource().subscribe(function (data) {
            var dataList = data.map(function (v) {
                return {
                    _checked: false,
                    id: v.id,
                    parent: v.parent,
                    label: v.name,
                    type: v.type,
                    sort: v.sort
                };
            });
            _this.resourceDataSet = dataList.map(function (v) { return (__assign({}, v)); });
            _this.createdTreeData(dataList);
        });
    };
    ResourceTree.prototype.getCheckedNodes = function () {
        var tree = this.$refs.tree;
        return tree.getCheckedNodes(false, true).map(function (x) { return x.id; }).filter(function (v) { return v !== 'root'; });
    };
    __decorate([
        Dependencies(ResourceService)
    ], ResourceTree.prototype, "resourceService", void 0);
    __decorate([
        Prop()
    ], ResourceTree.prototype, "cureentKey", void 0);
    __decorate([
        Model('check-menu-change')
    ], ResourceTree.prototype, "checkedMenuIds", void 0);
    __decorate([
        Emit('check-menu-change')
    ], ResourceTree.prototype, "emitCheckedMenuIds", null);
    __decorate([
        Emit("update:cureentKey")
    ], ResourceTree.prototype, "emitCurrentKey", null);
    __decorate([
        Watch('checkedMenuIds', { deep: true })
    ], ResourceTree.prototype, "onCheckedMenuIdsChange", null);
    ResourceTree = __decorate([
        Component({
            components: {}
        })
    ], ResourceTree);
    return ResourceTree;
}(Vue));
export default ResourceTree;
