import collectionCaseController from '~/config/server/domain-service/collection-case.controller';
import caseApplyOperateController from '~/config/server/domain-service/case-apply-operate.controller';
import personalController from '~/config/server/domain-service/personal.controller';
import caseFollowController from '~/config/server/domain-service/case-follow.controller';
import paymentRecordController from '~/config/server/domain-service/payment-record.controller';
import distributeCaseController from '~/config/server/domain-service/distribute-case.controller';
import assistCollectionCaseController from '~/config/server/domain-service/assist-collection-case.controller';
import publicCollectionCaseController from '~/config/server/domain-service/public-collection-case.controller';
import caseDetailController from '~/config/server/domain-service/case-detail.controller';
import historyCaseController from '~/config/server/domain-service/history-case.controller';
import caseTransferLogController from '~/config/server/domain-service/case-transfer-log.controller';
import caseAnnexController from '~/config/server/domain-service/case-annex.controller';
export var domainService = {
    collectionCaseController: collectionCaseController,
    caseApplyOperateController: caseApplyOperateController,
    personalController: personalController,
    caseFollowController: caseFollowController,
    paymentRecordController: paymentRecordController,
    distributeCaseController: distributeCaseController,
    assistCollectionCaseController: assistCollectionCaseController,
    publicCollectionCaseController: publicCollectionCaseController,
    caseDetailController: caseDetailController,
    historyCaseController: historyCaseController,
    caseTransferLogController: caseTransferLogController,
    caseAnnexController: caseAnnexController
};
