var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import ApplicationDataAgree from "~/components/assistant-manage/application-data/application-data-agree.vue";
import ApplicationDataOperate from "~/components/assistant-manage/application-data/application-data-operate.vue";
import ApplicationDataRefuse from "~/components/assistant-manage/application-data/application-data-refuse.vue";
import { CaseApplyManageService } from "~/services/business-service/case-apply-manage.service";
import { ApprovalExportManageService } from "~/services/dataimp-service/approval-export-manage.service.ts";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import ProcessApprove from "~/components/assistant-manage/process-approve.vue";
var AccFlowApproval = /** @class */ (function (_super) {
    __extends(AccFlowApproval, _super);
    function AccFlowApproval() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.applicationModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            certificateNo: "",
            applyDate: [],
            caseNumber: "",
        };
        // 默认激活页面
        _this.activeName = "pending-operate";
        _this.rowData = {};
        _this.approvalRules = {
            result: [{ required: true, message: "请选择审批结果", trigger: "change" }],
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: "请输入至少4位的身份证号", trigger: "blur" }],
        };
        _this.dialog = {
            approvedialog: false,
        };
        _this.selectedList = [];
        return _this;
    }
    AccFlowApproval.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    AccFlowApproval.prototype.mounted = function () {
        this.refreshData();
    };
    //切换tab页面刷新对应的页面
    AccFlowApproval.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.certificateNoSearchFelid();
        this.refreshData();
    };
    AccFlowApproval.prototype.handleResetForm = function () {
        this.applicationModel.principalId = "";
    };
    /**
     * 弹出框
     */
    AccFlowApproval.prototype.approvedMeth = function (rowdata) {
        this.dialog.approvedialog = true;
        this.rowData = rowdata;
    };
    //刷新数据
    AccFlowApproval.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    AccFlowApproval.prototype.certificateNoSearchFelid = function () {
        this.applicationModel = {
            principalName: "",
            batchNumber: "",
            personalName: "",
            applyRealName: "",
            certificateNo: "",
            applyDate: [],
        };
    };
    AccFlowApproval.prototype.exportCase = function () {
        var _this = this;
        var handledOperate = this.$refs["handled-operate"];
        if (handledOperate.selectionList.length == 0) {
            this.$message("请选择要导出的案件!");
            return;
        }
        var processApplicationIds = handledOperate.selectionList.map(function (v) { return v.processApplicationId; });
        var exportModel = {
            flowType: "CHECK_MATERIAL_APPLY",
            processApplicationIds: processApplicationIds,
        };
        this.$confirm("您确定要导出所选案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.approvalExportManageService.approvalMessageExport(exportModel, _this.loading).subscribe(function (data) {
                _this.$message.success("导出成功"), _this.refreshData();
            }, function (err) { });
        })
            .catch(function () { });
    };
    __decorate([
        Dependencies(PageService)
    ], AccFlowApproval.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], AccFlowApproval.prototype, "caseApplyManageService", void 0);
    __decorate([
        Dependencies(ApprovalExportManageService)
    ], AccFlowApproval.prototype, "approvalExportManageService", void 0);
    AccFlowApproval = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ApplicationDataAgree: ApplicationDataAgree,
                ApplicationDataOperate: ApplicationDataOperate,
                ApplicationDataRefuse: ApplicationDataRefuse,
                PrincipalBatch: PrincipalBatch,
                ProcessApprove: ProcessApprove,
            },
        })
    ], AccFlowApproval);
    return AccFlowApproval;
}(Vue));
export default AccFlowApproval;
