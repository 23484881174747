var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Watch } from "vue-property-decorator";
import SvgIcon from "~/components/common/svg-icon.vue";
import { namespace } from "vuex-class";
import { CaseWaringInfoService } from "~/services/business-service/case-waring-info.service";
var collectionManageModule = namespace("collection-manage");
var TopMessage = /** @class */ (function (_super) {
    __extends(TopMessage, _super);
    function TopMessage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.announcement = null;
        _this.messageList = [];
        _this.index = 0;
        _this.hanldId = null;
        return _this;
    }
    TopMessage.prototype.onCaseIdChanged = function (val) {
        val && this.registerClock();
    };
    /**
     * 时钟定时切换公告
     */
    TopMessage.prototype.registerClock = function () {
        var _this = this;
        window.clearTimeout(this.hanldId);
        this.caseWaringInfoService.queryCaseWarningInfo(this.caseId).subscribe(function (data) {
            _this.messageList = data;
            _this.index = 0;
            _this.getNextMsg();
        });
    };
    TopMessage.prototype.getNextMsg = function () {
        var _this = this;
        if (this.index === this.messageList.length)
            this.index = 0;
        this.announcement = this.messageList[this.index];
        this.hanldId = setTimeout(function () {
            _this.announcement = "";
            _this.index++;
            _this.hanldId = setTimeout(_this.getNextMsg, 21000);
        }, 1000);
    };
    __decorate([
        Dependencies(CaseWaringInfoService)
    ], TopMessage.prototype, "caseWaringInfoService", void 0);
    __decorate([
        collectionManageModule.State
    ], TopMessage.prototype, "caseId", void 0);
    __decorate([
        Watch('caseId', { immediate: true })
    ], TopMessage.prototype, "onCaseIdChanged", null);
    TopMessage = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], TopMessage);
    return TopMessage;
}(Vue));
export default TopMessage;
