var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Layout, Dependencies } from '~/core/decorator';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import { State, Getter } from 'vuex-class';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { SysParamService } from '~/services/manage-service/sys-param.service';
import ModifyParameter from '~/components/system-manage/param-manage/modify-parameter.vue';
//@Auth(2016)
var SystemParameterManage = /** @class */ (function (_super) {
    __extends(SystemParameterManage, _super);
    function SystemParameterManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.queryModel = {
            code: '',
            status: ''
        };
        _this.sysParamDataSet = [];
        _this.dialog = {
            modifysysparamterDialog: false,
        };
        _this.modifyData = {};
        return _this;
    }
    SystemParameterManage.prototype.mounted = function () {
        this.refreshData();
    };
    SystemParameterManage.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    SystemParameterManage.prototype.refreshData = function () {
        var _this = this;
        this.sysParamService.findAllSysParam(this.queryModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.sysParamDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 修改
     */
    SystemParameterManage.prototype.modifyClick = function (scope) {
        this.modifyData = scope.row;
        this.dialog.modifysysparamterDialog = true;
    };
    /**
     * 修改open事件 ，获取要修改的当前行数据
     */
    SystemParameterManage.prototype.getmodifyData = function () {
        var _this = this;
        this.$nextTick(function () {
            var refresh = _this.$refs['refresh'];
            refresh.refresh(_this.modifyData);
        });
    };
    __decorate([
        State
    ], SystemParameterManage.prototype, "companyList", void 0);
    __decorate([
        Getter
    ], SystemParameterManage.prototype, "isSupperAdmin", void 0);
    __decorate([
        Dependencies(SysParamService)
    ], SystemParameterManage.prototype, "sysParamService", void 0);
    __decorate([
        Dependencies(PageService)
    ], SystemParameterManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SystemParameterManage.prototype, "sortService", void 0);
    __decorate([
        State
    ], SystemParameterManage.prototype, "userData", void 0);
    SystemParameterManage = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ModifyParameter: ModifyParameter,
            },
        })
    ], SystemParameterManage);
    return SystemParameterManage;
}(Vue));
export default SystemParameterManage;
