var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var ExportLetterService = /** @class */ (function (_super) {
    __extends(ExportLetterService, _super);
    function ExportLetterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 新建模板
     */
    ExportLetterService.prototype.createLetterTemplate = function (model, loading) {
        return this.netService.send({
            server: managementService.exportLetterController.createLetterTemplate,
            data: {
                templateName: model.templateName,
                type: model.type,
                principalId: model.principalId,
                principalName: model.principalName,
                modelFileId: model.modelFileId,
            },
            loading: loading
        });
    };
    /**
     * 修改模板
     */
    ExportLetterService.prototype.letterTemplateModify = function (model, loading) {
        return this.netService.send({
            server: managementService.exportLetterController.letterTemplateModify,
            data: {
                id: model.id,
                name: model.templateName,
                type: model.type,
                principalId: model.principalId,
                principalName: model.principalName,
                modelFileId: model.modelFileId,
            },
            loading: loading
        });
    };
    /**
     * 信函模板列表查询
     */
    ExportLetterService.prototype.getTemplateByPrin = function (model, page, loading) {
        return this.netService.send({
            server: managementService.exportLetterController.getTemplateByPrin,
            data: {
                principalId: model.principalId,
                templateName: model.templateName,
                type: model.type,
            },
            page: page,
            loading: loading
        });
    };
    /**
     * 删除模板
     */
    ExportLetterService.prototype.deleteLetter = function (id, loading) {
        return this.netService.send({
            server: managementService.exportLetterController.deleteLetter,
            data: {
                id: id,
            },
            loading: loading
        });
    };
    /**
     * 根据委托方查询模板
     */
    ExportLetterService.prototype.getTemplateByPrincipalId = function (principalId, loading) {
        return this.netService.send({
            server: managementService.exportLetterController.getTemplateByPrincipalId,
            data: {
                principalId: principalId,
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ExportLetterService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ExportLetterService.prototype, "createLetterTemplate", null);
    __decorate([
        Debounce()
    ], ExportLetterService.prototype, "letterTemplateModify", null);
    __decorate([
        Debounce()
    ], ExportLetterService.prototype, "deleteLetter", null);
    __decorate([
        Debounce()
    ], ExportLetterService.prototype, "getTemplateByPrincipalId", null);
    return ExportLetterService;
}(Service));
export { ExportLetterService };
