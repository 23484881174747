var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { SysParamService } from "~/services/manage-service/sys-param.service";
import { Emit } from "vue-property-decorator";
import FileUpload from "~/components/common/file-upload.vue";
var ModifyParameter = /** @class */ (function (_super) {
    __extends(ModifyParameter, _super);
    function ModifyParameter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.modifyParams = {
            id: "",
            status: "",
            value: "",
            remark: "",
            code: "",
            flag: ""
        };
        _this.fileList = [];
        _this.rules = {
            status: [{ required: true, message: "请选择状态", trigger: "change" }]
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    ModifyParameter.prototype.refreshList = function () { };
    /**
     * 取消
     */
    ModifyParameter.prototype.cancel = function () {
        var modifyForm = this.$refs["modify-form"];
        modifyForm.resetFields();
        this.fileList = [];
        //   关闭修改角色dialog
        this.$emit("close");
    };
    ModifyParameter.prototype.onUploadSuccess = function (id, data) {
        this.modifyParams.value = data.id;
    };
    /**
     * 修改
     */
    ModifyParameter.prototype.modifyCommit = function () {
        var _this = this;
        var modifyform = this.$refs["modify-form"];
        modifyform.validate(function (valid) {
            if (!valid)
                return false;
            _this.loading.state = true;
            _this.sysParamService.modifySysParam(_this.modifyParams, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshList();
                _this.cancel();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    /**
     * 获取修改行数据
     */
    ModifyParameter.prototype.refresh = function (modifyData) {
        this.modifyParams.code = modifyData.code;
        this.modifyParams.id = modifyData.id;
        this.modifyParams.status = modifyData.status;
        this.modifyParams.remark = modifyData.remark;
        this.modifyParams.value = modifyData.value;
        this.modifyParams.flag = modifyData.flag;
    };
    __decorate([
        Dependencies(SysParamService)
    ], ModifyParameter.prototype, "sysParamService", void 0);
    __decorate([
        Emit("refreshList")
    ], ModifyParameter.prototype, "refreshList", null);
    ModifyParameter = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], ModifyParameter);
    return ModifyParameter;
}(Vue));
export default ModifyParameter;
